import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';
import { memoize } from 'lodash';

export const getStandardLabourLinesTabs = async (id) => {
    let res = await getWithAuth(`StandardLabourLines`);
    return res;
};

export const setStandardLabourLinesTab = async (data) => {
    let res = await postWithAuth(`StandardLabourLines`, data);
    return res;
};

export const StandardLabourLineItems_AddUpdate = async (data) => {
    let res = await postWithAuth(`StandardLabourLines/StandardLabourLineItems_AddUpdate`, data);
    return res;
};

export const getStandardLabourLineByItemID = async (LineID) => {
    let res = await getWithAuth(`StandardLabourLines/StandardLabourLineItems_GetDetails?LineItemID=${LineID}`);
    return res;
};

export const getStandardLabourDropDown = async () => {
    let res = await getWithAuth(`StandardLabourLines/Dropdowns`);
    return res;
};
export const deleteLabourLine = async (id) => {
    let res = await postWithAuth(`StandardLabourLines/StandardLabourLineItems_DeleteItem?LineItemID=${id}`, {});
    return res;
};
