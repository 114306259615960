import React, { createContext, useEffect, useState } from 'react';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import LoaderState from '../../Loader/LoaderState';
import { getAllAdminDash } from '../../Services/Admin/AdminDashboardService';
import { getAllCustomerDashboard } from '../../Services/Customer/customerDashService';
import { getAllDriverDash } from '../../Services/Driver/driverDashboard';
import { getAllInternalDash } from '../../Services/InternalUser/InternalDashService';
import { getAllServiceAgentDash } from '../../Services/ServiceAgent/ServiceAgent';
import ToastState from '../../Toaster/ToastState';
import DialogState from './DialogState';

export const AppContext = createContext();

export const CommonModals = {
    ChangePassword: 'ChangePassword',
    ForgotPassword: 'ForgotPassword',
    ResetPassword: 'ResetPassword',
    GenericModal: 'GenericModal'
};

export const AppContextProvider = (props) => {
    let loaderState = LoaderState();
    let toastState = ToastState();
    let dialogState = DialogState();
    const [commonState, setCommonState] = useState({
        dashBoardData: null,
        isReload: null
    });
    const { userRoles } = useLoginInfo();

    const isAdmin = userRoles.includes('admin');
    const isServiceagent = userRoles.includes('servicearea');
    const isInternalUser = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isDriver = userRoles.includes('driver');

    useEffect(() => {
        getDashBoardData();
    }, [commonState.isReload]);

    const getDashBoardData = async () => {
        let res = {};
        if (isDriver) {
            res = await getAllDriverDash();
        }
        if (isInternalUser) {
            res = await getAllInternalDash();
        }
        if (isServiceagent) {
            res = await getAllServiceAgentDash();
        }
        if (isAdmin) {
            res = await getAllAdminDash();
        }
        if (isCustomer) {
            res = await getAllCustomerDashboard();
        }

        setCommonState((st) => ({
            ...st,
            dashBoardData: res
        }));
    };

    const dashBoardDataReload = () => {
        setCommonState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    return (
        <AppContext.Provider value={{ loaderState, toastState, dialogState, getDashBoardData, commonState, dashBoardDataReload }}>
            <>{props.children}</>
        </AppContext.Provider>
    );
};
