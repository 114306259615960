import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import './ckEditor.scss';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    customeStyle: {
        '&..ck.ck-editor__editable_inline': {
            height: (props) => `${props.height ? props.height : 230}px`,
            overflow: 'auto',
            padding: '0 var(--ck-spacing-standard)',
            border: '1px solid transparent'
        }
    }
});
export const CustomeEditor = (props) => {
    const classes = useStyles(props);

    const { height, ...otherProps } = props;

    return <CKEditor className={classes.customeStyle} editor={ClassicEditor} {...otherProps} />;
};

export default CustomeEditor;
