import React, { useState, useContext } from 'react';
import { DatePicker, CustomChip, SpeedDials } from '../../../../../Core/Controls';
import CustomizedTables from '../../../../../Core/Controls/Table';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddEditDepots from './addEditDepots';
import { DepotProvider, DepotScreenContext } from './depotProvider';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import { formatters } from '../../../../../Core/Controls';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import StickyNotes from '../../Notes/stickyNotes';

const EditDepotDash = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const {
        scheduleFields,
        handleSubmit,
        scheduleDetailsChange,
        handleCheckbox,
        closeModal,
        handledepotFields,
        handledepotCheckbox,
        depotFields,
        handleActive
    } = useContext(DepotScreenContext);

    const [state, setstate] = useState({});
    const handleAddPopUp = (id, name) => {
        setstate((st) => ({ ...st, [name]: true, VehicleID: id }));
    };

    const isValue = (val) => {
        return val ? val : 'N/A';
    };
    const newLineValue = (val) => {
        return val
            ? val.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                      {line}
                      <br />
                  </React.Fragment>
              ))
            : 'N/A';
    };
    const isYesNo = (val) => {
        return val ? 'Yes' : 'No';
    };
    const EmptyVal = (val) => {
        return <div>&nbsp;</div>;
    };
    const tableData = [
        {
            name1: 'Address',
            value1: formatters.addressFormatter(
                depotFields.address1,
                depotFields.address2,
                depotFields.depotTown,
                depotFields.depotPostCode,
                depotFields.depotCounty
            )
            // name2: 'Telephone',
            // value2: formatters.phoneNumberFormatter(depotFields.depotTelephone),
            // name3: 'Contact Number',
            // value3: formatters.phoneNumberFormatter(depotFields.depotContact),
            // name4: 'Email',
            // value4: formatters.mailFormatter(depotFields.depotEmail)
        },
        { name1: 'Notes', value1: newLineValue(depotFields.depotNotes) }
        // {
        // name1: 'Address',
        // value1: formatters.addressFormatter(
        //     depotFields.address1,
        //     depotFields.address2,
        //     depotFields.depotTown,
        //     depotFields.depotPostCode,
        //     depotFields.depotCounty
        // ),
        // value1: depotFields.address1 + ", " + depotFields.address2 + ", " + depotFields.depotTown + ", " + depotFields.depotPostCode + ", " + depotFields.depotCounty,
        // name1: 'Customers Ref',
        // value1: isValue(depotFields.depotCustomersRef),
        // name2: 'Primary Service Area',
        // value2: isValue(depotFields.PrimaryServiceAgent),
        // name3: 'Display ISO Week Number?',
        // value3: isYesNo(depotFields.displayISOWeek),
        // name4: '-',
        // value4: EmptyVal
        // },
        // {
        //     name1: 'Max Schedule Merge Range',
        //     value1: isValue(depotFields.mergeRange),
        //     name2: 'Schedule Planner Email',
        //     value2: formatters.mailFormatter(depotFields.depotSchedulePlannerEmail),
        //     name3: 'Schedule Booking Mode',
        //     value3: isValue(depotFields.depotScheduleBookingMode),
        //     name4: 'Merge Schedule?',
        //     value4: isYesNo(depotFields.mergeSchedules)
        // },
        // {
        //     name1: 'Sunday',
        //     value1: isYesNo(depotFields.depotBookingPreferenceSunday),
        //     name2: 'Monday',
        //     value2: isYesNo(depotFields.depotBookingPreferenceMonday),
        //     name3: 'Tuesday',
        //     value3: isYesNo(depotFields.depotBookingPreferenceTuesday),
        //     name4: 'Wednesday',
        //     value4: isYesNo(depotFields.depotBookingPreferenceWednesday),
        //     name5: 'Thursday',
        //     value5: isYesNo(depotFields.depotBookingPreferenceThursday),
        //     name6: 'Friday',
        //     value6: isYesNo(depotFields.depotBookingPreferenceFriday),
        //     name7: 'Saturday',
        //     value7: isYesNo(depotFields.depotBookingPreferenceSaturday)
        // }
        // {
        //     name1: 'Third Party Planner Email Address',
        //     value1: formatters.mailFormatter(depotFields.depotThirdPartyPlannerEmailAddress),
        //     name2: 'Additional Planning Emails',
        //     value2: formatters.mailFormatter(depotFields.depotAdditionalPlanningEmails),
        //     name3: 'CPC Holder Email',
        //     value3: formatters.mailFormatter(depotFields.cPCHolderEmail),
        //     name4: 'CPC Holder Name',
        //     value4: isValue(depotFields.cPCHolderName)
        // },
        // {
        //     name1: 'Secondary Email',
        //     value1: formatters.mailFormatter(depotFields.secondaryEmail),
        //     name2: 'Contact Mobile',
        //     value2: formatters.phoneNumberFormatter(depotFields.depotContactMobile),
        //     name3: 'Secondary Contact Number',
        //     value3: formatters.phoneNumberFormatter(depotFields.depotSecondaryContact),
        //     name4: 'PCN Email',
        //     value4: formatters.mailFormatter(depotFields.depotPCNEmail)
        // },
        // {
        //     name1: 'Account Name',
        //     value1: isValue(depotFields.depotAccountName),
        //     name2: 'Account Email',
        //     value2: isValue(depotFields.depotAccountEmail),
        //     name3: 'Add Paperwork Received?',
        //     value3: isYesNo(depotFields.addPaperworkReceived),
        //     name4: 'Traffic Style Colour Coding?',
        //     value4: isYesNo(depotFields.trafficStyleColourCoding)
        // },
        // {
        //     name1: 'Access Code',
        //     value1: isValue(depotFields.depotGateAccessCode),
        //     name2: 'Opening Times',
        //     value2: isValue(depotFields.depotOpeningTimes),
        //     name3: 'Key Holder1 Name',
        //     value3: isYesNo(depotFields.depotKeyHolder1Name),
        //     name4: 'Key Holder1 Number',
        //     value4: isYesNo(depotFields.depotKeyHolder1Number)
        // },
        // {
        //     name1: 'Key Holder2 Name',
        //     value1: isValue(depotFields.depotKeyHolder2Name),
        //     name2: 'Key Holder2 Number',
        //     value2: isValue(depotFields.depotKeyHolder2Number)
        // }
    ];
    const Header = () => {
        return (
            <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                <Grid xs={3} sm={4} md={6} item>
                    <Typography variant="h6" color="secondary">
                        Depot Details: {depotFields.name}
                    </Typography>
                </Grid>
                <Grid xs={9} sm={8} md={6} item>
                    <div className="dash-controller-btn" style={{ float: 'right' }}>
                        <FileUploaderButton recordId={props.depotID} fileCount={depotFields.fileCount} fileUploaderConfigName="DepotScreen" />
                        &nbsp;&nbsp;
                        <CustomChip
                            className="btn-m"
                            icon={AddIcon}
                            label="Edit Depot"
                            onClick={() => handleAddPopUp(null, 'showDepotScreen')}
                            // disabled={!getAccessParam['CanUpdateDepots']}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid className="controller-screen" style={{ marginTop: '10px' }}>
            <StickyNotes frompage="Customers" customerID={depotFields?.depotCustomerID} bgColor="#ffcf89" />
            <StickyNotes frompage="Depots" depotID={props?.depotID || state?.depotID} variant="filled" />

            {state.showDepotScreen ? (
                <AddEditDepots depotID={props.depotID} onFormClose={props.onFormClose} />
            ) : (
                <CustomizedTables tableHeader={Header()} tableData={tableData} />
            )}
        </Grid>
    );
};

const Screen = (props) => {
    return (
        <DepotProvider {...props}>
            <EditDepotDash {...props} />
        </DepotProvider>
    );
};
export default Screen;
