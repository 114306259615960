import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { getServiceAgentById } from '../../../../Core/Services/InternalUser/ServiceAgent/serviceAgentService';
import { formatters } from '../../../../Core/Controls';
import { CircularProgress } from '@material-ui/core';

function ServiceAgentDetails(props) {
    const [state, setState] = useState({
        serviceAgentID: null,
        serviceAgentName: null,
        serviceAgentAddress1: null,
        serviceAgentAddress2: null,
        serviceAgentCounty: null,
        serviceAgentCity: null,
        serviceAgentPostCode: null,
        serviceAgentPhone: null,
        serviceAgentEmail: null,
        loader: false,
    });

    useEffect(async () => {
        if (props.serviceAgentID) {
            let res = await getServiceAgentById(props.serviceAgentID);
            const data = res.data.details[0];
            setState((st) => ({
                ...st,
                serviceAgentID: data.serviceAgentID,
                serviceAgentName: data.serviceAgentName,
                serviceAgentAddress1: data.serviceAgentAddress1,
                serviceAgentAddress2: data.serviceAgentAddress2,
                serviceAgentCounty: data.serviceAgentCounty,
                serviceAgentCity: data.serviceAgentCity,
                serviceAgentPostCode: data.serviceAgentPostCode,
                serviceAgentPhone: data.serviceAgentPhone,
                serviceAgentEmail: data.serviceAgentEmail,
                loader: true
            }));
        }
    }, []);

    return (
        state.loader ? (
            <Grid container direction="row" spacing={2} style={{ minWidth: '600px', minHeight: '300px' }}>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Service Area Name</FormHelperText>
                    <Typography>{state.serviceAgentName}</Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Address</FormHelperText>
                    <Typography>
                        {formatters.addressFormatter(
                            state.serviceAgentAddress1,
                            state.serviceAgentAddress2,
                            state.serviceAgentCity,
                            state.serviceAgentCounty,
                            state.serviceAgentPostCode
                        )}
                    </Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Email</FormHelperText>
                    <Typography variant="button">{formatters.mailFormatter(state.serviceAgentEmail)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>Phone</FormHelperText>
                    <Typography variant="button">{formatters.phoneNumberFormatter(state.serviceAgentPhone)}</Typography>
                </Grid>
            </Grid>
        ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '500px', height: '300px' }}>
                <CircularProgress style={{ margin: 'auto' }} />
            </div>
        )
    );
}

export default ServiceAgentDetails;
