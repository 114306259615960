import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { getServiceAgentById } from '../../../../Core/Services/InternalUser/ServiceAgent/serviceAgentService';
import { formatters } from '../../../../Core/Controls';
import { CircularProgress } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { SecondaryButton } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { DatePicker } from '../../../../Core/Controls';
import moment from 'moment/moment';
import { PortableWifiOffSharp } from '@material-ui/icons';
import { archiveVehicle, unArchiveVehicle } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../Core/Hooks/useToast';

function ArchiveVehicleModal(props) {
    const { showToastError, showToastSuccess } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        date: moment().format('YYYY-MM-DD'),
        VehicleID: props.VehicleID,
        VehicleActive: props.VehicleActive,

        errors: {}
    });

    useEffect(async () => {}, []);

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { date } = state;
        let formIsValid = true;
        let errors = {};
        if (!date || date == '') {
            errors.date = 'Date is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        if (validations()) {
            setBtnLoader(true);
            const { date, VehicleID, VehicleActive } = state;

            let res = {};

            if (VehicleActive) {
                res = await archiveVehicle(VehicleID, date);
            } else {
                res = await unArchiveVehicle(VehicleID, date);
            }

            if (res?.success) {
                showToastSuccess(res?.data?.message);
                props.onClose(res);
            } else {
                showToastError(res?.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <DialogComp title={`${props.VehicleActive ? 'Archive' : 'Unarchive'} Vehicle?`} onClose={() => props.onClose(false)} maxWidth="lg" overflow="auto">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputLabel required shrink>
                        Date
                    </InputLabel>
                    <DatePicker placeholder="Date" value={state.date} onChange={inputChange} name="date" />
                    <FormHelperText error>{state.errors.date}</FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                        {props.VehicleActive ? 'Archive' : 'Unarchive'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </DialogComp>
    );
}

export default ArchiveVehicleModal;
