import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { SecondaryButton, PrimaryButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import JobEstimateModal from './JobEstimate';
import JobQueryModal from './JobQuery';
import AcceptEstimateModal from './AcceptEstimateModal';
import SubmitWarnngModal from './SubmitWarning';
import DeleteJobModal from '../deleteJobModal';
import { useHistory } from 'react-router-dom';
import { CustomChip } from '../../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const CancelButton = (props) => {
    return (
        // <Link
        //     to={
        //         props.position == 'm'
        //             ? '/maintenance/schedules'
        //             : props.position == 's'
        //             ? '/allvehicleschedules'
        //             : props.position == 'jobs'
        //             ? '/controller/Jobs'
        //             : props.position == 'defects'
        //             ? '/maintenance/defects'
        //             : props.position == 'breakdown'
        //             ? '/maintenance/breakdowns/:all'
        //             : props.position == 'jobrem'
        //             ? '/controller/jobreminder'
        //             : `/controller/editVehicle/${props.vehicleID}/${props.jobRegNumber}`
        //     }
        //     style={{ textDecoration: 'none' }}
        // >
        <SecondaryButton onClick={props.goPreviousScreen}>Cancel</SecondaryButton>
        // </Link>
    );
};

function StatusBasedButton(props) {
    const [state, setState] = useState({
        showJobQuery: false,
        showEstimate: false,
        showSubmitCostModal: false,
        title: ''
    });

    const { userRoles } = useLoginInfo();
    const isServiceagent = userRoles.includes('servicearea');
    const isInternalUser = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const { status, vehicleID, handleData, data } = props;
    const history = useHistory();

    const handelPopup = async (title, setShow) => {
        if (data.jobOrderTotal != 0 && title == 'Submit Cost') {
            setShow = 'showSubmitCostModal';
        }
        setState((st) => ({
            ...st,
            title: title,
            [setShow]: true
        }));
    };
    const showSubmitModal = (res) => {
        setState((st) => ({
            ...st,
            showSubmitCostModal: true,
            showSubmit: false
        }));
    };
    const closeTheDialog = async (res) => {
        setState((st) => ({
            ...st,
            showJobQuery: false,
            showEstimate: false,
            showSubmitCostModal: false,
            showSubmit: false
        }));
        res && props.refreshScreen(res);
    };

    const closeDeleteDefectModal = (res) => {
        setState((st) => ({
            ...st,
            showJobDeleteModal: false
        }));
        if (res) {
            history.goBack();
        }
    };

    const CostomButton = (btnLable, modalName, icon) => {
        return (
            <>
                {props.btnType == 'chip' ? (
                    <Grid item>
                        <CustomChip
                            icon={icon}
                            label={`${btnLable}`}
                            onClick={() => {
                                handelPopup(`${btnLable}`, `${modalName}`);
                            }}
                            style={{ fontWeight: 'bold' }}
                        />
                    </Grid>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            handelPopup(`${btnLable}`, `${modalName}`);
                        }}
                    >
                        {`${btnLable}`}
                    </SecondaryButton>
                )}
            </>
        );
    };

    return (
        <Grid container item spacing={0} className="controller-screen">
            {isServiceagent ? (
                <Grid container item xs={12} className="btn-hw">
                    {status !== 'AU' && (
                        <CancelButton
                            vehicleID={vehicleID}
                            jobRegNumber={data.jobRegNumber}
                            position={props.position}
                            goPreviousScreen={props.goPreviousScreen}
                        />
                    )}
                    {(status == 'CQ' || status == 'IQ') && props.otherOptionsBtnShow ? CostomButton('Reply Query', 'showJobQuery', AddIcon) : null}
                    {(status == 'AA' || status == 'JP' || status == 'ER' || status == 'EP' || status == 'AQ' || status == 'AR' || status == 'JC') &&
                    props.otherOptionsBtnShow
                        ? CostomButton('Send Query', 'showJobQuery', AddIcon)
                        : null}
                    {(status == 'AA' || status == 'JP') && props.otherOptionsBtnShow ? CostomButton('Submit Cost', 'showSubmit', AddIcon) : null}
                    {status == 'ER' && props.otherOptionsBtnShow ? CostomButton('Submit Estimate', 'showEstimate', AddIcon) : null}
                    {status == 'AA' && props.otherOptionsBtnShow ? CostomButton('Set In Progress', 'showEstimate', AddIcon) : null}
                </Grid>
            ) : null}
            {isInternalUser ? (
                <Grid container item xs={12} className="btn-hw">
                    {props.isShownHeadrer ? null : (
                        <>
                            {(status == 'AU' ||
                                status == 'AA' ||
                                status == 'JP' ||
                                status == 'ER' ||
                                status == 'AQ' ||
                                status == 'CQ' ||
                                status == 'IQ' ||
                                status == 'AR') &&
                            props.showSave ? (
                                <PrimaryButton className="btn-text" onClick={handleData}>
                                    Save
                                </PrimaryButton>
                            ) : null}
                            <CancelButton
                                vehicleID={vehicleID}
                                jobRegNumber={data.jobRegNumber}
                                position={props.position}
                                goPreviousScreen={props.goPreviousScreen}
                            />
                        </>
                    )}
                    {(status == 'CQ' || status == 'AQ') && props.otherOptionsBtnShow ? CostomButton('Reply Query', 'showJobQuery', AddIcon) : null}
                    {(status == 'AA' || status == 'JP' || status == 'ER' || status == 'EP' || status == 'IQ' || status == 'AR') && props.otherOptionsBtnShow
                        ? CostomButton('Send Query', 'showJobQuery', AddIcon)
                        : null}
                    {status == 'EP' && props.otherOptionsBtnShow ? CostomButton('Estimate Accept', 'showEstimate', AddIcon) : null}
                    {(status == 'AA' || status == 'JP') && props.otherOptionsBtnShow ? CostomButton('Request Estimate', 'showEstimate', AddIcon) : null}
                    {status == 'AR' && props.otherOptionsBtnShow ? CostomButton('Approve Order', 'showEstimate', AddIcon) : null}
                    {status !== 'JD' && props.otherOptionsBtnShow ? CostomButton('Delete Job', 'showJobDeleteModal', DeleteIcon) : null}
                </Grid>
            ) : null}
            {isCustomer ? (
                <Grid container item xs={12} className="btn-hw">
                    {props.isShownHeadrer ? null : (
                        <CancelButton
                            vehicleID={vehicleID}
                            jobRegNumber={data.jobRegNumber}
                            position={props.position}
                            goPreviousScreen={props.goPreviousScreen}
                        />
                    )}
                    {(status == 'AQ' || status == 'IQ') && props.otherOptionsBtnShow ? CostomButton('Reply Query', 'showJobQuery', AddIcon) : null}
                    {(status == 'AA' || status == 'JP' || status == 'AU' || status == 'ER' || status == 'EP' || status == 'CQ' || status == 'AR') &&
                    props.otherOptionsBtnShow
                        ? CostomButton('Send Query', 'showJobQuery', AddIcon)
                        : null}
                    {(status == 'AA' || status == 'JP') && props.otherOptionsBtnShow ? CostomButton('Request Estimate', 'showJobQuery', AddIcon) : null}
                    {status == 'EP' && props.otherOptionsBtnShow ? CostomButton('Estimate Accept', 'showEstimate', AddIcon) : null}
                    {status == 'AR' && props.otherOptionsBtnShow ? CostomButton('Approve Order', 'showEstimate', AddIcon) : null}
                    {/* {status !== 'AU' && (
                        <CancelButton
                            vehicleID={vehicleID}
                            jobRegNumber={data.jobRegNumber}
                            position={props.position}
                            goPreviousScreen={props.goPreviousScreen}
                        />
                    )} */}
                </Grid>
            ) : null}
            {state.showSubmit && (
                <DialogComp title="Are you sure to submit order with zero cost?" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <SubmitWarnngModal title={state.title} onYes={showSubmitModal} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showJobQuery && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobQueryModal title={state.title} jobQueryJobID={props.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showSubmitCostModal && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <AcceptEstimateModal
                        title={state.title}
                        mileageIsRequired={data.mileageRequired}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        jobMileage={data.jobMileage}
                        jobMileageDate={data.jobMileageDate}
                        onClose={closeTheDialog}
                    />
                </DialogComp>
            )}
            {state.showEstimate && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobEstimateModal
                        title={state.title}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        onClose={closeTheDialog}
                    />
                </DialogComp>
            )}
            {/* //handleSubmitCost */}
            {state.showJobDeleteModal ? (
                <DialogComp open={true} onClose={() => closeDeleteDefectModal(false)} title={'Delete Job'} maxWidth="sm" fullWidth>
                    <DeleteJobModal deleteID={props.jobID} fromPage="Job" onClose={closeDeleteDefectModal} />
                </DialogComp>
            ) : null}
        </Grid>
    );
}

export default StatusBasedButton;
