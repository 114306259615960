import React, { useMemo, useState } from 'react';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { EditButton } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ContractAddEditScreen from './addEditContract';

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'eventName', name: 'Name' },
    { key: 'firstEvent', name: 'First Schedule' },
    { key: 'lastEvent', name: 'Last Schedule' }
];

const ContractListScreen = (props) => {
    const [state, setState] = useState({
        selectedRowData: {}
    });

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Schedule Events"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            selectedRowData: row,
                            isAdd: false
                        }));
                    }}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };

    const handleClose = (res) => {
        setState((st) => {
            const newSt = { ...st };
            newSt.open = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });

        // res && pullApiData();
    };

    const urlState = `ContractSchedules/ContractSchedule_List?TypeID=${props.contractTypeID}`;

    return (
        <div>
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Contract Schedule"
                onBtnClick={addBtnClicked}
            />
            {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? 'Add Contract Schedule' : 'Edit Contract Schedule'}
                    fullWidth
                    maxWidth="sm"
                    overflow="auto"
                >
                    <ContractAddEditScreen selectedRowData={state.selectedRowData} contractTypeID={props.contractTypeID} onClose={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ContractListScreen;
