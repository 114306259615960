import React, { useState, useEffect, useMemo } from 'react';
import { getAllDefectTypes } from '../../../../../../Core/Services/InternalUser/defectTypeService';
import DataGrid from '../../../../../../Core/Controls/Grid';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import AddEditDepartment from './addEditDepotContacts';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../../../../Core/Controls';
import GijgoGrid from '../../../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import AddEditDepotContacts from './addEditDepotContacts';
// import '../../.././commonStyle.scss';

const base_columns = [
    { key: 'action', name: 'Action', width: 50 },
    { key: 'depotContactName', name: 'Name', width: 210 },
    { key: 'depotContactPhone', name: 'Phone', width: 210 },
    { key: 'depotContactEmail', name: 'Email', width: 210 },
    { key: 'depotContactCompanyName', name: 'Company', width: 210 }
];

const DepotContacts = (props) => {
    const [state, setState] = useState({
        depotContactID: null,
        open: false,
        data: null
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAdd = (params) => {
        setState((state) => ({ ...state, open: true, depotContactID: params?.depotContactID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleAdd(row)} toolTipTitle="Edit Contact" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    }, []);

    const title = state.data ? 'Update Contact' : 'Add Contact';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullData();
    };

    return (
        <div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={columns}
                getUrl={`DepotContacts?DepotID=${props.depotID} `}
                onBtnClick={() => handleAdd(null)}
                btnTitle={title}
                isReload={state.isReload}
            />

            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <AddEditDepotContacts data={state.data} onClose={closeModal} depotContactID={state.depotContactID} depotID={props.depotID} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default DepotContacts;
