import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getDropDownHolidayUser = async (id) => {
    let res = await getWithAuth(`UserHolidays/UserHolidays_DropDowns`);
    return res;
};
export const getHolidayDataByID = async (userID, year) => {
    let res = await getWithAuth(`UserHolidays/UserHolidays_GetDetails?UserHolidayUserID=${userID}&Year=${year}`);
    return res;
};

export const postHolidayUserData = async (data) => {
    let res = await postWithAuth(`UserHolidays/UserHolidays_AddUpdate`, data);
    return res;
};
// https://devapi.fleet247.co.uk/api/ServiceAgentSchedules?VehicleID=&Status=2
