import InputText from './index';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import './inputStyle.scss';

export function MandatoryField(props) {
    const { inputLabel, isDisabled, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value || 'N/A'}</div>
        </div>
    ) : (
        <>
            <InputLabel required shrink error>
                {inputLabel}
            </InputLabel>
            <InputText {...rest} />
            <FormHelperText error>{rest.errors}</FormHelperText>
        </>
    );
}

function SimpleField(props) {
    const { inputLabel, isDisabled, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value || 'N/A'}</div>
        </div>
    ) : (
        <>
            <InputLabel shrink>{inputLabel}</InputLabel>
            <InputText {...rest} />
        </>
    );
}
export default SimpleField;
