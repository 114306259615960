import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { MandatoryField, SecondaryButton, SelectBox, SimpleField } from '../../../Core/Controls';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { GetAllPartsByPartNumber, getPartListByPartNumber } from '../../../Core/Services/InternalUser/JOB/jobItemsService';
import { useToast } from '../../../Core/Hooks/useToast';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '10px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});
const LabourPartModal = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ errors: {}, matchingPartNumber: [], isPartSelectedFlag: false });
    const { showToastSuccess, showToastError } = useToast();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let newSt = {};
        newSt[name] = value;

        setState((state) => ({
            ...state,
            ...newSt
        }));
    };

    const pullDataByPartNumber = useCallback(
        debounce(async (e, type) => {
            const isPartSearch = type === 'P';
            const searchValue = isPartSearch ? state.partNumber : state.partDescription;
            const emptyMessage = isPartSearch ? 'Please enter part number to fetch data' : 'Please enter description to fetch data';

            if (!searchValue) {
                showToastError(emptyMessage);
                return;
            }
            setState((st) => ({ ...st, loading: true }));
            try {
                const res = await getPartListByPartNumber(state.partNumber, null, null, state.partDescription);
                if (res.success) {
                    let selectedParts = new Set(props.stdParts.map((q) => q.standardLabourPartPartID));
                    const filteredArray1 = res.data.partDetails.filter((item) => !selectedParts.has(item.partID));
                    setState((st) => ({
                        ...st,
                        matchingPartNumber: filteredArray1,
                        loading: false,
                        openModal: true
                    }));
                } else {
                    showToastError('Failed to fetch data');
                    setState((st) => ({ ...st, loading: false }));
                }
            } catch (error) {
                showToastError('Error fetching data');
                setState((st) => ({ ...st, loading: false }));
            }
        }, 500),
        [state.partNumber, state.partDescription]
    );

    const handleClick = (event, selectedindex) => {
        const { checked } = event.target;
        let tempList = [...state.matchingPartNumber];
        tempList[selectedindex].isSelected = checked;
        const isPartSelectedFlag = tempList.some((item) => item.isSelected);
        setState((st) => ({
            ...st,
            matchingPartNumber: tempList,
            isPartSelectedFlag: isPartSelectedFlag
        }));
    };

    const submitHandler = () => {
        let tempList = state.matchingPartNumber.filter((q) => q.isSelected);
        const modifiedTempList = tempList.map((q) => ({
            standardLabourPartID: null,
            partID: q.id,
            description: q.pd,
            partNumber: q.pn,
            standardLabourPartQuantity: q.q || 0,
            standardLabourPartStatusID: q.standardLabourPartStatusID
        }));
        props.addPartsHandler(modifiedTempList);
    };

    const inputChange = (event, selectedindex) => {
        const { name, value } = event.target;
        let tempList = [...state.matchingPartNumber];
        tempList[selectedindex][name] = value;
        setState((st) => ({
            ...st,
            matchingPartNumber: tempList
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    isDisabled={state.readOnly}
                    inputLabel="Part Number"
                    name="partNumber"
                    placeholder="Part Number"
                    onChange={handleInputChange}
                    value={state.partNumber}
                    errors={state.errors.partNumber}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={'Fetch Part Details By Number'} arrow>
                                    <Grid>
                                        <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'P')} size="small" state={state}>
                                            {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                                        </IconButton>
                                    </Grid>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <MandatoryField
                    isDisabled={state.readOnly}
                    inputLabel="Part Description"
                    name="partDescription"
                    placeholder="Part Description"
                    onChange={handleInputChange}
                    value={state.partDescription}
                    errors={state.errors.partDescription}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={'Fetch Part Details By Description'} arrow>
                                    <Grid>
                                        <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'D')} size="small">
                                            {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                                        </IconButton>
                                    </Grid>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer
                    // component={Paper}
                    className="custom-scroll"
                    // style={{ minHeight: 600, minWidth: '600px' }} className="custom-scroll"
                    style={{ height: '450px', borderRadius: '0px' }}
                >
                    <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Part No.</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell width="20%">Status</StyledTableCell>
                                <StyledTableCell width="20%">Quantity</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {state?.matchingPartNumber.length > 0 ? (
                                state?.matchingPartNumber.map((row, i) => {
                                    return (
                                        <StyledTableRow key={row.partID}>
                                            <StyledTableCell>
                                                <Checkbox
                                                    name={''}
                                                    onChange={(e) => handleClick(e, i)}
                                                    checked={row.isSelected}
                                                    // disabled={shouldBeDisabled(props.name)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>{row.pn}</StyledTableCell>
                                            <StyledTableCell>{row.pd}</StyledTableCell>
                                            <StyledTableCell>
                                                {' '}
                                                <SelectBox
                                                    name="standardLabourPartStatusID"
                                                    value={row.standardLabourPartStatusID || ''}
                                                    onChange={(e) => inputChange(e, i)}
                                                    List={props.statusList || []}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <SimpleField
                                                    type="number"
                                                    name="q"
                                                    onChange={(e) => inputChange(e, i)}
                                                    value={row.q}
                                                    // error={i === index}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={5} height={'100%'}>
                                        <Alert severity="info">No Record Found</Alert>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton disabled={!state.isPartSelectedFlag} onClick={submitHandler}>
                        {'Add'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LabourPartModal;
