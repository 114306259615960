import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AddButton, DataGrid, EditButton, formatters, DeleteButton } from '../../../../Core/Controls';
import AddEditNotes from './addEditNotes';
import DeleteNotes from './deleteNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import '../../../commonStyle.scss';
import { NotesProvider, NotesScreenContext } from './NotesProvider';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'noteTitle', name: 'Title', width: 150 },
    { key: 'noteDescription', name: 'Description' },
    { key: 'noteStickyDisplayFromDate', name: 'Start Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    { key: 'noteStickyDisplayEndDate', name: 'End Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 }
];

export const NoteDashboard = (props) => {
    const { state, handleAdd, closeNoteModal, title, handleDelete, deleteTitle, handleDeleteSubmit } = useContext(NotesScreenContext);
    let { isReadOnlyUser } = useLoginInfo();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const dataFormatter = (data, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    <>
                        <EditButton onClick={() => handleAdd(row.noteId)} toolTipTitle="Edit Note" />
                        {!isReadOnlyUser && <DeleteButton onClick={() => handleDelete(row.noteId)} toolTipTitle="Delete Note" />}
                    </>
                }
            </div>
        );
    };
    console.log(state.userBasePermi, 'userBasePermi');
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        if (checkhaveAccess?.CanViewNotes && state.userBasePermi) {
            tempCols.splice(0, 0, {
                key: 'action',
                name: 'Action',
                width: '5%',
                formatter: dataFormatter
            });
        }
        return tempCols;
    }, [state.userBasePermi]);
    const gridBtnTitle = `Add ${props.frompage === 'ServiceAgents' ? 'Service Area' : props.frompage} Notes`;
    return (
        <div>
            <GijgoGrid
                columns={columns}
                getUrl={state.response}
                dataConverter={(res) => ({
                    records: res.data.notelist,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle={state.userBasePermi ? gridBtnTitle : ''}
                onBtnClick={() => handleAdd(null)}
            />
            {state.show ? (
                <DialogComp title={title} onClose={() => closeNoteModal(false)} maxWidth="sm">
                    <AddEditNotes NoteID={state.noteID} onFormSubmit={() => closeNoteModal(true)} {...props} />
                </DialogComp>
            ) : null}
            {state.showDelete ? (
                <DialogComp title={deleteTitle} onClose={() => closeNoteModal(false)} maxWidth="sm">
                    <DeleteNotes NoteID={state.noteID} onFormCancel={() => closeNoteModal(true)} onFormSubmit={() => handleDeleteSubmit()} {...props} />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <NotesProvider {...props}>
            <NoteDashboard {...props} />
        </NotesProvider>
    );
};
export default Screen;
