import React, { useRef, useState } from 'react';
import './custStyle.scss';
import SimpleField, { MandatoryField } from '../Inputs/MandatoryField';
import { InputAdornment, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { getDepotsDetails } from '../../Services/InternalUser/postCodeService';
import DepotsDetailsTable from '../Table/depotTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import useClickOutside from '../../Hooks/useClickOutside';

export default function DepotFilterList(props) {
    const { name, value, onChange, getVal, options, clearValue } = props;

    const [state, setState] = useState({
        showDetails: false,
        searchValue: '',
        DepotsList: [...options]
    });

    const detailsRef = useRef();

    const pullAddress = (value) => {
        async function fetchData() {
            try {
                if (value) {
                    let res = await getDepotsDetails(value);
                    setState((st) => ({
                        ...st,
                        showDetails: true,
                        DepotsList: [...res.data.list]
                    }));
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    };

    const handleSearch = () => {
        pullAddress(value);
    };
    const closeDetails = () => {
        setState((st) => ({
            ...st,
            showDetails: false
        }));
    };

    useClickOutside(detailsRef, closeDetails);
    return (
        <div className="Maindiv">
            <SimpleField
                placeholder="Depot"
                value={value}
                onChange={onChange}
                name={name}
                autoComplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {value && <CloseIcon onClick={() => clearValue()} style={{ cursor: 'pointer' }} />}
                            <Tooltip title="Search Address" arrow>
                                {!value ? (
                                    <ExpandMoreIcon
                                        onClick={() => {
                                            setState((st) => ({
                                                ...st,
                                                showDetails: !st.showDetails,
                                                DepotsList: [...options]
                                            }));
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <SearchIcon style={{ cursor: 'pointer' }} onClick={handleSearch} />
                                )}
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                {...props}
            />
            {state.showDetails ? (
                <div className="customScroll" ref={detailsRef}>
                    <DepotsDetailsTable onClose={closeDetails} List={state.DepotsList} getDetailsValue={getVal} />
                </div>
            ) : null}
        </div>
    );
}
