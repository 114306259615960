import React, { useEffect, useState, useMemo } from 'react';
import { getAllDriver } from '../../../../Core/Services/InternalUser/ControllerService/driverService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import AddEditDriver from './addEditDriver';
import '../../../commonStyle.scss';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import CustomerDetails from '../DetailsModal/customerDetails';
import DepotDetails from '../DetailsModal/depotDetails';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80,
        addFileUploaderIcon: true
    },
    { key: 'driverFirstName', name: 'Name', width: 110 },
    { key: 'customerName', name: 'Customer', width: 160 },
    // { key: "driverAddress1", name: "Address1" },
    // { key: "driverAddress2", name: "Address2" },
    // { key: "driverTown", name: "Town" },
    // { key: "driverCounty", name: "County" },
    { key: 'driverDepotName', name: 'Depot Name', width: 150 },
    {
        key: 'driverAddress1',
        name: 'Address',
        formatter: (data) =>
            formatters.addressFormatter(
                data.row?.driverAddress1,
                data.row?.driverAddress2,
                data.row?.driverTown,
                data.row?.driverCounty,
                data.row?.driverPostCode
            ),
        filterable: 'no'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Drivers', active: true }
];

const DriverScreen = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastError } = useToast();
    const [state, setState] = useState({
        show: false,
        showLoader: true,
        DriverList: [],
        DriverID: null,
        isReload: null
    });

    const pullDriverAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    // useEffect(async () => {
    //     pullDriverAndUpdateState();
    // }, []);

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: true
        }));
    };

    const closeDriverModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDriverAndUpdateState();
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editdriver/${val.driverID}/${val.driverFirstName}`,
            search: '',
            state: '',
            name: val.driverName
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.driverDepotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const dataFormatter = (val, row) => {
        return (
            <Grid item>
                <EditButton toolTipTitle="Edit Driver" onClick={() => handleEditClick(row)} />
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let customerNameCol = tempCols.find((element) => element.key === 'customerName');
        let depotNameCol = tempCols.find((element) => element.key === 'driverDepotName');
        let driverNameCol = tempCols.find((element) => element.key === 'driverFirstName');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (driverNameCol) {
            driverNameCol.formatter = (data) => data.row.driverFirstName + ' ' + data.row.driverLastName;
        }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         3,
        //         0,
        //         { key: 'driverAddress1', name: 'Address1' },
        //         { key: 'driverAddress2', name: 'Address2' },
        //         { key: 'driverTown', name: 'Town', width: 110 },
        //         { key: 'driverCounty', name: 'County', width: 110 }
        //     );
        // }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            {state.show ? (
                <AddEditDriver DriverID={state.DriverID} onFormSubmit={closeDriverModal} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        btnTitle={checkhaveAccess?.CanUpdateDrivers ? 'Add Driver' : ''}
                        onBtnClick={handleAdd}
                        getUrl={`drivers`}
                        columns={columns}
                        idFieldName={`${checkhaveAccess?.CanUploadDriverDocuments ? 'driverID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadDriverDocuments ? 'Driver' : null}`}
                        dataConverter={(res) => ({
                            records: res.data.drivers,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.driverCustomerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.driverDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default DriverScreen;
