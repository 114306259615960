import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useToast } from '../../../Core/Hooks/useToast';
import { SecondaryButton, MandatoryField, SimpleField } from '../../../Core/Controls';
import { postAddress } from '../../../Core/Services/InternalUser/postCodeService';
import { singleEmailValidation } from '../../../Core/Basic/StaticService';
import { FormHelperText } from '@material-ui/core';

const AddEditAddress = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        addressName: '',
        addressCode: null,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressTown: '',
        addressCounty: '',
        addressCountry: '',
        addressPostCode: '',
        addressTelephone: '',
        addressFax: '',
        addressContactName: '',
        addressEmail: '',
        approvalLimit: '',
        addressWebsite: '',
        errors: {},
        disabled: props.checkhaveAccess?.CanUpdateAddress ? !props.checkhaveAccess?.CanUpdateAddress : props.checkhaveAccess?.CanViewAddresss || true
    });

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (props.data?.addressCode) {
            let data = props.data;
            setState((st) => ({
                ...st,
                addressName: data.addressName || null,
                addressCode: data.addressCode || null,
                addressLine1: data.addressLine1 || null,
                addressLine2: data.addressLine2 || null,
                // addressLine3: data.addressLine3 || null,
                addressTown: data.addressTown || null,
                addressCounty: data.addressCounty || null,
                // addressCountry: data.addressCountry || null,
                addressPostCode: data.addressPostCode || null,
                addressTelephone: data.addressTelephone || null,
                // addressFax: data.addressFax || null,
                // addressContactName: data.addressContactName || null,
                addressEmail: data.addressEmail || null,
                approvalLimit: data.approvalLimit || null
                // addressWebsite: data.addressWebsite || null
            }));
        }
    }, []);

    let validation = () => {
        let errors = {};
        let isValid = true;
        let { addressCode, addressName, addressLine1, addressTown, addressCounty, addressCountry, addressPostCode, addressEmail } = state;

        if (!addressCode) {
            errors.addressCode = 'Address Code is required';
            isValid = false;
        }
        if (!addressName) {
            errors.addressName = 'Address Name is required';
            isValid = false;
        }
        if (!addressLine1) {
            errors.addressLine1 = 'Address Line 1 is required';
            isValid = false;
        }
        if (!addressTown) {
            errors.addressTown = 'Address Town is required';
            isValid = false;
        }
        if (!addressCounty) {
            errors.addressCounty = 'Address County is required';
            isValid = false;
        }
        // if (!addressCountry) {
        //     errors.addressCountry = 'Address Country is required';
        //     isValid = false;
        // }
        if (!addressPostCode) {
            errors.addressPostCode = 'Address Post Code is required';
            isValid = false;
        }
        // if (!addressPostCode) {
        //     errors.addressPostCode = 'Address Post Code is required';
        //     isValid = false;
        // }
        // console.log(addressEmail, 'email');
        if (addressEmail != '' && addressEmail != null) {
            if (!singleEmailValidation(addressEmail)) {
                isValid = false;
                errors.addressEmail = 'Please Enter Valid Email';
            }
        }
        setState((st) => ({
            ...st,
            errors
        }));

        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                addressName: state.addressName || '',
                addressCode: state.addressCode || '',
                addressLine1: state.addressLine1 || '',
                addressLine2: state.addressLine2 || '',
                // addressLine3: state.addressLine3 || '',
                addressTown: state.addressTown || '',
                addressCounty: state.addressCounty || '',
                // addressCountry: state.addressCountry || '',
                addressPostCode: state.addressPostCode || '',
                addressTelephone: state.addressTelephone || '',
                // addressFax: state.addressFax || '',
                // addressContactName: state.addressContactName || '',
                addressEmail: state.addressEmail || '',
                approvalLimit: state.approvalLimit || ''
                // addressWebsite: state.addressWebsite || ''
            };
            let res = await postAddress(data);
            if (res.success) {
                showToastSuccess(res?.data?.message);
                props.onClose(null, true);
            } else {
                showToastError(`Fail to ${props?.data?.addressName ? `Updated` : `Added`} Address`);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6} lg={8} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Name"
                    placeholder="Name"
                    value={state.addressName}
                    onChange={inputChange}
                    name="addressName"
                    errors={state.errors.addressName}
                    disabled={state.disabled}
                    autoFocus={true}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Address Code"
                    placeholder="Address Code"
                    value={state.addressCode}
                    onChange={inputChange}
                    name="addressCode"
                    errors={state.errors.addressCode}
                    disabled={props?.data?.addressCode || state.disabled}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Address Line 1"
                    placeholder="Address Line 1"
                    value={state.addressLine1}
                    onChange={inputChange}
                    name="addressLine1"
                    errors={state.errors.addressLine1}
                    disabled={state.disabled}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Address Line 2"
                    disabled={state.disabled}
                    placeholder="Address Line 2"
                    value={state.addressLine2}
                    onChange={inputChange}
                    name="addressLine2"
                />
            </Grid>
            {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                <SimpleField
                    inputLabel="Address Line 3"
                    disabled={state.disabled}
                    placeholder="Address Line 3"
                    value={state.addressLine3}
                    onChange={inputChange}
                    name="addressLine3"
                />
            </Grid> */}
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Town"
                    placeholder="Town"
                    value={state.addressTown}
                    onChange={inputChange}
                    name="addressTown"
                    errors={state.errors.addressTown}
                    disabled={state.disabled}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="County"
                    placeholder="County"
                    value={state.addressCounty}
                    onChange={inputChange}
                    name="addressCounty"
                    errors={state.errors.addressCounty}
                    disabled={state.disabled}
                />
            </Grid>
            {/* <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                <SimpleField
                    inputLabel="Country"
                    disabled={state.disabled}
                    placeholder="Country"
                    value={state.addressCountry}
                    onChange={inputChange}
                    name="addressCountry"
                />
            </Grid> */}
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Post Code"
                    placeholder="Post Code"
                    value={state.addressPostCode}
                    onChange={inputChange}
                    name="addressPostCode"
                    errors={state.errors.addressPostCode}
                    disabled={state.disabled}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Telephone"
                    disabled={state.disabled}
                    placeholder="Telephone"
                    value={state.addressTelephone}
                    onChange={inputChange}
                    name="addressTelephone"
                />
            </Grid>
            {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                <SimpleField inputLabel="Fax" placeholder="Fax" disabled={state.disabled} value={state.addressFax} onChange={inputChange} name="addressFax" />
            </Grid> */}
            {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                <SimpleField
                    inputLabel="Contact Name"
                    disabled={state.disabled}
                    placeholder="Contact Name"
                    value={state.addressContactName}
                    onChange={inputChange}
                    name="addressContactName"
                />
            </Grid> */}
            <Grid item xs={12} md={4} sm={6} lg={8}>
                <SimpleField
                    inputLabel="Email"
                    disabled={state.disabled}
                    placeholder="Email"
                    value={state.addressEmail}
                    onChange={inputChange}
                    name="addressEmail"
                />
                <FormHelperText error>{state.errors.addressEmail}</FormHelperText>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Approval Limit"
                    disabled={state.disabled}
                    placeholder="Approval Limit"
                    value={state.approvalLimit}
                    onChange={inputChange}
                    name="approvalLimit"
                />
                <FormHelperText error>{state.errors.approvalLimit}</FormHelperText>
            </Grid>
            {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                <SimpleField
                    inputLabel="Website"
                    disabled={state.disabled}
                    placeholder="Website"
                    value={state.addressWebsite}
                    onChange={inputChange}
                    name="addressWebsite"
                />
                <FormHelperText error>{state.errors.addressEmail}</FormHelperText>
            </Grid> */}

            <Grid item container xs={12} spacing={1} justify="space-between" style={{ marginTop: 8 }}>
                <Grid item xs={6}>
                    <SecondaryButton
                        onClick={handleSubmit}
                        className="submit_btn"
                        isBtnLoader={btnLoader}
                        disabled={state.disabled && !props.checkhaveAccess?.CanUpdateAddress}
                    >
                        {props.data?.addressCode ? 'Update' : 'Submit'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton className="submit_btn" onClick={() => props.onClose(null, false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddEditAddress;
