import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, InputText, NoButton, SecondaryButton, SelectBox, TimePicker, YesButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';
import './scheduleVehicleStyle.scss';
import {
    getScheduleRequestBookingById,
    getAllDropDowns,
    postScheduleRequestBooking
} from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';

const ScheduleBookingStatus = (props) => {
    const [state, setState] = useState({
        scheduleDateDue: '',
        scheduleID: null,
        scheduleNightBooking: null,
        scheduleRequestedBookingDate: '',
        scheduleRequestedBookingTimeSlot: '',
        dropList: [],
        showLocationBox: false,
        scheduleCollectionLocation: '',
        scheduleDeliveryLocation: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const { showToastSuccess, showToastError } = useToast();
    const pullBookingData = async () => {
        let DataList = await getAllDropDowns();
        setState((st) => ({
            ...st,
            dropList: DataList.data
        }));
        let res = await getScheduleRequestBookingById(props.scheduleId);
        let bookingData = res.data;
        setState((st) => ({
            ...st,
            vehicleID: bookingData.vehicleID || null,
            scheduleCollectAfter: bookingData.scheduleCollectAfter,
            scheduleCollectionAndDeliverID: bookingData.scheduleCollectionAndDeliverID,
            scheduleDateDue: bookingData.scheduleDateDue || '',
            scheduleDeliverBefore: bookingData.scheduleDeliverBefore,
            scheduleEventID: bookingData.scheduleEventID,
            scheduleEventName: bookingData.scheduleEventName,
            scheduleID: bookingData.scheduleID,
            scheduleNightBooking: bookingData.scheduleNightBooking,
            scheduleRequestedBookingDate: bookingData.scheduleRequestedBookingDate || bookingData.scheduleDateDue,
            scheduleRequestedBookingTimeSlot: bookingData.scheduleRequestedBookingTimeSlot,
            scheduleCollectionLocation: bookingData.scheduleCollectionLocation || '',
            scheduleDeliveryLocation: bookingData.scheduleDeliveryLocation || ''
        }));
    };
    useEffect(() => {
        pullBookingData();
    }, []);

    const checkNull = (param) => {
        if (/[^\s]/.test(param)) {
            return param;
        } else {
            return null;
        }
    };

    const validations = () => {
        const errors = [];
        let noErrors = true;
        if (state.showLocationBox) {
            if (!/[^\s]/.test(state.scheduleCollectionLocation)) {
                errors.scheduleCollectionLocation = 'Collection Location Required';
                noErrors = false;
            }
            if (!/[^\s]/.test(state.scheduleDeliveryLocation)) {
                errors.scheduleDeliveryLocation = 'Delivery Location Required';
                noErrors = false;
            }
            setErrors(errors);
            return noErrors;
        } else {
            return true;
        }
    };

    const handleData = async (ev) => {
        if (validations()) {
            setBtnLoader(true);
            let response = await postScheduleRequestBooking({
                vehicleID: props.vehicleId,
                scheduleEventID: state.scheduleEventID,
                scheduleID: state.scheduleID,
                scheduleCollectionAndDeliverID: state.scheduleCollectionAndDeliverID,
                scheduleEventName: state.scheduleEventName,
                scheduleDateDue: state.scheduleDateDue,
                scheduleRequestedBookingDate: checkNull(state.scheduleRequestedBookingDate),
                scheduleRequestedBookingTimeSlot: checkNull(state.scheduleRequestedBookingTimeSlot),
                scheduleCollectAfter: checkNull(state.scheduleCollectAfter),
                scheduleDeliverBefore: checkNull(state.scheduleDeliverBefore),
                scheduleNightBooking: state.scheduleNightBooking,
                scheduleCollectionLocation: state.showLocationBox ? state.scheduleCollectionLocation : null,
                scheduleDeliveryLocation: state.showLocationBox ? state.scheduleDeliveryLocation : null
            });
            if (response) {
                response.success ? showToastSuccess('Request Booking Updated Successfully') : showToastError(response.message || 'Something went wrong');
            } else {
                showToastError('Something went wrong');
            }
            props.onClose(true);
            setBtnLoader(false);
        }
    };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };
    const yesNoChanged = (ev) => {
        if (ev.target.innerText == 'YES') {
            setState((st) => ({
                ...st,
                scheduleNightBooking: true
            }));
        } else {
            setState((st) => ({
                ...st,
                scheduleNightBooking: false
            }));
        }
    };

    useEffect(() => {
        if (state.scheduleCollectionAndDeliverID === 3 || state.scheduleCollectionAndDeliverID === 4 || state.scheduleCollectionAndDeliverID === 5) {
            setState((st) => ({ ...st, showLocationBox: true }));
        } else {
            setState((st) => ({ ...st, showLocationBox: false }));
        }
    }, [state.scheduleCollectionAndDeliverID]);
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} className="typo">
                    <FormHelperText>Event</FormHelperText>
                    <Typography>{state.scheduleEventName}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} className="typo">
                    <FormHelperText>Date Due</FormHelperText>
                    <Typography>{state.scheduleDateDue ? moment(state.scheduleDateDue).format('DD/MM/YYYY') : 'No Date Assigned'}</Typography>
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <InputLabel shrink>Requested Booking Date</InputLabel>
                    <DatePicker
                        name="scheduleRequestedBookingDate"
                        value={moment(state.scheduleRequestedBookingDate).format('YYYY-MM-DD') || ''}
                        onChange={valueChanged}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputLabel shrink>Requested Booking Time Slot</InputLabel>
                    <TimePicker name="scheduleRequestedBookingTimeSlot" value={state.scheduleRequestedBookingTimeSlot || ''} onChange={valueChanged} />
                </Grid>
                <Grid item sm={4} xs={12} className="yes_no_container">
                    <InputLabel shrink>Is Night Booking?</InputLabel>
                    <span className="yes_no">
                        <YesButton name="scheduleNightBooking" state={state.scheduleNightBooking} onClick={yesNoChanged} />
                    </span>
                    <span className="yes_no">
                        <NoButton name="scheduleNightBooking" state={state.scheduleNightBooking} onClick={yesNoChanged} />
                    </span>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputLabel shrink>Collection And Delivery</InputLabel>
                    <SelectBox
                        name="scheduleCollectionAndDeliverID"
                        value={state.scheduleCollectionAndDeliverID || ''}
                        onChange={valueChanged}
                        List={state.dropList.collectionAndDelivery || []}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputLabel shrink>Deliver Before</InputLabel>
                    <TimePicker name="scheduleDeliverBefore" value={state.scheduleDeliverBefore} onChange={valueChanged} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputLabel shrink>Collect After</InputLabel>
                    <TimePicker name="scheduleCollectAfter" value={state.scheduleCollectAfter} onChange={valueChanged} />
                </Grid>
                {state.showLocationBox && (
                    <>
                        <Grid item xs={12} sm={6} className="mandatory-fields">
                            <InputLabel required shrink>
                                Collection Location
                            </InputLabel>
                            <InputText
                                placeholder="Collection Location"
                                value={state.scheduleCollectionLocation}
                                name="scheduleCollectionLocation"
                                onChange={valueChanged}
                            />
                            <FormHelperText error>{errors.scheduleCollectionLocation}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} className="mandatory-fields">
                            <InputLabel required shrink>
                                Pickup Location
                            </InputLabel>
                            <InputText
                                placeholder="Pickup Location"
                                value={state.scheduleDeliveryLocation}
                                name="scheduleDeliveryLocation"
                                onChange={valueChanged}
                            />
                            <FormHelperText error>{errors.scheduleDeliveryLocation}</FormHelperText>
                        </Grid>
                    </>
                )}

                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default ScheduleBookingStatus;
