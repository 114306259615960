import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Alert } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '2px 5px'
        // border: '1px solid'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black,
        border: '1px solid rgba(0,0,0,0.03)'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

export default function CustomerDetailsTable({ onClose, List, getDetailsValue }) {
    const getRowDetails = (val) => {
        try {
            getDetailsValue(val);
            onClose();
        } catch (error) {
            console.warn(error);
        }
    };

    const classes = useStyles();

    return (
        <TableContainer style={{ maxHeight: 500 }} className="custom-scroll">
            <Table className={classes.table} aria-label="customized table">
                <TableBody>
                    {List.length > 0 ? (
                        List.map((row) => (
                            <StyledTableRow key={row.name} onClick={() => getRowDetails(row)}>
                                <StyledTableCell component="th" scope="row">
                                    <b>
                                        {row.addressName} [{row.addressCode}]
                                    </b>{' '}
                                    &nbsp;( {row.addressLine1},{row.addressLine2},{row.addressTown},{row.addressCountry}, {row.addressPostCode})
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <TableRow>
                            <StyledTableCell>
                                <Alert severity="info">No Address Found</Alert>
                            </StyledTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
