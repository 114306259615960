import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, ButtonGroup, Button, Container } from '@material-ui/core';
import { SingleSelect, SecondaryButton, SelectBox, MandatoryField, SimpleField, AppButtonGroup, DateTimePicker } from '../../../../Core/Controls';
import { getAllJobDropDowns, getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { postJobData } from '../../../../Core/Services/InternalUser/JOB/jobService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../controllerStyle.scss';
import { getDropDownForVor } from '../../../../Core/Services/InternalUser/VOR/addEditVor';
import { useHistory } from 'react-router-dom';
const options = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' }
];

function JobScreenModal(props) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        errors: {},
        description: '',
        customerOrderNo: '',
        serviceAgentList: [],
        jobDepotList: [],
        jobCustomerList: [],
        jobTypesList: [],
        jobAuthorityRef: '',
        technicianList: [],
        jobCostTypeID: '',
        addVOR: false,
        vorTypeList: [],
        AllvorSubCategoryList: [],
        AllvorTypeDetails: [],
        faultTypes: [],
        vorTypeDetails: [],
        jobCostTypeList: [],
        vorTypeDetailList: [],
        vorSubCategoryList: []
    });

    const onBtnSelected = (o) => {
        setState((dt) => ({
            ...dt,
            addVOR: o
        }));
    };

    const [tempOptions, setTempOptions] = useState({
        jobDepotID: null,
        jobCustomerID: null,
        serviceAgentID: null,
        jobEngineerUserID: null,
        jobTypeID: null,
        jobCostTypeID: null,
        vorVehicleTypeIDValue: null,
        vorVehicleTypeDetailIDValue: null,
        vorSubCategoryIdValue: null
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getAllJobDropDowns(props.VehicleID);
                setState((state) => ({
                    ...state,
                    jobDepotList: res.data.depots,
                    jobCustomerList: res.data.customers,
                    jobTypesList: res.data.jobTypes,
                    serviceAgentList: res.data.serviceAgents,
                    vehicleId: props.VehicleID,
                    // technicianList: res.data.technician,
                    jobCostTypeList: res.data.costTypes
                }));

                let VorRes = await getDropDownForVor(props.VehicleID);
                if (VorRes.success) {
                    setState((state) => ({
                        ...state,
                        vorTypeList: VorRes.data.vorTypes.map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription })),
                        AllvorSubCategoryList: VorRes.data.vorSubCategories,
                        AllvorTypeDetails: VorRes.data.vorTypeDetails,
                        faultTypes: VorRes.data.faultTypes
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.VehicleID]);

    useEffect(async () => {
        if (tempOptions.serviceAgentID?.id) {
            let newSt = {};
            let res = await getJobEnggById(tempOptions.serviceAgentID?.id);
            if (res.success) {
                setState((d) => ({ ...d, technicianList: res.data.list }));
                if (props.technicianUserID) {
                    newSt.technicianUserIDValue = res.data.list.find(({ id }) => id === props.technicianUserID);
                }
                setTempOptions((st) => ({
                    ...st,
                    jobEngineerUserID: newSt.technicianUserIDValue || {}
                }));
            }
        }
    }, [tempOptions.serviceAgentID?.id]);

    useEffect(() => {
        let filterTypeDetails = [];

        if (tempOptions.vorVehicleTypeIDValue) {
            filterTypeDetails = state.AllvorTypeDetails.filter((v) => {
                return v.vorTypeDetailTypeID == tempOptions.vorVehicleTypeIDValue?.id;
            }).map((l) => ({ id: l.vorTypeDetailID, name: l.vorTypeDetailDescription }));
        }

        let filterSubCatagoryList = state.AllvorSubCategoryList.filter((v) => {
            return v.vorSubCategoryCategoryId == tempOptions.vorVehicleTypeDetailIDValue?.id;
        }).map((l) => ({ id: l.vorSubCategoryId, name: l.vorSubCategoryDescription }));

        setState((st) => ({ ...st, vorTypeDetailList: filterTypeDetails, vorSubCategoryList: filterSubCatagoryList }));
    }, [tempOptions.vorVehicleTypeIDValue, tempOptions.vorVehicleTypeDetailIDValue, state.AllvorSubCategoryList, state.AllvorTypeDetails]);

    useEffect(() => {
        let newSt = {};
        if (props.depotID) {
            newSt.depotIDValue = state.jobDepotList.find(({ id }) => id === props.depotID);
        }
        if (props.customerID) {
            newSt.jobCustomerIDValue = state.jobCustomerList.find(({ id }) => id === props.customerID);
        }
        if (props.serviceAgentID) {
            newSt.serviceAgentIDValue = state.serviceAgentList.find(({ id }) => id === props.serviceAgentID);
        }
        // if (props.technicianUserID) {
        //     newSt.technicianUserIDValue = state.technicianList.find(({ id }) => id === props.technicianUserID);
        // }

        setTempOptions((st) => ({
            ...st,
            jobDepotID: newSt.depotIDValue || {},
            jobCustomerID: newSt.jobCustomerIDValue || {},
            serviceAgentID: newSt.serviceAgentIDValue || {}
            // jobEngineerUserID: newSt.technicianUserIDValue || {}
        }));
    }, [props.depotID, state.jobDepotList, props.serviceAgentID, state.serviceAgentList, props.technicianUserID]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { description } = state;
        const { jobDepotID, jobCustomerID, serviceAgentID, jobEngineerUserID, jobTypeID, jobCostTypeID } = tempOptions;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(description)) {
            errors.description = 'Description is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(serviceAgentID) || serviceAgentID == null) {
            errors.serviceAgentID = ' Service Area is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(jobTypeID) || jobTypeID == null) {
            errors.jobTypeID = 'Job Priority is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(jobCostTypeID) || jobCostTypeID == null) {
            errors.jobCostTypeID = 'Cost Type is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(jobDepotID) || jobDepotID == null) {
            errors.jobDepotID = ' Job Depot is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(jobCustomerID) || jobCustomerID == null) {
            errors.jobCustomerID = ' Customer is required';
            formIsValid = false;
        }
        // if (!jobEngineerUserID?.id || jobEngineerUserID == null) {
        //     errors.jobEngineerUserID = 'Engineer is required';
        //     formIsValid = false;
        // }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };
    const submitHandler = async () => {
        setLoader(true);
        if (validations()) {
            const data = {
                vehicleID: props.VehicleID,
                description: state.description,
                jobCustomerOrderNo: state.customerOrderNo,
                jobServiceAgentID: tempOptions.serviceAgentID?.id,
                jobDepotID: tempOptions.jobDepotID?.id,
                jobCustomerID: tempOptions.jobCustomerID?.id,
                jobAuthorityRef: state.jobAuthorityRef,
                jobEngineerUserID: tempOptions.jobEngineerUserID?.id || '',
                jobTypeID: tempOptions.jobTypeID?.id || '',
                vORVehicleTypeID: tempOptions.vorVehicleTypeIDValue?.id || '',
                vORSubCategoryId: tempOptions.vorSubCategoryIdValue?.id || '',
                vORVehicleTypeDetailID: tempOptions.vorVehicleTypeDetailIDValue?.id || '',
                vORVehicleDateStarted: state.vorVehicleDateStarted || '',
                jobCostTypeID: tempOptions.jobCostTypeID?.id || '',
                addVOR: state.addVOR
            };
            // console.log(data, 'data');
            let res = await postJobData(data);
            if (res.success) {
                setLoader(false);
                history.push({
                    pathname: `/controller/jobTabScreen/${res?.message}/${props.VehicleID}`,
                    state: 'jobs',
                    search: 'tab_testing_tabs=1'
                });
                showToastSuccess('Job added sucessfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
        setLoader(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Grid container spacing={1} style={{ overflow: 'auto' }}>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Job Description"
                            name="description"
                            value={state.description}
                            onChange={handleInputChange}
                            autoFocus={true}
                        />
                        <FormHelperText error>{state.errors.description}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} className="mandatory-fields">
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect options={state.jobCustomerList} value={tempOptions.jobCustomerID} onChange={singleSelectChange('jobCustomerID')} />
                        <FormHelperText error>{state.errors.jobCustomerID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} className="mandatory-fields">
                        <InputLabel required shrink>
                            Job Depot
                        </InputLabel>
                        <SingleSelect options={state.jobDepotList} value={tempOptions.jobDepotID} onChange={singleSelectChange('jobDepotID')} />
                        <FormHelperText error>{state.errors.jobDepotID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} className="mandatory-fields">
                        <InputLabel required shrink>
                            Service Area
                        </InputLabel>
                        <SingleSelect options={state.serviceAgentList} value={tempOptions.serviceAgentID} onChange={singleSelectChange('serviceAgentID')} />
                        <FormHelperText error>{state.errors.serviceAgentID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Engineer</InputLabel>
                        <SingleSelect options={state.technicianList} value={tempOptions.jobEngineerUserID} onChange={singleSelectChange('jobEngineerUserID')} />
                        {/* <FormHelperText error>{state.errors.jobEngineerUserID}</FormHelperText> */}
                    </Grid>

                    <Grid item xs={12} sm={6} className="mandatory-fields">
                        <InputLabel required shrink>
                            Job Priority
                        </InputLabel>
                        <SingleSelect options={state.jobTypesList} value={tempOptions.jobTypeID} onChange={singleSelectChange('jobTypeID')} />
                        <FormHelperText error>{state.errors.jobTypeID}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} className="mandatory-fields">
                        <InputLabel shrink required>
                            Cost Type
                        </InputLabel>
                        <SingleSelect options={state.jobCostTypeList} value={tempOptions.jobCostTypeID} onChange={singleSelectChange('jobCostTypeID')} />
                        <FormHelperText error>{state.errors.jobCostTypeID}</FormHelperText>
                        {/* <SelectBox name="jobCostTypeID" value={state.jobCostTypeID} onChange={handleInputChange} List={state.jobCostTypeList || []} /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SimpleField
                            inputLabel="Customer Order No"
                            name="customerOrderNo"
                            placeholder="Customer Order No"
                            onChange={handleInputChange}
                            value={state.customerOrderNo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SimpleField inputLabel="Authority Reference" value={state.jobAuthorityRef} name="jobAuthorityRef" onChange={handleInputChange} />
                    </Grid>
                    {!props.VORId && (
                        <Grid item xs={12}>
                            <InputLabel shrink>Add VOR</InputLabel>
                            <ButtonGroup size="small" aria-label="small outlined button group" color="primary">
                                <Button onClick={() => onBtnSelected(true)} variant={state.addVOR ? 'contained' : 'outlined'}>
                                    Yes
                                </Button>
                                <Button onClick={() => onBtnSelected(false)} variant={!state.addVOR ? 'contained' : 'outlined'}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}
                    {!props.VORId && state.addVOR && (
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel required shrink>
                                    VOR Type
                                </InputLabel>
                                <SingleSelect
                                    options={state.vorTypeList}
                                    value={tempOptions.vorVehicleTypeIDValue}
                                    onChange={singleSelectChange('vorVehicleTypeIDValue')}
                                    disabled={state.isReadOnly}
                                    autoFocus={true}
                                />
                                <FormHelperText error>{state.errors.vorVehicleTypeID}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel required shrink>
                                    VOR Reason
                                </InputLabel>
                                <SingleSelect
                                    options={state.vorTypeDetailList}
                                    value={tempOptions.vorVehicleTypeDetailIDValue}
                                    onChange={singleSelectChange('vorVehicleTypeDetailIDValue')}
                                    disabled={state.isReadOnly}
                                />
                                <FormHelperText error>{state.errors.vorVehicleTypeDetailID}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel required shrink>
                                    VOR Sub Category
                                </InputLabel>
                                <SingleSelect
                                    options={state.vorSubCategoryList}
                                    value={tempOptions.vorSubCategoryIdValue}
                                    onChange={singleSelectChange('vorSubCategoryIdValue')}
                                    disabled={state.isReadOnly}
                                />
                                <FormHelperText error>{state.errors.vorSubCategoryId}</FormHelperText>
                            </Grid>
                            {/* <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Start Date
                    </InputLabel>
                    <DatePicker placeholder="VOR Start Date" value={state.vorVehicleDateStarted} onChange={inputChange} name="vorVehicleDateStarted" />
                    <FormHelperText error>{state.errors.vorVehicleDateStarted}</FormHelperText>
                </Grid> */}

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink>VOR Start Date</InputLabel>
                                <DateTimePicker
                                    placeholder="VOR Start Date"
                                    value={state.vorVehicleDateStarted}
                                    onChange={handleInputChange}
                                    name="vorVehicleDateStarted"
                                    inputProps={{
                                        max: new Date().toISOString().slice(0, 16)
                                    }}
                                    // InputProps={{ inputProps: { max: `${state.arrivedDate}` } }}
                                    // disabled={state.isReadOnly}
                                />
                                {/* <FormHelperText error>{state.errors.vorVehicleDateStarted}</FormHelperText> */}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item container spacing={2}>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={submitHandler} disabled={loader}>
                        Add
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton forceEnabled={true} onClick={() => props.onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>

            {/* {props.VORId && <Grid item xs={6} className="submit_btn"></Grid>} */}
        </Grid>
    );
}

export default JobScreenModal;
