import React, { useMemo, useState } from 'react';
import { BreadCrumbs, EditButton, formatters } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import DialogComp from '../../../Core/Dialog/DialogComp';
import AddUpdateLabourLine from './AddEditLaourLine';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { PrimaryButton, CustomIconButton, SecondaryButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Typography } from '@material-ui/core';
import { useToast } from '../../../Core/Hooks/useToast';
import { deleteLabourLine } from '../../../Core/Services/Admin/StandardLabourLine';

const basecolumns = [
    { key: 'action', name: 'Action', width: '5%' },
    {
        key: 'lineItemDescription',
        name: 'Description',
        width: '30%'
    },
    {
        key: 'lineItemHourInternalRate',
        name: 'Internal Rate',
        width: '10%',
        align: 'right',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'lineItemHourRate',
        name: 'Customer Rate',
        width: '10%',
        align: 'right',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'lineItemHours',
        name: 'Hours',
        width: '5%',
        align: 'right'
    },
    {
        key: 'vmrsCategory',
        name: 'Category',
        width: '9%'
    },
    {
        key: 'vmrsSubCategory',
        name: 'Sub Category',
        width: '9%'
    },
    {
        key: 'costType',
        name: 'Cost Type',
        width: '9%'
    }
];

const ListStandardLabourScreen = (props) => {
    const { showToastError, showToastSuccess } = useToast();
    const [state, setState] = useState({
        open: false,
        partID: null,
        showWarehouseStock: false,
        showDelete: false,
        lineItemDescription: null,
        lineItemID: null
    });
    const pullAllData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleDelete = async () => {
        let res = await deleteLabourLine(state.lineItemID);
        if (res.success) {
            setState((st) => ({
                ...st,
                showDelete: false,
                lineItemDescription: null,
                lineItemID: null
            }));
            showToastSuccess(res.data?.message || 'Line deleted successfully');
            pullAllData();
        } else {
            showToastError(res.message || 'Something went wrong');
        }
    };
    const renderEdit = (ev, row) => {
        return (
            <Grid container justify="space-around" alignItems="center">
                <Grid item>
                    <EditButton
                        toolTipTitle="Edit Labour Line"
                        onClick={() => {
                            setState((st) => ({
                                ...st,
                                open: true,
                                title: 'Edit Labour Line',
                                lineItemID: row.lineItemID,
                                selectedData: row
                            }));
                        }}
                    />
                </Grid>
                <Grid item>
                    <CustomIconButton
                        icon={DeleteIcon}
                        color="secondary"
                        className="modal-btn-update"
                        toolTipTitle="Delete Standard Line"
                        onClick={() => {
                            setState((st) => ({
                                ...st,
                                showDelete: true,
                                lineItemDescription: row.lineItemDescription,
                                lineItemID: row.lineItemID
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Labour Line',
            lineItemID: null,
            selectedData: {}
        }));
    };

    const closeDialog = (res = false) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.open = null;
            newSt.lineItemID = null;
            newSt.selectedData = {};
            if (res) newSt.isReload = new Date();

            return newSt;
        });
        // res && pullcheckData();
        // res && pullTabData();
    };

    const urlState = `StandardLabourLines/StandardLabourLineItems_ByLineID/${props.lineID}`;

    return (
        <>
            <div>
                <GijgoGrid
                    columns={columns}
                    getUrl={urlState}
                    dataConverter={(res) => ({
                        records: res.data.table,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    btnTitle="Add Labour Line Item"
                    onBtnClick={() => addBtnClicked(null)}
                />
            </div>
            {state.open ? (
                <DialogComp title={state.lineItemID ? 'Update Labour Item' : 'Add Labour Item'} onClose={() => closeDialog(false)} maxWidth="md" fullWidth>
                    <AddUpdateLabourLine lineID={props.lineID} selectedData={state.selectedData} onClose={() => closeDialog(true)} />
                </DialogComp>
            ) : null}
            {state.showDelete ? (
                <DialogComp
                    title={`Delete Line Item - ${state.lineItemDescription}`}
                    onClose={() => setState((st) => ({ ...st, showDelete: false }))}
                    maxWidth="sm"
                >
                    <Grid container spacing={0} style={{ marginTop: -12 }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography component="legend">Are you sure to delete standard line?</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={handleDelete} fullWidth className="submit_btn">
                                    Yes
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <PrimaryButton onClick={() => setState((st) => ({ ...st, showDelete: false }))} fullWidth className="submit_btn">
                                    No
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </>
    );
};

export default ListStandardLabourScreen;
