import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, SecondaryCheckbox, DatePicker, DateTimePicker, SimpleField } from '../../../../Core/Controls';
import { NotesProvider, NotesScreenContext } from './NotesProvider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel } from '@material-ui/core';

const FlaggedBox = (props) => {
    // useEffect(() => {
    //     !props.chkValue && props.resetInput(props.txtName);
    // }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                        type={props.fieldType || 'text'}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const AddEditNotes = (props) => {
    const { state, btnLoader, handleSubmit, inputChange, handleCheckbox, resetInput } = useContext(NotesScreenContext);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Note Title</InputLabel>
                <InputText placeholder="Note Title" value={state.noteTitle || ''} onChange={inputChange} name="noteTitle" autoFocus={true} />
                <FormHelperText error>{state.errors.noteTitle}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Description</InputLabel>
                <InputText
                    multiline
                    rows={3}
                    placeholder="Description"
                    value={state.noteDescription}
                    onChange={inputChange}
                    name="noteDescription"
                    autoFocus={state.noteTitle ? true : false}
                />
                <FormHelperText error>{state.errors.noteDescription}</FormHelperText>
            </Grid>
            {props.frompage === 'Defects' && (
                <Grid item xs={12}>
                    <FlaggedBox
                        label="Send Message To Phone"
                        chkName="noteSendSMS"
                        chkValue={state.noteSendSMS}
                        onCheckChange={handleCheckbox}
                        txtName="noteSendSMSNumber"
                        txtValue={state.noteSendSMSNumber}
                        inputChange={inputChange}
                        resetInput={resetInput}
                        disabled={state.isDisable}
                        fieldType="number"
                    />
                    <FormHelperText error>{state.errors.noteSendSMSNumber}</FormHelperText>
                </Grid>
            )}
            {props.frompage !== 'Defects' && (
                <Grid item xs={12} sm={12}>
                    <InputLabel shrink>Settings</InputLabel>
                    <RadioGroup row aria-label="noteStickyDisplayType" name="noteStickyDisplayType" value={state.noteStickyDisplayType} onChange={inputChange}>
                        {state.NotesButtons.map((i, index) => (
                            <FormControlLabel key={index} checked={state.noteStickyDisplayType === `${i.id}`} value={i.id} control={<Radio />} label={i.name} />
                        ))}
                    </RadioGroup>
                </Grid>
            )}
            {state.noteStickyDisplayType === 'D' ? (
                <Grid container item xs={12} sm={12} spacing={2} className="mandatory-fields" style={{ marginTop: '-20px' }}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Start Date & Time"
                            value={state.noteStickyDisplayFromDate}
                            onChange={inputChange}
                            name="noteStickyDisplayFromDate"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>End Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="End Date & Time"
                            value={state.noteStickyDisplayEndDate}
                            onChange={inputChange}
                            name="noteStickyDisplayEndDate"
                            InputProps={{ inputProps: { min: `${state.noteStickyDisplayFromDate}` } }}
                        />
                    </Grid>
                    <FormHelperText error>{state.errors.noteStickyDisplayEndDate}</FormHelperText>
                </Grid>
            ) : null}
            {props.frompage === 'Jobs' ? (
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <SecondaryButton isBtnLoader={btnLoader} onClick={() => handleSubmit(false)} fullWidth>
                            Save
                        </SecondaryButton>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <SecondaryButton onClick={() => handleSubmit(true)} fullWidth >
                            Create New Note
                        </SecondaryButton>
                    </Grid> */}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} fullWidth className="submit_btn">
                        Save
                    </SecondaryButton>
                </Grid>
            )}
        </Grid>
    );
};

const Screen = (props) => {
    return (
        <NotesProvider {...props}>
            <AddEditNotes {...props} />
        </NotesProvider>
    );
};
export default Screen;
