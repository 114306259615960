import React, { useEffect, useMemo, useState } from 'react';
import AddEditDefectModal from '../Maintenance/Defects/addEditDefectScreen';
import { getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { BreadCrumbs, formatters } from '../../../Core/Controls';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DefectEditDetails from '../Maintenance/Defects/DefectEditDetails';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../Controllers/DetailsModal/serviceAgent';
import ReactDOM from 'react-dom';
import DialogComp from '../../../Core/Dialog/DialogComp';

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Deleted Records', active: true },
    { name: 'Deleted Defects', path: '/', active: true }
];

const baseColumns = [
    {
        key: 'defecteNo',
        name: 'Defect No',
        headerRenderer: formatters.ColumnHeaderAlignLeft,
        formatter: formatters.ColumnTextAlignLeft,
        width: '7%'
    },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: '7%'
    },

    { key: 'description', name: 'Details', width: '50%' },
    { key: 'contactName', name: 'Reported By', width: '15%' },
    { key: 'status', name: 'Status', width: '15%' }
];

const DeletedDefect = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        fromPage: 'Defects',
        show: false,
        filterVal: ''
    });
    let history = useHistory();

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    } else {
                        newSt.show = false;
                    }

                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
        pullDefects();
    }, [props.VehicleID, state.filterVal]);

    const pullDefects = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.defectVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        tempCols.splice(0, 0, { key: 'regNo', name: 'Reg No', width: '8%', formatter: renderRegNumberEdit });
        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCrumbs = [...baseCrumbs];
        if (state.modalEditDetail) {
            tempCrumbs.splice(3, 0, { name: `Defect Number ${state.defecteNo} [${formatters.DateTimeFormatter(state.defectDate)}]`, active: true });
        }
        return tempCrumbs;
    }, [state.modalToggle]);

    const gridBtnTitle = state.show || props.VehicleID ? 'Add Defect' : null;
    const isPage = !!(props.VehicleID || props.customerID);
    return (
        <div className={!isPage ? `screen` : ''} style={{ marginTop: 8 }}>
            <>
                {!isPage && (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                        <div style={{ width: 'auto' }}>
                            <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                        </div>
                    </div>
                )}
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={'VehicleDefects/DeletedDefect'}
                    btnTitle={gridBtnTitle}
                    idFieldName="defectID"
                    fileUploaderConfigName="DefectScreen"
                    isReload={state.isReload}
                />
            </>
        </div>
    );
};

export default DeletedDefect;
