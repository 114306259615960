import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryButton, MandatoryField, SingleSelect, SimpleField, SecondaryCheckbox } from '../../../../../Core/Controls';
import { getVmrsSubCategoryById, postVmrsSubCategoryData } from '../../../../../Core/Services/InternalUser/VMRS/vmrsSubCategoryService';
import { getAllVmrsCategorys } from '../../../../../Core/Services/InternalUser/VMRS/vmrsCategoryService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { useLoader } from '../../../../../Core/Hooks/useLoader';
import '../../../../commonStyle.scss';

const DialogStyle = {
    dialogInput: { width: '100%' },
    mandatoryLabel: { color: 'red' }
};

const AddEditVmrsModal = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        vmrsSubCategoryID: null,
        vmrsCategoryValue: '',
        vmrsSubCategoryDescription: '',
        // vmrsTaskCodeID: '',
        vmrsSubCategoryNominalCode: '',
        vmrsCategoryList: [],
        errors: {},
        vmrsSubCategoryInternalRate: '',
        vmrsSubCategoryRechargeRate: '',
        vmrsSubCategoryFixedCost: false
    });

    const [tempOptions, setTempOptions] = useState({
        vmrsCategoryValueID: null
    });
    useEffect(async () => {
        let resCategoryDropDown = await getAllVmrsCategorys();
        setState((st) => ({
            ...st,
            vmrsCategoryList: resCategoryDropDown.data.list.map((w) => ({
                id: w.vmrsCategoryID,
                name: w.vmrsDescription
            }))
        }));
        if (props.subCategoryId) {
            let res = await getVmrsSubCategoryById(props.subCategoryId);
            setState((st) => ({
                ...st,
                vmrsSubCategoryDescription: res.data.vmrsSubCategoryDescription,
                vmrsSubCategoryID: res.data.vmrsSubCategoryID,
                // vmrsTaskCodeID: res.data.vmrsTaskCodeID,
                vmrsSubCategoryNominalCode: res.data.vmrsSubCategoryNominalCode,
                vmrsCategoryValue: res.data.vmrsCategory,
                vmrsDescription: res.data.vmrsDescription,
                vmrsSubCategoryInternalRate: res.data.vmrsSubCategoryInternalRate,
                vmrsSubCategoryRechargeRate: res.data.vmrsSubCategoryRechargeRate,
                vmrsSubCategoryFixedCost: res.data.vmrsSubCategoryFixedCost
            }));
        }
    }, []);

    useEffect(() => {
        let vmrsCategoryValueID = null;
        if (state.vmrsCategoryValue) {
            vmrsCategoryValueID = state.vmrsCategoryList.find(({ id }) => id === state.vmrsCategoryValue);
        }
        setTempOptions((st) => ({
            ...st,
            vmrsCategoryValueID: vmrsCategoryValueID
        }));
    }, [state.vmrsCategoryValue]);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { vmrsSubCategoryDescription, vmrsSubCategoryNominalCode } = state;
        let formIsValid = true;
        let errors = {};

        if (!/[^\s]/.test(vmrsSubCategoryDescription)) {
            errors.vmrsSubCategoryDescription = 'This field is required';
            formIsValid = false;
        }
        // if (!/[^\s]/.test(vmrsTaskCodeID)) {
        //     errors.vmrsTaskCodeID = 'This field is required';
        //     formIsValid = false;
        if (!/[^\s]/.test(vmrsSubCategoryNominalCode)) {
            errors.vmrsSubCategoryNominalCode = 'This field is required';
            formIsValid = false;
        } else if (!/^[0-9]*$/.test(vmrsSubCategoryNominalCode)) {
            errors.vmrsSubCategoryNominalCode = 'This field accepts number 1 - 0';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const formData = {
                vmrsSubCategoryDescription: state.vmrsSubCategoryDescription,
                vmrsSubCategoryID: state.vmrsSubCategoryID,
                // vmrsTaskCodeID: state.vmrsTaskCodeID,
                vmrsSubCategoryNominalCode: state.vmrsSubCategoryNominalCode,
                vmrsCategory: tempOptions.vmrsCategoryValueID?.id,
                vmrsSubCategoryInternalRate: state.vmrsSubCategoryInternalRate,
                vmrsSubCategoryRechargeRate: state.vmrsSubCategoryRechargeRate,
                vmrsSubCategoryFixedCost: state.vmrsSubCategoryFixedCost
            };
            setBtnLoader(true);
            let res = await postVmrsSubCategoryData(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction={'row'} spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    VMRS Category
                </InputLabel>
                <SingleSelect value={tempOptions.vmrsCategoryValueID} onChange={singleSelectChange('vmrsCategoryValueID')} options={state.vmrsCategoryList} />
                <FormHelperText error>{state.errors.vmrsCategory}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    placeholder="Description"
                    value={state.vmrsSubCategoryDescription || ''}
                    onChange={inputChange}
                    name="vmrsSubCategoryDescription"
                    errors={state.errors.vmrsSubCategoryDescription}
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Nominal Code"
                    placeholder="Nominal Code"
                    value={state.vmrsSubCategoryNominalCode}
                    onChange={inputChange}
                    name="vmrsSubCategoryNominalCode"
                    errors={state.errors.vmrsSubCategoryNominalCode}
                />
            </Grid>
            <Grid item xs={4}>
                <SimpleField
                    inputLabel="Internal Rate"
                    placeholder="Internal Rate"
                    value={state.vmrsSubCategoryInternalRate}
                    onChange={inputChange}
                    name="vmrsSubCategoryInternalRate"
                    errors={state.errors.vmrsSubCategoryInternalRate}
                />
            </Grid>
            <Grid item xs={4}>
                <SimpleField
                    inputLabel="Recharge Rate"
                    placeholder="Recharge Rate"
                    value={state.vmrsSubCategoryRechargeRate}
                    onChange={inputChange}
                    name="vmrsSubCategoryRechargeRate"
                    errors={state.errors.vmrsSubCategoryRechargeRate}
                />
            </Grid>
            <Grid item xs={4}>
                <SecondaryCheckbox name="vmrsSubCategoryFixedCost" checked={state.vmrsSubCategoryFixedCost} onChange={inputChange} label="Fixed Cost?" />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddEditVmrsModal;
