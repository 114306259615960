import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import {
    SecondaryButton,
    SelectBox,
    SingleSelect,
    MandatoryField,
    SimpleField,
    DatePicker,
    PrimaryButton,
    formatters,
    DeleteButton
} from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import '../Controllers/controllerStyle.scss';
import { useLoginInfo } from '../../..//Core/Hooks/useLoginInfo';

import {
    postgetVehicleInvoiceData,
    invoiceScreenDropdowns,
    getVehicleInvoiceById,
    invoiceSearchOrderNo
} from '../../../Core/Services/InternalUser/invoicesService';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function InvoiceModalScreen(props) {
    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        errors: {},
        orderNumberList: [],
        purchaseInvoiceStatusIDList: [],
        invoiceItemTypes: [],
        invoiceAgentList: [],
        purchaseInvoiceVehicleID: props.VehicleID || null,
        purchaseInvoiceID: null,
        purchaseInvoiceAgentID: props.ServiceAgentID || null,
        purchaseInvoiceDate: '',
        purchaseInvoiceJobDate: '',
        purchaseInvoiceAgentName: '',
        purchaseInvoiceNumber: null,
        purchaseInvoiceJobID: props.JobID || '',
        purchaseInvoiceNominalCode: '',
        purchaseInvoiceStatusID: null,
        purchaseInvoiceDateDue: null,
        purchaseInvoiceOrderNumber: '',
        purchaseInvoiceDatePaid: '',
        tempInputList: []
    });

    const [inputList, setInputList] = useState([
        {
            purchaseInvoiceItemID: null,
            purchaseInvoiceItemQuantity: '',
            purchaseInvoiceItemTypeID: '',
            purchaseInvoiceItemDescription: '',
            purchaseInvoiceItemNet: '',
            purchaseInvoiceItemVAT: '',
            purchaseInvoiceItemTypeIDValue: null
        }
    ]);

    const [tempOptions, setTempOptions] = useState({
        purchaseInvoiceAgentIDValue: null,
        purchaseInvoiceStatusIDValue: null
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await invoiceScreenDropdowns();
        let data = res.data;
        setState((state) => ({
            ...state,
            purchaseInvoiceStatusIDList: data.purchaseInvoiceStatus,
            invoiceItemTypes: data.invoiceItemTypes,
            invoiceAgentList: data.serviceAgents
        }));

        if (props.invoiceID) {
            let res = await getVehicleInvoiceById(props.invoiceID);
            let data = res?.data?.details[0];
            setState((st) => ({
                ...st,
                purchaseInvoiceAgentID: data.purchaseInvoiceAgentID,
                purchaseInvoiceAgentName: data.purchaseInvoiceAgentName,
                purchaseInvoiceDate: formatters.DateFormatter(data.purchaseInvoiceDate),
                purchaseInvoiceDateDue: formatters.DateFormatter(data.purchaseInvoiceDateDue),
                purchaseInvoiceDatePaid: formatters.DateFormatter(data.purchaseInvoiceDatePaid),
                purchaseInvoiceID: data.purchaseInvoiceID,
                purchaseInvoiceJobDate: data.purchaseInvoiceJobDate,
                purchaseInvoiceJobID: data.purchaseInvoiceJobID,
                purchaseInvoiceNominalCode: data.purchaseInvoiceNominalCode,
                purchaseInvoiceNumber: data.purchaseInvoiceNumber,
                purchaseInvoiceOrderNumber: data.orderNo,
                purchaseInvoiceStatusID: data.purchaseInvoiceStatusID,
                purchaseInvoiceVehicleID: data.purchaseInvoiceVehicleID,
                tempInputList: res?.data?.purchaseInvoiceItems
            }));
        }
    }, []);

    useEffect(() => {
        getOrderNo();
    }, [state.purchaseInvoiceOrderNumber]);

    useEffect(() => {
        if (state.invoiceItemTypes) {
            if (state.tempInputList.length > 0) {
                setInputList(
                    state.tempInputList.map((o) => {
                        o.purchaseInvoiceItemTypeIDValue = state.invoiceItemTypes.find(({ id }) => id == o.purchaseInvoiceItemTypeID);
                        return o;
                    })
                );
            } else {
                return {
                    purchaseInvoiceItemID: null,
                    purchaseInvoiceItemQuantity: '',
                    purchaseInvoiceItemTypeID: '',
                    purchaseInvoiceItemDescription: '',
                    purchaseInvoiceItemNet: '',
                    purchaseInvoiceItemVAT: '',
                    purchaseInvoiceItemTypeIDValue: null
                };
            }
        }
    }, [state.invoiceItemTypes, state.tempInputList]);

    useEffect(() => {
        let newSt = {};

        if (state.purchaseInvoiceAgentID && state.invoiceAgentList.length > 0) {
            newSt.purchaseInvoiceAgentIDValue = state.invoiceAgentList.find(({ id }) => id === state.purchaseInvoiceAgentID);
        }
        if (state.purchaseInvoiceStatusID && state.purchaseInvoiceStatusIDList.length > 0) {
            newSt.purchaseInvoiceStatusIDValue = state.purchaseInvoiceStatusIDList.find(({ id }) => id === state.purchaseInvoiceStatusID);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [state.purchaseInvoiceAgentID, state.purchaseInvoiceStatusID]);

    const getOrderNo = async () => {
        if (state.purchaseInvoiceOrderNumber?.length > 3) {
            let res = await invoiceSearchOrderNo(state.purchaseInvoiceOrderNumber);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    orderNumberList: res.data.list.map((row) => ({
                        id: row.jobID,
                        name: row.jobAgentOrderNo,
                        ApproveOrderNo: row.jobApprovedOrderNo,
                        jobDate: row.jobDate
                    }))
                }));
            }
        }
    };

    const setOrderNo = (e, val) => {
        setState((state) => ({
            ...state,
            purchaseInvoiceJobDate: val?.jobDate,
            purchaseInvoiceJobID: val?.id
            //ApproveOrderNo
        }));
    };

    const inputChange = (event, val) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const singleSelectInputChange = (nm, index) => (ev, val) => {
        const list = [...inputList];
        list[index][nm] = val;
        setInputList(list);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                purchaseInvoiceItemID: null,
                purchaseInvoiceItemQuantity: '',
                purchaseInvoiceItemDescription: '',
                purchaseInvoiceItemTypeID: '',
                purchaseInvoiceItemNet: ''
            }
        ]);
    };

    const validations = () => {
        const { purchaseInvoiceStatusID, purchaseInvoiceDate, purchaseInvoiceDatePaid, purchaseInvoiceNumber, purchaseInvoiceAgentID } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        const list = [...inputList];
        let errors = {};
        if (!emptyStrigPattern.test(purchaseInvoiceDate)) {
            errors.purchaseInvoiceDate = 'Date of invoice is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(purchaseInvoiceNumber)) {
            errors.purchaseInvoiceNumber = 'Purchase invoice number is required';
            formIsValid = false;
        }
        if (!tempOptions?.purchaseInvoiceAgentIDValue?.id) {
            errors.purchaseInvoiceAgentID = 'Agent is required';
            formIsValid = false;
        }
        if (!tempOptions.purchaseInvoiceStatusIDValue) {
            errors.purchaseInvoiceStatusIDValue = 'status is required';
            formIsValid = false;
        }
        if (tempOptions?.purchaseInvoiceStatusIDValue?.id == 'P') {
            if (!emptyStrigPattern.test(purchaseInvoiceDatePaid)) {
                errors.purchaseInvoiceDatePaid = 'Paid date is required';
                formIsValid = false;
            }
        }
        if (list[0]['purchaseInvoiceItemDescription'] === '') {
            formIsValid = false;
            errors['purchaseInvoiceItemDescription'] = 'Description cannot be empty';
        }
        if (list[0]['purchaseInvoiceItemQuantity'] === '') {
            formIsValid = false;
            errors['purchaseInvoiceItemQuantity'] = 'Quantity cannot be empty';
        }
        if (list[0]['purchaseInvoiceItemNet'] === '') {
            formIsValid = false;
            errors['purchaseInvoiceItemNet'] = 'Net cannot be empty';
        }
        if (list[0]['purchaseInvoiceItemNet'] === '') {
            formIsValid = false;
            errors['purchaseInvoiceItemNet'] = 'Net cannot be empty';
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const data = {
                purchaseInvoiceID: state.purchaseInvoiceID,
                purchaseInvoiceStatusID: tempOptions.purchaseInvoiceStatusIDValue?.id,
                purchaseInvoiceVehicleID: state.purchaseInvoiceVehicleID,
                purchaseInvoiceDate: state.purchaseInvoiceDate,
                purchaseInvoiceDateDue: state.purchaseInvoiceDateDue,
                purchaseInvoiceAgentID: tempOptions.purchaseInvoiceAgentIDValue?.id,
                purchaseInvoiceNumber: state.purchaseInvoiceNumber,
                purchaseInvoiceJobDate: state.purchaseInvoiceJobDate,
                purchaseInvoiceDatePaid: state.purchaseInvoiceDatePaid,
                purchaseInvoiceJobID: state.purchaseInvoiceJobID,
                purchaseInvoiceNominalCode: state.purchaseInvoiceNominalCode,
                purchaseInvoiceItems: inputList.map((o) => {
                    if (o.purchaseInvoiceItemTypeIDValue) {
                        o.purchaseInvoiceItemTypeID = o.purchaseInvoiceItemTypeIDValue?.id;
                        delete o.purchaseInvoiceItemTypeIDValue;
                    }
                    return o;
                })
            };
            setBtnLoader(true);

            let res = await postgetVehicleInvoiceData(data);
            if (res.success) {
                showToastSuccess('Invoice saved successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Invoice Number"
                    type="number"
                    name="purchaseInvoiceNumber"
                    placeholder="Invoice Number"
                    onChange={inputChange}
                    disabled={isCustomer}
                    value={state.purchaseInvoiceNumber}
                    errors={state.errors.purchaseInvoiceNumber}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                <InputLabel shrink required>
                    Date of Invoice
                </InputLabel>
                <DatePicker name="purchaseInvoiceDate" disabled={isCustomer} value={state.purchaseInvoiceDate} onChange={inputChange} />
                <FormHelperText error>{state.errors.purchaseInvoiceDate}</FormHelperText>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink>Job Number</InputLabel>
                <SingleSelect
                    style={{ marginTop: -8 }}
                    onInputChange={(e, val) => {
                        setState((state) => ({
                            ...state,
                            purchaseInvoiceOrderNumber: val
                        }));
                    }}
                    options={state.orderNumberList}
                    disabled={isCustomer}
                    value={state.purchaseInvoiceOrderNumber}
                    onChange={setOrderNo}
                />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink>Invoice Due Date</InputLabel>
                <DatePicker
                    inputProps={{
                        min: state.purchaseInvoiceDate
                    }}
                    name="purchaseInvoiceDateDue"
                    disabled={isCustomer}
                    value={state.purchaseInvoiceDateDue}
                    onChange={inputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={`${state.purchaseInvoiceStatusID == 'P' && 'mandatory-fields'}`}>
                <InputLabel shrink required={state.purchaseInvoiceStatusID == 'P' ? true : false}>
                    Invoice Date Paid
                </InputLabel>
                <DatePicker
                    name="purchaseInvoiceDatePaid"
                    disabled={isCustomer}
                    inputProps={{
                        min: state.purchaseInvoiceDate
                    }}
                    value={state.purchaseInvoiceDatePaid}
                    onChange={inputChange}
                />
                <FormHelperText error>{state.errors.purchaseInvoiceDatePaid}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <SimpleField
                    inputLabel="Nominal Code"
                    name="purchaseInvoiceNominalCode"
                    placeholder="Nominal Code"
                    onChange={inputChange}
                    disabled={isCustomer}
                    value={state.purchaseInvoiceNominalCode}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                <InputLabel required shrink>
                    Service Area
                </InputLabel>
                {/* <SelectBox name="purchaseInvoiceAgentID" value={state.purchaseInvoiceAgentID} onChange={inputChange} List={state.invoiceAgentList} /> */}
                <SingleSelect
                    options={state.invoiceAgentList}
                    disabled={isCustomer}
                    value={tempOptions.purchaseInvoiceAgentIDValue}
                    onChange={singleSelectChange('purchaseInvoiceAgentIDValue')}
                />
                <FormHelperText error>{state.errors.purchaseInvoiceAgentID}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                <InputLabel required shrink>
                    Payment Status
                </InputLabel>
                {/* <SelectBox
                    name="purchaseInvoiceStatusID"
                   disabled={isCustomer}  value={state.purchaseInvoiceStatusID}
                    onChange={inputChange}
                    List={state.purchaseInvoiceStatusIDList}
                /> */}
                <SingleSelect
                    options={state.purchaseInvoiceStatusIDList}
                    disabled={isCustomer}
                    value={tempOptions.purchaseInvoiceStatusIDValue}
                    onChange={singleSelectChange('purchaseInvoiceStatusIDValue')}
                />
                <FormHelperText error>{state.errors.purchaseInvoiceStatusIDValue}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" color="secondary">
                    Item(s)
                </Typography>
                <hr />
            </Grid>
            <Grid item xs={12} sm={12}>
                {inputList.length > 0 &&
                    inputList.map((x, i) => (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={2} lg={1}>
                                <InputLabel required shrink>
                                    Quantity
                                </InputLabel>
                                <SimpleField
                                    name="purchaseInvoiceItemQuantity"
                                    type="number"
                                    disabled={isCustomer}
                                    value={x.purchaseInvoiceItemQuantity}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                                <FormHelperText error>{state.errors['purchaseInvoiceItemQuantity']}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={2} lg={3}>
                                <InputLabel required shrink>
                                    Description
                                </InputLabel>
                                <SimpleField
                                    name="purchaseInvoiceItemDescription"
                                    disabled={isCustomer}
                                    value={x.purchaseInvoiceItemDescription}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                                <FormHelperText error>{state.errors['purchaseInvoiceItemDescription']}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <InputLabel required shrink>
                                    Type
                                </InputLabel>
                                {/* <SelectBox
                                    name="purchaseInvoiceItemTypeID"
                                   disabled={isCustomer}  value={x.purchaseInvoiceItemTypeID}
                                    onChange={(e) => handleInputChange(e, i)}
                                    List={state.invoiceItemTypes}
                                /> */}
                                <SingleSelect
                                    options={state.invoiceItemTypes}
                                    disabled={isCustomer}
                                    value={x.purchaseInvoiceItemTypeIDValue}
                                    onChange={singleSelectInputChange('purchaseInvoiceItemTypeIDValue', i)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <InputLabel required shrink>
                                    Net
                                </InputLabel>
                                <SimpleField
                                    type="number"
                                    name="purchaseInvoiceItemNet"
                                    disabled={isCustomer}
                                    value={x.purchaseInvoiceItemNet}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                                <FormHelperText error>{state.errors['purchaseInvoiceItemNet']}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <InputLabel required shrink>
                                    VAT
                                </InputLabel>
                                <SimpleField
                                    type="number"
                                    name="purchaseInvoiceItemVAT"
                                    disabled={isCustomer}
                                    value={x.purchaseInvoiceItemVAT}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} style={{ position: 'relative' }}>
                                <InputLabel required shrink>
                                    Price (Total)
                                </InputLabel>
                                {inputList.length !== 1 && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: -8
                                        }}
                                    >
                                        <DeleteButton onClick={() => handleRemoveClick(i)} toolTipTitle="Remove Item" />

                                        {/* <Tooltip title="Remove Item">
                                            <CloseIcon color="secondary" fontSize="small" onClick={() => handleRemoveClick(i)} />
                                        </Tooltip> */}
                                    </span>
                                )}
                                <SimpleField
                                    type="number"
                                    disabled={isCustomer}
                                    //  value={+x.purchaseInvoiceItemNet * +x.purchaseInvoiceItemVAT * 0.01 + +x.purchaseInvoiceItemNet}

                                    value={+x.purchaseInvoiceItemNet + +x.purchaseInvoiceItemVAT}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                            </Grid>
                            {inputList.length - 1 === i && (
                                <Grid container justify="flex-end">
                                    <Grid>
                                        <PrimaryButton disabled={isCustomer} onClick={handleAddClick}>
                                            Add Another
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton disabled={isCustomer} isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.invoiceID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton forceEnabled={true} onClick={() => props.onClose(false)}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default InvoiceModalScreen;
