import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getPartsDetails = async (id) => {
    let res = await getWithAuth(`AdminParts/Parts_GetDetails/${id}`);
    return res;
};

export const postParts = async (data) => {
    let res = await postWithAuth(`AdminParts/Part_AddUpdate`, data);
    return res;
};
export const getWarehouseParts = async (id) => {
    let res = await getWithAuth(`AdminParts/GetWarehouseParts?id=${id}`);
    return res;
};

export const PartToBeOrdered = async (data) => {
    let res = await postWithAuth(`StandardLabourLines/PartToBeOrdered`, data);
    return res;
};
