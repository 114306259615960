import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SecondaryButton, SelectBox } from '../../../../Core/Controls';
import { ContractSchedule_AddUpdate, getContractSchedules_DropDowns, getContractType_DropDowns } from '../../../../Core/Services/Admin/ContractService';
import { useToast } from '../../../../Core/Hooks/useToast';

const ContractAddEditScreen = (props) => {
    const [state, setState] = useState({
        contractScheduleDates: [],
        contractScheduleEvents: [],
        contractScheduleEventID: props.selectedRowData.contractScheduleEventID || '',
        contractScheduleFirstEventDate: props.selectedRowData.contractScheduleFirstEventDate || '',
        contractScheduleLastEventDate: props.selectedRowData.contractScheduleLastEventDate || ''
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        let dropDownResponse = await getContractSchedules_DropDowns(props.selectedRowData?.contractScheduleID);
        setState((st) => ({
            ...st,
            contractScheduleDates: dropDownResponse.data.scheduleDates || [],
            contractScheduleEvents: dropDownResponse.data.scheduleEvents || []
        }));
    }, []);

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!state.contractScheduleEventID) {
            err.contractScheduleEventID = true;
            noErrors = false;
        }
        if (!state.contractScheduleFirstEventDate) {
            err.contractScheduleFirstEventDate = true;
            noErrors = false;
        }
        if (!state.contractScheduleLastEventDate) {
            err.contractScheduleLastEventDate = true;
            noErrors = false;
        }

        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            setBtnLoader(true);
            let data = {
                contractScheduleID: props.selectedRowData?.contractScheduleID || null,
                contractScheduleContractTypeID: props.contractTypeID,
                contractScheduleEventID: state.contractScheduleEventID,
                contractScheduleFirstEventDate: state.contractScheduleFirstEventDate,
                contractScheduleLastEventDate: state.contractScheduleLastEventDate
            };
            console.log(data, 'data');
            // let response = await ContractSchedule_AddUpdate(data);
            // if (response) {
            //     response.success
            //         ? showToastSuccess(props.selectedRowData.contractScheduleID ? 'Contract Events added successfully' : 'Contract Events updated successfully')
            //         : showToastError(response.message || 'Something went wrong');
            // } else {
            //     showToastError('Something went wrong');
            // }
            setBtnLoader(false);
            props.onClose(true);
        }
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel shrink required>
                        Schedule events
                    </InputLabel>
                    <SelectBox
                        name="contractScheduleEventID"
                        value={state.contractScheduleEventID || ''}
                        onChange={valueChanged}
                        List={state.contractScheduleEvents || []}
                    />
                    <FormHelperText error>{errors.contractScheduleEventID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel shrink required>
                        First event
                    </InputLabel>
                    <SelectBox
                        name="contractScheduleFirstEventDate"
                        value={state.contractScheduleFirstEventDate || ''}
                        onChange={valueChanged}
                        List={state.contractScheduleDates || []}
                    />
                    <FormHelperText error>{errors.contractScheduleFirstEventDate ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel shrink required>
                        Last event
                    </InputLabel>
                    <SelectBox
                        name="contractScheduleLastEventDate"
                        value={state.contractScheduleLastEventDate || ''}
                        onChange={valueChanged}
                        List={state.contractScheduleDates || []}
                    />
                    <FormHelperText error>{errors.contractScheduleLastEventDate ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        {props.selectedRowData.contractScheduleID ? 'UPDATE' : 'ADD'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ContractAddEditScreen;
