import React, { useEffect, useState } from 'react';
import { getStandardLabourLinesTabs } from '../../../Core/Services/Admin/StandardLabourLine';
import { BreadCrumbs, TabView } from '../../../Core/Controls';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import DialogComp from '../../../Core/Dialog/DialogComp';
import AddUpdateStandardLabourLine from './AddUpdateStandardLabourLine';
import ListStandardLabourScreen from './ListStandardLabour';

const crumbs = [
    { name: 'Home', path: '/' },
    // { name: 'Vehicles', active: true },
    { name: 'Standard Labour Line', active: true }
];

const StandardLabourLineTabsScreen = () => {
    const [index, setIndex] = useState(undefined);

    const [state, setState] = useState({
        standardLabourLineList: [],
        lineID: null,
        selectedData: {}
    });
    useEffect(() => {
        pullTabData();
    }, []);

    const pullTabData = async () => {
        let res = await getStandardLabourLinesTabs();
        setState((st) => ({ ...st, standardLabourLineList: res?.data?.table || [] }));
    };

    const handleSection = (val) => {
        setState((st) => ({ ...st, tabsToggle: true, lineID: val?.lineID, selectedData: val }));
    };

    const tabs = state.standardLabourLineList.map((a) => {
        return {
            label: (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'space-around', padding: 0, alignItems: 'center' }}>
                        <IconButton onClick={() => handleSection(a)}>
                            <EditIcon style={{ color: '#f50057', fontSize: 15 }} size="small" />
                        </IconButton>
                        {a.lineDescription}
                    </span>
                </div>
            ),
            // body: <ContractStatusScreen contractStatusID={a.id} />
            body: <ListStandardLabourScreen lineID={a.lineID} />
        };
    });

    const closeDialog = (res) => {
        setState((state) => ({ ...state, tabsToggle: false, lineID: null, selectedData: {} }));
        // res && pullcheckData();
        res && pullTabData();
    };

    tabs.push({
        id: null,
        label: <AddBoxIcon color="secondary" onClick={handleSection} style={{ cursor: 'pointer' }} />,
        accData: []
    });

    return (
        <>
            <div className="screen">
                <BreadCrumbs crumbs={crumbs} />
                <TabView selectedIndex={index} tabList={tabs} id="VehicleContrctStatus_tabs" />
            </div>
            {state.tabsToggle ? (
                <DialogComp title={state.lineID ? 'Update Labour Line' : 'Add Labour Line'} onClose={() => closeDialog(false)} maxWidth="sm" fullWidth>
                    <AddUpdateStandardLabourLine lineID={state.lineID} selectedData={state.selectedData} onClose={() => closeDialog(true)} />
                </DialogComp>
            ) : null}
        </>
    );
};

export default StandardLabourLineTabsScreen;
