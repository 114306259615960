import React, { useContext, useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import { getAllAdminDash } from './../Services/Admin/AdminDashboardService';
import { getAllServiceAgentDash } from './../Services/ServiceAgent/ServiceAgent';
import { getAllInternalDash } from './../Services/InternalUser/InternalDashService';
import { getAllCustomerDashboard } from './../Services/Customer/customerDashService';
import { getAllDriverDash } from './../Services/Driver/driverDashboard';
import { Link } from 'react-router-dom';
import { useLoginInfo } from '../Hooks/useLoginInfo';
import MailIcon from '@material-ui/icons/Mail';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EventIcon from '@material-ui/icons/Event';
import Tooltip from '@material-ui/core/Tooltip';
import HealingIcon from '@material-ui/icons/Healing';
import BuildIcon from '@material-ui/icons/Build';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WorkIcon from '@material-ui/icons/Work';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import { useWindowSize } from '../Hooks/useWindowSize';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MenuItem, MenuList, Popover } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { AppContext } from './PageLevel/AppContextProvider';
import DeleteIcon from '@material-ui/icons/Delete';
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArchiveIcon from '@material-ui/icons/Archive';
import SettingsIcon from '@material-ui/icons/Settings';
import { AppStorage } from '../Basic/storage-service';
import RepeatIcon from '@material-ui/icons/Repeat';

let DashNotification = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    // disabled={!getAccessParam['CanDeleteSchedules']}
    const [state, setState] = useState({
        data: [],
        deletedMenu: [
            {
                link: '/Deleted_jobs',
                title: 'Deleted Jobs',
                icons: <WorkIcon style={{ marginBottom: '-6px' }} />,
                show: true
            },
            {
                link: '/Deleted_Defects',
                title: 'Deleted Defects',
                icons: <BuildIcon style={{ marginBottom: '-6px' }} />
            }
        ],
        reportingMenu: [
            {
                link: '/controller/VorReporting/',
                title: 'VOR Reporting',
                icons: <WorkIcon style={{ marginBottom: '-6px' }} />
            },
            {
                link: '/maintenance/defect/reporting/',
                title: 'Defect Reporting',
                icons: <BuildIcon style={{ marginBottom: '-6px' }} />
            },
            {
                link: '/maintenance/breakdown/reporting/',
                title: 'Breakdown Reporting',
                icons: <BuildIcon style={{ marginBottom: '-6px' }} />
            },
            !userRoles.includes('customer') && {
                link: '/job/job_Reporting',
                title: 'Job Reporting',
                icons: <WorkIcon style={{ marginBottom: '-6px' }} />
            }
        ],
        settingMenu: [
            {
                link: '/company_details',
                title: 'Company Details',
                icons: <WorkIcon style={{ marginBottom: '-6px' }} />,
                show: true
            }
        ],
        currentTarget: null
    });
    const [anchorEl, setAnchorEl] = React.useState(null);

    // const { userRoles } = useLoginInfo();
    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    const isAdmin = userRoles.includes('admin');
    const isServiceagent = userRoles.includes('servicearea');
    const isInternalUser = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isDriver = userRoles.includes('driver');
    const [widths] = useWindowSize();
    const { commonState } = useContext(AppContext);

    useEffect(async () => {
        let res1 = '';
        let res = commonState.dashBoardData;
        if (res) {
            if (isDriver) {
                // let res = await getAllDriverDash();

                const cards = res.data.dashboard[0];
                res1 = [
                    {
                        // cnt: '',
                        link: '/maintenance/dash/Check In',
                        title: 'Check In',
                        icons: <PersonIcon />,
                        show: true
                    },
                    {
                        cnt: cards.breakdownOnsite || 0,
                        link: '/maintenance/breakdowns/typebo',
                        title: 'Breakdown On site',
                        icons: <BuildIcon />
                    },
                    {
                        cnt: cards.breakdownRoadside || 0,
                        link: '/maintenance/breakdowns/typebr',
                        title: 'Breakdown Road side',
                        icons: <LocalShippingIcon />
                    },
                    {
                        cnt: cards.driverDefect || 0,
                        link: '/maintenance/defects',
                        title: 'Driver Defect',
                        icons: <DriveEtaIcon />
                    },
                    {
                        cnt: cards.driverCheck || 0,
                        link: '/',
                        title: 'Driver Check',
                        icons: <WorkIcon />
                    }
                ];
            }
            if (isInternalUser) {
                // let res = await getAllInternalDash();

                const cards = res?.data?.dashboard[0] || {};
                res1 = [
                    {
                        cnt: cards.unreadMessageCount || 0,
                        link: '/message',
                        title: 'Message',
                        icons: <MailIcon />,
                        show: true
                    },
                    {
                        cnt: cards.activeVehicles || 0,
                        link: '/controller/Vehicles/:all',
                        title: 'Active Vehicle',
                        icons: <LocalTaxiIcon />
                    },
                    {
                        cnt: cards.breakdownOnsite || 0,
                        link: '/maintenance/breakdowns/typebo',
                        title: 'Breakdown On site',
                        icons: <BuildIcon />
                    },
                    {
                        cnt: cards.breakdownRoadside || 0,
                        link: '/maintenance/breakdowns/typebr',
                        title: 'Breakdown Road side',
                        icons: <LocalShippingIcon />
                    },
                    {
                        cnt: cards.failedChecks || 0,
                        link: '/internalDailyChecks/failed',
                        title: 'Failed Checks',
                        icons: <DriveEtaIcon />
                    },
                    {
                        cnt: cards.unassignedJobs || 0,
                        link: '/controller/unassigned-jobs',
                        title: 'Unassigned Jobs',
                        icons: <WorkIcon />,
                        isAccess: !getAccessParam['CanViewJobs']
                    },
                    {
                        cnt: cards.activeRecallCount || 0,
                        link: '/RecallsByStatus',
                        title: 'Completed Recalls',
                        icons: <RepeatIcon />,
                        isAccess: !getAccessParam['CanUpdateVehicleRecalls']
                    }
                ];
            }
            if (isServiceagent) {
                // let res = await getAllServiceAgentDash();

                const temp = res.data.dashboard;
                res1 = [
                    { cnt: temp[1]?.cnt, link: '/maintenance/breakdowns/typebo', icons: <BuildIcon />, title: 'Breakdown Onsite' },
                    { cnt: temp[2]?.cnt, link: '/maintenance/breakdowns/typebr', icons: <LocalShippingIcon />, title: 'Breakdown Road Site' },
                    { cnt: temp[5]?.cnt, link: '/serviceagent/OJ', icons: <WorkIcon />, title: 'Open Jobs', isAccess: !getAccessParam['CanViewJobs'] },
                    { cnt: temp[7]?.cnt, link: '/', icons: <LocalShippingIcon />, title: 'Schedule Defect' }
                ];
            }
            if (isAdmin) {
                // let res = await getAllAdminDash();

                const cards = res.data.dashboard[0];
                res1 = [
                    {
                        cnt: cards.activeUsers || 0,
                        link: '/users/active',
                        title: 'Active Users',
                        icons: <PeopleAltIcon />
                    },
                    {
                        cnt: cards.eventDocuments || 0,
                        link: '/vehicle/eventdocs',
                        title: 'Event Documents',
                        icons: <EventIcon />
                    },
                    {
                        cnt: cards.inspectionSheetChecks || 0,
                        link: '/vehicle/VehicleCheckChecks',
                        title: 'Inspection Sheet Checks',
                        icons: <HealingIcon />
                    },
                    {
                        cnt: cards.inspectionSheetSections || 0,
                        link: '/vehicle/checksection',
                        title: 'Inspection Sheet Section',
                        icons: <NotificationsActiveIcon />
                    },
                    {
                        cnt: cards.totalUsers || 0,
                        link: '/users/:all',
                        title: 'Total Users',
                        icons: <PeopleAltIcon />
                    }
                ];
            }
            if (isCustomer) {
                // let res = await getAllCustomerDashboard();

                const cards = res.data.dashboard[0];
                res1 = [
                    {
                        cnt: cards.unreadMessageCount || 0,
                        link: '/message',
                        title: 'Message',
                        icons: <MailIcon />,
                        show: true
                    },
                    {
                        cnt: cards.breakdownOnsite || 0,
                        link: '/maintenance/breakdowns/typebo',
                        title: 'Breakdown On site',
                        icons: <BuildIcon />
                    },
                    {
                        cnt: cards.breakdownRoadside || 0,
                        link: '/maintenance/breakdowns/typebr',
                        title: 'Breakdown Road side',
                        icons: <LocalShippingIcon />
                    },
                    {
                        cnt: cards.failedChecks || 0,
                        link: '/customerDailyChecks/failed',
                        title: 'Failed Checks',
                        icons: <DriveEtaIcon />
                    },
                    {
                        cnt: cards.unassignedJobs || 0,
                        link: '/controller/unassigned-jobs',
                        title: 'Unassigned Jobs',
                        icons: <WorkIcon />,
                        isAccess: !getAccessParam['CanViewJobs']
                    }
                ];
            }
            setState((st) => ({
                ...st,
                data: res1
            }));
        }
    }, [commonState]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const CommonPopover = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(props.currentTarget);
        const open = Boolean(anchorEl);
        const handleCloseReporting = () => {
            setAnchorEl(null);
            props.onCloseHandler(props.id);
        };

        return (
            <Popover
                id={props.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseReporting}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuList>
                    {props.menuList &&
                        props.menuList?.map((m, i) => {
                            return (
                                <MenuItem key={i}>
                                    <Link to={m.link} style={{ textDecoration: 'none', color: `black`, cursor: 'pointer' }} onClick={handleCloseReporting}>
                                        {m.icons}
                                        <span> {m.title}</span>
                                    </Link>
                                </MenuItem>
                            );
                        })}
                </MenuList>
            </Popover>
        );
    };

    const tempPop = (val, e) => {
        setState((st) => ({
            ...st,
            [val]: e.currentTarget
        }));
    };

    const tempPopClose = (val) => {
        setState((st) => ({
            ...st,
            [val]: null
        }));
    };

    return (
        <React.Fragment>
            {widths >= 996 ? (
                <>
                    {state.data?.length > 0 &&
                        state.data?.map((option, index) =>
                            (option.cnt || option.show) && !option.isAccess ? (
                                <Tooltip title={option.title} key={index} arrow>
                                    <Link style={{ textDecoration: 'none', color: `${props.textColor}` }} to={option.link}>
                                        <Badge badgeContent={option.cnt} color="secondary" max={999} showZero>
                                            {option.icons}
                                        </Badge>
                                    </Link>
                                </Tooltip>
                            ) : null
                        )}

                    {(isInternalUser || isCustomer) && (
                        <Tooltip title={'Reporting Records'} arrow onClick={(e) => tempPop('reportingCurrentTarget', e)} style={{ cursor: 'pointer' }}>
                            <Badge color="secondary" badgeContent={isInternalUser ? 4 : 3} max={999} showZero>
                                <DashboardIcon />
                            </Badge>
                        </Tooltip>
                    )}
                    <CommonPopover
                        currentTarget={state.reportingCurrentTarget}
                        menuList={state.reportingMenu}
                        id="reportingCurrentTarget"
                        onCloseHandler={tempPopClose}
                    />

                    {isInternalUser && (
                        <>
                            <Tooltip title={'Deleted Records'} arrow onClick={(e) => tempPop('deleteCurrentTarget', e)} style={{ cursor: 'pointer' }}>
                                <Badge color="secondary" badgeContent={2} max={999} showZero>
                                    <ArchiveIcon />
                                </Badge>
                            </Tooltip>
                            <CommonPopover
                                currentTarget={state.deleteCurrentTarget}
                                menuList={state.deletedMenu}
                                id="deleteCurrentTarget"
                                onCloseHandler={tempPopClose}
                            />

                            <Tooltip title={'Settings'} arrow onClick={(e) => tempPop('settingCurrentTarget', e)} style={{ cursor: 'pointer' }}>
                                <Badge color="secondary" badgeContent={1} max={999} showZero>
                                    <SettingsIcon />
                                </Badge>
                            </Tooltip>
                            <CommonPopover
                                currentTarget={state.settingCurrentTarget}
                                menuList={state.settingMenu}
                                id="settingCurrentTarget"
                                onCloseHandler={tempPopClose}
                            />
                        </>
                    )}
                </>
            ) : (
                <Tooltip title={'Deleted Records'} arrow onClick={handleClick} style={{ cursor: 'pointer' }}>
                    <Badge color="secondary" badgeContent={'!'} max={999} showZero>
                        <NotificationsActiveIcon />
                    </Badge>
                </Tooltip>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuList>
                    {state.data &&
                        state.reportingMenu &&
                        state.deletedMenu &&
                        state.settingMenu &&
                        [...state.data, ...state.reportingMenu, ...state.deletedMenu, ...state.settingMenu]?.map((m, i) => {
                            return (
                                <MenuItem key={i}>
                                    <Link to={m.link} style={{ textDecoration: 'none', color: `black`, cursor: 'pointer' }} onClick={handleClose}>
                                        <Badge badgeContent={m.cnt} color="secondary" style={{ padding: 1 }} max={999} showZero></Badge>
                                        {m.icons}
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                cursor: 'pointer',
                                                marginLeft: 1
                                            }}
                                        >
                                            {m.title}
                                        </span>
                                    </Link>
                                </MenuItem>
                            );
                        })}
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default DashNotification;
