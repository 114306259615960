import React, { useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { SecondaryButton, formatters } from '../../../../../Core/Controls';
import { InputLabel, Typography } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid } from '../../../../../Core/Controls';
import { Backup } from '@material-ui/icons';
import { postImportDepots } from '../../../../../Core/Services/InternalUser/ControllerService/depotsService';
const columns = [
    { key: 'depotName', name: 'Name', width: '11%' },
    { key: 'address1', name: 'Address1', width: '10%' },
    { key: 'city', name: 'City', width: '7%' },
    { key: 'county', name: 'County', width: '7%' },
    { key: 'postCode', name: 'Post Code', width: '6.5%' },
    { key: 'countryCode', name: 'Country Code', width: '9%' },
    { key: 'telephone', name: 'Telephone', width: '8%' },
    { key: 'email', name: 'Email', width: '11%' },
    { key: 'webSite', name: 'Web Site', width: '11%' },
    { key: 'code', name: 'Code', width: '5%' },
    { key: 'result', name: 'Result', width: '15%' }
];

const ImportDepots = (props) => {
    const [state, setState] = useState({
        errors: {},
        file: '',
        rows: [],
        showTable: false
    });

    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const handleSubmit = async () => {
        if (state.fileName && state.selectedFile) {
            setBtnLoader(true);
            setState((st) => ({ ...st, uploading: true }));
            const data = new FormData();
            data.append(state.fileName, state.selectedFile);
            let res = await postImportDepots(data);
            if (res.success) {
                setState((st) => ({ ...st, rows: res.data.res }));
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    return (
        <Grid container spacing={2} className="screen mt_10">
            <Grid item xs={12}>
                <InputLabel required>Upload file</InputLabel>
                <Tooltip title="Upload Document">
                    <label>
                        <Backup color="secondary" />
                        <input multiple type="file" onChange={onFileSelect} style={{ display: 'none' }} accept=".xlsx, .xls, .csv" />
                    </label>
                </Tooltip>
                <FormHelperText error>{state.errors.file}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={2}>
                            <SecondaryButton isBtnLoader={btnLoader} disabled={!state.selectedFile} onClick={handleSubmit} fullWidth className="submit_btn">
                                Import Depots
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                            <SecondaryButton onClick={props.onClose} fullWidth className="submit_btn">
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <DataGrid rows={state.rows} columns={columns} paginate height={350} />
            </Grid>
        </Grid>
    );
};

export default ImportDepots;
