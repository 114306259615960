import React, { useState, useEffect, useMemo } from 'react';
import { getAllDefectTypes } from '../../../../../../Core/Services/InternalUser/defectTypeService';
import DataGrid from '../../../../../../Core/Controls/Grid';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import AddEditDepartment from './addEditDepartment';
import { BreadCrumbs, AddButton, EditButton, formatters } from '../../../../../../Core/Controls';
import GijgoGrid from '../../../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
// import '../../.././commonStyle.scss';

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'departmentName', name: 'Name ', width: '15%' },
    { key: 'departmentContactName', name: 'Contact Name ', width: '15%' },
    { key: 'departmentPhone', name: 'Phone Number', width: '15%' },
    { key: 'departmentContactEmail', name: 'Email', width: '15%' }
    // { key: 'address', name: 'Address ', width: '40%' }
];

const Department = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        departmentID: null,
        open: false,
        data: null
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAdd = (params) => {
        setState((state) => ({ ...state, open: true, departmentID: params?.departmentID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleAdd(row)} toolTipTitle="Edit Department" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    }, []);

    const title = state.data ? 'Update Department' : 'Add Department';
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullData();
    };

    return (
        <div>
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={columns}
                getUrl={`Departments?DeportID=${props.depotID} `}
                onBtnClick={() => handleAdd(null)}
                btnTitle={getAccessParam['CanUpdateDepotDepartments'] ? title : ''}
                isReload={state.isReload}
            />

            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="md">
                    <AddEditDepartment data={state.data} onClose={() => closeModal(true)} departmentID={state.departmentID} depotID={props.depotID} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default Department;
