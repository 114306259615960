import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid, formatters } from '../../../../../Core/Controls';
import { getJobHistory } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

// const columns = [
//     { key: 'dateStarted', name: 'Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
//     { key: 'startedBy', name: 'Name' },
//     { key: 'status', name: 'Status' }
// ];

export const JobSingleNote = (props) => {
    const { notes } = props.note;

    return (
        <>
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic', cursor: 'pointer', padding: '5px 10px' }}>
                    {notes || 'N/A'}
                </div>
            </div>
        </>
    );
};

const JobHistory = (props) => {
    const { showToastError } = useToast();
    const [state, setState] = useState({
        showLoader: true,
        jobHistoryList: []
    });

    const pullHistoryAndUpdateState = async () => {
        let res = await getJobHistory(props.jobID);
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, jobHistoryList: res.data.jobHistoryList }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullHistoryAndUpdateState();
    }, [props.refreshDependancy]);

    return (
        <div
            item
            xs={12}
            style={{
                padding: '5px',
                // minHeight: '430px',
                height: props.height || '430px',
                overflow: 'auto'
            }}
            className="grid-style custom-scroll"
        >
            {state.jobHistoryList?.map((n, i) => (
                <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px' }}>
                    <JobSingleNote key={i} note={n} />
                </div>
            ))}
            {/* <DataGrid rows={state.jobHistoryList} columns={columns} loadingData={state.showLoader} height={props.height || 300} /> */}
        </div>
    );
};
export default JobHistory;
