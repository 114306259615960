import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { InputText, SecondaryButton, MandatoryField, SimpleField, Accordion, SingleSelect, Multiselect } from '../../../../Core/Controls';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import NoteIcon from '@material-ui/icons/Note';
import AddIcon from '@material-ui/icons/Add';
import { SelectBox } from '../../../../Core/Controls';
import { DefectsProvider as StateProvider, DefectsScreenContext } from './DefectsProvider';
import DefectDetails from './defectDetail';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import CustomizedSteppers from '../BreackdownDefectStepper/defectBreackDownStepper';

const AddEditDefectModal = (props) => {
    const { defectFields, userSettings, submitHandler, inputChange, pullDefectByID, singleSelectChange, btnLoader, ModuleChange, selectedData } =
        useContext(DefectsScreenContext);

    return (
        <div style={{ padding: 5 }}>
            {props.fromPage === 'Defects' && defectFields.defectNumberGenerated ? (
                <DefectDetails data={defectFields} isAddJobBtnShow={props.isAddJobBtnShow} pullDefectByID={pullDefectByID} />
            ) : null}
            <Grid container spacing={2}>
                {defectFields.vehicleJobProgress.map((a, index) => (
                    <Grid item xs={12} key={index}>
                        <CustomizedSteppers data={a} userData={defectFields} onStepperSubmit={pullDefectByID} />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Reporting Depot
                    </InputLabel>
                    {/* <SelectBox
                        name="defectReportingDepotID"
                        value={defectFields.defectReportingDepotID}
                        onChange={inputChange}
                        List={defectFields.depotsList || []}
                    /> */}
                    <SingleSelect
                        options={defectFields.depotsList}
                        value={defectFields.defectReportingDepotIDValue}
                        onChange={singleSelectChange('defectReportingDepotIDValue')}
                    />
                    <FormHelperText error>{defectFields.errors.defectReportingDepotID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MandatoryField
                        inputLabel="Customer Defect Number"
                        placeholder="Customer defect Number"
                        value={defectFields.defectCustomerDefectNumber}
                        onChange={inputChange}
                        name="defectCustomerDefectNumber"
                        errors={defectFields.errors.defectCustomerDefectNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Vehicle Location"
                        placeholder="Vehicle Location"
                        value={defectFields.defectLocation}
                        onChange={inputChange}
                        name="defectLocation"
                        errors={defectFields.errors.defectLocation}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField inputLabel="Mileage" placeholder="Mileage" value={defectFields.defectMileage} onChange={inputChange} name="defectMileage" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported By"
                        placeholder="Reported By"
                        value={defectFields.defectContactName}
                        onChange={inputChange}
                        name="defectContactName"
                        errors={defectFields.errors.defectContactName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported Phone"
                        placeholder="Reported Phone"
                        value={defectFields.defectContactNumber}
                        onChange={inputChange}
                        name="defectContactNumber"
                        errors={defectFields.errors.defectContactNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MandatoryField
                        inputLabel="Driver Name"
                        placeholder="Driver Name"
                        value={defectFields.defectDriverName}
                        onChange={inputChange}
                        name="defectDriverName"
                        errors={defectFields.errors.defectDriverName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MandatoryField
                        inputLabel="Driver Phone"
                        placeholder="Driver Phone"
                        value={defectFields.defectDriverTelephone}
                        onChange={inputChange}
                        name="defectDriverTelephone"
                        errors={defectFields.errors.defectDriverTelephone}
                    />
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Defect Details"
                        multiline
                        rows={3}
                        placeholder="Defect Details"
                        value={defectFields.defectDescription}
                        onChange={inputChange}
                        name="defectDescription"
                        errors={defectFields.errors.defectDescription}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Authorised By"
                        placeholder="Authorised By"
                        value={defectFields.defectAuthorisedBy}
                        onChange={inputChange}
                        name="defectAuthorisedBy"
                        errors={defectFields.errors.defectAuthorisedBy}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="Preferred Service Area"
                        placeholder="Preferred Service Area"
                        value={defectFields.preferredServiceAgent}
                        onChange={inputChange}
                        name="preferredServiceAgent"
                    />
                    <FormHelperText error>{defectFields.errors.preferredServiceAgent}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel InputLabel="" shrink required>
                        Fault Category
                    </InputLabel>
                    <Multiselect options={defectFields.faultTypes} value={selectedData.modules} onChange={ModuleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Accordion header={'Additional Details'}>
                        <Grid container spacing={2} justify="flex-start">
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container direction="row" justify="space-between" alignItems="baseline">
                                    <Grid item xs={12}>
                                        <InputLabel shrink>Already Booked?</InputLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            row
                                            aria-label="defectAlreadyBooked"
                                            name="defectAlreadyBooked"
                                            value={userSettings.defectAlreadyBooked}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectAlreadyBooked} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectAlreadyBooked} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Already Completed?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectAlreadyCompleted"
                                            name="defectAlreadyCompleted"
                                            value={userSettings.defectAlreadyCompleted}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectAlreadyCompleted} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectAlreadyCompleted} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Call Out Required</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectCallOutRequired"
                                            name="defectCallOutRequired"
                                            value={userSettings.defectCallOutRequired}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectCallOutRequired} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectCallOutRequired} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Urgent Call Out</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectUrgentCallOut"
                                            name="defectUrgentCallOut"
                                            value={userSettings.defectUrgentCallOut}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectUrgentCallOut} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectUrgentCallOut} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Put On VOR</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup row aria-label="defectVOR" name="defectVOR" value={userSettings.defectVOR} onChange={inputChange}>
                                            <FormControlLabel checked={userSettings.defectVOR} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectVOR} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Advised Don't Drive?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectAdvisedDontDrive"
                                            name="defectAdvisedDontDrive"
                                            value={userSettings.defectAdvisedDontDrive}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectAdvisedDontDrive} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectAdvisedDontDrive} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Passenger Involved?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectPassengersInvolved"
                                            name="defectPassengersInvolved"
                                            value={userSettings.defectPassengersInvolved}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectPassengersInvolved} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectPassengersInvolved} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <SimpleField
                                    inputLabel="Number Of Passenger"
                                    placeholder="Number Of Passenger"
                                    value={defectFields.defectNumberOfPassengers}
                                    onChange={inputChange}
                                    name="defectNumberOfPassengers"
                                />
                                <FormHelperText error>{defectFields.errors.defectNumberOfPassengers}</FormHelperText>
                            </Grid>

                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Accident Damage?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectAccidentDamage"
                                            name="defectAccidentDamage"
                                            value={userSettings.defectAccidentDamage}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectAccidentDamage} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectAccidentDamage} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <SimpleField
                                    inputLabel="Insurance Reference"
                                    placeholder="Insurance Reference"
                                    value={defectFields.defectInsuranceReference}
                                    onChange={inputChange}
                                    name="defectInsuranceReference"
                                />
                                <FormHelperText error>{defectFields.errors.defectInsuranceReference}</FormHelperText>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Replacement vehicle Required?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectReplacementVehicle"
                                            name="defectReplacementVehicle"
                                            value={userSettings.defectReplacementVehicle}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectReplacementVehicle} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectReplacementVehicle} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Vehicle Loaded?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectVehicleLoaded"
                                            name="defectVehicleLoaded"
                                            value={userSettings.defectVehicleLoaded}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectVehicleLoaded} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectVehicleLoaded} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                                <InputLabel required shrink>
                                    Load Type
                                </InputLabel>
                                {/* <SelectBox
                                name="defectVehicleLoadTypeID"
                                value={defectFields.defectVehicleLoadTypeID}
                                onChange={inputChange}
                                List={defectFields.vehicleLoadTypesList}
                            /> */}
                                <SingleSelect
                                    options={defectFields.vehicleLoadTypesList}
                                    value={defectFields.defectVehicleLoadTypeIDValue}
                                    onChange={singleSelectChange('defectVehicleLoadTypeIDValue')}
                                />
                                <FormHelperText error>{defectFields.errors.defectVehicleLoadTypeID}</FormHelperText>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Police On Scene?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectPoliceOnScene"
                                            name="defectPoliceOnScene"
                                            value={userSettings.defectPoliceOnScene}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectPoliceOnScene} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectPoliceOnScene} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Mobility Requested?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectMobilityRequested"
                                            name="defectMobilityRequested"
                                            value={userSettings.defectMobilityRequested}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectMobilityRequested} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectMobilityRequested} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>Mobility Used?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectMobilityUsed"
                                            name="defectMobilityUsed"
                                            value={userSettings.defectMobilityUsed}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectMobilityUsed} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectMobilityUsed} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={3} item>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <InputLabel shrink>PG9 Issued?</InputLabel>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <RadioGroup
                                            row
                                            aria-label="defectPG9Issued"
                                            name="defectPG9Issued"
                                            value={userSettings.defectPG9Issued}
                                            onChange={inputChange}
                                        >
                                            <FormControlLabel checked={userSettings.defectPG9Issued} value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel checked={!userSettings.defectPG9Issued} value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>

                <Grid container spacing={2}>
                    {!defectFields.defectID ? (
                        <Grid item xs={6} className="submit_btn">
                            <SecondaryButton onClick={submitHandler} isBtnLoader={btnLoader}>
                                Add
                            </SecondaryButton>
                        </Grid>
                    ) : (
                        <Grid item xs={6} className="submit_btn">
                            <SecondaryButton
                                onClick={() => {
                                    alert('Update Pending');
                                }}
                            >
                                Save
                            </SecondaryButton>
                        </Grid>
                    )}
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddEditDefectModal {...props} />
        </StateProvider>
    );
};
export default Screen;
