import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SelectBox, SecondaryCheckbox, DatePicker, SimpleField, YesButton, NoButton, SingleSelect } from '../../../../../Core/Controls';
import { VehicleProvider as StateProvider, VehicleScreenContext } from '../VehicleProvider';

import '../../controllerStyle.scss';

const OtherVehicleDetails = (props) => {
    // const { otherFields, otherFieldsChange } = props;
    const {
        state,
        handleSubmit,
        inputChange,
        otherFields,
        otherFieldsChange,
        jobFields,
        jobFieldsChange,
        handleCheckbox,
        dateFieldsChange,
        dateFields,
        handleTabPress,
        handleClick,
        handleActive,
        singleSelectChange,
        tempOptions
    } = useContext(VehicleScreenContext);

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Serial Number"
                    placeholder="Serial Number"
                    value={otherFields.assetNumber}
                    onChange={otherFieldsChange}
                    name="assetNumber"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Fleet Number"
                    placeholder="Fleet Number"
                    value={otherFields.fleetNumber}
                    onChange={otherFieldsChange}
                    name="fleetNumber"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Ministry Number"
                    placeholder="Ministry Number"
                    value={otherFields.ministryNumber}
                    onChange={otherFieldsChange}
                    name="ministryNumber"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField inputLabel="Colour" placeholder="Colour" value={otherFields.colour} onChange={otherFieldsChange} name="colour" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="P11D Value"
                    type="number"
                    placeholder="P11D Value"
                    value={otherFields.P11DValue}
                    onChange={otherFieldsChange}
                    name="P11DValue"
                />
                <FormHelperText error>{otherFields.errors.P11DValue}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Latest Mileage"
                    type="number"
                    placeholder="Latest Mileage"
                    value={jobFields.vehicleLatestMileage}
                    onChange={jobFieldsChange}
                    name="vehicleLatestMileage"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel shrink>Odometer Unit Code</InputLabel>
                {/* <SelectBox value={jobFields.odometerUnitCode} onChange={jobFieldsChange} name="odometerUnitCode" List={jobFields.odometerUnitCodeList} /> */}
                <SingleSelect
                    options={jobFields.odometerUnitCodeList}
                    value={tempOptions.odometerUnitCodeValue}
                    onChange={singleSelectChange('odometerUnitCodeValue')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Description"
                    placeholder="Description"
                    value={otherFields.description}
                    onChange={otherFieldsChange}
                    name="description"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Number Of Axles"
                    placeholder="Number Of Axles"
                    type="number"
                    value={otherFields.vehicleNoOfAxles}
                    onChange={otherFieldsChange}
                    name="vehicleNoOfAxles"
                />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel shrink>Service</InputLabel>
                <SelectBox value={jobFields.odometerUnitCode} onChange={jobFieldsChange} name="odometerUnitCode" List={jobFields.odometerUnitCodeList} />
                <SingleSelect
                    options={jobFields.scheduleEvents}
                    value={tempOptions.vehicleServiceScheduleIDValue}
                    onChange={singleSelectChange('vehicleServiceScheduleIDValue')}
                />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel shrink>DOT Schedule</InputLabel>
                <SelectBox value={jobFields.odometerUnitCode} onChange={jobFieldsChange} name="odometerUnitCode" List={jobFields.odometerUnitCodeList} />
                <SingleSelect
                    options={jobFields.scheduleInspection}
                    value={tempOptions.vehicleDOTScheduleIDValue}
                    onChange={singleSelectChange('vehicleDOTScheduleIDValue')}
                />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel shrink>Calibration Schedule</InputLabel>
                <SingleSelect
                    options={jobFields.calibrationSchedules}
                    value={tempOptions.vehicleCalibrationScheduleIDValue}
                    onChange={singleSelectChange('vehicleCalibrationScheduleIDValue')}
                />
            </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel shrink>Loler Test Schedule</InputLabel>
                <SingleSelect
                    options={jobFields.calibrationSchedules}
                    value={tempOptions.vehicleBrakeTestScheduleIDValue}
                    onChange={singleSelectChange('vehicleBrakeTestScheduleIDValue')}
                />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    // type="number"
                    inputProps={{ maxLength: 2 }}
                    inputLabel="Chassis Warranty Years"
                    placeholder="Chassis Warranty Years"
                    value={otherFields.vehicleChassisWarrantyYears}
                    onChange={otherFieldsChange}
                    name="vehicleChassisWarrantyYears"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputProps={{ maxLength: 2 }}
                    // type="number"
                    inputLabel="Body Warranty Years"
                    placeholder="Body Warranty Years"
                    value={otherFields.vehicleBodyWarrantyYears}
                    onChange={otherFieldsChange}
                    name="vehicleBodyWarrantyYears"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField inputLabel="Tracker" placeholder="Tracker" value={otherFields.vehicleTracker} onChange={otherFieldsChange} name="vehicleTracker" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    type="number"
                    inputLabel="Speed Limiter"
                    placeholder="Speed Limiter"
                    value={otherFields.vehicleSpeedLimiter}
                    onChange={otherFieldsChange}
                    name="vehicleSpeedLimiter"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField inputLabel="Vaisala" placeholder="Vaisala" value={otherFields.vehicleVaisala} onChange={otherFieldsChange} name="vehicleVaisala" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Distributor"
                    placeholder="Distributor"
                    value={otherFields.vehicleDistributor}
                    onChange={otherFieldsChange}
                    name="vehicleDistributor"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Camera System"
                    placeholder="Camera System"
                    value={otherFields.vehicleCameraSystem}
                    onChange={otherFieldsChange}
                    name="vehicleCameraSystem"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Sheeting System"
                    placeholder="Sheeting System"
                    value={otherFields.vehicleSheetingSystem}
                    onChange={otherFieldsChange}
                    name="vehicleSheetingSystem"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Control System"
                    placeholder="Control System"
                    value={otherFields.vehicleControlSystem}
                    onChange={otherFieldsChange}
                    name="vehicleControlSystem"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="On Board Weighing System"
                    placeholder="On Board Weighing System"
                    value={otherFields.vehicleOnboardWeighingSystem}
                    onChange={otherFieldsChange}
                    name="vehicleOnboardWeighingSystem"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Gross Weight"
                    placeholder="Gross Weight"
                    value={otherFields.vehicleGrossWeight}
                    onChange={otherFieldsChange}
                    name="vehicleGrossWeight"
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleField
                    inputLabel="Tyre Size"
                    placeholder="Tyre Size"
                    value={otherFields.vehicleTyreSizeAxle1}
                    onChange={otherFieldsChange}
                    name="vehicleTyreSizeAxle1"
                />
            </Grid>
            {/* <Grid item xs={6} sm={3}>
                <SecondaryCheckbox checked={jobFields.vehicleOffRoad} name="vehicleOffRoad" onChange={handleCheckbox} label="Vehicle Off Road?" />
            </Grid> */}
            {/* <Grid xs={12} container style={{ marginTop: 16, marginBottom: 16, marginLeft: 10 }}>
                <Grid xs={12} container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        Vehicle Off Road?
                    </Grid>
                    <Grid item xs={1}>
                        <YesButton state={jobFields.vehicleOffRoad} name="vehicleOffRoad" onClick={() => handleClick('vehicleOffRoad', true)} />
                    </Grid>
                    <Grid item xs={1}>
                        <NoButton state={jobFields.vehicleOffRoad} name="vehicleOffRoad" onClick={() => handleClick('vehicleOffRoad', false)} />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid xs={12}></Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        {/* <Paper className="controller-container"> */}
                        <Typography variant="h5" color="secondary">
                            Schedules
                        </Typography>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3} sm={6} lg={2}>
                                <InputLabel shrink>Service</InputLabel>
                                <SingleSelect
                                    options={jobFields.scheduleEvents || []}
                                    value={tempOptions.vehicleServiceScheduleIDValue}
                                    onChange={singleSelectChange('vehicleServiceScheduleIDValue')}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6} lg={2}>
                                <InputLabel shrink>DOT Schedule</InputLabel>
                                <SingleSelect
                                    options={jobFields.scheduleInspection || []}
                                    value={tempOptions.vehicleDOTScheduleIDValue}
                                    onChange={singleSelectChange('vehicleDOTScheduleIDValue')}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6} lg={2}>
                                <InputLabel shrink>Calibration Schedule</InputLabel>
                                <SingleSelect
                                    options={jobFields.calibrationSchedules || []}
                                    value={tempOptions.vehicleCalibrationScheduleIDValue}
                                    onChange={singleSelectChange('vehicleCalibrationScheduleIDValue')}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6} lg={3}>
                                <InputLabel shrink>Tachograph Calibration</InputLabel>
                                <SingleSelect
                                    options={jobFields.calibrationSchedules || []}
                                    value={tempOptions.vehicleNextTachoCalibrationScheduleIDValue}
                                    onChange={singleSelectChange('vehicleNextTachoCalibrationScheduleIDValue')}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6} lg={3}>
                                <InputLabel shrink>Brake Test Schedule</InputLabel>
                                <SingleSelect
                                    options={jobFields.calibrationSchedules || []}
                                    value={tempOptions.vehicleBrakeTestScheduleIDValue}
                                    onChange={singleSelectChange('vehicleBrakeTestScheduleIDValue')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default OtherVehicleDetails;
