import { useReducer, useMemo, useEffect } from 'react';
import { formatters, BreadCrumbs } from '../../../Core/Controls';
import EditIcon from '@material-ui/icons/Edit';
import { reducer, initState } from './inspectionReducer';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { HANDELCLOSE, HANDELADDCHECK, PULLINSPECTIONAPIDATA, HANDELCHECK, HANDELROWCLICK, CHECKBUTTON } from '../../../Action/actionName';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import EditVehicleDailyChecks from '../Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails.jsx';
import DepotDetails from '../Controllers/DetailsModal/depotDetails.jsx';
import DialogComp from '../../../Core/Dialog/DialogComp.jsx';
import ServiceAgentDetails from '../Controllers/DetailsModal/serviceAgent.jsx';
import ReactDOM from 'react-dom';
import WorkIcon from '@material-ui/icons/Work';
import { useHistory } from 'react-router-dom';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RequiredDocumentsModal from '../../ServiceAgent/Schedules/requiredDocumentsModal.jsx';
import BuildIcon from '@material-ui/icons/Build';
import VehicleDailyChecks from '../Controllers/Vehicles/VehicleDailyChecks/vehicleDailyChecks.jsx';
import { AppStorage } from '../../../Core/Basic/storage-service';

const basecolumns = [
    { key: 'action', name: 'Action', width: 140, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
    { key: 'customer', name: 'Customer Name', width: 200 },
    { key: 'depot', name: 'Depot Name', width: 150 },
    { key: 'scheduleEventName', name: 'Event', width: 130 },
    { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY, width: 85 },
    { key: 'agent', name: 'Service Area', width: 130 },
    { key: 'technician', name: 'Engineer', width: 130 }
    // { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
    // {
    //     key: 'bookingDate',
    //     name: 'Booked',
    //     formatter: formatters.Date_DDMMYYYY,
    //     width: 110
    // }
];
export default function VehicleInspection(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, dispatch] = useReducer(reducer, initState);
    const history = useHistory();
    const renderEdit = (ev, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={BuildIcon}
                    color="primary"
                    onClick={() => {
                        dispatch({ type: HANDELCHECK, payload: row });
                    }}
                    toolTipTitle="Edit Inspection Check"
                />
                {row?.jobId && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
                {row?.scheduleBookingStatusID == 5 && (
                    <CustomIconButton
                        icon={PostAddIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            dispatch({ type: CHECKBUTTON, payload: row, showRequiredDocumentsModal: true });
                        }}
                        toolTipTitle="Required Documents"
                    />
                )}

                {/* <CustomIconButton
                    icon={BuildIcon}
                    color="primary"
                    onClick={() => {
                        dispatch({ type: HANDELADDCHECK, payload: row });
                    }}
                    toolTipTitle="Add Inspection Check"
                /> */}
            </div>
        );
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobId}/${val?.scheduleVehicleID}`,
            search: '',
            state: ''
        });
    };
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val?.scheduleVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    dispatch({ type: HANDELROWCLICK, payload: row, showSaModal: true });
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.agent}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    dispatch({ type: HANDELROWCLICK, payload: row, showDepotModal: true });
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.depot}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    dispatch({ type: HANDELROWCLICK, payload: row, showCustomerModal: true });
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (props.status == '1' || props.status == '2' || props.status == '3' || !props.status) {
            tempCols.splice(4, 0, { key: 'statusDesc', name: 'Status', width: 130 });
            tempCols.splice(7, 0, { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 }, { key: 'costCentre', name: 'Cost Centre', width: 110 });
            tempCols.splice(11, 0, { key: 'jobReference', name: 'Job Reference?', width: 130 });
        }
        if (props.status == '4' || props.status == '5') {
            tempCols.splice(6, 0, { key: 'completed', name: 'Completed', width: 100 });
            tempCols.splice(
                9,
                0,
                { key: 'statusDesc', name: 'Status', width: 130 },
                { key: 'costCentre', name: 'Cost Centre', width: 110 },
                { key: 'dateOfReg', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 100 },
                { key: 'days', name: 'Days', width: 70 }
            );
        }
        let agentNameCol = tempCols.find((element) => element.key === 'agent');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        let depotNameCol = tempCols.find((element) => element.key === 'depot');
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        return tempCols;
    }, []);

    const getCrumbs = () => {
        let temp = {};
        let crumbs = [
            { name: 'Home', path: '/' },
            { name: 'Inspections', active: true }
        ];
        if (props.status == '1') {
            temp = { name: 'Repair Required', active: true };
        } else if (props.status == '2') {
            temp = { name: ' Repair In Progress', active: true };
        } else if (props.status == '3') {
            temp = { name: 'Awaiting Sign Off', active: true };
        } else if (props.status == '4') {
            temp = { name: 'Signed Off', active: true };
        } else if (props.status == '5') {
            temp = { name: 'Inspection Completed', active: true };
        } else {
            temp = { name: 'All Inspections', active: true };
        }
        crumbs.push(temp);
        return crumbs;
    };

    return (
        <div className="screen">
            {state.showBred ? <BreadCrumbs crumbs={getCrumbs()} /> : null}
            {}
            {/* {state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    onClose={() => dispatch({ type: HANDELCLOSE })}
                    displayCheck={state.selectedRowData.displayCheck}
                    frompage={false}
                />
            ) :  */}
            {state.showEditChecks ? (
                <EditVehicleDailyChecks
                    displayCheck={state.selectedRowData.displayCheck}
                    checkID={state.selectedRowData.vehicleInspectionID}
                    onClose={() => dispatch({ type: HANDELCLOSE })}
                    frompage={true}
                />
            ) : (
                <GijgoGrid
                    columns={columns}
                    idFieldName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'scheduleID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'ScheduleDocumentsRequired' : null}`}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={`VehicleInspection/GetInspections${props.status ? `?status=${props.status}` : ''}`}
                />
            )}
            {state.showRequiredDocumentsModal ? (
                <DialogComp open={true} onClose={() => dispatch({ type: HANDELCLOSE })} title={'Required Documents'} maxWidth="sm" fullWidth>
                    <RequiredDocumentsModal rowData={state.selectedRowData} onFormSubmit={() => dispatch({ type: HANDELCLOSE })} />
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={() => dispatch({ type: HANDELCLOSE })} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.agentID} onFormSubmit={() => dispatch({ type: HANDELCLOSE })} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={() => dispatch({ type: HANDELCLOSE })} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={() => dispatch({ type: HANDELCLOSE })} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={() => dispatch({ type: HANDELCLOSE })} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={() => dispatch({ type: HANDELCLOSE })} />
                </DialogComp>
            ) : null}
        </div>
    );
}
