import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getPostCodeDetails = async (val) => {
    let res = await getWithAuth(`Address/${val}`);
    return res;
};
export const getCustomerAddressDetails = async (val) => {
    let res = await getWithAuth(`Address/SearchAddress?SearchTerm=${val}`);
    return res;
};
export const getDepotsDetails = async (val) => {
    let res = await getWithAuth(`Depots/SearchDepots?SearchTerm=${val}`);
    return res;
};
export const postImportAddress = async (FileData) => {
    let res = await postWithAuth(`Address/Import_Addresses`, FileData);
    return res;
};
export const postAddress = async (data) => {
    let res = await postWithAuth('Address/Address_AddUpdate', data);
    return res;
};
