import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { SecondaryButton, MandatoryField } from '../../../../../Core/Controls';
import { postFaultTypes, getFaultTypesByID } from '../../../../../Core/Services/InternalUser/ControllerService/faultTypeService';

const AddEditFaultTypes = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        faultTypeID: null,
        faultTypeCustomerID: props.customerID,
        errors: {}
    });

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (props.faultTypeID) {
            let res = await getFaultTypesByID(props.faultTypeID);
            let data = res.data.list[0];
            if (res.success) {
                setState((st) => ({
                    ...st,
                    faultTypeID: data.faultTypeID || null,
                    faultTypeCustomerID: data.faultTypeCustomerID || null,
                    faultTypeDescription: data.faultTypeDescription || null
                }));
            }
        }
    }, []);

    let validation = () => {
        let errors = {};
        let isValid = true;
        let { faultTypeDescription } = state;
        if (!faultTypeDescription) {
            errors.faultTypeDescription = 'Description is required';
            isValid = false;
        }
        setState((st) => ({
            ...st,
            errors
        }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                faultTypeID: state.faultTypeID || null,
                faultTypeCustomerID: state.faultTypeCustomerID || null,
                faultTypeDescription: state.faultTypeDescription || null
            };
            let res = await postFaultTypes(data);
            if (res.success) {
                showToastSuccess(`Fault Type ${props.faultTypeID ? `Updated` : `Added`} Successfully`);
                props.onClose();
                setBtnLoader(false);
            } else {
                showToastError(`Fail to ${props.faultTypeID ? `Updated` : `Added`} Fault Type`);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    placeholder="Description"
                    value={state.faultTypeDescription}
                    onChange={inputChange}
                    name="faultTypeDescription"
                    errors={state.errors.faultTypeDescription}
                    autoFocus={true}
                />
            </Grid>
            <Grid item container xs={12} spacing={1} justify="space-between" style={{ marginTop: 8 }}>
                <Grid item xs={6}>
                    <SecondaryButton onClick={handleSubmit} className="submit_btn" isBtnLoader={btnLoader}>
                        {props.faultTypeID ? 'Update' : 'Submit'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton className="submit_btn" onClick={props.onClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddEditFaultTypes;
