import React, { useEffect, useMemo, useState } from 'react';
import AddEditDefectModal from '../../../InternalUser/Maintenance/Defects/addEditDefectScreen';
import { getVechicleIDByRegNO } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DefectEditDetails from '../../../InternalUser/Maintenance/Defects/DefectEditDetails';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import BuildIcon from '@material-ui/icons/Build';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Defects', path: '/', active: true }
];

const baseColumns = [
    { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: 110
    },
    { key: 'defectNumberGenerated', name: 'Defect No', width: 90 },
    { key: 'defectDescription', name: 'Defect Details' },
    { key: 'defectContactName', name: 'Reported By', width: 150 },
    { key: 'defectJobStatusDescription', name: 'Status', width: 200 },
    { key: 'defectType', name: 'DefectType', width: 150 }
];

const CustomerDefectsScreen = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        fromPage: 'Defects',
        filterVal: '',
        filterVehicleID: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    useEffect(async () => {
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
    }, []);

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);

                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                        newSt.isReload = new Date();
                    } else {
                        newSt.show = false;
                    }
                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
    }, [state.filterVal]);

    const pullDefects = async () => {
        setState((st) => ({ ...st, isReload: new Date() }));
    };
    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,
            state: 'defects'
        });
    };
    const handleEditDefect = (val) => {
        props.history.push({
            pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    const renderEdit = (data, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    toolTipTitle="Edit Breakdown"
                    // onClick={() => handleUpdate(row.defectID, row.defectVehicleID, 'modalEditDetail')}
                    onClick={() => handleEditDefect(row)}
                />
                {/* <EditButton toolTipTitle="Edit Defect" onClick={() => handleUpdate(row.defectID, row.defectVehicleID, 'modalEditDetail')} /> */}
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Book Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
            </div>
        );
    };

    let urlForcondition = `VehicleDefects${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}`;

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (!props.VehicleID) {
            tempCols.splice(1, 0, { key: 'defectRegNumber', name: 'Reg No', width: 110 });
        }
        return tempCols;
    }, []);

    const handleUpdate = (defectID, VehicleID, modalName) => {
        setState((state) => ({ ...state, defectID: defectID, rowVehicleID: VehicleID, [modalName]: true }));
    };

    const closeModalHandler = (res, modalName) => {
        setState((state) => ({ ...state, [modalName]: false }));
        res && pullDefects();
    };

    const crumbs = useMemo(() => {
        const tempCols = [...baseCrumbs];
        let actionCol = tempCols.find((element) => element.name === 'Defects');
        if (actionCol) {
            actionCol.onClick = closeModalHandler;
        }

        return tempCols;
    }, []);

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    const gridBtnTitle = !state.show && !props.VehicleID ? '' : 'Add Defect';

    return (
        <div className={!props.VehicleID ? `screen` : ''} style={{ marginTop: 8 }}>
            {state.modalToggle ? (
                <AddEditDefectModal
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID || state.filterVehicleID}
                    onClose={() => closeModalHandler(true, 'modalToggle')}
                    fromPage={state.fromPage}
                    isAddJobBtnShow={true}
                />
            ) : state.modalEditDetail ? (
                <DefectEditDetails
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID || state.filterVehicleID}
                    onClose={() => closeModalHandler(true, 'modalEditDetail')}
                    fromPage={state.fromPage}
                />
            ) : (
                <>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                        <div style={{ width: 'auto' }}>{!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}</div>
                        &nbsp;&nbsp;
                        <div style={{ width: 'auto' }}>
                            <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                        </div>
                    </div>
                    {/* {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}s */}
                    <GijgoGrid
                        columns={columns}
                        getUrl={urlForcondition}
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        btnTitle={gridBtnTitle}
                        onBtnClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                        idFieldName="defectID"
                        fileUploaderConfigName="DefectScreen"
                    />
                </>
            )}
        </div>
    );
};

export default CustomerDefectsScreen;
