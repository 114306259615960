import React, { useState, useEffect } from 'react';
import '../../../commonStyle.scss';
import { getAllJobQueryList } from '../../../../Core/Services/InternalUser/JOB/jobQueryService';
import JobDetail from './JOBItems/JobDetails';
import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { CustomChip, PrimaryButton } from '../../../../Core/Controls';
import AddEditJobsNotes from './JobDetails/Internal/addUpdateJobNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddJobUpdateNotes from './JobDetails/Internal/addJobNotes';
import JobQueryModal from './JobStatusBtn/JobQuery';

const JobSingleNote = (props) => {
    const { orderNo, name, dateCreated, message, dateReplied } = props.note;
    return (
        <>
            <div>
                <div className="msg" style={{ fontSize: 14, fontWeight: 500 }}>
                    {message}
                </div>

                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {name} @ {dateCreated ? moment(dateCreated).format('LLL') : '-'}
                </div>
                <br />
                {/* <hr style={{ opacity: '30%' }} /> */}
            </div>
        </>
    );
};
const JobQueriesScreen = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });
    useEffect(async () => {
        pullApiData();
    }, [props.refreshDependancy]);

    const pullApiData = async () => {
        let res = await getAllJobQueryList(props.JobID);
        setState((st) => ({
            ...st,
            showLoader: false,
            list: res.data.list
        }));
    };

    const handelModal = (name, val) => {
        setState((st) => ({
            ...st,
            [name]: val
        }));
    };

    const callonFormsubmit = (res) => {
        setState((st) => ({
            ...st,
            showAddJobUpdateNotes: false
        }));
        res && pullApiData();
    };
    return (
        <div>
            {/* {props.JobID && <JobDetail scheduleJobID={props.JobID} refresherFlag={state.refresherFlag} />} */}
            {state.showAddJobUpdateNotes ? (
                <DialogComp open={true} onClose={() => handelModal('showAddJobUpdateNotes', false)} title={'Job Update'} maxWidth="sm" fullWidth>
                    <JobQueryModal jobQueryJobID={props.JobID} onClose={callonFormsubmit} title={'Job Update'} hideGrid={true} />
                </DialogComp>
            ) : null}

            {!props.isHeaderNotShow && (
                <Grid
                    container
                    xs={12}
                    className="grid-style white-grid"
                    justify="space-between"
                    style={{ paddingBottom: '5px', paddingTop: '5px', paddingRight: '5px' }}
                >
                    <Grid item>
                        <Typography variant="h5" color="secondary">
                            Job Updates
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <Grid
                item
                xs={12}
                style={{
                    padding: '5px',
                    // minHeight: '430px',
                    height: props.height || '430px',
                    overflow: 'auto'
                }}
                className="grid-style custom-scroll"
            >
                {props.isDisabled && (
                    <Button color="primary" size="small" variant="text" onClick={() => handelModal('showAddJobUpdateNotes', true)}>
                        Add Job Updates
                    </Button>
                    // <CustomChip
                    //     style={{ fontWeight: 'bold' }}
                    //     icon={Add}
                    //     label="Add Reminder Notes"

                    // />
                )}
                {state.list?.map((n, i) => (
                    <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px', border: `1px dashed ${i % 2 ? '#EAD7D2' : '#EAD7D2'}` }}>
                        <JobSingleNote key={i} note={n} />
                    </div>
                ))}
            </Grid>
        </div>
    );
};

export default JobQueriesScreen;
