import React, { useState, useEffect, useMemo } from 'react';
import { getCostTypes } from '../../../../Core/Services/InternalUser/costTypeService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import CostTypesModal from './CostTypesModal';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { PrimaryButton, CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Typography } from '@material-ui/core';
import { useToast } from '../../../../Core/Hooks/useToast';
import { deleteCostType } from '../../../../Core/Services/InternalUser/costTypeService';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'Cost Types', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '7%' },
    { key: 'costTypeCode', name: 'Code', width: '6%' },
    { key: 'costTypeDescription', name: 'Description', width: '18%' },
    { key: 'internalLabourRate', name: 'Internal Rate', formatter: formatters.NumberWithPound, width: '15%' },
    { key: 'labourRate', name: 'Customer Rate', formatter: formatters.NumberWithPound, width: '15%' },
    { key: 'costTypeRechargeToCustomer', name: 'Recharge To Customer?', width: '15%' },
    { key: 'costTypeVisibleInDropdown', name: 'Visible in dropdown ?', width: '15%' },
    { key: 'costTypeAllowRechargeUpdate', name: 'Allow Recharge Update?', width: '15%' },
    { key: 'costTypePaySupplier', name: 'Pay to Supplier?', width: '15%' },
    { key: 'costTypeActive', name: 'Is Active ?', width: '9%' }
];

function CostTypes(props) {
    const { showToastError, showToastSuccess } = useToast();
    const [state, setState] = useState({
        costTypesList: [],
        loader: true,
        open: false,
        data: null,
        showDelete: false,
        costTypeDescription: null,
        costTypeCode: null
    });

    const pullAllData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleDelete = async () => {
        const data = {
            costTypeCode: state.costTypeCode
        };
        let res = await deleteCostType(data);
        if (res.success) {
            setState((st) => ({
                ...st,
                showDelete: false,
                costTypeDescription: null,
                costTypeCode: null
            }));
            showToastSuccess(res.data?.message || 'Cost Type deleted successfully');
            pullAllData();
        } else {
            showToastError(res.message || 'Something went wrong');
        }
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, data: params }));
    };

    const ActionButtons = (data, row) => {
        return (
            <Grid container justify="space-around" alignItems="center">
                <Grid item>
                    <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Cost Types" />
                </Grid>
                <Grid item>
                    <CustomIconButton
                        icon={DeleteIcon}
                        color="secondary"
                        className="modal-btn-update"
                        toolTipTitle="Delete Cost Type"
                        onClick={() => {
                            setState((st) => ({
                                ...st,
                                showDelete: true,
                                costTypeDescription: row.costTypeDescription,
                                costTypeCode: row.costTypeCode
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.data ? 'Update Cost Type' : 'Add Cost Type';
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, data: null }));
        res && pullAllData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                columns={columns}
                getUrl="CostTypes"
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Cost Type"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                    <CostTypesModal data={state.data} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
            {state.showDelete ? (
                <DialogComp
                    title={`Delete Cost Type - ${state.costTypeDescription}`}
                    onClose={() => setState((st) => ({ ...st, showDelete: false }))}
                    maxWidth="sm"
                >
                    <Grid container spacing={0} style={{ marginTop: -12 }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography component="legend">Are you sure to delete cost type?</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={handleDelete} fullWidth className="submit_btn">
                                    Yes
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <PrimaryButton onClick={() => setState((st) => ({ ...st, showDelete: false }))} fullWidth className="submit_btn">
                                    No
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </div>
    );
}
export default CostTypes;
