import React, { useEffect, useState, useMemo } from 'react';
import DataGrid from '../../../Core/Controls/Grid';
import LockIcon from '@material-ui/icons/Lock';
import AddUserModal from './addUser';
import AssignPerModal from './AssignedUser';
import { getAllUsers } from '../../../Core/Services/Admin/userService';
import { SecondaryButton } from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import { AddButton, EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import './user.scss';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DialogComp from '../../../Core/Dialog/DialogComp';
import ResetPasswordAdmin from './ResetPassword';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 90
    },
    { key: 'displayName', name: 'Name' },
    // { key: 'phoneNo', name: 'Phone No.', width: '9%' },
    // {
    //     key: 'email',
    //     name: 'Email'
    // },
    {
        key: 'userRole',
        name: 'User Type',
        width: 120
    },
    {
        key: 'isActive',
        name: 'Is Active ?',
        formatter: formatters.YesNo,
        width: 120
    },
    {
        key: 'isLocked',
        name: 'Is Locked ?',
        formatter: formatters.YesNo,
        width: 120
    }
];

const UserScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showAsgPermi: false,
        showPassReset: false,
        showLoader: true,
        userList: []
    });

    const crumbs = state.showAsgPermi
        ? [
              { name: 'Home', path: '/' },
              { name: 'Users', path: '/', active: true },
              { name: 'Permissions', path: '/', active: true },
              { name: `${state.displayName}`, path: '/', active: true }
          ]
        : [
              { name: 'Home', path: '/' },
              { name: 'Users', path: '/', active: true }
          ];

    const pullUserAndUpdateState = async () => {
        let res = await getAllUsers();
        let activeUser = res.data?.filter((active) => active.isActive == true);
        if (res.success) {
            setState((st) => ({ ...st, activeUser: activeUser, showLoader: false, userList: [...res.data] }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullUserAndUpdateState();
    }, []);

    const handleAsgPermission = (record) => {
        setState((st) => ({
            ...st,
            showAsgPermi: true,
            userId: record.id
        }));
    };

    const handleAdd = (record, screen) => {
        setState((st) => ({
            ...st,
            [screen]: true,
            userId: record?.id,
            userRole: record?.userRole,
            displayName: record?.displayName
        }));
    };

    const closeUserModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showAsgPermi: false,
            showPassReset: false
        }));
        res && pullUserAndUpdateState();
    };

    const dataFormatter = (data) => {
        return (
            <div className="user-screen">
                <EditButton onClick={() => handleAdd(data.row, 'show')} toolTipTitle="Edit User" />
                <span className="lock-btn">
                    <CustomIconButton
                        icon={LockIcon}
                        toolTipTitle="Assign Permissions"
                        forceEnabled={true}
                        onClick={() => handleAdd(data.row, 'showAsgPermi')}
                    />
                </span>
                <span className="lock-btn">
                    <CustomIconButton icon={RotateLeftIcon} toolTipTitle="Reset Password" onClick={() => handleAdd(data.row, 'showPassReset')} />
                </span>
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (window.innerWidth > 770) {
            tempCols.splice(2, 0, { key: 'phoneNo', name: 'Phone No.', width: 140 });
        }
        if (window.innerWidth > 415) {
            tempCols.splice(3, 0, { key: 'email', name: 'Email', width: 290 });
        }
        return tempCols;
    }, []);

    return (
        <div className="user-screen screen">
            <BreadCrumbs crumbs={crumbs} />
            {state.showAsgPermi ? (
                <AssignPerModal onFormSubmit={closeUserModal} userId={state.userId} userRole={state.userRole} displayName={state.displayName} />
            ) : (
                <>
                    <DataGrid
                        rows={props.match.params?.all == 'active' ? state.activeUser : state.userList}
                        columns={columns}
                        enableSearch={true}
                        loadingData={state.showLoader}
                        btnTitle="Add User"
                        onBtnClick={() => handleAdd(null, 'show')}
                        paginate
                        rowPerPage={15}
                        offset={210}
                    />
                    {state.show ? <AddUserModal userID={state.userId} onFormSubmit={closeUserModal} /> : null}

                    {state.showPassReset ? (
                        <DialogComp title="Reset Password" onClose={() => closeUserModal(false)} maxWidth="sm" fullWidth>
                            <ResetPasswordAdmin onFormSubmit={closeUserModal} userId={state.userId} />
                        </DialogComp>
                    ) : null}
                </>
            )}
        </div>
    );
};
export default UserScreen;
