import React, { useState, useEffect, useRef } from 'react';
import { FailButton, InputText, MandatoryField, NAButton, PassButton, SecondaryButton, SelectBox, MButton } from '../../../Core/Controls';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    InputLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    Tooltip,
    CircularProgress,
    FormHelperText,
    Badge
} from '@material-ui/core';
import { AppStorage } from '../../../Core/Basic/storage-service';
import CreateIcon from '@material-ui/icons/Create';
import SignaturePad from 'react-signature-canvas';
import { getVehicleById } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { getvehiclecheckchecksById, postvehiclecheckchecksData } from '../../../Core/Services/InternalUser/Vehicle/driverVehicle';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { useToast } from '../../../Core/Hooks/useToast';
import { getTitleData } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '25px',
        color: '#f50057',
        fontWeight: 600,
        paddingLeft: 9
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sign: {
        border: '1px solid grey',
        height: 240
    }
}));

const CheckButtons = {
    1: PassButton,
    2: FailButton,
    3: NAButton,
    5: MButton
};

const DriverChecks = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { displayName } = useLoginInfo();
    const classes = useStyles();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        checklist: [],
        checkType: [],
        checkOptions: [],
        vehicleID: props.filterVehicleID,
        date: moment().format('DD-MM-YYYY'),
        vehicleDailyCheckCheckTypeID: 1,
        vehicleDailyCheckStatusId: null,
        vehicleDailyCheckDateExpire: null,
        vehicleDailyCheckDriverComment: '',
        vehicleDailyCheckDriverId: null,
        vehicleDailyCheckId: null,
        selectAll: false,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        vehicleDailyCheckCheckerName: displayName,
        vehicleDailyCheckDriverName: displayName,
        desc: ''
    });

    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');

    useEffect(async () => {
        if (props.filterVehicleID) {
            let res2 = await getVehicleById(props.filterVehicleID);
            if (res2.success) {
                const data = res2.data;
                setState((st) => ({
                    ...st,
                    regNumber: data.vehicleRegNumber,
                    modelID: data.vehicleModel,
                    makeID: data.vehicleMake
                }));
            }
            let res = await getvehiclecheckchecksById(props.filterVehicleID);
            if (res.success) {
                const reportedUser = res.data.reportedUser.map((x) => ({
                    id: x.id,
                    name: x.name
                }));
                const checkType = res.data.dailyCheckTypes.map((m) => ({
                    id: m.id,
                    name: m.name
                }));
                const checkQueList = res.data.driverDailyCheckCheckSections;
                const checkOptions = res.data.driverDailyCheckOptions;

                setState((st) => ({
                    ...st,
                    checklist: checkQueList,
                    checkOptions: checkOptions,
                    checkType: checkType,
                    reportedUser: reportedUser
                }));
            }
        }
        pullDetails();
    }, []);

    const pullDetails = async () => {
        let res = await getTitleData(props.filterVehicleID);
        if (res.success) {
            let data = res.data.details[0];
            setState((st) => ({
                ...st,
                desc: data?.vehicleDescription
                // vorID: data?.vorVehicleID,
                // loader: false,
                // regNumber: data?.vehicleDescription.split('-')[0].trim()
            }));
        }
    };

    const handleButton = (id) => {
        setState((st) => ({
            ...st,
            vehicleDailyCheckCheckTypeID: id
        }));
    };

    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const checkAll = (passId) => (e) => {
        const c = e.target.checked;
        setState((st) => {
            const newSt = { ...st };
            newSt.selectAll = true;
            newSt.checklist.map((x) => {
                x.vehicleDailyCheckResultsVehicleDailyCheckOptionId = c ? passId : undefined;
            });

            newSt.checklist = [...newSt.checklist];
            return newSt;
        });
    };

    const handleButtonChange = (buttonVal, checkId) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === checkId);
        changedArray[objIndex].vehicleDailyCheckResultsVehicleDailyCheckOptionId = buttonVal;
        let val = changedArray.filter((c) => c.vehicleDailyCheckResultsVehicleDailyCheckOptionId !== 1).length === 0;
        setState((st) => ({ ...st, checklist: changedArray, selectAll: val }));
    };

    const handleNote = (id) => (e) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === id);
        changedArray[objIndex][e.target.name] = e.target.value;
        setState((st) => ({ ...st, checklist: changedArray }));
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, techniciansign: AppStorage.getSignature() }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        // if (!state.vehicleDailyCheckReportedUserID) {
        //     errors.vehicleDailyCheckReportedUserID = 'Reported User is required';
        //     formIsValid = false;
        // }
        if (!state.vehicleDailyCheckMileage) {
            errors.vehicleDailyCheckMileage = `Odometer Reading  is required`;
            formIsValid = false;
        }
        if (!state.vehicleDailyCheckDriverName) {
            errors.vehicleDailyCheckDriverName = 'Driver Name is required';
            formIsValid = false;
        }
        if (!state.techniciansign) {
            errors.techniciansign = 'Driver/Checker Signature is required';
            formIsValid = false;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        let driverDailyCheckResultsVM = state.checklist.map((m) => ({
            vehicleDailyCheckSectionID: m.dailyCheckSectionID ? m.dailyCheckSectionID : null,
            vehicleDailyCheckResultsVehicleDailyCheckOptionId: m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                ? m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                : null,
            vehicleDailyCheckResultsNotes: m.vehicleDailyCheckResultsNotes ? m.vehicleDailyCheckResultsNotes : null,
            vehicleDailyCheckResultsAssesementNotes: m.vehicleDailyCheckResultsAssesementNotes ? m.vehicleDailyCheckResultsAssesementNotes : null,
            vehicleDailyCheckResultsDefectID: m.vehicleDailyCheckResultsDefectID ? m.vehicleDailyCheckResultsDefectID : null,
            vehicleDailyCheckResultsStatusId: m.vehicleDailyCheckResultsStatusId ? m.vehicleDailyCheckResultsStatusId : null
        }));
        if (validations()) {
            setBtnLoader(true);
            let formData = {
                vehicleID: state.vehicleID,
                vehicleDailyCheckId: state.vehicleDailyCheckId,
                vehicleDailyCheckStatusId: state.vehicleDailyCheckStatusId,
                vehicleDailyCheckCheckTypeID: state.vehicleDailyCheckCheckTypeID,
                vehicleDailyCheckDateStarted: state.date,
                vehicleDailyCheckDateExpire: state.vehicleDailyCheckDateExpire,
                vehicleDailyCheckReportedUserID: state.vehicleDailyCheckReportedUserID,
                vehicleDailyCheckDriverName: state.vehicleDailyCheckDriverName,
                vehicleDailyCheckDriverId: state.vehicleDailyCheckDriverId,
                vehicleDailyCheckMileage: state.vehicleDailyCheckMileage,
                vehicleDailyCheckDriverComment: state.vehicleDailyCheckDriverComment,
                vehicleDailyCheckCheckerName: state.vehicleDailyCheckCheckerName,
                driverDailyCheckResultsVM: driverDailyCheckResultsVM,
                vehicleDailyCheckCreatorSignature: state.techniciansign
            };
            let res = await postvehiclecheckchecksData(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
            setBtnLoader(false);
        }
    };

    return (
        <div className={props.fromPage != 'checkIn' ? 'screen' : ''}>
            {props.fromPage != 'checkIn' && (
                <Typography variant="h5" color="secondary" style={{ marginBottom: 10 }}>
                    {state.desc?.toUpperCase() || ''}
                </Typography>
            )}
            <div>
                {state.desc ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <TableContainer component={Paper}>
                                <div
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.2)',
                                        padding: '10px 11px'
                                    }}
                                >
                                    <span className={classes.title}>Check Type</span>
                                    {state.checkType.map((m) => {
                                        return (
                                            <Button
                                                style={{ width: '10px', marginLeft: m.id == 1 ? 35 : 15, marginBottom: 4 }}
                                                variant={state.vehicleDailyCheckCheckTypeID === m.id ? 'contained' : 'outlined'}
                                                color="secondary"
                                                onClick={() => handleButton(m.id)}
                                            >
                                                {m.name}
                                            </Button>
                                        );
                                    })}
                                    <span className={classes.title} style={{ position: 'absolute', right: '46px' }}>
                                        {state.date}
                                    </span>
                                </div>
                                <Table aria-label="simple table" className={classes.paper}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell width="30%">Check</StyledTableCell>
                                            {state.checkOptions.map((m) => {
                                                return (
                                                    <StyledTableCell
                                                        align="center"
                                                        width={m.vehicleDailyCheckOptionDescription.toLowerCase() === 'pass' ? '7%' : '6%'}
                                                    >
                                                        {m.vehicleDailyCheckOptionDescription.toLowerCase() === 'pass' ? (
                                                            <input type="checkbox" onClick={checkAll(m.vehicleDailyCheckOptionId)} />
                                                        ) : (
                                                            ''
                                                        )}
                                                        {m.vehicleDailyCheckOptionDescription}
                                                    </StyledTableCell>
                                                );
                                            })}

                                            <StyledTableCell align="left" width="49%">
                                                Notes/Defect
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {state.checklist?.map((x) => {
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th">{x.dailyCheckSectionDescription}</StyledTableCell>
                                                    {state.checkOptions.map((m) => {
                                                        const Comp = CheckButtons[m.vehicleDailyCheckOptionId] || Button;
                                                        return (
                                                            <StyledTableCell align="center">
                                                                <Comp
                                                                    onClick={() => handleButtonChange(m.vehicleDailyCheckOptionId, x.dailyCheckSectionID)}
                                                                    state={x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === m.vehicleDailyCheckOptionId}
                                                                >
                                                                    <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                </Comp>
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                    <StyledTableCell align="left">
                                                        <InputText
                                                            error={
                                                                x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 2 ||
                                                                x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 5
                                                            }
                                                            placeholder="Notes/Defect"
                                                            name="vehicleDailyCheckResultsNotes"
                                                            onChange={handleNote(x.dailyCheckSectionID)}
                                                            value={x.vehicleDailyCheckResultsNotes}
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/*driver details...................*/}
                        <Grid item xs={12} style={{ marginTop: 12 }}>
                            <Grid container spacing={3}>
                                <Grid item container spacing={1} xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} maxwidth="sm">
                                        <InputLabel shrink>Defect Reported to *</InputLabel>
                                        <SelectBox
                                            onChange={handleInputs}
                                            value={state.vehicleDailyCheckReportedUserID}
                                            name="vehicleDailyCheckReportedUserID"
                                            List={state.reportedUser}
                                        />
                                        {/* <FormHelperText error>{state.errors?.vehicleDailyCheckReportedUserID}</FormHelperText> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} maxwidth="sm" className="mandatory-fields">
                                        <MandatoryField
                                            inputLabel="Odometer Reading"
                                            placeholder="Odometer Reading"
                                            name="vehicleDailyCheckMileage"
                                            value={state.vehicleDailyCheckMileage}
                                            onChange={handleInputs}
                                            errors={state.errors?.vehicleDailyCheckMileage}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} maxwidth="sm" className="mandatory-fields">
                                        <MandatoryField
                                            inputLabel="Driver Name"
                                            placeholder="Driver Name"
                                            name="vehicleDailyCheckDriverName"
                                            value={state.vehicleDailyCheckDriverName}
                                            onChange={handleInputs}
                                            errors={state.errors?.vehicleDailyCheckDriverName}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} maxwidth="sm">
                                        <InputLabel shrink>Checker Name</InputLabel>
                                        <InputText
                                            placeholder="Checker Name"
                                            name="vehicleDailyCheckCheckerName"
                                            value={state.vehicleDailyCheckCheckerName}
                                            onChange={handleInputs}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} className="mandatory-fields">
                                        <div>
                                            <div style={{ position: 'relative' }}>
                                                <InputLabel shrink>
                                                    Driver/Checker Signature
                                                    {state.signAvailable ? (
                                                        <Tooltip title="Use default signature">
                                                            <BorderColorIcon
                                                                onClick={useDefaultSig}
                                                                style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }}
                                                            />
                                                        </Tooltip>
                                                    ) : null}
                                                </InputLabel>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0
                                                    }}
                                                >
                                                    <Tooltip title="Clear Signature">
                                                        <DeleteIcon
                                                            onClick={clear}
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop: 5
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                {state.imgtechniciansign ? (
                                                    <div className={classes.sign}>
                                                        <img src={state.techniciansign} alt="my signature" className="sigPad" />
                                                    </div>
                                                ) : (
                                                    <div className={classes.sign}>
                                                        <SignaturePad
                                                            ref={signCanvas}
                                                            canvasProps={{ className: 'sigPad' }}
                                                            onEnd={() => {
                                                                GetTechnicianSign();
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <FormHelperText error>{state.errors?.techniciansign}</FormHelperText>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                                Submit
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                        </Grid>
                    </Grid>
                ) : (
                    <div className={classes.loader}>
                        <CircularProgress color="secondary" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DriverChecks;
