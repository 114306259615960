import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { getAllOutStandingDocuments } from '../../../../Core/Services/InternalUser/outstandingDocuments';
import { useToast } from '../../../../Core/Hooks/useToast';
import { EditButton, BreadCrumbs, DataGrid, formatters } from '../../../../Core/Controls';
// import AddEditDepots from './addEditDepots';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { GetOutstandingDocSchedulesExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';
import ScheduleSetReceivedModal from './ScheduleSetReceived';
import DescriptionIcon from '@material-ui/icons/Description';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Schedule', active: true },
    { name: 'Outstanding Documents', path: '/', active: true }
];
const baseColumns = [
    { key: 'regNumber', name: 'Reg Number', width: 110 },
    { key: 'customer', name: 'Customer Name', width: 250 },
    { key: 'depot', name: 'Depot Name', width: 250 },
    { key: 'agent', name: 'Service Area', width: 120 },
    { key: 'technician', name: 'Engineer', width: 150 },
    { key: 'dateRequired', name: 'Required', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'bookedDate', name: 'Booked', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'event', name: ' Event', width: 200 },
    { key: 'document', name: 'Document', width: 200 },
    { key: 'reminderSent', name: 'Reminder Sent', width: 121 },
    { key: 'dateOfReg', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'days', name: 'Days', width: 90 }
    // { key: 'agent', name: 'Service Area' },
    // { key: 'customer', name: 'Customer' },
    // { key: 'depot', name: 'Depot' },
    // { key: 'make', name: 'Make' },
    // { key: 'vehicleType', name: 'Vehicle Type', width: 120 }
    // { key: 'reminderSent', name: 'Reminder Sent', width: 121 }
];

const OutStandingDocuments = (props) => {
    let history = useHistory();
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    const { showToastError } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        outStandingDocsList: [],
        depotID: null,
        recordType: false,
        showSetReceived: false
    });

    // const handleAdd = (record) => {
    //     setState((st) => ({
    //         ...st,
    //         show: true,
    //         depotID: record
    //     }));
    // };

    // const closeDepotsModal = (res) => {
    //     setState((st) => ({
    //         ...st,
    //         show: false
    //     }));
    //     res && pullDepotsAndUpdateState();
    // };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            state: 'breakdown'
        });
    };
    const handleshowSetReceived = (val) => {
        setState((st) => ({ ...st, data: val, showSetReceived: true }));
    };

    const dataFormatter = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                {checkhaveAccess?.CanViewJobs && !isCustomer && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}
                {state.recordType == false && (
                    <CustomIconButton
                        icon={DescriptionIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Set Received Documents"
                        onClick={() => handleshowSetReceived(row)}
                    />
                )}
            </div>
        );
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agent}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depot}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = (res) => {
        let newst = {};
        newst.open = false;
        newst.showSaModal = false;
        newst.showCustomerModal = false;
        newst.showDepotModal = false;
        newst.showSetReceived = false;
        newst.showModal = false;
        if (res) newst.isReload = new Date();
        setState((st) => ({
            ...st,
            ...newst
        }));
        //isReload
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let regNumberCol = tempCols.find((element) => element.key === 'regNumber');
        let agentCol = tempCols.find((element) => element.key === 'agent');
        let customerCol = tempCols.find((element) => element.key === 'customer');
        let depotCol = tempCols.find((element) => element.key === 'depot');
        if (checkhaveAccess?.CanViewJobs || checkhaveAccess?.CanUploadScheduleDocuments) {
            tempCols.splice(0, 0, {
                key: 'action',
                name: 'Action',
                width: 120,
                addFileUploaderIcon: true,
                formatter: dataFormatter
            });
        }
        if (state.recordType == true) {
            tempCols.push({ key: 'completionDate', name: 'Completion Date', width: 130 });
        }
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        if (customerCol) {
            customerCol.formatter = renderCustomerName;
        }
        if (depotCol) {
            depotCol.formatter = renderDepotName;
        }
        return tempCols;
    }, [state.recordType]);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);
    let idFieldName = state.recordType == true ? 'scheduleID' : 'scheduleDocID';
    let fileUploaderConfigName = 'ScheduleDocumentsRequired';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <>
                <Grid container justify="flex-end">
                    <RadioGroup row aria-label="DefectCallOutRequired" name="recordType" value={state.recordType} onClick={inputChange}>
                        <FormControlLabel value={false} control={<Radio />} label="Outstanding" />
                        <FormControlLabel value={true} control={<Radio />} label="Received" />
                    </RadioGroup>
                </Grid>
                <GijgoGrid
                    getUrl={`Schedules/OutstandingScheduleDocuments?received=${state.recordType}`}
                    btnTitle={state.recordType ? 'Mail Documents' : ''}
                    columns={columns}
                    idFieldName={checkhaveAccess?.CanUploadScheduleDocuments ? idFieldName : ''}
                    fileUploaderConfigName={fileUploaderConfigName}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    fileUploaderModalClose={true}
                    downloadBtnName="Download xlsx"
                    downloadCustomUrl={(params) =>
                        GetOutstandingDocSchedulesExcelFile({
                            received: state.recordType,
                            fileExt: 'xlsx',
                            filterValues: params || {}
                        })
                    }
                />
            </>

            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showSetReceived ? (
                <DialogComp open={true} onClose={() => handleClose(false)} title={`Set Received (${state.data?.regNumber})`}>
                    <ScheduleSetReceivedModal val={state.data} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default OutStandingDocuments;
