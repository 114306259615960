import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryCheckbox, SecondaryButton, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { postEmailTemplatesData } from '../../../../Core/Services/InternalUser/emailTemplateService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';
import CustomeEditor from '../../../../Core/Controls/CkEditor';
import { Typography } from '@material-ui/core';

function EmailTemplatesModal(props) {
    const [state, setState] = useState({
        code: props?.data?.code || '',
        code1: props?.data?.code || '',
        subject: props?.data?.subject || '',
        body: props?.data?.body || '',
        mode: props?.data ? 'U' : 'A',
        active: props?.data?.active || true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };
    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    const validations = () => {
        const { code } = state;
        let emptyStrigPattern = RegExp('^[A-Z]{0,2}[0-9]{0,2}$');
        // /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!code) {
            errors.code = `Email Template code is required.`;
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };
    const submitHandler = async () => {
        if (validations()) {
            const emailTemplatesData = {
                emailTemplateCode: state.code,
                emailTemplateSubject: state.subject,
                emailTemplateBody: state.body,
                mode: state.mode,
                emailTemplateActive: state.active == 'Yes' ? true : false
            };
            let res = await postEmailTemplatesData(emailTemplatesData);
            if (res.success) {
                showToastSuccess('Email Templates Updated sucessfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Code"
                    name="code"
                    placeholder="Code"
                    onChange={handleInputChange}
                    value={state.code}
                    errors={state.errors.code}
                    minlength="2"
                    disabled={!!state.code1}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleField inputLabel="Subject" name="subject" placeholder="Subject" onChange={handleInputChange} value={state.subject} />
            </Grid>
            <Grid item xs={12}>
                {/* <SimpleField inputLabel="Body" multiline rows={6} name="body" placeholder="Body" onChange={handleInputChange} value={state.body} /> */}
                <Typography variant="body2">Body</Typography>
                <hr />
                <CustomeEditor data={state.body} onChange={handleCkeditorstate('body')} placeholder="Add description here" name="body" />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.active} name="active" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.data ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default EmailTemplatesModal;
