import React, { useState, useEffect, useRef } from 'react';
import { Grid, IconButton, InputAdornment, makeStyles, OutlinedInput, Paper, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { getMessageByID, sendMessage } from '../../../../../Core/Services/InternalUser/messageCustomer';
import '../../../../commonStyle.scss';
import '../../controllerStyle.scss';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { useToast } from '../../../../../Core/Hooks/useToast';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '40%',
        marginLeft: 'auto',
        margin: 10,
        backgroundColor: '#fef0d2',
        padding: 20
    },
    outgoning: {
        width: '40%',
        margin: 10,
        backgroundColor: '#a7ebf3',
        padding: 20
    },
    empty: {
        textAlign: 'center',
        margin: 10,
        padding: 20
    },
    customScroll: {
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.1em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#1976d2',
            opacity: 5,
            outline: '1px solid slategrey'
        }
    },
    activeBorder: {
        borderLeft: '7px solid #1976d2 '
    }
}));

const borderStyle = {
    br: {
        borderRight: '5px solid #ffae00'
    },
    bl: {
        borderLeft: '5px solid #1976d2'
    }
};

const JobMessages = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { displayName } = useLoginInfo();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    let [loader, setLoader] = useState(false);

    const [state, setState] = useState({
        message: null,
        showLoader: true,
        messageList: []
    });

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const pullMessageList = async () => {
        let res = await getMessageByID(props.scheduleJobID);
        if (res.success) {
            setState((st) => ({
                ...st,
                messageList: res.data.list?.sort((a, b) => (a.sdate > b.sdate ? 1 : b.sdate > a.sdate ? -1 : 0)),
                showLoader: false
            }));
        }
    };

    useEffect(async () => {
        pullMessageList();
    }, []);

    const validation = () => {
        let isValid = true;
        if (!state.message) {
            isValid = false;
            showToastError('Message is required');
        }
        if (state.message == '') {
            isValid = false;
            showToastError('Message is required');
        }
        return isValid;
    };

    const handleClick = async () => {
        if (validation()) {
            setLoader(true);
            let res = await sendMessage(props.scheduleJobID, state.message);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    message: ''
                }));
                pullMessageList();
            }
            setLoader(false);
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    function handleEnter(e) {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            handleClick();
        }
    }

    const classes = useStyles();

    return (
        <div className="controller-screen">
            <Grid
                container
                style={{
                    height: 'calc(100vh - 320px)',
                    // background: 'red',
                    overflow: 'hidden',
                    marginTop: '20px',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    border: '1px solid rgba(100, 100, 111, 0.2)'
                    // box-shadow: ;
                }}
            >
                {' '}
                {!isCustomer && null}
                <Grid item xs={12} md={12} lg={12} xl={12} style={{ padding: '0px 10px 10px 10px' }}>
                    <Typography variant="h6">&nbsp;</Typography>
                    {state.showLoader ? (
                        <div
                            style={{
                                height: 'calc(100vh - 400px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    ) : (
                        <div className={classes.customScroll} style={{ height: 'calc(100vh - 420px)' }}>
                            {state.messageList?.length !== 0 ? (
                                <>
                                    {state.messageList?.map((m, i) => {
                                        let classText = m.name === displayName ? classes.outgoning : classes.paper;

                                        return (
                                            <div>
                                                <Paper
                                                    className={classText}
                                                    style={m.rdate ? (m.name === displayName ? borderStyle.bl : borderStyle.br) : null}
                                                >
                                                    <Grid container spacing={2}>
                                                        <div style={{ width: '100%' }}>
                                                            <Typography>{m.notes}</Typography>
                                                        </div>
                                                        <div style={{ width: '50%' }}>
                                                            <small>
                                                                {m.name} @ {moment(m.sdate).format('MMM Do YYYY, h:mm a')}
                                                            </small>
                                                        </div>

                                                        {m.rdate && (
                                                            <div style={{ width: '50%', textAlign: 'right' }}>
                                                                <small> seen {moment(m.rdate).format('MMM Do YYYY, h:mm a')}</small>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        );
                                    })}
                                    <AlwaysScrollToBottom />
                                </>
                            ) : (
                                <div>
                                    <div className={classes.empty}>
                                        <Grid container spacing={2}>
                                            <div style={{ width: '100%' }}>
                                                <Typography>There are no messages sent/received</Typography>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <small>{moment().format('MMM Do YYYY, h:mm a')}</small>
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}>
                            <OutlinedInput
                                fullWidth
                                placeholder="Type a new message"
                                name="message"
                                value={state.message}
                                onChange={handleInput}
                                onKeyDown={handleEnter}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {loader ? (
                                            <CircularProgress color="secondary" />
                                        ) : (
                                            <IconButton onClick={handleClick} edge="end">
                                                <SendIcon style={{ cursor: 'pointer' }} color="secondary" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default JobMessages;
