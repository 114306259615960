import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { getEngineerDashboard } from '../../Core/Services/Dashboard/EngineerDash';
import moment from 'moment';

const LazyComponent = lazy(() => import('./dashboardUserInterface'));

const MainDashboard = (props) => (
    <Suspense fallback={<div>please Wait...</div>}>
        <LazyComponent {...props} />
    </Suspense>
);

const TechDashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    const singleCardData = [
        {
            name: 'Not Started ',
            value: dashboardData.jobsNotStarted,
            // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
            path: '/serviceagent/OJ',
            params: { vehicleJobStatus: 'Not Started' },
            valueColor: '#0096FF'
        },
        {
            innerComponent: [
                { name: 'On Hire ', value: dashboardData.onHireService, background: '', path: '/Contracts_Hire_Fleet', params: { status: 'On Hire' } },
                { name: 'Off Hire ', value: dashboardData.offHireService, background: '', path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' } }
            ],

            lg: 3,
            isInnerComp: true
        },
        {
            innerComponent: [
                {
                    name: 'Breakdown',
                    value: dashboardData.breakdowns,
                    // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
                    path: '/maintenance/breakdowns/:all',
                    lg: 2,
                    valueColor: 'red'
                },
                {
                    name: 'VOR',
                    value: dashboardData.vor,
                    //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/controller/vor',
                    valueColor: 'red'
                }
            ],
            lg: 3,
            isInnerComp: true
        }
    ];

    const groupCardData = [
        {
            title: 'MY JOBS',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',

            // path: '/serviceagent/OJ',
            items: [
                { label: 'All jobs', value: dashboardData.jobsTotal, path: '/serviceagent/OJ', params: { vehicleJobStatus: '' } },
                { label: 'Awaiting Parts', value: dashboardData.jobsAwaitingParts, path: '/serviceagent/OJ', params: { vehicleJobStatus: 'Awaiting Part' } },
                { label: 'Paused', value: dashboardData.jobsPaused, path: '/serviceagent/OJ', params: { vehicleJobStatus: 'Paused' } },
                { label: 'Work in progress', value: dashboardData.jobsWIP, path: '/serviceagent/OJ', params: { vehicleJobStatus: 'Work In Progress' } }
            ]
        },
        {
            title: 'EVENTS',
            // path: '/schedule/confirmedbookings',
            items: [
                {
                    label: 'Overdue',
                    value: dashboardData.scheduleOverdue,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=""&end=${moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')}`,
                    valueColor: 'red'
                },
                {
                    label: 'This Week',
                    value: dashboardData.scheduleThisWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().startOf('week').format('YYYY-MM-DD')}&end=${moment().endOf('week').format('YYYY-MM-DD')}`
                },
                {
                    label: 'Next Week',
                    value: dashboardData.scheduleNextWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('week')
                        .format('YYYY-MM-DD')}`
                },
                {
                    label: 'Future',
                    value: dashboardData.scheduleFuture,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(2, 'weeks').startOf('week').format('YYYY-MM-DD')}`
                }
            ]
        },
        {
            title: 'CONTRACT',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            // path: '/Contracts_Hire_Fleet',
            itemSize: {
                lg: 12,
                md: 12,
                sm: 12,
                xs: 6
            },
            items: [
                { label: 'Hire', value: dashboardData.hireActive, path: '/Contracts_Hire_Fleet', params: { status: '' } },
                { label: 'Maintenance', value: dashboardData.contractMaintenanceActive, path: '/Contracts_Maintenance' }
            ]
        },
        {
            title: 'WAREHOUSE',
            path: '/parts',
            items: [
                { label: 'Parts', value: dashboardData.warehouseParts },
                { label: 'On Order', value: 0 },
                { label: 'In Transit', value: 0 },
                { label: 'Back Order', value: 0 }
            ]
        }
    ];

    const pullDashboardData = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const res = await getEngineerDashboard();

            if (res.success) {
                setDashboardData(res.data.dashboard[0]);
            } else {
                console.warn('Unexpected response:', res);
            }
        } catch (error) {
            console.error('API Error:', error.response.data);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }, []);

    useEffect(() => {
        pullDashboardData();
    }, [pullDashboardData]);

    return (
        <div style={{ padding: 10 }}>
            <MainDashboard singleCardData={singleCardData} groupCardData={groupCardData} loading={loading} pullDashboardData={pullDashboardData} />
        </div>
    );
};

export default TechDashboard;
