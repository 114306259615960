import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getContractType_DropDowns = async () => {
    let res = await getWithAuth(`ContractSchedules/ContractType_DropDowns`);
    return res;
};

export const getContractSchedules_DropDowns = async (ContractScheduleID = '') => {
    let res = await getWithAuth(`ContractSchedules/ContractSchedules_DropDowns?ContractScheduleID=${ContractScheduleID}`);
    return res;
};

export const ContractSchedule_AddUpdate = async (data) => {
    let res = await postWithAuth('ContractSchedules/ContractSchedule_AddUpdate', data);
    return res;
};
