import React, { useMemo, useEffect, useCallback } from 'react';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import { PhoneIphone, AccessTime, Email } from '@material-ui/icons';
import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MenuItem, MenuList } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import MySignature from './userSignature';
import { UserSignatureReminder } from './userSignature';
import { AppStorage } from '../Basic/storage-service';
import { postSignature, postSignatureStatus } from '../Services/signatureService';
import { SelectBox, BreadCrumbs, MandatoryField, SimpleField, DatePicker } from '../Controls';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import { getVechicleIDByRegNO } from '../Services/InternalUser/ServiceAgent/breakdownService';
import ChangePasswordComp from '../../Core/Auth/ChangePasswordComp';

import { usePortalSettings } from '../Hooks/usePortalSettings';
import { SecondaryButton } from '../Controls';
import { useLoginInfo } from '../Hooks/useLoginInfo';
import MiniDrawer from '../NavAndMenu/AppNavbar';
import DashNotification from './DashNotification';
import SearchIcon from '@material-ui/icons/Search';
import DialogComp from '../Dialog/DialogComp';
import { useToast } from '../Hooks/useToast';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useWindowSize } from '../Hooks/useWindowSize';
import FilterCustomerList from './FilteredCustomerList';

const drawerWidth = 235;
const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: 173,
        maxHeight: 50
    },
    appBar: {
        backgroundColor: (props) => `${props.portalHeaderBackgroundColour}`,
        color: (props) => `${props.portalHeaderTextColour}`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 0
    },
    hide: {
        display: 'none'
    },
    logoutBtn: {
        display: 'inline-flex',
        VerticalAlign: 'text-bottom',
        BoxSizing: 'inherit',
        textAlign: 'center',
        AlignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        marginLeft: 1
    },
    root: {
        '& > *': {
            margin: theme.spacing(2)
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        maxHeight: '35px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));

export const RouterAndView = (props) => {
    const portalSettings = usePortalSettings();
    const [windowWidth, windowHeight] = useWindowSize();
    const { isLoggedIn, displayName, lastLogin, userRoles } = useLoginInfo();
    const showTopNavAndMenu = true;
    const theme = useTheme();
    const itemArray = useMemo(() => {
        return [
            { text: 'Call US', value: portalSettings.portalSettingCallUs, icon: PhoneIphone, isShow: false },
            { text: 'Opening Time', value: portalSettings.portalSettingOpeningTime, icon: AccessTime, isShow: true },
            { text: 'Email US', value: portalSettings.portalSettingEmailUs, icon: Email, isShow: true }
        ];
    }, [portalSettings]);
    const classes = useStyles(portalSettings);
    const [state, setState] = React.useState({});
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSign, setOpenSign] = React.useState(false);
    const [openSignRem, setOpenSignRem] = React.useState(false);
    const [passReset, setPassReset] = React.useState(false);

    const { showToastSuccess, showToastError } = useToast();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (isLoggedIn) {
            windowWidth >= 1200 ? setDrawerOpen(true) : setDrawerOpen(false);
        }
    }, [isLoggedIn, windowWidth]);

    useEffect(() => {
        if (isLoggedIn && AppStorage.getSignNotifiedStatus() === 0 && AppStorage.getSignatureStatus() != 1 && AppStorage.getSignatureStatus() != 2) {
            setOpenSignRem(true);
            AppStorage.setSignNotifiedStatus('set');
        }
    }, []);

    const postSignData = async (sign, id) => {
        let resSign = sign && (await postSignature(sign));
        let resStatus = id && (await postSignatureStatus(id));
        if (resSign) {
            if (resSign?.success) {
                AppStorage.setSignature(sign);
                showToastSuccess('Signature Updated Successfully!');
            } else {
                showToastError(resSign?.message || 'Something went wrong!');
            }
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeSign = () => {
        setOpenSign(!openSign);
    };

    const changePass = () => {
        setPassReset(!passReset);
    };

    const closeSignRem = () => {
        setOpenSignRem(false);
    };

    useEffect(() => {
        if (state.show && state.filterVehicleId) {
            const URL = `/controller/editVehicle/${state.filterVehicleId}`;
            window.location.href = window.location.origin + URL;
        }
    }, [state.show, state.filterVehicleId]);

    const inputChange = useCallback(async (e) => {
        const { name, value, type } = e.target;
        if (value.length > 4) {
            let resBrekDown = await getVechicleIDByRegNO(value);
            setState((st) => {
                let newSt = { ...st };
                if (resBrekDown.data?.list) {
                    newSt.show = resBrekDown.success;
                    newSt.filterVehicleId = resBrekDown.data?.list[0].vehicleID;
                    newSt.filterVehicleMakeModel = resBrekDown.data?.list[0].vehicleMakeModel;
                    newSt.vehicleReg = value;
                } else {
                    newSt.show = false;
                }

                return newSt;
            });
        } else {
            setState((st) => ({ ...st, show: false }));
        }
    }, []);

    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;

    let isAdmin = userRoles.includes('admin');
    return (
        <>
            <BrowserRouter>
                <div className="app-nav">
                    <AppBar
                        varient="info"
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: drawerOpen
                        })}
                    >
                        <Toolbar className={drawerOpen ? `nav-menu` : `nav-toobar`}>
                            {!isLoggedIn && (
                                <NavLink to="/" className="branding-logo">
                                    <img src={img} className={classes.logo} />
                                </NavLink>
                            )}
                            {isLoggedIn && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        className={clsx(classes.menuButton, {
                                            [classes.hide]: drawerOpen
                                        })}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <div>
                                        {portalSettings.portalSettingAdditionalLogo && (
                                            <img src={`${portalSettings.portalSettingAdditionalLogo}`} className={classes.logo} />
                                        )}
                                    </div>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search Reg No…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }}
                                            onChange={inputChange}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                    {/* {state.show && (
                                        <Link
                                            style={{ textDecoration: 'none', color: `${portalSettings.portalHeaderTextColour}` }}
                                            to={{
                                                pathname: `/controller/editVehicle/${state.filterVehicleId}`
                                            }}
                                        >
                                            <IconButton color="inherit" aria-label="find" className={classes.margin}>
                                                <FindInPageIcon />
                                            </IconButton>
                                        </Link>
                                    )} */}
                                </div>
                            )}
                            {!isLoggedIn && (
                                <div className="portal-info">
                                    {itemArray.map((c, i) => (
                                        <div
                                            className={'info ' + (c.isShow ? 'nav-bar-icons' : null)}
                                            style={{ borderLeft: c.isShow ? `2px solid ${portalSettings.portalHeaderTextColour}` : 'none' }}
                                            key={i}
                                        >
                                            <div className="icon">
                                                <c.icon fontSize="large" />
                                            </div>
                                            <div className="key-value">
                                                <label>{c.text}</label>
                                                <label className="value">{c.value}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {isLoggedIn ? (
                                <div className="nav-toobar">
                                    <div className={classes.root} style={{ marginRight: 8 }}>
                                        <FilterCustomerList textColor={portalSettings.portalHeaderTextColour} />
                                    </div>

                                    {portalSettings.portalSettingDisplayTopNavCounters ? (
                                        <div className={classes.root} style={{ marginRight: 8 }}>
                                            <DashNotification textColor={portalSettings.portalHeaderTextColour} />
                                        </div>
                                    ) : null}
                                    <div aria-describedby={id} onClick={handleClick} className={classes.logoutBtn}>
                                        <span>{displayName}</span>
                                        <ExpandMore />
                                    </div>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                    >
                                        <MenuList>
                                            <MenuItem>
                                                <Link to="/logout" className={classes.logoutBtn}>
                                                    <ExitToAppIcon style={{ marginRight: '4px' }} />
                                                    <span>Logout</span>
                                                </Link>
                                            </MenuItem>
                                            {!isAdmin && (
                                                <MenuItem>
                                                    {/* <Link to="/logout" className={classes.logoutBtn}> */}
                                                    <BorderColorIcon style={{ marginRight: '4px' }} />
                                                    <span onClick={closeSign}>My Signature</span>
                                                    {/* </Link> */}
                                                </MenuItem>
                                            )}
                                            <MenuItem>
                                                {/* <Link to="/logout" className={classes.logoutBtn}> */}
                                                <VpnKeyIcon style={{ marginRight: '4px' }} />
                                                <span onClick={changePass}>Change Password</span>
                                                {/* </Link> */}
                                            </MenuItem>
                                            <MenuItem>
                                                <span>Version: v1.21.0 (22/03/2024)</span>
                                            </MenuItem>
                                        </MenuList>
                                    </Popover>
                                </div>
                            ) : (
                                <Link to="/login" style={{ textDecoration: 'none' }}>
                                    <SecondaryButton>Login</SecondaryButton>
                                </Link>
                            )}
                        </Toolbar>
                    </AppBar>
                    <MiniDrawer isDrawer={drawerOpen} opneDrawer={handleDrawerOpen} closeDrawer={handleDrawerClose} portalSettings={portalSettings} />
                </div>
            </BrowserRouter>
            {openSign && (
                <DialogComp title="My Signature" open={true} onClose={closeSign} maxWidth="sm">
                    <MySignature onClose={closeSign} postData={postSignData} />
                </DialogComp>
            )}
            {passReset && (
                <DialogComp title="Reset Password" open={true} onClose={changePass} maxWidth="sm">
                    <ChangePasswordComp onClose={changePass} />
                </DialogComp>
            )}
            {!isAdmin && openSignRem && <UserSignatureReminder closeSign={closeSign} onClose={closeSignRem} postData={postSignData} />}
        </>
    );
};
