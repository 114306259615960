import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect, SelectBox, YesButton, NoButton, SecondaryCheckbox } from '../../../Core/Controls';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { truncate } from 'lodash';

const DropdownModal = (props) => {
    const { state, masterData, fieldChange, selectedData, BranchChange, ModuleChange, DepotChange, handleClick, onSelectCheckbox, ServiceChange } = props;
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel required shrink>
                    User Type
                </InputLabel>
                <FormControl component="fieldset">
                    <Grid item xs={12}>
                        <RadioGroup row aria-label="userType" name="userType" value={state.userType} onChange={fieldChange}>
                            <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                            <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                            <FormControlLabel value="internal" control={<Radio />} label="Internal User" />
                            <FormControlLabel value="servicearea" control={<Radio />} label="Service Area" />
                            <FormControlLabel value="driver" control={<Radio />} label="Driver" />
                            <FormControlLabel value="engineer" control={<Radio />} label="Engineer" />
                            {/* <FormControlLabel value="leadgenerator" control={<Radio />} label="Lead Generator" /> */}
                        </RadioGroup>
                    </Grid>
                    <FormHelperText error>{state.errors['userType']}</FormHelperText>
                </FormControl>
            </Grid>
            <>
                <Grid item xs={12}>
                    {state?.showModules && (
                        <>
                            <InputLabel shrink>Modules</InputLabel>
                            <Multiselect options={masterData.modules} value={selectedData.modules} onChange={ModuleChange} />
                        </>
                    )}
                    {/* internal user-start */}
                    {/* {state.userType === "internal" ? (
          <div>
            <SecondaryCheckbox onChange={onSelectCheckbox} checked={state.allbranch} name="allbranch" value={state.allbranch} label="All branches" />
            {!state.allbranch ? (
              <>
                <InputLabel shrink>Branches</InputLabel>
                <Multiselect options={masterData.branchs} onChange={BranchChange} value={selectedData.branchs} />
              </>
            ) : null}
          </div>
        ) : null} */}
                    {/* internal user-end*/}
                    {/* service Agent user-end*/}
                    {state.userType === 'servicearea' || state.userType === 'engineer' ? (
                        <>
                            <div>
                                <InputLabel shrink>Service Area</InputLabel>
                                <Multiselect options={masterData.serviceAgentList} value={selectedData.serviceAgent} onChange={ServiceChange} />
                            </div>
                            <div>
                                <InputLabel shrink>Warehouse</InputLabel>
                                <SelectBox value={state.warehouseCode || ''} onChange={fieldChange} List={masterData.warehouseCodeList} name="warehouseCode" />
                            </div>
                        </>
                    ) : null}
                    {/* service Agent user-end*/}
                    {state.userType === 'customer' || state.userType === 'driver' ? (
                        <div>
                            <InputLabel shrink>Customers</InputLabel>
                            <SelectBox value={state.customerIds || ''} onChange={fieldChange} List={masterData.customer} name="customerIds" />
                            {/* <SecondaryCheckbox onChange={onSelectCheckbox} checked={state.alldepots} name="alldepots" value={state.alldepots} label="All Depots" /> */}
                            {!state.alldepots ? (
                                <>
                                    <InputLabel shrink> Depot </InputLabel>
                                    {/* <Multiselect options={state.matchDepoCustId} onChange={DepotChange} value={selectedData.depots} /> */}
                                    <SelectBox value={state.depotIds || ''} onChange={fieldChange} List={state.matchDepoCustId} name="depotIds" />
                                </>
                            ) : null}
                            {state.userType === 'driver' && (
                                <>
                                    <InputLabel shrink>Driver</InputLabel>
                                    {/* <Multiselect options={state.matchDepoCustId} onChange={DepotChange} value={selectedData.depots} /> */}
                                    <SelectBox value={state.driverId || ''} onChange={fieldChange} List={state.driverList} name="driverId" />
                                </>
                            )}
                        </div>
                    ) : null}
                </Grid>
            </>

            <Grid xs={12} container style={{ marginTop: 16 }}>
                <Grid xs={12} container spacing={1}>
                    <Grid item xs={4} sm={6}>
                        Is Active ?
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <YesButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', true)} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <NoButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', false)} />
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        Is Locked ?
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <YesButton state={state.isLocked} name="isLocked" onClick={() => handleClick('isLocked', true)} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <NoButton state={state.isLocked} name="isLocked" onClick={() => handleClick('isLocked', false)} />
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        Is Read Only ?
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <YesButton state={state.isReadOnly} name="isReadOnly" onClick={() => handleClick('isReadOnly', true)} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <NoButton state={state.isReadOnly} name="isReadOnly" onClick={() => handleClick('isReadOnly', false)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default DropdownModal;
