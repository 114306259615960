import { authMenu, unAuthMenu, adminMenu, internalMenu, serviceAgent, customer, driverMenu, technician, leadMenu } from './menu';
import * as Components from './../../Components';
import { TypeOfAuth } from './menu/menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ScheduleIcon from '@material-ui/icons/Schedule';

export const getMenuForRole = (roles, isLoggedIn) => {
    let menus = [];
    if (!isLoggedIn) {
        menus.push(...unAuthMenu);
    } else {
        roles.forEach((role) => {
            switch (role) {
                case 'admin':
                    menus.push(...adminMenu);
                    break;
                case 'internal':
                    menus.push(...internalMenu);
                    menus.push({
                        label: 'Dashboard',
                        path: '/',
                        exact: true,
                        component: Components.InternalDashboard,
                        authType: TypeOfAuth.Both,
                        icon: <DashboardIcon />
                    });
                    break;
                case 'servicearea':
                    menus.push(...internalMenu);
                    menus.push({
                        label: 'Dashboard',
                        path: '/',
                        exact: true,
                        component: Components.ServiceAgentDash,
                        authType: TypeOfAuth.Both,
                        icon: <DashboardIcon />
                    });

                    break;
                case 'customer':
                    menus.push(...customer);
                    break;
                case 'driver':
                    menus.push(...driverMenu);
                    break;
                case 'engineer':
                    menus.push(...internalMenu);
                    menus.push({
                        icon: <DashboardIcon />,
                        label: 'Dashboard',
                        path: '/',
                        exact: true,
                        component: () => {
                            return <Components.TechDashboard />;
                        },
                        authType: TypeOfAuth.Auth
                    });
                    // menus.push({
                    //     icon: <ScheduleIcon />,
                    //     label: 'Dashboard',
                    //     path: '/',
                    //     exact: true,
                    //     component: () => {
                    //         return <Components.TechnicianSchedule status="5" />;
                    //     },
                    //     authType: TypeOfAuth.Auth
                    // });
                    break;
                case 'leadgenerator':
                    menus.push(...leadMenu);
                    break;
            }
        });
        menus.push(...authMenu);
    }

    if (process.env.NODE_ENV !== 'production') {
        menus.push({
            label: 'ⓘ',
            path: '/help',
            component: Components.Help,
            authType: TypeOfAuth.Both
        });
    }
    return menus.filter((m) => m.authType === TypeOfAuth.Both || m.authType === (isLoggedIn ? TypeOfAuth.Auth : TypeOfAuth.NonAuth));
};
