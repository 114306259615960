import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { memo, useContext } from 'react';
import { EditInapectionContaxt } from './editInspectionProviderImports';

const setValue = (val, unit) => {
    if (val) {
        return (
            <>
                {val}
                <small>{unit}</small>
            </>
        );
    } else {
        return 'N/A';
    }
};
export const EditInspectionRoadTest = memo(({ classes }) => {
    const { state } = useContext(EditInapectionContaxt);

    return (
        <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
            <TableContainer component={Paper}>
                <div
                    style={{
                        border: '1px solid rgba(0,0,0,0.2)'
                    }}
                >
                    <h3 className={classes.title}>
                        Road Test &nbsp;<small>(Brake Temperature Assessment)</small>
                    </h3>
                </div>
                <Table aria-label="simple table" className={classes.paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="20%">Side</TableCell>
                            <TableCell align="left">Axle 1</TableCell>
                            <TableCell align="left">Axle 2</TableCell>
                            <TableCell align="left">Axle 3</TableCell>
                            <TableCell align="left">Axle 4</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th">
                                <b>O/S</b>
                            </TableCell>

                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[0]?.osBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[1]?.osBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[2]?.osBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[3]?.osBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">
                                <b>N/S</b>
                            </TableCell>

                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[0]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[1]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[2]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="button"> {setValue(state.tyreAxles[3]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
});
