import React from 'react';
import { BreadCrumbs, TabView } from '../../../Core/Controls';
// import JobBookingDetail from '../Vehicles/ScheduleVehicle/scheduleJobDetail';./JOBItems/JobItemsScreen
// import JobItems from '../../InternalUser/Controllers/JOBS/JOBItems/JobItemsScreen';
// import JobNotes from '../../../Controllers/Notes/index';
// import JobDetail from './JOBItems/JobDetails';
import TreeViewScreen from './TreeViewScreen';

function TreeViewTab(props) {
    const id = props.match.params.id;
    const vehicleID = props.match.params.vehicleID;
    const { state } = props.location;

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Navigation', active: true },
        { name: 'Left Menu', active: true }
    ];
    const tabs = [
        // {
        //     label: 'Customer',
        //     body: <JobBookingDetail scheduleJobID={id} vehicleID={vehicleID} position={state || 'v'} />
        // },
        // {
        //     label: 'Internal',
        //     body: (
        //         <>
        //             <JobDetail scheduleJobID={id} /> <JobNotes frompage="Jobs" fromTab={true} JobID={id} noteRefsGuidID={id} />
        //         </>
        //     )
        // },
        {
            label: 'Customer',
            body: <TreeViewScreen />
        },
        {
            label: 'Internal User',
            body: <TreeViewScreen />
        },
        {
            label: 'Service Area',
            body: <TreeViewScreen />
        }
    ];

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} m={5} />
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}

export default TreeViewTab;
