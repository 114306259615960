import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getAllInternalDash = async () => {
    let res = await getWithAuth(`InternalDashboard`);
    return res;
};

export const getAllInternalVehicleContractDash = async () => {
    let res = await getWithAuth(`VehicleContract/GetInternalDashVehicleContractStatus`);
    return res;
};

export const getVehicleContractStatus_Dropdown = async () => {
    let res = await getWithAuth(`VehicleContract/VehicleContractStatus_Dropdown`);
    return res;
};
export const getVehicleContractStatusStepper = async (id) => {
    let res = await getWithAuth(`VehicleContract/VehicleContract_GetProgressDetails?id=${id}`);
    return res;
};

export const ContractsStepper_DropDown = async (Id) => {
    let res = await getWithAuth(`Contracts/ContractsStepper_DropDown `);
    return res;
};

export const Contract_CompleteScheduleDetails = async (Id) => {
    let res = await getWithAuth(`VehicleContract/Contract_CompleteScheduleDetails?Id=${Id}`);
    return res;
};
export const getLocatiOnOverRide = async (id) => {
    let res = await getWithAuth(`VehicleContract/VehicleContract_GetOffhireLocation?ContractVehicleID=${id}`);
    return res;
};

export const ContractSetDeliveryDate = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetDeliveryDate', data);
    return res;
};

export const ContractSetCollected = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetCollected', data);
    return res;
};

export const ContractSetAPIPauseCompleted = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_CompleteResetContract', data);
    return res;
};

export const ContractSetOnsiteDate = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetOnsiteDate', data);
    return res;
};

export const ContractSetOffHire = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetOffHire', data);
    return res;
};
export const ContractChangeOffHireLocation = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_ChangeOffhireLocation', data);
    return res;
};

export const ContractSetVehiclePicked = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetVehiclePicked', data);
    return res;
};

export const ContractSetArrived = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_SetArrived', data);
    return res;
};

export const ContractSetResumeContract = async (data) => {
    let res = await postWithAuth('VehicleContract/VehicleContract_ResumeContract', data);
    return res;
};
