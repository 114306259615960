import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { MandatoryField, SecondaryButton } from '../../../Core/Controls';
import { setStandardLabourLinesTab } from '../../../Core/Services/Admin/StandardLabourLine';
// import { useToast } from '../../../../Core/Hooks/useToast';

const AddUpdateStandardLabourLine = (props) => {
    // const { showToastSuccess, showToastError } = useToast();
    console.log(props, 'lllll');
    const [state, setState] = useState({
        lineDescription: props?.selectedData?.lineDescription || '',
        lineID: props?.selectedData?.lineID || null,
        errors: {}
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const submitHandler = async () => {
        let formData = {
            lineID: state.lineID,
            lineDescription: state.lineDescription
        };

        let res = await setStandardLabourLinesTab(formData);
        if (res.success) {
            props.onClose(true);
        } else {
            // showToastError(res.message);
            props.onClose(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="lineDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.lineDescription}
                    errors={state.errors.lineDescription}
                />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.lineID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddUpdateStandardLabourLine;
