import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormHelperText, InputLabel, Typography, RadioGroup, Radio } from '@material-ui/core';
import { SecondaryButton, SelectBox, BreadCrumbs, MandatoryField, SimpleField, DatePicker, SingleSelect } from '../../../Core/Controls';
import { CompanyProvider, CompanyScreenContext } from './CompanyProvider';
import '../../../Components/commonStyle.scss';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Postcode } from '../../../Core/Controls';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Company', active: true },
    { name: 'Add/Update Company', active: true }
];

const modelgridStyle = {
    gridContainer: { height: '600px' },
    formatterImageContainer: { width: '100%', textAlign: 'center' },
    formatterImage: { width: 120, maxHeight: 40, backgroundSize: 'cover' },
    formatterEditBtn: { textAlign: 'center' }
};
const YesNoRadio = (props) => {
    return (
        <Grid container>
            <Grid container item xs={12}>
                <InputLabel shrink>{props.label}</InputLabel>
            </Grid>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};
const AddEditCompany = (props) => {
    const { state, btnLoader, handleSubmit, inputChange, imagehandler, clearImage, setVal, setValRegistered, countryList } = useContext(CompanyScreenContext);
    let history = useHistory();
    let getAccessParam = AppStorage.getAllowAcces();

    return (
        <div style={{ overflow: 'hidden' }} className={'screen' + ' controller-screen'}>
            <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Company Details
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6} sm={6} lg={3} className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Company Name"
                                        placeholder="Company Name"
                                        value={state.companyName}
                                        onChange={inputChange}
                                        name="companyName"
                                        autoFocus={true}
                                    />
                                    <FormHelperText error>{state.errors.companyName}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6} lg={3} className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Companies House Registration Number"
                                        placeholder="Companies House Registration Number"
                                        value={state.companyRegistrationNumber}
                                        onChange={inputChange}
                                        name="companyRegistrationNumber"
                                    />
                                    <FormHelperText error>{state.errors.companyRegistrationNumber}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="VAT Number"
                                        placeholder="VAT Number"
                                        value={state.companyVATNumber}
                                        onChange={inputChange}
                                        type="number"
                                        name="companyVATNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <InputLabel shrink>Country Code</InputLabel>
                                    <SelectBox name="companyCountryCode" value={state.companyCountryCode} onChange={inputChange} List={countryList} />
                                    <FormHelperText error>{state.errors.countryCode}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Postcode
                                        value={state.companyPostCode || ''}
                                        onChange={inputChange}
                                        name="companyPostCode"
                                        setVal={setVal}
                                        errors={state.errors.companyPostCode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Address 1"
                                        placeholder="Address 1"
                                        value={state.companyAddress1 || ''}
                                        onChange={inputChange}
                                        name="companyAddress1"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Address 2"
                                        placeholder="Address 2"
                                        value={state.companyAddress2 || ''}
                                        onChange={inputChange}
                                        name="companyAddress2"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="City"
                                        placeholder="City"
                                        value={state.companyCity || ''}
                                        onChange={inputChange}
                                        name="companyCity"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="County"
                                        placeholder="County"
                                        value={state.companyCounty || ''}
                                        onChange={inputChange}
                                        name="companyCounty"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Phone"
                                        placeholder="Phone"
                                        value={state.companyPhone || ''}
                                        onChange={inputChange}
                                        name="companyPhone"
                                    />
                                    <FormHelperText error>{state.errors.companyPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Email"
                                        placeholder="Email"
                                        value={state.companyEmail || ''}
                                        onChange={inputChange}
                                        name="companyEmail"
                                    />
                                    <FormHelperText error>{state.errors.companyEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Web site"
                                        placeholder="Web site"
                                        value={state.companyWebsite}
                                        onChange={inputChange}
                                        name="companyWebsite"
                                    />
                                    <FormHelperText error>{state.errors.companyWebsite}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Registered Address
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="companyRegisteredAddressSameAsCompanyAddress"
                                        value={state.companyRegisteredAddressSameAsCompanyAddress}
                                        onChange={inputChange}
                                        label="Use Address As Company Address ?"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <InputLabel shrink>Country Code</InputLabel>
                                    <SelectBox
                                        name="companyRegisteredCountryCode"
                                        value={state.companyRegisteredCountryCode}
                                        onChange={inputChange}
                                        List={countryList}
                                        disabled={state.companyRegisteredAddressSameAsCompanyAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Postcode
                                        value={state.companyRegisteredPostCode || ''}
                                        onChange={inputChange}
                                        name="companyRegisteredPostCode"
                                        setVal={setValRegistered}
                                        errors={state.errors.companyRegisteredPostCode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Address1"
                                        placeholder="Address1"
                                        value={state.companyRegisteredAddress1 || ''}
                                        onChange={inputChange}
                                        name="companyRegisteredAddress1"
                                        disabled={state.companyRegisteredAddressSameAsCompanyAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Address2"
                                        placeholder="Address2"
                                        value={state.companyRegisteredAddress2 || ''}
                                        onChange={inputChange}
                                        disabled={state.companyRegisteredAddressSameAsCompanyAddress}
                                        name="companyRegisteredAddress2"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="City"
                                        placeholder="City"
                                        value={state.companyRegisteredCity || ''}
                                        onChange={inputChange}
                                        name="companyRegisteredCity"
                                        disabled={state.companyRegisteredAddressSameAsCompanyAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="County"
                                        placeholder="County"
                                        value={state.companyRegisteredCounty || ''}
                                        onChange={inputChange}
                                        name="companyRegisteredCounty"
                                        disabled={state.companyRegisteredAddressSameAsCompanyAddress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Bank Details
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Bank Name"
                                        placeholder="Bank Name"
                                        name="companyBankName"
                                        value={state.companyBankName}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.companyBankName}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Bank Account Name"
                                        placeholder="Bank Account Name"
                                        name="companyBankAccountName"
                                        value={state.companyBankAccountName}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Bank Sort Code"
                                        placeholder="Bank Sort Code"
                                        name="companyBankSortCode"
                                        value={state.companyBankSortCode}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.companyBankSortCode}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Bank Account Number"
                                        placeholder="Bank Account Number"
                                        name="companyBankAccountNumber"
                                        value={state.companyBankAccountNumber}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.companyBankAccountNumber}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Company Logo
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={4} style={{ position: 'relative' }}>
                                    <InputLabel shrink reuired>
                                        Company Logo
                                    </InputLabel>
                                    {state.companyLogo && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0
                                            }}
                                        >
                                            <Tooltip title="Remove Logo">
                                                <CloseIcon fontSize="small" onClick={clearImage} />
                                            </Tooltip>
                                        </span>
                                    )}
                                    <Grid container>
                                        <Grid item xs={6} style={{ marginTop: '8px' }}>
                                            <input type="file" name="image_value" onChange={imagehandler} accept="image/*" />
                                        </Grid>
                                        <Grid item xs={6} style={modelgridStyle.formatterImageContainer}>
                                            <img
                                                src={state.companyLogo?.includes('base64') ? state.companyLogo : 'data:image/png;base64,' + state.companyLogo}
                                                alt={'logo'}
                                                style={modelgridStyle.formatterImage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} disabled={!getAccessParam?.CanUpdateCompanyDetails}>
                            {state.companyID ? 'Save' : 'Add'}
                        </SecondaryButton>
                    </Grid>
                }
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton forceEnabled={true} onClick={() => history.push('/')}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

const CompanyDetail = (props) => {
    return (
        <CompanyProvider {...props}>
            <AddEditCompany {...props} />
        </CompanyProvider>
    );
};
export default CompanyDetail;
