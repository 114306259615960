import { Button, FormHelperText, Grid, Hidden, IconButton, InputLabel } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../../../../../../Core/Controls/Table/StyledCell&Row';
import { InputText, UploadWithBadge } from '../../../../../../Core/Controls';
import { memo, useContext } from 'react';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

const CheckButtonsBorder = {
    D: 'red',
    M: 'orange',
    N: 'blue',
    S: 'green',
    R: 'red'
};

export const InspectionCheckBody = memo(({ p, m, CheckButtons, index }) => {
    const { state, flags, changeResutNotesState, changeState, imageHandler, changeNotesState, handleInpectionRow, InHire_imageHandler } =
        useContext(InspectionCheCkContaxt);
    return (
        <>
            {flags.displayStartEvent ? (
                <Grid container alignItems="center" style={{ background: index % 2 && '#f5f5f5' }} spacing={2}>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <div style={{ fontWeight: 600, marginLeft: 10, color: 'black' }}>{p.inspectionSheetCheckDescription}</div>
                        <div>
                            {p.previousNotes && (
                                <IconButton
                                    style={{
                                        background: CheckButtonsBorder[p?.previousResultCode],
                                        borderRadius: 0,
                                        height: 20,
                                        // width: '100%',
                                        padding: 10,
                                        fontSize: 12
                                    }}
                                    onClick={() => handleInpectionRow(p.previousVehicleInspectionID, p.previousNotes)}
                                >
                                    {' '}
                                    <div item style={{ fontSize: 11, fontWeight: 600, color: 'white', textAlign: 'left' }}>
                                        {p.previousNotes}
                                    </div>
                                </IconButton>
                            )}
                        </div>
                        {!p.inspectionSheetCheckResultOptionCode && state.errors?.message ? (
                            <FormHelperText error>{state.errors?.message}</FormHelperText>
                        ) : null}
                    </Grid>
                    <Grid item xl={1} lg={1} md={2} sm={3} xs={6} style={{ background: '#F8EDE3' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            {p.startFreeText ? (
                                <FormHelperText shrink style={{ fontWeight: 600, color: 'black', lineHeight: 1, fontSize: 10 }}>
                                    {p.startFreeText}
                                </FormHelperText>
                            ) : (
                                state.buttonList.map((b) => {
                                    const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                    const checks = p.startResultCode;

                                    if (checks === b.inspectionSheetCheckOptionCode) {
                                        return <Comp state={true} disabled={true}></Comp>;
                                    }
                                })
                            )}
                            {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                <UploadWithBadge
                                    onClick={() => InHire_imageHandler(p.startInspectionSheetCheckResultID)}
                                    count={p.startPhotosUploaded}
                                    state={p.startPhotosUploaded > 0}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid
                        item
                        xl={p.inspectionSheetCheckFreeTextField && p.inspectionSheetCheckCanHideDefectDescription ? 6 : 2}
                        lg={p.inspectionSheetCheckFreeTextField && p.inspectionSheetCheckCanHideDefectDescription ? 6 : 2}
                        md={4}
                        sm={9}
                        xs={12}
                        // style={{ background: '#ffff99' }}
                    >
                        {p.inspectionSheetCheckFreeTextField ? (
                            <div style={{ display: 'flex' }}>
                                <InputText
                                    placeholder="Result"
                                    name="inspectionSheetCheckResultNotes"
                                    onChange={changeResutNotesState(p.inspectionSheetCheckID, m.inspectionSheetCheckResultEnteredText)}
                                    value={state.inspectionSheetCheckResultEnteredText}
                                />
                                {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                    <>
                                        <Grid item xs={1}></Grid>
                                        <Grid item>
                                            <UploadWithBadge
                                                onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                count={p.basicCount}
                                                state={p.basicCount > 0}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </div>
                        ) : (
                            <Grid container justify="flex-start" spacing={1}>
                                {state.buttonList.map((b) => {
                                    const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || Button;
                                    return (
                                        <Grid item>
                                            <Comp
                                                onClick={() => changeState(b.inspectionSheetCheckOptionCode, p.inspectionSheetCheckID)}
                                                state={p.inspectionSheetCheckResultOptionCode === b.inspectionSheetCheckOptionCode}
                                            />
                                        </Grid>
                                    );
                                })}
                                {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                    <>
                                        <Grid item xs={1}></Grid>
                                        <Grid item>
                                            <UploadWithBadge
                                                onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                count={p.basicCount}
                                                state={p.basicCount > 0}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {!p.inspectionSheetCheckCanHideDefectDescription && (
                        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                            {/* <InputLabel shrink error>
                                    {' '}
                                    {p.startNotes}
                                </InputLabel> */}
                            <InputText
                                error={p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? true : false}
                                placeholder="Defect description"
                                name="inspectionSheetCheckResultNotes"
                                onChange={changeNotesState(p.inspectionSheetCheckID, m.inspectionSheetSectionDescription)}
                                value={state.inspectionSheetCheckResultNotes}
                            />

                            <FormHelperText error>
                                {p.startNotes && (
                                    <IconButton
                                        style={{
                                            // fontWeight: 900,
                                            background: '#F8EDE3',
                                            borderRadius: 0,
                                            height: 20,
                                            // width: '100%',
                                            padding: 10,
                                            fontSize: 12
                                        }}
                                        onClick={() => handleInpectionRow(p.startVehicleInspectionID, p.startNotes)}
                                    >
                                        {' '}
                                        <div item style={{ fontSize: 11, fontWeight: 600, color: 'black', textAlign: 'left' }}>
                                            {p.startNotes}
                                        </div>
                                    </IconButton>
                                )}
                            </FormHelperText>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Grid container spacing={2} alignItems="center" style={{ background: index % 2 && '#f5f5f5' }}>
                    <Grid item xl={5} lg={5} md={8} sm={8} xs={12}>
                        <div style={{ fontWeight: 600, marginLeft: 10, color: 'black' }}>{p.inspectionSheetCheckDescription}</div>
                        <div>
                            {p.previousNotes && (
                                <IconButton
                                    style={{
                                        // fontWeight: 900,
                                        background: CheckButtonsBorder[p?.previousResultCode],
                                        borderRadius: 0,
                                        height: 20,
                                        // width: '100%',
                                        padding: 10,
                                        fontSize: 12
                                    }}
                                    onClick={() => handleInpectionRow(p.previousVehicleInspectionID, p.previousNotes)}
                                >
                                    {' '}
                                    <div item style={{ fontSize: 11, fontWeight: 600, color: 'white', textAlign: 'left' }}>
                                        {p.previousNotes}
                                    </div>
                                </IconButton>
                            )}
                        </div>
                        {!p.inspectionSheetCheckResultOptionCode && state.errors?.message ? (
                            <FormHelperText error>{state.errors?.message}</FormHelperText>
                        ) : null}
                    </Grid>
                    <Grid
                        item
                        xl={p.inspectionSheetCheckFreeTextField && p.inspectionSheetCheckCanHideDefectDescription ? 7 : 2}
                        lg={p.inspectionSheetCheckFreeTextField && p.inspectionSheetCheckCanHideDefectDescription ? 7 : 2}
                        md={4}
                        sm={4}
                        xs={12}
                    >
                        {p.inspectionSheetCheckFreeTextField ? (
                            <div style={{ display: 'flex' }}>
                                <InputText
                                    placeholder="Result"
                                    name="inspectionSheetCheckResultNotes"
                                    onChange={changeResutNotesState(p.inspectionSheetCheckID, m.inspectionSheetCheckResultEnteredText)}
                                    value={state.inspectionSheetCheckResultEnteredText}
                                />
                                {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                    <>
                                        <Grid item xs={1}></Grid>
                                        <Grid item>
                                            <UploadWithBadge
                                                onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                count={p.basicCount}
                                                state={p.basicCount > 0}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </div>
                        ) : (
                            <Grid container justify="flex-start" spacing={1}>
                                {state.buttonList.map((b) => {
                                    const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || Button;
                                    return (
                                        <Grid item>
                                            <Comp
                                                onClick={() => changeState(b.inspectionSheetCheckOptionCode, p.inspectionSheetCheckID)}
                                                state={p.inspectionSheetCheckResultOptionCode === b.inspectionSheetCheckOptionCode}
                                            />
                                        </Grid>
                                    );
                                })}
                                {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                    <>
                                        <Grid item xs={1}></Grid>
                                        <Grid item>
                                            <UploadWithBadge
                                                onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                count={p.basicCount}
                                                state={p.basicCount > 0}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {!p.inspectionSheetCheckCanHideDefectDescription && (
                        <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                            <InputText
                                style={{ marginTop: 7 }}
                                error={p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? true : false}
                                placeholder="Defect description"
                                name="inspectionSheetCheckResultNotes"
                                onChange={changeNotesState(p.inspectionSheetCheckID, m.inspectionSheetSectionDescription)}
                                value={state.inspectionSheetCheckResultNotes}
                            />

                            <FormHelperText
                                error
                                style={{
                                    visibility: p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? '' : 'hidden'
                                }}
                            >
                                {state.errors?.note}
                            </FormHelperText>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
});
