import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { InputText, SecondaryButton, SecondaryCheckbox, MandatoryField, YesButton, NoButton, SimpleField } from '../../../Core/Controls';
import { getPartsDetails } from '../../../Core/Services/Admin/partService';

const PartAddUpdate = (props) => {
    const [state, setState] = useState({
        partNumber: '',
        description: '',
        minimumUnitOfStock: null,
        totalStockQuantity: null,
        sellingPrice: null,
        unitOfPricePer: null,
        standardCost: null,
        isActive: true,
        partID: null
    });
    const [errors, setErrors] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);

    // useEffect(async () => {
    //     if (props.partID) {
    //         let res = await getPartsDetails(props.partID);
    //         let temp = res.data?.table[0];
    //         setState((st) => ({
    //             ...st,
    //             partID: temp?.partID,
    //             partNumber: temp?.partNumber,
    //             description: temp?.description,
    //             minimumUnitOfStock: temp?.minimumUnitOfStock,
    //             totalStockQuantity: temp?.totalStockQuantity,
    //             sellingPrice: temp?.sellingPrice,
    //             unitOfPricePer: temp?.unitOfPricePer,
    //             standardCost: temp?.standardCost,
    //             isActive: temp?.isActive
    //         }));
    //     }
    // }, []);

    useEffect(() => {
        const fetchPartDetails = async () => {
            try {
                if (props.partID) {
                    let res = await getPartsDetails(props.partID);
                    let temp = res.data?.table[0];

                    setState((st) => ({
                        ...st,
                        partID: temp?.partID || st.partID,
                        partNumber: temp?.partNumber || st.partNumber,
                        description: temp?.description || st.description,
                        minimumUnitOfStock: temp?.minimumUnitOfStock || st.minimumUnitOfStock,
                        totalStockQuantity: temp?.totalStockQuantity || st.totalStockQuantity,
                        sellingPrice: temp?.sellingPrice || st.sellingPrice,
                        unitOfPricePer: temp?.unitOfPricePer || st.unitOfPricePer,
                        standardCost: temp?.standardCost || st.standardCost,
                        isActive: temp?.isActive !== undefined ? temp.isActive : st.isActive
                    }));
                }
            } catch (error) {
                console.error('Failed to fetch part details:', error);
            }
        };

        fetchPartDetails();
    }, [props.partID]);
    const validate = () => {
        let { partNumber, description, totalStockQuantity, minimumUnitOfStock } = state;
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(partNumber)) {
            err.partNumber = 'Part number is required';
            noErrors = false;
        }
        if (!/[^\s]/.test(description)) {
            err.description = 'Part description is required';
            noErrors = false;
        }

        if (minimumUnitOfStock > totalStockQuantity) {
            err.minimumUnitOfStock = 'Minimum unit of stock should be less than or equal to total stock quantity';
        }
        setErrors(err);
        return noErrors;
    };
    const handleData = () => {
        if (validate()) {
            setBtnLoader(true);
            props.saveData(state);
            props.onClose();
            setBtnLoader(false);
        }
    };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.type === 'checkbox' ? temp.checked : temp.value
        }));
        setErrors({});
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Part Number"
                        name="partNumber"
                        placeholder="Part Number"
                        value={state.partNumber || ''}
                        onChange={valueChanged}
                        errors={errors.partNumber}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Description"
                        name="description"
                        placeholder="Description"
                        value={state.description || ''}
                        onChange={valueChanged}
                        errors={errors.description}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Stock Quantity"
                        name="totalStockQuantity"
                        placeholder="Stock Quantity"
                        value={state.totalStockQuantity || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Minimum Unit Of Stock"
                        name="minimumUnitOfStock"
                        placeholder="Minimum Unit Of Stock"
                        value={state.minimumUnitOfStock || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                    <FormHelperText error>{errors.minimumUnitOfStock}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Selling Price"
                        name="sellingPrice"
                        placeholder="Selling Price"
                        value={state.sellingPrice || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Unit Of Price Per"
                        name="unitOfPricePer"
                        placeholder="Unit Of Price Per"
                        type="number"
                        value={state.unitOfPricePer || ''}
                        onChange={valueChanged}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Standard Cost"
                        name="standardCost"
                        placeholder="Standard Cost"
                        value={state.standardCost || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} container spacing={1} style={{ marginTop: -8 }}>
                    <Grid item xs={12} sm={12}>
                        Is Active ?
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginTop: -9 }}>
                        <YesButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', true)} />
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginTop: -9 }}>
                        <NoButton state={state.isActive} name="isActive" onClick={() => handleClick('isActive', false)} />
                    </Grid>
                </Grid>

                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        {props.partID ? 'UPDATE' : 'ADD'}
                    </SecondaryButton>
                </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Part Number"
                        name="partNumber"
                        placeholder="Part Number"
                        value={state.partNumber || ''}
                        onChange={valueChanged}
                        errors={errors.partNumber}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Description"
                        name="description"
                        placeholder="Description"
                        value={state.description || ''}
                        onChange={valueChanged}
                        errors={errors.description}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Stock Quantity"
                        name="totalStockQuantity"
                        placeholder="Stock Quantity"
                        value={state.totalStockQuantity || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Minimum Unit Of Stock"
                        name="minimumUnitOfStock"
                        placeholder="Minimum Unit Of Stock"
                        value={state.minimumUnitOfStock || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                    <FormHelperText error>{errors.minimumUnitOfStock}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Target stock level"
                        name="sellingPrice"
                        placeholder="Target stock level"
                        value={state.sellingPrice || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Retail Price "
                        name="unitOfPricePer"
                        placeholder="Retail Price "
                        type="number"
                        value={state.unitOfPricePer || ''}
                        onChange={valueChanged}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Cost Price"
                        name="standardCost"
                        placeholder="Cost Price"
                        value={state.standardCost || ''}
                        onChange={valueChanged}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} container spacing={1} style={{ marginTop: -8 }}>
                    <Grid item xs={12} sm={12}>
                        Part Type ?
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginTop: -9 }}>
                      
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginTop: -9 }}>
                       
                    </Grid>
                </Grid>

                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        {props.partID ? 'UPDATE' : 'ADD'}
                    </SecondaryButton>
                </Grid>
            </Grid> */}
        </>
    );
};
export default PartAddUpdate;
