import React, { useState, useEffect, useMemo } from 'react';
import { EditButton, formatters, BreadCrumbs, DataGrid, DatePicker } from '../../../Core/Controls';
import ScheduleNotes from '../Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import ScheduleBookingStatus from '../Controllers/Vehicles/ScheduleVehicle/scheduleBookingStatus'; //'./scheduleBookingStatus';
import ScheduleBookingSection from '../Controllers/Vehicles/ScheduleVehicle/scheduleBookingSection';
import DialogComp from '../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import EventIcon from '@material-ui/icons/Event';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CompleteScheduleModal from './completeSchedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import '../../commonStyle.scss';
import '../Controllers/Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import { getScheduleByStatus } from '../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import VehicleDailyChecks from '../Controllers/Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails';
import DepotDetails from '../Controllers/DetailsModal/depotDetails';
import ServiceAgentDetails from '../Controllers/DetailsModal/serviceAgent';
import { AppStorage } from '../../../Core/Basic/storage-service';
import { GetSchedulesExcelFile } from '../../../Core/Services/InternalUser/excelDownload';
import { Button, Grid, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';

const basecolumns = [
    { key: 'action', name: 'Action', width: 160, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 }
    // {
    //     key: 'scheduleDateDue',
    //     name: 'Due',
    //     formatter: formatters.Date_DDMMYYYY,
    //     width: 90
    // },
    // {
    //     key: 'scheduleRequestedBookingDate',
    //     name: 'Booked Date',
    //     formatter: formatters.Date_DDMMYYYY,
    //     width: 120
    // },
    // { key: 'dueDateISOWeek', name: 'iso week', width: 90 },
    // { key: 'scheduleEventName', name: 'event', width: 90 },
    // {
    //     key: 'agentName',
    //     name: 'Service Area',
    //     width: 110
    // }
    // { key: 'customerCompanyName', name: 'Customer', width: 200 }
];
const InternalUserSchedule = (props) => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('start');
    const endDate = params.get('end');
    const fromDash = Boolean(params.get('fromDash'));

    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        showChecks: false,
        showEditChecks: false,
        showBred: true,
        isReload: null,
        fromDate: fromDash ? startDate : moment().startOf('month').format('YYYY-MM-DD'),
        toDate: fromDash ? endDate : moment().endOf('month').format('YYYY-MM-DD')
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const { userRoles } = useLoginInfo();

    const isEngineer = userRoles.includes('engineer');

    const history = useHistory();
    const [openDialog, setOpenDialog] = useState({});
    const getCrumbs = () => {
        let temp = {};
        let crumbs = [
            { name: 'Home', path: '/' },
            { name: 'Schedules', active: true }
        ];
        if (props.status == '1') {
            temp = { name: 'Bookings Requsted', active: true };
        } else if (props.status == '2') {
            temp = { name: 'Accepted by Agents', active: true };
        } else if (props.status == '3') {
            temp = { name: 'Bookings Rejected', active: true };
        } else if (props.status == '5') {
            temp = { name: 'Confirmed Bookings', active: true };
        }
        crumbs.push(temp);
        return crumbs;
    };
    let screenName = getCrumbs();

    useEffect(async () => {
        pullApiData();
    }, []);

    const handleCheck = (row, val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'Ad') {
                newSt.showChecks = true;
                newSt.selectedRowData = row;
                newSt.showBred = false;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
                newSt.selectedRowData = row;
                newSt.showBred = false;
            }
            return newSt;
        });
    };

    const pullApiData = async () => {
        // let res = await getScheduleByStatus(props.status);
        // setState((st) => ({
        //     ...st,
        //     rows: [...(res.data.schedules || [])],
        //     showLoader: false
        // }));
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.scheduleJobID}/${val?.scheduleVehicleID}`,
            search: isEngineer ? '?tab_testing_tabs=1' : '',
            state: 'jobs'
        });
    };

    const renderEdit = (ev, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {row?.displayNotes === 'Y' && checkhaveAccess?.CanUpdateScheduleNotes && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )}

                {row?.displayBookingRequest === 'Y' && (
                    <CustomIconButton
                        icon={EventIcon}
                        onClick={() => {
                            checkButton(row, 'status');
                        }}
                        toolTipTitle="Update Booking Request"
                    />
                )}

                {row?.displayBookJob === 'Y' && (
                    <CustomIconButton
                        icon={ConfirmationNumberIcon}
                        color="primary"
                        onClick={() => {
                            checkButton(row, 'section');
                        }}
                        toolTipTitle="Book Job"
                    />
                )}

                {row?.displayJobDetails === 'Y' && checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}

                {row?.displayCompleteSchedule === 'Y' && checkhaveAccess?.CanCompleteSchedule && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        color="primary"
                        onClick={() => {
                            checkButton(row, 'complete');
                        }}
                        toolTipTitle="Complete Schedule"
                    />
                )}

                {row?.displayAddInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(row, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                )}

                {row?.displayEditInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={EditIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(row, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                )}
            </div>
        );
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.scheduleVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleModalClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');

        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (props.status == '1' || props.status == '3') {
            tempCols.splice(2, 0, { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY, width: 90 });
            tempCols.splice(3, 0, {
                key: 'scheduleRequestedBookingDate',
                name: 'Date Requested',
                formatter: formatters.Date_DDMMYYYY,
                width: 120
            });
            tempCols.splice(
                4,
                0,
                { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
                { key: 'scheduleEventName', name: 'Event', width: 90 },
                { key: 'agentName', name: 'Service Area', width: 110 },
                { key: 'customerCompanyName', name: 'Customer', width: 200 }
            );
        }
        // if (props.status == '1' || props.status == '2' || props.status == '3') {
        //     tempCols.splice(3, 0, {
        //         key: 'scheduleRequestedBookingDate',
        //         name: 'Date Requested',
        //         formatter: formatters.Date_DDMMYYYY,
        //         width: 120
        //     });
        //     tempCols.splice(5, 0, { key: 'scheduleEventName', name: 'event', width: 90 });
        //     tempCols.splice(7, 0, { key: 'customerCompanyName', name: 'Customer', width: 200 });
        // }
        // else {
        //     tempCols.splice(3, 0, {
        //         key: 'bookingDate',
        //         name: 'Booked Date',
        //         formatter: formatters.Date_DDMMYYYY,
        //         width: 110
        //     });
        // }
        if (props.status == '2') {
            tempCols.splice(
                2,
                0,
                { key: 'scheduleEventName', name: 'Event', width: 90 },
                { key: 'customerCompanyName', name: 'Customer', width: 200 },
                { key: 'depotName', name: 'Depot', width: 150 },
                { key: 'agentName', name: 'Service Area', width: 110 },
                { key: 'technician', name: 'Engineer', width: 110 },
                { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
                { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'bookingDate', name: 'Booked', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'scheduleBookingStatus', name: 'Status', width: 90 },
                { key: 'scheduleCompletionDate', name: 'Completed', formatter: formatters.Date_DDMMYYYY, width: 110 },
                { key: 'dateOfReg', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 110 },
                { key: 'costCentre', name: 'Cost Centre', width: 110 }
            );
        }
        if (props.status == '5') {
            tempCols.splice(
                2,
                0,
                { key: 'customerCompanyName', name: 'Customer Name', width: 200 },
                { key: 'depotName', name: 'Depot Name', width: 150 },
                { key: 'scheduleBookingStatus', name: 'Status', width: 160 },
                { key: 'scheduleEventName', name: 'Event', width: 170 },
                { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'dueDateISOWeek', name: 'ISO Week', width: 80 },
                { key: 'costCentre', name: 'Cost Centre', width: 105 },
                { key: 'agentName', name: 'Service Area', width: 110 },
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'jobReference', name: 'Job Reference?', width: 130 }
            );
        }
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        let agentCol = tempCols.find((element) => element.key === 'agentName');
        let custCol = tempCols.find((element) => element.key === 'customerCompanyName');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        if (custCol) {
            custCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        return tempCols;
    }, []);

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            showChecks: false,
            showEditChecks: false,
            selectedRowData: {},
            showBred: true
        }));
        res && pullApiData();
    };
    const closeTheDialog = (res) => {
        setOpenDialog({});
        res && pullApiData();
    };

    const checkButton = (row, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: row
        }));
    };
    const dateRangeChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({ ...st, [temp.name]: temp.value }));
    };

    useEffect(() => {
        let getUrl = `Schedules?vehicleId=&status=${props.status}&startDate=${state.fromDate}&endDate=${state.toDate}`;
        setState((st) => ({
            ...st,
            getUrl: getUrl,
            isReload: new Date()
        }));
    }, [state.fromDate, state.toDate]);

    return (
        <div className="screen all_schedules">
            {state.showBred ? <BreadCrumbs crumbs={getCrumbs()} /> : null}
            {state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    displayCheck={state.selectedRowData.displayCheck}
                    onClose={() => handleClose(true)}
                    frompage={true}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks
                    checkID={state.selectedRowData.vehicleInspectionID}
                    displayCheck={state.selectedRowData.displayCheck}
                    onClose={() => handleClose(true)}
                    frompage={true}
                />
            ) : (
                <>
                    <Grid container>
                        <Grid item className="date_range">
                            <span className="date_items">
                                <InputLabel shrink>From</InputLabel>
                                <DatePicker
                                    fullWidth={false}
                                    placeholder="From"
                                    value={state.fromDate}
                                    onChange={dateRangeChanged}
                                    name="fromDate"
                                    InputProps={{ inputProps: { max: `${state.toDate}` } }}
                                    focused={state.fromDate ? true : false}
                                />
                            </span>
                            <span className="date_items ">
                                <InputLabel shrink>To</InputLabel>
                                <DatePicker
                                    fullWidth={false}
                                    placeholder="To"
                                    value={state.toDate}
                                    onChange={dateRangeChanged}
                                    name="toDate"
                                    InputProps={{ inputProps: { min: `${state.fromDate}` } }}
                                    focused={state.toDate ? true : false}
                                />
                            </span>
                            <span className="date_items">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setState((st) => ({ ...st, fromDate: '', toDate: '' }));
                                    }}
                                    size="large"
                                    style={{ marginTop: '10px' }}
                                >
                                    Reset
                                </Button>
                            </span>
                        </Grid>
                    </Grid>
                    <GijgoGrid
                        columns={columns}
                        idFieldName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'scheduleID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'ScheduleDocumentsRequired' : null}`}
                        dataConverter={(res) => ({
                            records: res.data.schedules,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        getUrl={state.getUrl}
                        downloadBtnName="Download xlsx"
                        downloadCustomUrl={() =>
                            GetSchedulesExcelFile({
                                status: props.status || '',
                                mode: state?.filterID || '',
                                startDate: state?.fromDate || '',
                                endDate: state?.toDate || '',
                                fileExt: 'xlsx'
                            })
                        }
                    />
                </>
            )}
            {openDialog.complete ? (
                <DialogComp title="Complete Schedule" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <CompleteScheduleModal scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.notes ? (
                <DialogComp title="Schedule Notes" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleNotes vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.status ? (
                <DialogComp title="Customer Booking Request" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingStatus vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.section ? (
                <DialogComp title="Booking Section" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingSection vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}

            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleModalClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleModalClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleModalClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleModalClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleModalClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleModalClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default InternalUserSchedule;
