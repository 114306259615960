import React, { useEffect, useState, useMemo } from 'react';
import { getAllDriver } from '../../../../Core/Services/InternalUser/ControllerService/driverService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import { getAllVor, getVorByVehicle } from '../../../../Core/Services/InternalUser/VOR/addEditVor';
import AddVor from './AddEditVor';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import '../../../commonStyle.scss';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import BuildIcon from '@material-ui/icons/Build';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import DepotDetails from '../DetailsModal/depotDetails';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import CustomerDetails from '../DetailsModal/customerDetails';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import WorkIcon from '@material-ui/icons/Work';
import moment from 'moment';
import { Button, ButtonGroup } from '@material-ui/core';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 70
    },
    { key: 'vorVehicleDateStarted', name: 'Start Date', width: 140, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'sa', name: 'Service Area', width: 120 },
    { key: 'technician', name: 'Engineer', width: 150 },
    { key: 'vorTypeDetailDescription', name: 'Reason', width: 90 },
    { key: 'costCentre', name: 'Cost Centre', width: 150 },
    { key: 'lastUpdateDate', name: 'Date/Time Update', width: 150, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'lastUpdate', name: 'Last Update', width: 400 }
    // { key: 'vorVehicleLocation', name: 'Location' },
    // { key: 'vorTypeDescription', name: 'Type', width: '9%' },
    // { key: 'vehicleCategoryDescription', name: 'Sub Category', width: '8%' },
    // { key: 'lastUpdateDate', name: 'Date/Time Last Update', width: '10%', formatter: formatters.Date_DDMMMYYYY_HHMM }
];
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'VOR', active: true }
];

const VorScreen = (props) => {
    const { showToastError } = useToast();
    const FromDash = props.location?.query?.FromDash;
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        VorList: [],
        DriverID: null,
        checkedA: true,
        filterFlag: FromDash ? true : false,
        activeOrInactive: FromDash ? true : false,
        vorFlag: FromDash ? true : '',
        isReload: null,
        getUrl: props.VehicleID
            ? `VORVehicles?VehicleID=${props.VehicleID}`
            : `VORVehicles?active=${true}&StartDate=${props.location.startDate ? props.location.startDate : ''}&EndDate=${
                  props.location.endDate ? props.location.endDate : ''
              }&CustomerId=${props.location.customerID ? props.location.customerID : ''}&Make=${props.location.make ? props.location.make : ''}&Model=${
                  props.location.model ? props.location.model : ''
              }`,
        filterButtonID: 1
    });

    // long? CustomerId,  string Make,string Model

    let history = useHistory();

    // const handleChange = (event) => {
    //     const { name, checked } = event.target;
    //     let tempSt = {};
    //     if (name === 'filterFlag') {
    //         tempSt = {
    //             filterFlag: checked,
    //             activeOrInactive: checked ? true : false,
    //             vorFlag: checked ? true : ''
    //         };
    //     } else if (name === 'activeOrInactive') {
    //         tempSt = {
    //             activeOrInactive: checked,
    //             vorFlag: checked
    //         };
    //     }
    const handleVor = (id) => {
        let url = ``;

        if (id === 1) {
            url = `VORVehicles?active=${true}`;
        } else if (id === 2) {
            url = `VORVehicles?active=${false}`;
        } else if (id === 3) {
            url = `VORVehicles`;
        }

        setState((st) => ({ ...st, getUrl: url, filterButtonID: id }));
    };

    const handleAdd = (data) => {
        setState((st) => ({
            ...st,
            show: true,
            vorVehicleID: data.vorVehicleID,
            regNo: data.vehicleRegNumber,
            VehicleID: data.vorVehicleVehicleID
        }));
    };

    const closeVorModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            if (res) {
                newSt.isReload = new Date();
            }
            newSt.show = false;

            return newSt;
        });
        // res && pullAllVor();
    };
    const handleEditDefect = (val) => {
        if (val.defectTypeCode == 'DD') {
            history.push({
                pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.vorVehicleVehicleID}`,
                state: 'Edit'
            });
        } else {
            history.push({
                pathname: `/maintenance/breakdowns/edit/${val?.defectID}/${val?.vorVehicleVehicleID}`,
                state: 'Edit'
            });
        }
    };
    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vorVehicleVehicleID}`,
            search: '',
            state: 'jobs'
        });
    };
    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'left' }}>
                <EditButton toolTipTitle="Edit Vor" onClick={() => handleAdd(row)} />
                {row.defectID && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        // onClick={() => handleUpdate(row, null, 'modalEditDetail')}
                        onClick={() => handleEditDefect(row)}
                        toolTipTitle="Edit Defect"
                    />
                )}
                {checkhaveAccess?.CanViewJobs && row.jobID && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
            </div>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vorVehicleVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.sa}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (state.activeOrInactive) {
            tempCols.splice(2, 0, { key: 'vorVehicleEstimatedEndDate', name: 'Estimated Completion', width: 200, formatter: formatters.Date_DDMMYYYY });
        } else {
            tempCols.splice(2, 0, { key: 'vorVehicleDateEnded', name: 'End Date', width: 140, formatter: formatters.Date_DDMMMYYYY_HHMM });
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (!props.VehicleID) {
            tempCols.splice(1, 0, { key: 'vehicleRegNumber', name: 'Reg Number', width: 110, formatter: renderRegNumberEdit });
            tempCols.splice(4, 0, { key: 'customerCompanyName', name: 'Customer', width: 330, formatter: renderCustomerName });
            tempCols.splice(5, 0, { key: 'depotName', name: 'Depot', width: 280, formatter: renderDepotName });
        }
        let agentNameCol = tempCols.find((element) => element.key === 'sa');
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        return tempCols;
    }, [state.activeOrInactive]);

    const onRowDataBound = (e, $row, id, record) => {
        $row.css('background-color', `white`);
        const lastUpdateDate = moment(record.lastUpdateDate).format('MM/DD/YYYY');
        const daysPast = moment().diff(lastUpdateDate, 'days');
        if (daysPast > 1) {
            $row.css('background-color', 'orange');
        }
    };

    return (
        <div className={`${!props.VehicleID && 'screen'}` + ' controller-screen'} style={{ position: 'relative' }}>
            {state.show ? (
                // <AddEditDriver DriverID={state.DriverID} onFormSubmit={closeDriverModal} />
                <DialogComp title={`Update VOR - ${state.regNo}`} onClose={() => closeVorModal(false)} maxWidth="lg" overflow="auto">
                    <AddVor vorVehicleID={state.vorVehicleID} update={true} onClose={closeVorModal} VehicleID={props.VehicleID || state.VehicleID} />
                </DialogComp>
            ) : null}
            {/* {!props.VehicleID && } */}
            {!props.VehicleID && (
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} />
                    </Grid>
                    <Grid item>
                        {' '}
                        <ButtonGroup size="small" aria-label="small outlined button group" color="primary">
                            <Button
                                onClick={() => {
                                    handleVor(1);
                                }}
                                variant={state.filterButtonID === 1 ? 'contained' : 'outlined'}
                            >
                                Active
                            </Button>
                            <Button
                                onClick={() => {
                                    handleVor(2);
                                }}
                                variant={state.filterButtonID === 2 ? 'contained' : 'outlined'}
                            >
                                Completed
                            </Button>
                            <Button
                                onClick={() => {
                                    handleVor(3);
                                }}
                                variant={state.filterButtonID === 3 ? 'contained' : 'outlined'}
                            >
                                All
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    {/* <Grid component="label" item className="childSwitchBtnContainer" alignItems="center">
                        <Grid item>
                            <Switch checked={state.filterFlag} onChange={handleChange} value="checked" name="filterFlag" />
                        </Grid>
                        <Grid item>On</Grid>
                    </Grid>
                    <Grid component="label" item className="childSwitchBtnContainer" alignItems="center">
                        <Grid item>Inactive</Grid>
                        <Grid item>
                            <Switch
                                checked={state.activeOrInactive}
                                onChange={handleChange}
                                value="checked"
                                name="activeOrInactive"
                                disabled={!state.filterFlag}
                            />
                        </Grid>
                        <Grid item>Active</Grid>
                    </Grid> */}
                </Grid>
            )}

            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                onRowDataBound={(e, $row, id, record) => onRowDataBound(e, $row, id, record)}
                columns={columns}
                getUrl={state.getUrl}
                btnTitle=""
                onBtnClick={handleAdd}
                isReload={state.isReload}
                filterVal={props?.location?.state || {}}
            />
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default VorScreen;
