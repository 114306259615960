import React, { useState, useEffect } from 'react';
import { getAllJobUpdateNotesList } from '../../../../../../Core/Services/InternalUser/JOB/jobQueryService';
import { Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import AddEditJobsNotes from './addUpdateJobNotes';
import { CustomChip } from '../../../../../../Core/Controls';
import { Add } from '@material-ui/icons';
import AddJobUpdateNotes from './addJobNotes';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';

export const JobSingleNote = (props) => {
    const { id, name, jobUpdateDateCreated, notes, notesResult, jobUpdateDateClosed, jobUpdateClosedUserID } = props.note;
    const [stateNotes, setNotsState] = useState({ show: false });
    let checkIsClosed = jobUpdateDateClosed || jobUpdateClosedUserID;
    const closeModal = (res) => {
        setNotsState({ show: false });
        res && props.onFormSubmit(true);
    };

    const showModal = () => {
        if (!checkIsClosed) {
            setNotsState({ show: true });
        }
    };
    return (
        <>
            {stateNotes.show ? (
                <AddEditJobsNotes
                    showHistory={false}
                    orderNo={props.orderNo}
                    regNo={props.regNo}
                    id={id}
                    onClose={() => closeModal(false)}
                    jobID={props.jobID}
                    onFormSubmit={closeModal}
                />
            ) : null}
            <div className="single-note">
                <div
                    className="msg"
                    style={{ fontSize: 14, fontStyle: 'italic', ...(!checkIsClosed && { color: '#f50057', cursor: 'pointer' }) }}
                    onClick={showModal}
                >
                    {notes}
                </div>
                <div style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'left', float: 'left' }}>{notesResult ? notesResult : ''}</div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {name} @ {jobUpdateDateCreated ? moment(jobUpdateDateCreated).format('LLL') : '-'}
                </div>
                <br />
                {/* <hr style={{ opacity: '30%' }} /> */}
            </div>
        </>
    );
};
const JobUpdtaeNotes = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        showAddJobUpdateNotes: false
    });
    useEffect(async () => {
        pullApiData();
    }, [props.refreshDependancy]);

    const pullApiData = async () => {
        let res = await getAllJobUpdateNotesList(props.jobID);
        setState((st) => ({
            ...st,
            showLoader: false,
            list: res.data.notelist
        }));
    };
    const callonFormsubmit = (res) => {
        setState((st) => ({
            ...st,
            showAddJobUpdateNotes: false
        }));
        res && pullApiData();
    };
    const handelModal = (name, val) => {
        setState((st) => ({
            ...st,
            [name]: val
        }));
    };
    return (
        <div>
            <Grid
                item
                xs={12}
                style={{
                    padding: '5px',
                    height: props.height || '430px',
                    overflow: 'auto'
                }}
                className="grid-style custom-scroll"
            >
                {props.isDisabled && (
                    // <CustomChip
                    //     style={{ fontWeight: 'bold' }}
                    //     icon={Add}
                    //     label="Add Reminder Notes"
                    //     onClick={() => handelModal('showAddJobUpdateNotes', true)}
                    // />
                    <Button color="primary" size="small" variant="text" onClick={() => handelModal('showAddJobUpdateNotes', true)}>
                        Add Reminder Notes
                    </Button>
                )}
                <hr style={{ opacity: '30%' }} />
                {state.list?.map((n, i) => (
                    <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px', border: `1px dashed ${i % 2 ? '#EAD7D2' : '#EAD7D2'}` }}>
                        <JobSingleNote orderNo={props.orderNo} regNo={props.regNo} key={i} note={n} jobID={props.jobID} onFormSubmit={callonFormsubmit} />
                    </div>
                ))}
            </Grid>
            {state.showAddJobUpdateNotes ? (
                <DialogComp open={true} onClose={() => handelModal('showAddJobUpdateNotes', false)} title={'Next Progress Reminder'} maxWidth="sm" fullWidth>
                    <AddJobUpdateNotes jobID={props.jobID} onFormSubmit={callonFormsubmit} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default JobUpdtaeNotes;
