import React, { useCallback, useEffect } from 'react';
import { BreadCrumbs, DatePicker, DateTimePicker, formatters, SingleSelect } from '../../../../Core/Controls';
import { Card, Grid, InputLabel, Typography, CircularProgress } from '@material-ui/core';
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import './jobReport.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { useState } from 'react';
import moment from 'moment';
import { getjobByType } from '../../../../Core/Services/InternalUser/jobReportingService';
import { getVehicleScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { GetJobReportingExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';

const Crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Job Reporting', active: true }
];

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const columns = [
    { key: 'depot', name: 'Depot', width: '10%' },
    { key: 'reg', name: 'Reg No.', width: '10%' },
    { key: 'orderNo', name: 'Order No.', width: '10%' },
    { key: 'jobDate', name: 'Job Date', width: '13%', formatter: formatters.Date_DDMMYYYY },
    { key: 'bookedDate', name: 'Booked Date', width: '13%', formatter: formatters.Date_DDMMYYYY },
    { key: 'approvedDate', name: 'Approved Date', width: '15%', formatter: formatters.Date_DDMMYYYY },
    { key: 'description', name: 'Description', width: '10%' },
    { key: 'jobType', name: 'Job Type', width: '10%' },
    { key: 'part', name: 'Part', width: '10%', formatter: formatters.NumberWithPound },
    { key: 'labour', name: 'Labour', width: '10%', formatter: formatters.NumberWithPound },
    { key: 'total', name: 'Total', width: '10%', formatter: formatters.NumberWithPound }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const JobReportScreen = (props) => {
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        deoptList: [],
        jobTypeList: [],
        monthYearList: [],
        vmrsDescriptionList: [],
        url: `vehicleJob/GetCustomerSpend?startDate=${startDate}&endDate=${endDate}`,
        checkCondition: false,
        showDetailsPopup: false
    });

    const [value, setValue] = useState({
        name: '',
        value: '',
        heading: ''
    });

    const handleDetailpopUp = (params, heading) => {
        setState((st) => ({ ...st, showDetailsPopup: !st.showDetailsPopup }));
        setValue((st) => ({ ...st, name: params.name || '', value: params.Total || '', heading: heading || '' }));
    };

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null
    });

    let getURl = () => {
        return `vehicleJob/GetCustomerSpend?startDate=${state.startDate}&endDate=${state.endDate}&custId=${
            tempOptions.customerIDValue?.id || ''
        }&ServiceAgentID=${tempOptions.serviceAreaIDValue?.id || ''}&TechnicianUserID=${tempOptions.engineerIDValue?.id || ''}&DepotID=${
            tempOptions.deoptIDValue?.id || ''
        }`;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        if (val) {
            setTempOptions((st) => {
                let newSt = { ...st, [nm]: val };
                if (nm === 'customerIDValue') {
                    newSt.depotsIDValue = null;
                }
                return newSt;
            });
        } else {
            setTempOptions((st) => ({ ...st, [nm]: null }));
        }
    };

    useEffect(async () => {
        let isCustSelected;
        let res = await getVehicleScreenDropdowns();
        setState((st) => ({
            ...st,
            customerList: res.data.customers || [],
            depotsList: res.data.depots || [],
            technician: res.data.technician || [],
            serviceAgents: res.data.serviceAgents || []
        }));

        if (props.customerID && props.fromTab) {
            isCustSelected = res.data.customers.find((p) => props.customerID == p.id);
        } else if (isCustomer) {
            isCustSelected = res.data.customers[0];
        }
        setTempOptions((st) => ({ ...st, customerIDValue: isCustSelected }));
    }, []);

    useEffect(async () => {
        let res = await getjobByType(
            state.startDate,
            state.endDate,
            tempOptions.customerIDValue?.id || '',
            tempOptions.serviceAreaIDValue?.id || '',
            tempOptions.engineerIDValue?.id || '',
            tempOptions.deoptIDValue?.id || ''
        );
        if (res.success) {
            let deoptList = [...res?.data?.depot].map((m, i) => ({
                name: m.depot,
                Total: m.jobTotal
            }));
            let vmrsDescriptionList = [...res?.data?.vmrsDescription].map((m, i) => ({
                name: m.vmrsDescription,
                Total: m.jobTotal
            }));

            let jobTypeList = [...res?.data?.jobType].map((m) => ({
                name: m.jobType,
                Total: m.jobTotal
            }));

            let monthYearList = [...res?.data?.monthYear].map((m) => ({
                name: m.monthYear,
                Total: m.jobTotal
            }));
            setState((st) => ({
                ...st,
                deoptList: deoptList,
                jobTypeList: jobTypeList,
                monthYearList: monthYearList,
                vmrsDescriptionList: vmrsDescriptionList,
                checkCondition: jobTypeList.length > 0 ? true : false,
                url: getURl()
            }));
        }
        getURl();
    }, [tempOptions.customerIDValue, state.startDate, state.endDate, tempOptions.serviceAreaIDValue, tempOptions.engineerIDValue, tempOptions.deoptIDValue]);

    const convertData = (res) => {
        setState((st) => ({ ...st, VorList: res.data.list }));
        return {
            records: res.data.list,
            total: res.data.total
        };
    };

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 10;

        return (
            <g>
                <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                    {value ? <>£{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                </text>
            </g>
        );
    };

    return (
        <div className={`${props.fromTab ? '' : 'screen'}`}>
            {props.fromTab ? '' : <BreadCrumbs crumbs={Crumbs} />}

            <Grid container spacing={2}>
                {!isCustomer && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect
                            options={state.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel required shrink>
                        Depot
                    </InputLabel>
                    <SingleSelect
                        options={state.depotsList}
                        value={tempOptions.deoptIDValue}
                        onChange={singleSelectChange('deoptIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel required shrink>
                        Service Area
                    </InputLabel>
                    <SingleSelect
                        options={state.serviceAgents}
                        value={tempOptions.serviceAreaIDValue}
                        onChange={singleSelectChange('serviceAreaIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel required shrink>
                        Engineer
                    </InputLabel>
                    <SingleSelect
                        options={state.technician}
                        value={tempOptions.engineerIDValue}
                        onChange={singleSelectChange('engineerIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <DatePicker value={state.startDate} onChange={inputChange} name="startDate" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>End Date</InputLabel>
                    <DatePicker value={state.endDate} onChange={inputChange} name="endDate" />
                </Grid>

                {/* {tempOptions.customerIDValue ? ( */}
                <>
                    <Grid item xs={12} md={4} sm={6} lg={3}></Grid>
                    {isCustomer && <Grid item xs={12} md={4} sm={6} lg={3}></Grid>}
                    {!state.checkCondition ? (
                        <Grid item xs={12}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>No Records Found</h4>
                            </div>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button">Month Year</Typography>
                                    <div className="pieChart">
                                        {state.monthYearList.length > 0 ? (
                                            <ResponsiveContainer>
                                                <PieChart>
                                                    <Pie
                                                        data={state.monthYearList}
                                                        cx="25%"
                                                        cy="50%"
                                                        paddingAngle={3}
                                                        outerRadius={80}
                                                        innerRadius={60}
                                                        fill="#8884d8"
                                                        dataKey="Total"
                                                    >
                                                        <Tooltip
                                                        // contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                        // position={{ x: 30, y: 20 }}
                                                        />
                                                        {state.monthYearList.map((entry, index) => (
                                                            <>
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={COLORS[index % COLORS.length]}
                                                                    style={{ cursor: 'pointer' }}
                                                                    // onClick={() => {
                                                                    //     handleDetailpopUp(entry, 'Month Year');
                                                                    // }}
                                                                />
                                                            </>
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <Grid item container justifyContent="center" alignContent="center">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                    <div style={{ position: 'absolute', right: 2, top: 44, maxHeight: '190px', overflow: 'auto' }} className="custom-scroll">
                                        {state.monthYearList.map((m, i) => {
                                            let color = COLORS[i];
                                            return (
                                                <div style={{ padding: 0 }}>
                                                    <div style={{ fontSize: 13, width: 150, display: 'flex', justifyContent: 'flex-start' }}>
                                                        {' '}
                                                        <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                        <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div>
                                                        <div style={{ fontSize: 11, marginTop: 2 }}>
                                                            &nbsp;({' '}
                                                            {m.Total ? <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b> : <>£0.00</>}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <Typography variant="button">VMRS Description</Typography>
                                <div className="barchart">
                                    {state.vmrsDescriptionList.length > 0 ? (
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={state.vmrsDescriptionList}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip
                                                    contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                    position={{ x: 30, y: 20 }}
                                                />
                                                <Legend />

                                                <Bar dataKey="Total" fill={'White'} barSize={40} height={10}>
                                                    <LabelList dataKey="Total" content={renderCustomizedLabel} />
                                                    {state.vmrsDescriptionList.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={barColors[index]}
                                                            // onClick={() => {
                                                            //     handleDetailpopUp(entry, 'VMRS');
                                                            // }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <Grid item container justifyContent="space-evenly" alignContent="center">
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <h4>No Records Found</h4>
                                            </div>
                                        </Grid>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button">Job Type</Typography>
                                    <div className="pieChart">
                                        {state.jobTypeList.length > 0 ? (
                                            <ResponsiveContainer>
                                                <PieChart>
                                                    <Pie
                                                        data={state.jobTypeList}
                                                        cx="25%"
                                                        cy="50%"
                                                        paddingAngle={3}
                                                        outerRadius={80}
                                                        innerRadius={60}
                                                        fill="#8884d8"
                                                        dataKey="Total"
                                                        // label
                                                    >
                                                        {state.jobTypeList.map((entry, index) => (
                                                            <>
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={COLORS[index % COLORS.length]}
                                                                    style={{ cursor: 'pointer' }}
                                                                    // onClick={() => {
                                                                    //     handleDetailpopUp(entry, ' Job Type');
                                                                    // }}
                                                                />
                                                            </>
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <Grid item container justifyContent="space-evenly" alignContent="center">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                    <div style={{ position: 'absolute', right: 2, top: 44, maxHeight: '190px', overflow: 'auto' }} className="custom-scroll">
                                        {state.jobTypeList.map((m, i) => {
                                            let color = COLORS[i];
                                            return (
                                                <div style={{ padding: 0 }}>
                                                    <div style={{ fontSize: 13, width: 150, display: 'flex', justifyContent: 'flex-start' }}>
                                                        {' '}
                                                        <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                        <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div>
                                                        <div style={{ fontSize: 11, marginTop: 2 }}>
                                                            &nbsp; (
                                                            {m.Total ? <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b> : <>£0.00</>})
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <div>
                                    <Typography variant="button" className="barHeading">
                                        Depot
                                    </Typography>
                                    <div className="barchart">
                                        {state.deoptList.length > 0 ? (
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={state.deoptList}
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip
                                                        contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                        position={{ x: 30, y: 20 }}
                                                    />
                                                    <Legend />

                                                    <Bar dataKey="Total" fill={'White'} barSize={40} height={10}>
                                                        <LabelList dataKey="Total" content={renderCustomizedLabel} />
                                                        {state.deoptList.map((entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={barColors[index]}
                                                                // onClick={() => {
                                                                //     handleDetailpopUp(entry, ' Depots');
                                                                // }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <Grid item container justifyContent="space-evenly" alignContent="center">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <GijgoGrid
                                    dataConverter={(res) => convertData(res)}
                                    columns={columns}
                                    getUrl={state.url}
                                    // isReload={state.isReload}
                                    showDownload={true}
                                    downloadBtnName="Download xlsx"
                                    downloadCustomUrl={() =>
                                        GetJobReportingExcelFile({
                                            fromDate: state.startDate,
                                            toDate: state.endDate,
                                            customerID: tempOptions.customerIDValue?.id || '',
                                            fileExt: 'xlsx'
                                        })
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </>
                {/* ) : (
                    <Grid item xs={12}>
                        {!isCustomer ? (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>Select customer to view report data</h4>
                            </div>
                        ) : (
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        height: 'calc(100vh - 150px)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </Grid>
                )} */}
            </Grid>
            {state.showDetailsPopup ? (
                <DialogComp maxWidth="sm" title={`${value.heading} Details`} onClose={handleDetailpopUp}>
                    <div style={{ padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Name</b> :<b style={{ width: '50%', textAlign: 'Right' }}>{value.name}</b>
                            </Grid>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Total </b>:<b style={{ width: '50%', textAlign: 'Right' }}>{value.value}</b>
                            </Grid>
                        </Grid>
                    </div>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default JobReportScreen;
