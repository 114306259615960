import { FormHelperText, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MandatoryField, SecondaryButton, SelectBox, SimpleField, SingleSelect } from '../../../Core/Controls';
import { getStandardLabourLineByItemID, StandardLabourLineItems_AddUpdate, getStandardLabourDropDown } from '../../../Core/Services/Admin/StandardLabourLine';
import { useToast } from '../../../Core/Hooks/useToast';
import LabourPartModal from './LabourPartModal';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '10px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});

const AddUpdateLabourLine = (props) => {
    const classes = useStyles();
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        lineItemDescription: '',
        lineItemID: props?.selectedData?.lineItemID || null,
        errors: {},
        workList: [],
        costTypeList: [],
        selectedListPartList: [],
        workCategoryValue: null,
        lineItemCostTypeIDValue: null,
        showPartModal: false,
        stdParts: [],
        statusList: []
    });

    useEffect(async () => {
        let res1 = await getStandardLabourDropDown();
        if (res1.success) {
            const category = res1.data.vmrsCategory.map((item) => ({
                id: item.catid + ',' + item.subcatid,
                name: item.subcatname
            }));
            setState((st) => ({
                ...st,
                workList: category,
                costTypeList: res1.data.costType,
                statusList: res1.data.status || []
            }));
        }
        if (props?.selectedData?.lineItemID) {
            let res = await getStandardLabourLineByItemID(props?.selectedData?.lineItemID);
            if (res.success) {
                let data = res?.data.list[0];
                setState((st) => ({
                    ...st,
                    lineItemDescription: data.lineItemDescription,
                    // lineItemHourRate: data.lineItemHourRate,
                    lineItemHours: data.lineItemHours,
                    lineNumber: data.lineNumber,
                    lineItemVMRSCategoryID: data.lineItemVMRSCategoryID,
                    lineItemVMRSSubCategoryid: data.lineItemVMRSSubCategoryid,
                    lineItemLineID: data.lineItemLineID,
                    lineItemCostTypeID: data.lineItemCostTypeID,
                    workCategory: data.lineItemVMRSCategoryID + ',' + data.lineItemVMRSSubCategoryid,
                    stdParts: res.data.stdParts || []
                }));
            }
        }
    }, []);

    useEffect(() => {
        let newSt = {};
        if (state.workCategory && state.workList.length > 0) {
            newSt.workCategoryValue = state.workList?.find(({ id }) => id === state.workCategory);
        }

        if (state.lineItemCostTypeID && state.costTypeList.length > 0) {
            newSt.lineItemCostTypeIDValue = state.costTypeList?.find(({ id }) => id === state.lineItemCostTypeID);
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    }, [state.workCategory, state.workList, state.costTypeList, state.lineItemCostTypeID]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const validations = () => {
        const { lineItemDescription, workCategoryValue, lineItemHours, lineNumber, lineItemHourRate, lineItemCostTypeIDValue } = state;

        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(lineItemDescription)) {
            errors.lineItemDescription = 'Description is required';
            formIsValid = false;
        }
        if (!workCategoryValue?.id) {
            errors.workCategoryValue = 'Work Category is required';
            formIsValid = false;
        }

        // if (!lineItemHours) {
        //     errors.lineItemHours = 'Hours is required';
        //     formIsValid = false;
        // }
        if (!lineItemCostTypeIDValue) {
            errors.lineItemCostTypeIDValue = 'Cost Type is required';
            formIsValid = false;
        }
        // if (!lineItemHourRate) {
        //     errors.lineItemHourRate = 'Hours Rate is required';
        //     formIsValid = false;
        // }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            let formData = {
                lineItemLineID: props.lineID,
                lineItemDescription: state.lineItemDescription,
                lineItemID: state.lineItemID,
                // lineItemHourRate: state.lineItemHourRate,
                lineItemHours: state.lineItemHours,
                lineNumber: state.lineNumber,
                lineItemVMRSCategoryID: state.lineItemVMRSCategoryID,
                lineItemVMRSSubCategoryid: state.lineItemVMRSSubCategoryid,
                lineItemCostTypeID: state.lineItemCostTypeIDValue?.id,
                stdParts: state.stdParts
            };
            let res = await StandardLabourLineItems_AddUpdate(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    const WorkCategoryChange = (nm) => (e, val) => {
        if (nm === 'workCategoryValue') {
            const cat = val?.id?.split(',');
            if (cat?.length) {
                setState((state) => ({
                    ...state,
                    lineItemVMRSCategoryID: cat[0],
                    lineItemVMRSSubCategoryid: cat[1],
                    [nm]: val
                }));
            }
        } else {
            setState((state) => ({
                ...state,
                [nm]: val
            }));
        }
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, showPartModal: false }));
    };

    const inputChange = (event, selectedindex) => {
        // stdParts
        // setState((state) => ({ ...state, showPartModal: false }));
        const { name, value } = event.target;
        let tempList = [...state.stdParts];
        tempList[selectedindex][name] = value;
        setState((st) => ({
            ...st,
            stdParts: tempList
        }));
    };

    const addPartsHandler = (SelectedList) => {
        let tempList = SelectedList.map((q) => ({
            standardLabourPartID: null,
            standardLabourPartPartID: q.partID,
            description: q.description,
            partNumber: q.partNumber,
            standardLabourPartQuantity: q.standardLabourPartQuantity || 0,
            standardLabourPartStatusID: q.standardLabourPartStatusID || '',
            isDeleted: false
        }));

        setState((state) => ({
            ...state,
            showPartModal: false,
            stdParts: [...state.stdParts, ...tempList]
        }));
    };

    const handleAddEditshowPartModal = (res) => {
        setState((state) => ({ ...state, showPartModal: true }));
    };
    const handleDeletePart = (selectedindex) => {
        let tempList = [...state.stdParts];
        tempList[selectedindex].isDeleted = true;
        setState((st) => ({
            ...st,
            stdParts: tempList
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Labour Description"
                    fullWidth
                    name="lineItemDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.lineItemDescription}
                    errors={state.errors.lineItemDescription}
                    autoFocus={true}
                />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={6}>
                <Grid item xs={12} sm={6}>
                    <SimpleField
                        type="number"
                        inputLabel="Hours"
                        placeholder="Hours"
                        value={state.lineItemHours}
                        onChange={handleInputChange}
                        errors={state.errors.lineItemHours}
                        name="lineItemHours"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SimpleField
                        type="number"
                        inputLabel="Line Number"
                        placeholder="Line Number"
                        value={state.lineNumber}
                        onChange={handleInputChange}
                        errors={state.errors.lineNumber}
                        name="lineNumber"
                    />
                </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6} className="mandatory-fields">
                <MandatoryField
                    type="number"
                    inputLabel="Labour Rate"
                    name="lineItemHourRate"
                    placeholder="Rate"
                    onChange={handleInputChange}
                    value={state.lineItemHourRate}
                    errors={state.errors.lineItemHourRate}
                />
            </Grid> */}
            <Grid item xs={12} sm={6} className="mandatory-fields">
                <SingleSelect
                    inputLabel="Cost Type"
                    isRequired={true}
                    style={{ marginTop: -8 }}
                    options={state.costTypeList || []}
                    value={state.lineItemCostTypeIDValue}
                    isDisabled={props.isCustomer || state.readOnly}
                    onChange={WorkCategoryChange('lineItemCostTypeIDValue')}
                />
                <FormHelperText error>{state.errors.lineItemCostTypeIDValue}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} className="mandatory-fields">
                <SingleSelect
                    inputLabel="Work Category"
                    isRequired={true}
                    style={{ marginTop: -8 }}
                    options={state.workList}
                    value={state.workCategoryValue}
                    onChange={WorkCategoryChange('workCategoryValue')}
                />
                <FormHelperText error>{state.errors.workCategoryValue}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props?.selectedData?.lineItemID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
            <Grid item xs={12}>
                <TableContainer
                    // component={Paper}
                    className="custom-scroll"
                    // style={{ minHeight: 600, minWidth: '600px' }} className="custom-scroll"
                    style={{ height: '450px', borderRadius: '0px' }}
                >
                    <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>
                                    <IconButton onClick={() => handleAddEditshowPartModal()} style={{ padding: '0px 4px' }} color="primary">
                                        <AddBoxIcon />
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell>Part No.</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell width="20%">Status</StyledTableCell>
                                <StyledTableCell width="20%">Quantity</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {state?.stdParts.length > 0 ? (
                                state?.stdParts
                                    .filter((row) => !row.isDeleted)
                                    .map((row, i) => {
                                        return (
                                            <StyledTableRow key={row.standardLabourPartPartID}>
                                                <StyledTableCell>
                                                    <DeleteIcon color="secondary" onClick={() => handleDeletePart(i)} style={{ cursor: 'pointer' }} />
                                                </StyledTableCell>
                                                <StyledTableCell>{row.partNumber}</StyledTableCell>
                                                <StyledTableCell>{row.description}</StyledTableCell>
                                                <StyledTableCell>
                                                    <SelectBox
                                                        name="standardLabourPartStatusID"
                                                        value={row.standardLabourPartStatusID || ''}
                                                        onChange={(e) => inputChange(e, i)}
                                                        List={state.statusList || []}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SimpleField
                                                        type="number"
                                                        name="standardLabourPartQuantity"
                                                        onChange={(e) => inputChange(e, i)}
                                                        value={row.standardLabourPartQuantity}
                                                        // error={i === index}
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={5} height={'100%'}>
                                        <Alert severity="info">No Record Found</Alert>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {state.showPartModal ? (
                <DialogComp title={'Add Parts'} onClose={() => closeDialog(false)} maxWidth="md" fullWidth>
                    <LabourPartModal
                        addPartsHandler={addPartsHandler}
                        stdParts={state.stdParts}
                        onClose={() => closeDialog(true)}
                        statusList={state.statusList || []}
                    />
                </DialogComp>
            ) : null}
        </Grid>
    );
};

export default AddUpdateLabourLine;
