import { result } from 'lodash';
import React, { useRef, useState } from 'react';
import { PrimaryButton } from '../../../../../Core/Controls';
import { InputLabel } from '@material-ui/core';
import DialogComp from '../../../../../Core/Dialog/DialogComp';

const InspectionImages = ({ state, setState, disabled, hideBtn }) => {
    const [img, setImg] = useState(false);

    const fileInputRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setState((st) => ({ ...st, vehicleInspectionBrakeTestPhoto: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const openImag = () => {
        setImg(!img);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} style={{ display: 'none' }} />
            <div>
                <InputLabel>&nbsp;</InputLabel>
                {!hideBtn && (
                    <PrimaryButton onClick={handleButtonClick} disabled={disabled}>
                        Upload Image
                    </PrimaryButton>
                )}
            </div>
            {state.vehicleInspectionBrakeTestPhoto && (
                <img
                    src={state.vehicleInspectionBrakeTestPhoto}
                    alt="Uploaded Preview"
                    style={{ maxWidth: '30%', maxHeight: '60px', cursor: 'pointer' }}
                    onClick={openImag}
                />
            )}
            {img ? (
                <div style={{ padding: 10 }}>
                    <DialogComp onClose={openImag}>
                        <img src={state.vehicleInspectionBrakeTestPhoto} alt="Uploaded Preview" style={{ marginTop: '50px', maxWidth: '100%' }} />
                    </DialogComp>
                </div>
            ) : null}
        </div>
    );
};

export default InspectionImages;
