import { memoize } from 'lodash';
import { getWithAuth } from '../../Basic/HttpService';

export const getEngineerDashboard = async (screenName) => {
    let res = await getWithAuth(`EngineerDashboard`);
    return res;
};
export const getRegNo = memoize(async (screenName) => {
    let res = await getWithAuth(`EngineerDashboard/GetVehicleReg`);
    return res;
});
