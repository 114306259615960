import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, EditButton } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { Business } from '@material-ui/icons';
import DialogComp from '../../../Core/Dialog/DialogComp';
import ImportAddress from './importAddress';
import AddEditAddress from './addUpdate';
import { AppStorage } from '../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'action', name: 'Action', width: 80 },
    { key: 'addressName', name: 'Name', width: 250 },
    { key: 'addressCode', name: 'Code', width: 80 },
    { key: 'address', name: 'Address', width: 450 },
    // { key: 'addressLine2', name: 'Address2', width: 300 },
    // { key: 'addressTown', name: 'City', width: 300 },
    // { key: 'addressCounty', name: 'County', width: 250 },
    // { key: 'addressCountry', name: 'Country ', width: 150 },
    // { key: 'addressPostCode', name: 'Post Code', width: 100 },
    { key: 'addressTelephone', name: 'Telephone', width: 130 },
    // { key: 'addressFax', name: 'Fax', width: 150 },
    // { key: 'addressContactName', name: 'Contact Name', width: 150 },
    { key: 'addressEmail', name: 'Email', width: 250 }
];
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Address', path: '/', active: true }
];

const AddressScreen = (props) => {
    const [state, setState] = useState({
        showImportAdd: false,
        isReload: true,
        data: {}
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const pullDepotsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullDepotsAndUpdateState();
    }, []);

    const onImportBtnClick = () => {
        setState((st) => ({ ...st, showImportAdd: true }));
    };

    const handleClose = (res) => {
        console.log(res);
        setState((st) => ({ ...st, showImportAdd: false }));
        res && pullDepotsAndUpdateState();
    };

    const handleModal = (val, res) => {
        setState((st) => ({ ...st, showModal: !st.showModal, data: val }));
        res && pullDepotsAndUpdateState();
    };

    const handleAdd = () => {
        setState((st) => ({ ...st, showModal: !st.showModal }));
    };

    const btnToRender = [
        {
            btnIcon: Business,
            btnTitle: `${checkhaveAccess?.CanImportAddress ? 'Import Address' : ''}`,
            btnLabel: 'Import Address',
            onBtnClick: onImportBtnClick
        }
    ];
    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleModal(row, false)} toolTipTitle="Edit Address" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            <>
                <BreadCrumbs crumbs={crumbs} />
                <GijgoGrid
                    btnToRender={btnToRender}
                    getUrl={`Address/Address_List`}
                    columns={columns}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    btnTitle={checkhaveAccess?.CanUpdateAddress ? 'Add Address' : ''}
                    onBtnClick={handleAdd}
                />
            </>
            {state.showImportAdd ? (
                <DialogComp open={true} onClose={() => handleClose(true)} title={'Import Address'} fullScreen>
                    <ImportAddress onClose={handleClose} />
                </DialogComp>
            ) : null}

            {state.showModal ? (
                <DialogComp title={state.data?.addressCode ? 'Update Address' : 'Add Address'} onClose={() => handleModal(null, false)} maxWidth="md" fullWidth>
                    <AddEditAddress onClose={handleModal} data={state.data} checkhaveAccess={checkhaveAccess} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default AddressScreen;
