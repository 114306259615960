import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import LinkIcon from '@material-ui/icons/Link';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import WorkIcon from '@material-ui/icons/Work';
import EventIcon from '@material-ui/icons/Event';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EuroIcon from '@material-ui/icons/Euro';
import GrainIcon from '@material-ui/icons/Grain';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import BuildIcon from '@material-ui/icons/Build';
import MapIcon from '@material-ui/icons/Map';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.AdminDashboard,
        authType: TypeOfAuth.Both,
        icon: <DashboardIcon />
    },
    {
        label: 'Users',
        path: '/users/:all',
        exact: true,
        component: Components.UserScreen,
        authType: TypeOfAuth.Both,
        icon: <PeopleAltIcon />
    },
    {
        label: 'Customers',
        path: '/controller/customer',
        exact: true,
        icon: <PermContactCalendarIcon />,
        component: Components.CustomerScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Edit Customer',
        path: '/controller/editcustomer/:id/:customer',
        hidden: true,
        exact: true,
        component: Components.EditCustomer,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Branches',
        path: '/branches',
        exact: true,
        component: Components.BranchScreen,
        authType: TypeOfAuth.Both,
        icon: <LinkIcon />
    },
    // {
    //     label: 'Admin',
    //     authType: TypeOfAuth.Both,
    //     icon: <SettingsIcon />,
    //     children: [
    {
        label: 'Report Catalogues',
        path: '/reportcatalogues',
        icon: <AssignmentIcon />,
        exact: true,
        component: Components.ReportCataloguesScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Drivers',
        authType: TypeOfAuth.Both,
        icon: <AirlineSeatReclineExtraIcon />,
        children: []
    },
    {
        label: 'License Types',
        path: '/driver/licensetype',
        exact: true,
        component: Components.LicenseType,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Types',
        path: '/driver/drivertype',
        exact: true,
        component: Components.DriverType,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Jobs',
        authType: TypeOfAuth.Both,
        icon: <WorkIcon />,
        children: []
    },
    {
        label: 'VMRS Categories',
        path: '/vehicle/VmrsCategory',
        exact: true,
        component: Components.VmrsCategory,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VMRS SubCategories',
        path: '/vehicle/vmrssubcategory',
        exact: true,
        component: Components.VmrsSubCategoryScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Booking Status',
        path: '/vehicle/bookingstatus',
        exact: true,
        component: Components.BookingStatus,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Booking Delay Reasons',
        path: '/vehicle/bookingdelayreason',
        exact: true,
        component: Components.BookingDelayReason,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Collection And Deliveries',
        path: '/vehicle/collectionndelivery',
        exact: true,
        component: Components.CollectionNDelivery,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Cost Categories',
        path: '/vehicle/cost-categories',
        exact: true,
        component: Components.CostCategories,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Cost Types',
        path: '/vehicle/Cost-types',
        exact: true,
        component: Components.CostTypes,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Events',
        authType: TypeOfAuth.Both,
        icon: <EventIcon />,
        children: []
    },
    {
        label: 'Slippage Reasons',
        path: '/vehicle/SlippageScreen',
        exact: true,
        component: Components.Slippage,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Documents',
        path: '/vehicle/eventdocs',
        exact: true,
        component: Components.EventDocuments,
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'Types',
    //     path: '/vehicle/eventinternaltypes',
    //     exact: true,
    //     component: Components.EventIntTypes,
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Schedule Events',
        path: '/vehicle/scheduleevents',
        exact: true,
        component: Components.ScheduleEvents,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Standard Labour Line',
        path: '/StandardLabourLine',
        exact: true,
        component: Components.StandardLabourLineTabsScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agents',
        authType: TypeOfAuth.Auth,
        // icon: < BuildIcon / > ,
        path: '/serviceagent',
        exact: true,
        component: Components.ServiceAgent,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'ServiceAgentDashBoard',
        path: '/controller/editServiceAgent/:id',
        exact: true,
        component: Components.EditServiceAgent,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Maintenance Providers',
        path: '/maintenance/maintenanceprovider',
        exact: true,
        component: Components.MaintenanceProvider,
        authType: TypeOfAuth.Auth,
        icon: <GavelIcon />
    },
    {
        label: 'Vehicles',
        authType: TypeOfAuth.Both,
        icon: <LocalTaxiIcon />,
        children: []
    },
    {
        label: 'Vehicle Types',
        path: '/vehicle/vehicletype',
        exact: true,
        component: Components.VehicleType,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Makes',
        path: '/vehicle/make',
        exact: true,
        component: Components.VehicleManufacturer,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Models',
        path: '/vehicle/model',
        exact: true,
        component: Components.ModelScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VOR Types',
        path: '/VOR/vortypes',
        exact: true,
        component: Components.VorType,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Retorque Reasons',
        path: '/VOR/retorquereasons',
        exact: true,
        component: Components.RetorqueReasons,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Retorque Positions',
        path: '/VOR/retorqueposition',
        exact: true,
        component: Components.RetorquePositions,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VOR Type Details',
        path: '/VOR/vortypedetails',
        exact: true,
        component: Components.VORTypeDetail,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vehicle Categories',
        path: '/vehicle/vehicle-category',
        exact: true,
        component: Components.VehicleCategory,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Fuel Types',
        path: '/vehicle/fuel-types',
        exact: true,
        component: Components.FuelTypes,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defect Types',
        path: '/vehicle/defect-Type',
        exact: true,
        component: Components.DefectType,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vehicle Checks',
        authType: TypeOfAuth.Both,
        icon: <VerifiedUserIcon />,
        children: []
    },
    {
        label: 'Inspection Sections',
        path: '/vehicle/checksection',
        exact: true,
        component: Components.CheckSection,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Inspection Checks',
        path: '/vehicle/VehicleCheckChecks',
        exact: true,
        component: Components.VehicleCheckChecks,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Daily Checks',
        path: '/vehicle/VehicleDailyChecks',
        exact: true,
        component: Components.vehicleDailyChecksTab,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Navigation',
        authType: TypeOfAuth.Both,
        icon: <MapIcon />,
        children: []
    },
    // {
    //     label: 'Left',
    //     path: '/navigation/left-menu',
    //     exact: true,
    //     hidden: true,
    //     component: Components.TreeViewTab,
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Menu Items',
        path: '/navigation/menu-items',
        exact: true,
        component: Components.MenuTab,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent Facilities',
        path: '/vehicle/serviceagentfacilities',
        exact: true,
        component: Components.ServiceAgentFacilities,
        // icon: < EmojiTransportationIcon / > ,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Cost Centres',
        path: '/vehicle/costcentre',
        icon: <EuroIcon />,
        exact: true,
        component: Components.CostCenter,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Product Groups',
        path: '/vehicle/ProductGroupScreen',
        icon: <GrainIcon />,
        hidden: true,
        exact: true,
        component: Components.ProductGroup,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Email Templates',
        path: '/vehicle/email_templates',
        icon: <MailOutlineRoundedIcon />,
        exact: true,
        component: Components.EmailTemplates,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Settings',
        path: '/Settings',
        icon: <SettingsIcon />,
        exact: true,
        component: Components.Settings,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'BookJob',
        path: '/controller/jobTabScreen/:id/:vehicleID',
        exact: true,
        component: Components.jobTabScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contract Schedule',
        path: '/contract_shedules',
        exact: true,
        component: Components.ContractEvents,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Parts',
        path: '/parts',
        exact: true,
        component: Components.Parts,
        authType: TypeOfAuth.Auth
    }
];
// }
// ];
