import { InputLabel, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import StickyNotes from '../../Controllers/Notes/stickyNotes';
import '../controllerStyle.scss';
import AddEditCustomer from './addCustomer';
import { CustomerProvider, CustomerScreenContext } from './CustomerProvider';
import { CustomChip } from '../../../../Core/Controls';
import ImportMileage from './ImportMileage';
import ImportJourney from './ImportMileage/importJourney';
import ImportFuel from './ImportMileage/importFuel';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { formatters } from '../../../../Core/Controls';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import { useToast } from '../../../../Core/Hooks/useToast';
import { postCustomerLicence } from '../../../../Core/Services/Admin/licenceCheck';
import { CreditTopup, LicenceResponseTable } from './LicenceCheckTopUp';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { FromLoader } from '../../../../Core/Loader/formLoader';
import { Link } from 'react-router-dom';
import VorReportingTab from './VorTab';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import ImportDepot from './ImportMileage/importDepot';
import ImportServiceAgent from './ImportMileage/importServiceAgent';
import { Person } from '@material-ui/icons';
import { markCustomerOnStop } from '../../../../Core/Services/InternalUser/ControllerService/customerService';
import { StyledTableCell, StyledTableRow } from '../../../../Core/Controls/Table/StyledCell&Row';

const ISODays = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' }
];

export const CustomerDashBoard = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { state, optionsState, pullCustomerdata, activeLoader, inputChange, invoiceFields } = useContext(CustomerScreenContext);
    const { userRoles } = useLoginInfo();
    const isAdmin = userRoles.includes('admin');
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [listNames, setListNames] = useState({
        accountManagaerName: null,
        currency: null,
        isoStartDate: null
    });

    const [licence, setLicence] = useState({ checkBtn: false, isMandatory: '', show: true, showTopUp: false, showRes: false });
    const [resList, setResList] = useState([]);

    const [data, setData] = useState({
        show: false,
        CustomerID: null,
        customerIsOnStop: false
    });

    useEffect(() => {
        setData((st) => ({
            ...st,
            customerIsOnStop: state.customerIsOnStop
        }));
    }, [state.customerIsOnStop]);

    useEffect(() => {
        if (props.customerID) {
            setData((st) => ({
                ...st,
                CustomerID: props.customerID
            }));
        }
    }, []);

    useEffect(() => {
        let accName = optionsState.aCManagerList?.filter((x) => {
            return x.id == state.customerAccountManagerID;
        })[0]?.name;
        let currencyName = optionsState.currencyList?.filter((x) => {
            return x.id == state.customerCurrencyID;
        })[0]?.name;
        let isoName = ISODays?.filter((x) => {
            return x.id == state.customerISOWeekStartDay;
        })[0]?.name;
        setListNames((st) => ({
            ...st,
            accountManagaerName: accName,
            currency: currencyName,
            isoStartDate: isoName
        }));
    }, [state.customerAccountManagerID, state.customerCurrencyID, state.customerISOWeekStartDay]);

    const handleAdd = (id, name) => {
        setData((st) => ({
            ...st,
            [name]: true
        }));
    };

    useEffect(() => {
        setlicenceCheckMessage();
    }, [state.customerLicenceCheckAccountCode]);

    const setlicenceCheckMessage = () => {
        let isMandatory = true;
        let { customerCompanyName, customerPostCode, customerAddress1, customerCity, customerTelephone, customerEmail, customerLicenceCheckAccountCode } =
            state;
        let licenceCheckMessage = [];
        let show = true;

        if (!customerCompanyName) {
            licenceCheckMessage.push('Company Name');
            isMandatory = false;
        }
        if (!customerPostCode) {
            licenceCheckMessage.push('PostCode');
            isMandatory = false;
        }
        if (!customerAddress1) {
            licenceCheckMessage.push('Address1');
            isMandatory = false;
        }
        if (!customerCity) {
            licenceCheckMessage.push('Town');
            isMandatory = false;
        }
        if (!customerTelephone) {
            licenceCheckMessage.push('Telephone');
            isMandatory = false;
        }
        if (!customerEmail) {
            licenceCheckMessage.push('Email');
            isMandatory = false;
        }
        if (customerLicenceCheckAccountCode) {
            isMandatory = false;
            show = false;
        }

        setLicence((w) => {
            let newSt = { ...w };
            newSt.isMandatory = licenceCheckMessage.toString();
            newSt.show = show;
            if (isMandatory) {
                newSt.checkBtn = true;
            } else {
                newSt.checkBtn = false;
            }
            return newSt;
        });

        return isMandatory;
    };

    const handleTopup = () => {
        setLicence((w) => ({ ...w, showTopUp: true }));
    };

    const onStop = async (e) => {
        const { name, checked } = e.target;
        setData((st) => ({ ...st, [name]: checked }));
        let res = await markCustomerOnStop(props.customerID, checked);
        if (res.success) {
            // console.log(res?.data?.list[0]?.msg, res?.data);
            showToastSuccess(res?.data?.list[0]?.msg);
            pullCustomerdata();
        }
        if (res.errors) {
            for (const [key, value] of Object.entries(res?.errors)) {
                showToastError(`${key}: ${value}`);
            }
        }
    };

    const closeCustomerModal = (res, showTost) => {
        setData((st) => ({
            ...st,
            show: false,
            showMileage: false,
            showJourney: false,
            showFuel: false,
            showDepot: false,
            showServiceAgent: false
        }));
        res && activeLoader(true);
        res && pullCustomerdata();

        setLicence((w) => ({ ...w, showTopUp: false, showRes: false }));

        if (showTost) {
            showToastSuccess('Licence Check Account created successfully');
        }
    };

    const addLicenceChecks = async (id) => {
        if (setlicenceCheckMessage()) {
            activeLoader(true);
            let res = await postCustomerLicence(id);
            if (res.success) {
                let list = res.data;
                setResList(list);
                setLicence((w) => ({ ...w, showRes: true }));
                activeLoader(false);
            } else {
                showToastError('Failed to create company');
                activeLoader(false);
            }
        } else {
            showToastError(`Fields ${licence.isMandatory} must be set to create Licence Check account`);
        }
    };

    return (
        <div className="controller-screen">
            {data.show ? (
                <AddEditCustomer customerID={data.CustomerID} onFormSubmit={closeCustomerModal} />
            ) : data.showMileage ? (
                <ImportMileage name={state.customerCompanyName} customerID={data.CustomerID} onClose={closeCustomerModal} />
            ) : data.showDepot ? (
                <ImportDepot name={state.customerCompanyName} customerID={data.CustomerID} onClose={closeCustomerModal} />
            ) : data.showServiceAgent ? (
                <ImportServiceAgent name={state.customerCompanyName} customerID={data.CustomerID} onClose={closeCustomerModal} />
            ) : data.showJourney ? (
                <ImportJourney name={state.customerCompanyName} customerID={data.CustomerID} onClose={closeCustomerModal} />
            ) : data.showFuel ? (
                <ImportFuel name={state.customerCompanyName} customerID={data.CustomerID} onClose={closeCustomerModal} />
            ) : licence.showRes ? (
                <LicenceResponseTable resList={resList} onClose={closeCustomerModal} showLoader={state.isLoader} />
            ) : (
                <div className="edit-controller-btn">
                    <StickyNotes frompage="Customers" customerID={props.customerID} variant="filled" />
                    <Grid container item xs={12}>
                        <Grid container item xs={12} md={12} lg={8} spacing={1} className="mt_10">
                            <Grid container className="grid-style white-grid" style={{ justifyContent: 'space-between' }} alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" color="secondary">
                                        Customer Profile
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item style={{ marginTop: 5 }}>
                                            On Stop
                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                checked={data.customerIsOnStop}
                                                onChange={onStop}
                                                value="customerIsOnStop"
                                                name="customerIsOnStop"
                                                label="On Stop"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ marginRight: 15, marginBottom: '5px' }}>
                                    <div className=" ">
                                        {!data.show ? (
                                            <>
                                                <CustomChip
                                                    icon={EditIcon}
                                                    iconFontSize={16}
                                                    label="Edit"
                                                    onClick={() => handleAdd(data.CustomerID, 'show')}
                                                    className="btn-m"
                                                    style={{ fontWeight: 'bold' }}
                                                />
                                                {isAdmin && (
                                                    <>
                                                        {licence.show ? (
                                                            <CustomChip
                                                                icon={!licence.checkBtn ? ErrorIcon : AddIcon}
                                                                iconFontSize={16}
                                                                label="Add Licence Check"
                                                                onClick={() => addLicenceChecks(data.CustomerID)}
                                                                className="btn-m"
                                                                style={{ fontWeight: 'bold', color: !licence.checkBtn ? 'red' : 'green' }}
                                                            />
                                                        ) : (
                                                            <Badge badgeContent={state.credits || 0} showZero color="secondary" max={9999}>
                                                                <CustomChip
                                                                    icon={DeleteIcon}
                                                                    iconFontSize={16}
                                                                    label="Archive Licence Check Account"
                                                                    onClick={() => handleTopup(data.CustomerID)}
                                                                    className="btn-m"
                                                                    style={{ fontWeight: 'bold' }}
                                                                />
                                                            </Badge>
                                                        )}
                                                    </>
                                                )}

                                                {checkhaveAccess?.CanImportDepots && (
                                                    <CustomChip
                                                        icon={LocalShippingIcon}
                                                        iconFontSize={16}
                                                        label="Import Vehicle Depot"
                                                        onClick={() => handleAdd(data.CustomerID, 'showDepot')}
                                                        className="btn-m"
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                                {checkhaveAccess?.CanImportServiceAgents && (
                                                    <CustomChip
                                                        icon={Person}
                                                        iconFontSize={16}
                                                        label="Import Service Areas"
                                                        onClick={() => handleAdd(data.CustomerID, 'showServiceAgent')}
                                                        className="btn-m"
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                                {checkhaveAccess?.CanImportMileages && (
                                                    <CustomChip
                                                        icon={LocalShippingIcon}
                                                        iconFontSize={16}
                                                        label="Import Mileage"
                                                        onClick={() => handleAdd(data.CustomerID, 'showMileage')}
                                                        className="btn-m"
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                                {checkhaveAccess?.CanImportJourneys && (
                                                    <CustomChip
                                                        icon={LocalTaxiIcon}
                                                        iconFontSize={16}
                                                        label="Import Journey"
                                                        onClick={() => handleAdd(data.CustomerID, 'showJourney')}
                                                        className="btn-m"
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                                {checkhaveAccess?.CanImportFuelPurchases && (
                                                    <CustomChip
                                                        icon={LocalGasStationIcon}
                                                        iconFontSize={16}
                                                        label="Import Fuel Purchase"
                                                        onClick={() => handleAdd(data.CustomerID, 'showFuel')}
                                                        className="btn-m"
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                </Grid>
                            </Grid>
                            {!state.isLoader ? (
                                <>
                                    <Grid container className="grid-style gray-grid">
                                        {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Short Name</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerShortName || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Name</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {state.customerCompanyName || 'N/A'}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Address</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                <div>{state.customerAddress1} </div>
                                                <div>{state.customerAddress2} </div>
                                                <div>{state.customerCity}</div>
                                                <div>{state.customerCounty}</div>
                                                <div>{state.customerPostCode}</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption"> Invoice Address</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                <div>{invoiceFields.invoiceAddressName}</div>
                                                <div>{invoiceFields.customerInvoiceAddress1}</div>
                                                <div>{invoiceFields.customerInvoiceAddress2}</div>
                                                <div>{invoiceFields.customerInvoiceAddressCity}</div>
                                                <div>{invoiceFields.customerInvoiceAddressCounty}</div>
                                                <div>{invoiceFields.customerInvoiceAddressPostCode}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container className="grid-style white-grid"> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Account Manager</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {listNames.accountManagaerName || 'N/A'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Phone</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {formatters.phoneNumberFormatter(state.customerTelephone)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Email</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {formatters.mailFormatter(state.customerEmail)}
                                            </div>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={3}> */}
                                    {/* <div className="customer_label">
                                                <Typography variant="caption">Website</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerWebSite || 'N/A'}
                                            </Typography> */}
                                    {/* </Grid> */}

                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Earned Recognition?</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerEarnedRecognition ? 'Yes' : 'No' || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* </Grid> */}
                                    <Grid container className="grid-style white-grid">
                                        {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Earned Recognition Start Date</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {(state.customerERStartDate && moment(state.customerERStartDate).format('DD/MM/YYYY')) || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Operation Manager Name</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {state.customerOperationsManagerName || 'N/A'}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Operation Manager Phone</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {formatters.phoneNumberFormatter(state.customerOperationsManagerPhone)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Operation Manager Email</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {formatters.mailFormatter(state.customerOperationsManagerEmail)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="grid-style gray-grid">
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Authority Contact Name</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {state.customerFinanceDirectorName || 'N/A'}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Authority Contact Phone</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {formatters.phoneNumberFormatter(state.customerFinanceDirectorPhone)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Authority Contact Email</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {formatters.mailFormatter(state.customerFinanceDirectorEmail)}
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={4} sm={3}>
                                        <div className="customer_label">
                                            <Typography variant="caption">Auto Approve Order Number</Typography>
                                        </div>
                                        <Typography className="customer_label_data" variant="button">
                                            {state.autoApproveOrderAckNo ? 'Yes' : 'No' || 'N/A'}
                                        </Typography>
                                    </Grid> */}
                                    </Grid>
                                    {/* <Grid container className="grid-style gray-grid"> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Handling Breakdowns</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerHandlingDefects ? 'Yes' : 'No' || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Handling Olicense</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.handlingOLicense ? 'Yes' : 'No' || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Handling Defects</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerHandlingDefects ? 'Yes' : 'No' || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Order Approval Limit</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerAutoApprovalLimit || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* </Grid> */}
                                    {/* <Grid container className="grid-style white-grid"> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Auto Approve Query Days</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.autoApproveQueryDays ? 'Yes' : 'No' || 'N/A'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Overdue Queries Days</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.overdueQueriesDays || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Currency</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {listNames.currency || 'N/A'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">ISO Week Start Date</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {listNames.isoStartDate || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* </Grid> */}
                                    {/* <Grid container className="grid-style gray-grid"> */}
                                    {/* <Grid item xs={4} sm={3}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Licence Check Account Code</Typography>
                                            </div>
                                            <Typography className="customer_label_data" variant="button">
                                                {state.customerLicenceCheckAccountCode || 'N/A'}
                                            </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={4} sm={4} md={4}>
                                            <div className="customer_label">
                                                <Typography variant="caption">Service Area</Typography>
                                            </div>
                                            <div className="customer_label_data" variant="button">
                                                {state.serviceAreaName || 'N/A'}
                                            </div>
                                        </Grid> */}
                                    {/* </Grid> */}
                                </>
                            ) : (
                                <div style={{ width: '100%' }}>
                                    <FromLoader />
                                </div>
                            )}
                        </Grid>
                        <Grid container item xs={12} md={12} lg={4} spacing={1} className="mt_10">
                            <Grid container className="grid-style white-grid" style={{ justifyContent: 'space-between' }}>
                                <Grid item xs={12} style={{ height: `cal(100%-40px)` }}>
                                    {/* <Typography variant="h5" color="secondary" className="mtl">
                                        <Link
                                            style={{ textDecoration: 'none', cursor: 'pointer' }}
                                            color="inherit"
                                            to={{ pathname: `/controller/VorReporting/${props.customerID}` }}
                                        >
                                            <CustomChip icon={EditIcon} iconFontSize={16} label="VOR Reporting" style={{ fontWeight: 'bold', marginTop: -7 }} />
                                        </Link>
                                    </Typography> */}
                                    <VorReportingTab customerID={props.customerID} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
            {licence.showTopUp ? (
                <DialogComp title={state.customerCompanyName} onClose={() => closeCustomerModal(false)} maxWidth="sm" overflow="auto">
                    <CreditTopup
                        name={state.customerCompanyName}
                        customerID={data.CustomerID}
                        onClose={closeCustomerModal}
                        credit={state.credits}
                        accountCode={state.customerLicenceCheckAccountCode}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <CustomerProvider {...props}>
            <CustomerDashBoard {...props} />
        </CustomerProvider>
    );
};
export default Screen;
