import React, { useState, useEffect, useMemo } from 'react';
import { getAllServiceAgentFacilities } from '../../../../Core/Services/InternalUser/serviceAgentFacilitiesService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ServiceAgentFacilitiesModal from './ServiceAgentFacilitiesModal';
import { BreadCrumbs, AddButton, EditButton } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Admin', active: true },
    { name: 'Service Area Facilities', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'serviceAgentFacilityName', name: 'Description' },
    { key: 'serviceAgentFacilityAddInMapFilter', name: 'Add in Map Filter?' },
    { key: 'serviceAgentFacilityActive', name: 'Is Active?' }
];

function ServiceAgentFacilities(props) {
    const [state, setState] = useState({
        serviceAgentFacilitiesList: [],
        loader: true,
        open: false,
        isReload: null
    });

    const pullServiceAgent = async () => {
        setState((state) => ({
            ...state,
            loader: false,
            isReload: new Date()
        }));
    };

    const serviceAgentUpdateHandler = (params) => {
        setState((state) => ({ ...state, open: true, serviceAgentFacilityID: params?.serviceAgentFacilityID }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => serviceAgentUpdateHandler(row)} toolTipTitle="Edit Service Area Facility" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    }, []);

    const title = state.serviceAgentFacilityID ? 'Update Service Area Facility' : 'Add Service Area Facility';

    const closeServiceAgentModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullServiceAgent();
    };

    const urlState = 'ServiceAgentFacilities';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Service Area Facility"
                onBtnClick={() => serviceAgentUpdateHandler(null)}
            />

            {state.open ? (
                <DialogComp title={title} onClose={() => closeServiceAgentModal(false)} maxWidth="md">
                    <ServiceAgentFacilitiesModal serviceAgentFacilityID={state.serviceAgentFacilityID} onClose={() => closeServiceAgentModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default ServiceAgentFacilities;
