import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../Core/Controls/Grid/index';
import { EditButton, formatters, BreadCrumbs, DatePicker, SecondaryCheckbox } from '../../../Core/Controls';
import DialogBox from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleEdit';
import ScheduleNotes from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import ScheduleBookingStatus from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingStatus';
import ScheduleBookingSection from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingSection';
import DialogComp from '../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import EventIcon from '@material-ui/icons/Event';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import WorkIcon from '@material-ui/icons/Work';
import '../../commonStyle.scss';
import '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import { Link } from 'react-router-dom';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../../Core/Modal/ConfirmationModal';
import { useToast } from '../../../Core/Hooks/useToast';
import Typography from '@material-ui/core/Typography';
import { getAllVehicleSchedules } from '../../../Core/Services/InternalUser/Maintenance/scheduleService';
import { deleteFutureUnCompletedSchedules } from '../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import ReactDOM from 'react-dom';
import { CustomIconButton, PrimaryButton, SecondaryButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import './allSchedulesStyle.scss';
import { Button, Grid, InputLabel } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CompleteScheduleModal from '../../InternalUser/Schedule/completeSchedule';
import { NavigateBeforeSharp } from '@material-ui/icons';
import moment from 'moment';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import SyncIcon from '@material-ui/icons/Sync';
import ScheduleRevise from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleRevise';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../../InternalUser/Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../../InternalUser/Controllers/DetailsModal/serviceAgent';
import { AppStorage } from '../../../Core/Basic/storage-service';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RequiredDocumentsModal from './requiredDocumentsModal';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { GetSchedulesExcelFile } from '../../../Core/Services/InternalUser/excelDownload';
import DepotDetails from '../../InternalUser/Controllers/DetailsModal/depotDetails';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import VehicleDailyChecks from '../../InternalUser/Controllers/Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../InternalUser/Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';

const crumbsServiceAgent = [
    { name: 'Home', path: '/' },
    { name: 'Schedules', active: true }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Schedules', path: '/', active: true }
];
// Date Due, Event, Booking Date, Completion Date, Agent, Status

const basecolumns = [
    { key: 'action', name: 'Action', width: 160, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg No', width: 85 },
    { key: 'scheduleEventName', name: 'Event', width: 180 },
    { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
    { key: 'scheduleDateDue', name: 'Due', width: 90 },
    { key: 'scheduleRequestedBookingDate', name: 'Requested', width: 100 },
    { key: 'bookingDate', name: 'Booked', width: 100 },
    { key: 'scheduleCompletionDate', name: 'Completed', width: 120 },
    { key: 'customerCompanyName', name: 'Customer', width: 200 },
    { key: 'depotName', name: 'Depot', width: 200 },
    { key: 'agentName', name: 'Service Area', width: 200 },
    { key: 'scheduleBookingStatus', name: 'Status', width: 180 }
];
const modelgridStyle = {
    styleCol: {
        cursor: 'pointer',
        fontStyle: 'italic',
        textDecorationLine: 'underline'
    },
    NoneCondition: {}
};
const AllVehicleSchedules = (props) => {
    // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    // const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    let getAccessParam = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        rows: [],
        isAdd: true,
        filteredRows: [],
        finalData: [],
        showDelete: false,
        filterID: '',
        fromDate: null, //startOfMonth,
        toDate: null, //endOfMonth,
        isDeleteFuture: false,
        showChecks: false,
        showEditChecks: false
    });
    // const [dateRange, setDateRange] = useState({ fromDate: startOfMonth, toDate: endOfMonth });
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();

    const { userRoles } = useLoginInfo();
    const isServiceAgent = userRoles.includes('servicearea');
    const pos = isServiceAgent ? 's' : 'm';

    const { showToastSuccess, showToastError } = useToast();

    const [openDialog, setOpenDialog] = useState({});

    useEffect(async () => {
        let fromDate = '';
        let toDate = '';
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        let filterID = '';
        if (url[urlIndex] === ':' || url[urlIndex] === undefined || url[urlIndex] === null) {
            filterID = '';
            fromDate = moment().startOf('month').format('YYYY-MM-DD');
            toDate = moment().endOf('month').format('YYYY-MM-DD');
        } else {
            filterID = url[urlIndex];
        }
        setState((st) => ({
            ...st,
            filterID: filterID,
            fromDate: fromDate,
            toDate: toDate
        }));
        // pullApiData();
    }, [props.location.pathname]);

    useEffect(() => {
        if (state.fromDate != null && state.toDate != null && state.filterID !== undefined) {
            let getUrl = `Schedules?mode=${state.filterID}&startDate=${state.fromDate}&endDate=${state.toDate}`;
            setState((st) => ({
                ...st,
                getUrl: getUrl,
                isReload: new Date()
            }));
        }
    }, [state.fromDate, state.toDate, state.filterID]);

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
        // let res = await getAllVehicleSchedules(url[urlIndex]);
        // setState((st) => ({
        //     ...st,
        //     rows: [...(res.data.schedules || [])],
        //     finalData: [...(res.data.schedules || [])],
        //     showLoader: false
        // }));
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/jobTabScreen/${val?.scheduleJobID}/${val?.scheduleVehicleID}`,
            search: '',
            state: pos
        });
    };

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'Ad') {
                newSt.showChecks = true;
                newSt.selectedRowData = ev;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
                newSt.selectedRowData = ev;
            }
            return newSt;
        });
    };

    const renderEdit = (ev, row) => {
        return (
            <div>
                {/* {row?.displayNotes === 'Y' && getAccessParam['CanUpdateScheduleNotes'] && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )} */}

                {/* {row?.displayEditVehicleSchedule === 'Y' && getAccessParam['CanUpdateSchedules'] && (
                    <span className="modal-btn-update">
                        <EditButton
                            toolTipTitle="Edit Vehicle Schedule"
                            onClick={() => {
                                setState((st) => ({
                                    ...st,
                                    open: true,
                                    selectedRowData: row,
                                    isAdd: false
                                }));
                            }}
                        />
                    </span>
                )} */}
                {/* {row?.displayReviseSchedule === 'Y' && (
                    <CustomIconButton
                        icon={SyncIcon}
                        color="primary"
                        className="modal-btn-update"
                        onClick={() => {
                            checkButton(row, 'revise');
                        }}
                        toolTipTitle="Change Booking Date"
                    />
                )} */}
                {row?.scheduleBookingStatusID == 5 && getAccessParam['CanViewDocuments'] && (
                    <CustomIconButton
                        icon={PostAddIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'showRequiredDocumentsModal');
                        }}
                        toolTipTitle="Required Documents"
                    />
                )}
                {/* {row?.displayBookingRequest === 'Y' && (
                    <CustomIconButton
                        icon={EventIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'status');
                        }}
                        toolTipTitle="Update Booking Request"
                    />
                )} */}
                {/* 
                {row?.displayBookJob === 'Y' && (
                    <CustomIconButton
                        icon={EventNoteIcon}
                        color="primary"
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'section');
                        }}
                        toolTipTitle="Book Job"
                    />
                )} */}

                {row?.displayJobDetails === 'Y' && getAccessParam?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update" toolTipTitle="Job Details" onClick={() => handleEditClick(row)} />
                )}
                {/* {row?.displayCompleteSchedule === 'Y' && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        className="modal-btn-update btn_space"
                        color="primary"
                        onClick={() => {
                            checkButton(row, 'complete');
                        }}
                        toolTipTitle="Complete Schedule"
                    />
                )} */}
                {row?.displayAddInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(row, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                )}
                {row?.displayEditInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={EditIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(row, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                )}
                {row?.displayDeleteSchedules === 'Y' && getAccessParam['CanDeleteSchedules'] && (
                    <CustomIconButton
                        icon={DeleteIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        onClick={() => {
                            setState((st) => ({ ...st, showDelete: true, data: row }));
                        }}
                        toolTipTitle="Delete future uncompleted schedules"
                    />
                )}
            </div>
        );
    };

    const dateFormatter = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '--:--';
    };

    const deleteFutureUnComSchedules = async () => {
        let data = {
            scheduleVehicleID: state.data.scheduleVehicleID,
            scheduleEventID: state.data.scheduleEventID,
            isDeleteFuture: state.isDeleteFuture,
            scheduleID: state.data.scheduleID
        };
        let res = await deleteFutureUnCompletedSchedules(data);
        if (res.success) {
            showToastSuccess('Record deleted successfully');
            setState((st) => ({ ...st, showDelete: false }));
            pullApiData();
        } else {
            showToastError(res.message);
        }
    };

    const dueDateFormatter = ({ row, column }) => {
        const spn = document.createElement('span');
        let textColor = formatters.getScheduleColour(row.scheduleCompletionDate, row.scheduleDateDue, row.dayRange);
        const dueDate = row.scheduleDateDue;
        let show = row?.displayEditVehicleSchedule === 'Y' && getAccessParam['CanUpdateSchedules'];
        const HandeClick = () => {
            setState((st) => ({
                ...st,
                open: true,
                selectedRowData: row,
                isAdd: false
            }));
        };
        const lnk = (
            <div
                style={{
                    color: `${textColor}`,
                    fontWeight: 'bold',
                    cursor: show ? 'pointer' : '',
                    fontStyle: show ? 'italic' : '',
                    textDecorationLine: show ? 'underline' : ''
                }}
                onClick={() => (show ? HandeClick() : '')}
            >
                {dateFormatter(dueDate)}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.scheduleVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleCloseModal = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false,
            showRequiredDocumentsModal: false,
            showDepotModal: false,
            showChecks: false,
            showEditChecks: false
        }));
        res && pullApiData();
    };
    const getRenderValue = (val, row) => {
        if (val === 'scheduleEventName') {
            return (
                <div
                    onClick={() => (row?.displayNotes === 'Y' && row?.isNote > 0 ? checkButton(row, 'notes') : '')}
                    style={row?.displayNotes === 'Y' && row?.isNote > 0 ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                >
                    {row.scheduleEventName}
                </div>
            );
        } else if (val === 'scheduleRequestedBookingDate') {
            return (
                <div
                    style={row?.displayBookingRequest === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() => (row?.displayBookingRequest === 'Y' ? checkButton(row, 'status') : '')}
                >
                    {row?.displayBookingRequest === 'Y' && row.scheduleRequestedBookingDate
                        ? dateFormatter(row.scheduleRequestedBookingDate)
                        : row?.displayBookingRequest === 'Y'
                        ? 'Request'
                        : dateFormatter(row.scheduleRequestedBookingDate)}
                </div>
            );
        } else if (val === 'bookingDate') {
            return (
                <div
                    style={row?.displayBookJob === 'Y' || row?.displayReviseSchedule === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() =>
                        row?.displayBookJob === 'Y' ? checkButton(row, 'section') : row?.displayReviseSchedule === 'Y' ? checkButton(row, 'revise') : ''
                    }
                >
                    {row?.displayReviseSchedule === 'Y'
                        ? dateFormatter(row.bookingDate)
                        : row?.displayBookJob === 'Y'
                        ? 'Book Job'
                        : dateFormatter(row.bookingDate || null)}
                </div>
            );
        } else if (val === 'scheduleCompletionDate') {
            return (
                <div
                    style={row?.displayCompleteSchedule === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() => (row?.displayCompleteSchedule === 'Y' ? checkButton(row, 'complete') : '')}
                >
                    {row?.displayCompleteSchedule === 'Y' ? 'Complete' : dateFormatter(row.scheduleCompletionDate)}
                </div>
            );
        } else {
            return '';
        }
    };

    const BasicColFormate =
        (clVal) =>
        ({ row, col }, grid) => {
            const spn = document.createElement('span');
            const lnk = getRenderValue(clVal, row);
            ReactDOM.render(lnk, spn);
            return spn;
        };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let urls = props.location.pathname;
        let urlsIndex = urls.lastIndexOf('/') + 1;
        if (urls[urlsIndex] !== 'M' && urls[urlsIndex] !== 'I' && urls[urlsIndex] !== 'S' && urls[urlsIndex] !== 'O') {
            tempCols.splice(
                3,
                9,
                { key: 'customerCompanyName', name: 'Customer', width: 330 },
                { key: 'depotName', name: 'Depot', width: 230 },
                { key: 'agentName', name: 'Service Area', width: 120 },
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'dueDateISOWeek', name: 'ISO Week', width: 85 },
                { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'bookingDate', name: 'Booked', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'scheduleBookingStatus', name: 'Status', width: 160 },
                { key: 'scheduleCompletionDate', name: 'Completed', formatter: formatters.Date_DDMMYYYY, width: 100 },
                { key: 'dateOfReg', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 105 },
                { key: 'costCentre', name: 'Cost Center', width: 110 }
            );
        }
        let dateDueCol = tempCols.find((e) => e.key === 'scheduleDateDue');
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        let customerCol = tempCols.find((element) => element.key === 'customerCompanyName');
        let agentCol = tempCols.find((element) => element.key === 'agentName');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let scheduleEventNameCol = tempCols.find((e) => e.key === 'scheduleEventName');
        let scheduleRequestedDatecol = tempCols.find((e) => e.key === 'scheduleRequestedBookingDate');
        let bookingDateCol = tempCols.find((e) => e.key === 'bookingDate');
        let scheduleCompletionDateCol = tempCols.find((e) => e.key === 'scheduleCompletionDate');
        if (scheduleCompletionDateCol) scheduleCompletionDateCol.formatter = BasicColFormate('scheduleCompletionDate');
        if (bookingDateCol) bookingDateCol.formatter = BasicColFormate('bookingDate');
        if (scheduleRequestedDatecol) scheduleRequestedDatecol.formatter = BasicColFormate('scheduleRequestedBookingDate');
        if (scheduleEventNameCol) scheduleEventNameCol.formatter = BasicColFormate('scheduleEventName');

        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (customerCol) {
            customerCol.formatter = renderCustomerName;
        }
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        if (depotNameCol) depotNameCol.formatter = renderDepotName;
        if (dateDueCol) {
            dateDueCol.formatter = dueDateFormatter;
        }
        return tempCols;
    }, []);

    const crumbsMaintenance = useMemo(() => {
        const tempCrums = [...baseCrumbs];
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        if (url[urlIndex] === 'M') {
            tempCrums.push({ name: 'MOTs' });
        } else if (url[urlIndex] === 'I') {
            tempCrums.push({ name: 'Overdue Inspections' });
        } else if (url[urlIndex] === 'S') {
            tempCrums.push({ name: 'Overdue Services' });
        } else if (url[urlIndex] === 'O') {
            tempCrums.push({ name: 'Overdue Other Schedules' });
        } else {
            tempCrums.push({ name: 'All Schedules', active: true });
        }
        return tempCrums;
    }, [props.location.pathname]);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: {}
        }));
        res && pullApiData();
    };
    const closeTheDialog = (res) => {
        setOpenDialog({});
        res && pullApiData();
    };

    const checkButton = (row, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: row,
            scheduleVehicleID: row.scheduleVehicleID
        }));
    };

    const dateRangeChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({ ...st, [temp.name]: temp.value }));
    };

    const menuClicked = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const menuClose = () => {
        setAnchorEl(null);
    };
    const onSelectCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => ({ ...st, [name]: checked }));
    };
    return (
        <div className="screen all_schedules">
            {/* <DataGrid
                // rows={state.filteredRows.length <= 0 ? state.rows : state.filteredRows}
                // rows={state.rows}
                rows={state.finalData}
                columns={columns}
                enableSearch={true}
                loadingData={state.showLoader}
                idFieldName="scheduleID"
                fileUploaderConfigName="ScheduleDocumentsRequired"
                paginate
                offset={210}
            /> */}

            {state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    displayCheck={state.selectedRowData.displayCheck}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    onClose={handleCloseModal}
                    frompage={true}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks
                    checkID={state.selectedRowData.vehicleInspectionID}
                    onClose={handleCloseModal}
                    frompage={true}
                    displayCheck={state.selectedRowData.displayCheck}
                />
            ) : (
                <>
                    {' '}
                    <BreadCrumbs crumbs={isServiceAgent ? crumbsServiceAgent : crumbsMaintenance} />
                    <Grid container>
                        <Grid item className="date_range">
                            <span className="date_items">
                                <InputLabel shrink>From</InputLabel>
                                <DatePicker
                                    fullWidth={false}
                                    placeholder="From"
                                    value={state.fromDate}
                                    onChange={dateRangeChanged}
                                    name="fromDate"
                                    InputProps={{ inputProps: { max: `${state.toDate}` } }}
                                    focused={state.fromDate ? true : false}
                                />
                            </span>
                            <span className="date_items ">
                                <InputLabel shrink>To</InputLabel>
                                <DatePicker
                                    fullWidth={false}
                                    placeholder="To"
                                    value={state.toDate}
                                    onChange={dateRangeChanged}
                                    name="toDate"
                                    InputProps={{ inputProps: { min: `${state.fromDate}` } }}
                                    focused={state.toDate ? true : false}
                                />
                            </span>
                            {/* <span className="date_items">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setState((st) => ({ ...st, fromDate: startOfMonth, toDate: endOfMonth }));
                            }}
                            size="large"
                            style={{ marginTop: '10px' }}
                        >
                            Current Month
                        </Button>
                    </span> */}
                            <span className="date_items">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setState((st) => ({ ...st, fromDate: '', toDate: '' }));
                                    }}
                                    size="large"
                                    style={{ marginTop: '10px' }}
                                >
                                    Reset
                                </Button>
                            </span>
                        </Grid>
                    </Grid>
                    <GijgoGrid
                        columns={columns}
                        // idFieldName={getAccessParam['CanUploadScheduleDocuments'] ? 'scheduleID' : ''}
                        idFieldName={getAccessParam['CanViewSchedules'] && getAccessParam['CanViewDocuments'] ? 'scheduleID' : ''}
                        fileUploaderConfigName="ScheduleDocumentsRequired"
                        dataConverter={(res) => ({
                            records: res.data.schedules,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        getUrl={state.getUrl}
                        downloadBtnName="Download xlsx"
                        downloadCustomUrl={() =>
                            GetSchedulesExcelFile({
                                status: props.status || '',
                                mode: state.filterID || '',
                                startDate: state.fromDate || '',
                                endDate: state.toDate || '',
                                fileExt: 'xlsx'
                            })
                        }
                    />
                </>
            )}
            {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? 'Add Schedule' : 'Edit Schedule'}
                    maxWidth="sm"
                    name="addEdit"
                >
                    <DialogBox
                        data={state}
                        isAdd={state.isAdd}
                        scheduleId={state.selectedRowData.scheduleID}
                        vehicleId={state.selectedRowData.scheduleVehicleID}
                        onClose={() => {
                            handleClose(true);
                        }}
                    />
                </DialogComp>
            ) : null}
            {openDialog.notes ? (
                <DialogComp title="Schedule Notes" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleNotes vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.status ? (
                <DialogComp title="Customer Booking Request" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingStatus vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.section ? (
                <DialogComp title="Booking Section" maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingSection vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.complete ? (
                <DialogComp title="Complete Schedule" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <CompleteScheduleModal rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.revise ? (
                <DialogComp title={`Change Booking Date`} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <ScheduleRevise scheduleId={state.selectedRowData.scheduleID} rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {state.showDelete ? (
                <DialogComp title="Delete" onClose={() => setState((st) => ({ ...st, showDelete: false }))} maxWidth="sm">
                    <Grid container spacing={0} style={{ marginTop: -12 }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                <Typography component="legend">
                                    <b> Date Due:{formatters.DateFormatter(state.data?.scheduleDateDue)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component="legend">
                                    <b>Event Name:{state.data?.scheduleEventName}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend">Are you sure you want to delete this record?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SecondaryCheckbox
                                    onChange={onSelectCheckbox}
                                    checked={state.isDeleteFuture}
                                    name="isDeleteFuture"
                                    value={state.isDeleteFuture}
                                    label="Delete Future Schedules?"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={deleteFutureUnComSchedules} fullWidth className="submit_btn">
                                    Submit
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={() => setState((st) => ({ ...st, showDelete: false }))} fullWidth className="submit_btn">
                                    Cancel
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={() => handleCloseModal(false)} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleCloseModal} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={() => handleCloseModal(false)} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleCloseModal} />
                </DialogComp>
            ) : null}
            {openDialog.showRequiredDocumentsModal ? (
                <DialogComp open={true} onClose={() => closeTheDialog(false)} title={'Required Documents'} maxWidth="sm" fullWidth>
                    <RequiredDocumentsModal rowData={state.selectedRowData} onFormSubmit={handleCloseModal} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={() => handleCloseModal(false)} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleCloseModal} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AllVehicleSchedules;

//Ignore This
// (
//   <Link to={`/controller/jobbookeddetail/${state.scheduleVehicleID}/${ev.row?.scheduleID}`}>
//   <CustomIconButton disabled icon={ConfirmationNumberIcon} color="secondary" className="modal-btn-update" title="Book Job Details" />
//   </Link>
// )
