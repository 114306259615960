import { Button, ButtonGroup, FormControlLabel, FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { DateTimePicker, formatters, MandatoryField, PrimaryButton, SecondaryButton, SimpleField, SingleSelect } from '../../../../../../Core/Controls';
import { Alert } from '@material-ui/lab';
import { GreenCheckbox, PrimaryCheckbox } from '../../../../../../Core/Controls/Inputs/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import JobDetail from '../JobDetails';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import PurchaseInvoice from '../JobInvoiceLines/purchaseInvoice';
// ../../JOBItems/JobInvoiceLines/sellerInvoice
import SellerInvoiceModal from '../../JOBItems/JobInvoiceLines/sellerInvoice';

const JobAdminstration = ({ handleNext, onClose, isinternal, jobDetails, isDisabled, jobStatus }) => {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();
    const [state, setState] = useState({
        showMailBody: false,
        performaButtons: 2,
        performaLabel: 'Download',
        salesInvoiceStatus: 'P',
        jobApprovedOrderNo: '',
        jobasInvoiced: false,
        jobasproforma: jobStatus === 'AS' ? true : false,
        isDocuments: false,
        isShowPerformaInvoice: false
    });
    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState((st) => {
            let NewSt = { ...st };
            if (name === 'jobasInvoiced') {
                NewSt.salesInvoiceStatus = 'I';
                NewSt.showMailBody = false;
            } else if (name === 'jobasproforma') {
                NewSt.salesInvoiceStatus = 'P';
                NewSt.showMailBody = false;
            }
            NewSt[name] = checked;

            return NewSt;
        });
    };

    const showPerformaInvoice = (bool) => {
        setState((st) => ({ ...st, isShowPerformaInvoice: bool }));
    };

    const handleMail = (name) => () => {
        switch (name) {
            case 'view':
                setState((st) => ({ ...st, showMailBody: false, performaButtons: 1, performaLabel: 'View' }));
                break;
            case 'dwnld':
                setState((st) => ({ ...st, showMailBody: false, performaButtons: 2, performaLabel: 'Download' }));
                break;
            case 'email':
                setState((st) => ({ ...st, showMailBody: !st.showMailBody, performaButtons: 3, performaLabel: 'Send' }));
                break;
            default:
                setState((st) => ({ ...st, showMailBody: false, performaButtons: 0, performaLabel: '' }));
                break;
        }
    };

    const inputChange = (e) => {
        const { name, value, type } = e.target;

        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmitValue = () => {
        handleNext();
    };

    const setAPIScheduleDocEmail = (obj, isDocuments) => {
        setState((st) => ({ ...st, sentBy: obj.sentBy, sendEmailTo: obj.email, isDocuments: isDocuments }));
    };

    return (
        <div style={{ padding: 0 }}>
            <Grid container spacing={2}>
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={2}>
                            <FormHelperText shrink>Order Date</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobOrderDate || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormHelperText shrink>Job Reference</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobAgentOrderNo || 'N/A'}</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <FormHelperText shrink>Reg Number</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobRegNumber || 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'end' }}>
                            <FormHelperText style={{ textAlign: 'end' }}>Chargeable Total</FormHelperText>
                            <Typography variant="button">
                                {/* £{jobDetails?.jobCustomerOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                                {formatters.CurrencyNumberWithPound(jobDetails?.jobCustomerOrderTotal)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'end' }}>
                            <FormHelperText style={{ textAlign: 'end' }}>Non Chargeable Total</FormHelperText>
                            <Typography variant="button">
                                {/* £{jobDetails.nonChargable?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                                {formatters.CurrencyNumberWithPound(jobDetails.nonChargable)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {state.isShowPerformaInvoice ? (
                        <Grid container item xs={12} spacing={2} alignItems="center">
                            <Grid item>
                                <FormControlLabel
                                    control={<GreenCheckbox onChange={handleCheck} name="jobasproforma" checked={state.jobasproforma} />}
                                    label="Create Performa"
                                    disabled={!isDisabled}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<GreenCheckbox onChange={handleCheck} name="jobasInvoiced" checked={state.jobasInvoiced} disabled={!isDisabled} />}
                                    label="Invoice Job"
                                />
                            </Grid>
                            {state.jobasInvoiced && (
                                <Grid item xs>
                                    <input
                                        style={{ minWidth: 250, padding: 5 }}
                                        placeholder="Order Acknowledgment Number"
                                        name="jobApprovedOrderNo"
                                        value={state.jobApprovedOrderNo}
                                        onChange={inputChange}
                                        disabled={!isDisabled}
                                    />
                                </Grid>
                            )}
                            {state.isDocuments && (
                                <>
                                    {state.sentBy ? (
                                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                                            {state.sentBy}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <SimpleField type="email" inputLabel="Email" value={state.sendEmailTo} name="sendEmailTo" onChange={inputChange} />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Alert severity="info">There are no chargeable lines.Click the submit button below to complete job</Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SellerInvoiceModal
                            scheduleJobID={jobDetails?.jobID}
                            hideDetailBar={true}
                            hideInputs={true}
                            salesInvoiceStatus={state.salesInvoiceStatus}
                            jobApprovedOrderNo={state.jobApprovedOrderNo}
                            sendEmailTo={state.sendEmailTo}
                            setAPIScheduleDocEmail={setAPIScheduleDocEmail}
                            handleSubmitValue={handleSubmitValue}
                            disabled={isDisabled}
                            showPerformaInvoice={showPerformaInvoice}
                        />
                    </Grid>

                    {/* {state.salesInvoiceStatus === 2 && (
                        <Grid item container xs={12} spacing={2}>
                            {state.showMailBody && (
                                <>
                                    <Grid item xs={12}>
                                        <SimpleField inputLabel="Email Address" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SimpleField inputLabel="Subject" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SimpleField inputLabel="Body" multiline rows={4} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )} */}
                </Grid>

                {/* {state.salesInvoiceStatus === 1 ? ( */}
                {/* <Grid item xs={12}>
                    <PrimaryButton onClick={handleSubmitValue}>Submit</PrimaryButton>
                </Grid> */}
                {/* ) : ( */}
                {/* <>
                        <Grid item xs={6}>
                            <PrimaryButton onClick={handleNext}>
                              
                                {state.performaLabel}
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton onClick={handleMail}>Cancel</PrimaryButton>
                        </Grid>
                    </>
                )} */}
            </Grid>
        </div>
    );
};

export default JobAdminstration;
