import { Grid, TableBody, TableContainer, TableRow, Table, TableHead, InputLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { InputText, SecondaryButton } from '../../../../Core/Controls';
import { Alert } from '@material-ui/lab';
import { StyledTableRow, StyledTableCell } from '../../../../Core/Controls/Table/StyledCell&Row';
import CustomeEditor from '../../../../Core/Controls/CkEditor';
import { getConfigIdFromName } from '../../../../Core/Controls/GenericFileUploader/FileUploaderConfig';
import { FileUploaderSendMultiDocs } from '../../../../Core/Services/FileUploaderService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { GetEmailTemplate } from '../../../../Core/Services/InternalUser/emailTemplateService';
import ReactDOMServer from 'react-dom/server';
const CustomeTableContainer = (props) => {
    const { List } = props;
    return (
        <TableContainer style={{ maxHeight: 250 }} className="custom-scroll">
            <Table aria-label="customized table" stickyHeader>
                <TableHead>
                    <StyledTableRow style={{ color: 'black', fontWeight: 600 }}>
                        <StyledTableCell width="30%" align="left">
                            Document
                        </StyledTableCell>
                        <StyledTableCell width="15%" align="left">
                            Event
                        </StyledTableCell>
                        <StyledTableCell width="15%" align="left">
                            Reg Number
                        </StyledTableCell>
                        <StyledTableCell width="15%" align="left">
                            Agent
                        </StyledTableCell>
                        <StyledTableCell width="30%" align="left">
                            Customer
                        </StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {List.length > 0 ? (
                        List.map((row) => {
                            return (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell style={{ fontSize: 14 }} width="30%" align="left">
                                        {row.document}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ fontSize: 14 }} width="15%" align="left">
                                        {row.event}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ fontSize: 14 }} width="15%" align="left">
                                        {row.regNumber}
                                    </StyledTableCell>{' '}
                                    <StyledTableCell style={{ fontSize: 14 }} width="15%" align="left">
                                        {row.agent}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <StyledTableCell colSpan={5}>
                                <Alert severity="info">No Documents Found</Alert>
                            </StyledTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const MailCommonComponent = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    let [state, setState] = useState({
        toEmail: '',
        subject: '',
        body: '',
        uniqueFileName: '',
        fileName: '',
        fileExt: ''
    });
    useEffect(async () => {
        let res = await GetEmailTemplate(props.code);
        if (res.success) {
            let emailtemp = res.data?.details[0];
            const regNumbers = props.selectedRecords.map((record) => record.regNumber).join(', ');
            const customers = props.selectedRecords.map((record) => record.customer).join(', ');

            const emails = props.selectedRecords
                .map((record) => record.docEmailTo)
                .filter((email) => email)
                .join(', ');

            const renderedTableHTML = ReactDOMServer.renderToStaticMarkup(<CustomeTableContainer List={props.selectedRecords} />);

            setState((st) => ({
                ...st,
                toEmail: emails,
                subject: emailtemp.emailTemplateSubject.replace('{regnumber}', regNumbers),
                body: emailtemp.emailTemplateBody.replace('{documentlist}', renderedTableHTML).replace('{customer}', customers)
            }));
        }
    }, []);

    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const submitHandler = async () => {
        if (props.selectedRecords.length > 0) {
            const selectedIds = props.selectedRecords.map((record) => record[props.idFieldName]);
            let data = {
                toEmail: state.toEmail,
                subject: state.subject,
                body: state.body,
                recordIds: selectedIds,
                configId: getConfigIdFromName(props.fileUploaderConfigName),
                docIDPassed: props.idFieldName == 'scheduleDocID' ? true : false
            };

            let res = await FileUploaderSendMultiDocs(data);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        } else {
            showToastError('Please select documents to mail');
        }
    };

    return (
        <div style={{ height: 'calc(100vh - 150px)', overflow: 'auto' }} className="custom-scroll">
            <Grid container>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <InputLabel shrink>Email</InputLabel>
                    <InputText placeholder="Email" onChange={handleChange} name="toEmail" value={state.toEmail} autoFocus={true} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <InputLabel shrink>Email Subject</InputLabel>
                    <InputText placeholder="Email Subject" onChange={handleChange} name="subject" value={state.subject} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputLabel shrink>Email Template</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomeEditor
                                data={state.body}
                                onChange={handleCkeditorstate('body')}
                                placeholder="Add description here"
                                name="body"
                                height={400}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <SecondaryButton fullWidth onClick={submitHandler} disabled={!state.toEmail && !state.subject}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default MailCommonComponent;
