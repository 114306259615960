import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, ButtonGroup, FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { ContractSetAPIPauseCompleted, ContractsStepper_DropDown } from '../../../../../Core/Services/InternalUser/InternalDashService';

import moment from 'moment';
import { useHistory } from 'react-router-dom';

const ContractSetPauseCompleted = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        errors: {},
        buttonState: 1,
        commonDate: '' //moment().format('YYYY-MM-DD')
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleFilter = (cond, val) => {
        setState((st) => ({
            ...st,
            buttonState: val
        }));
    };

    const validations = () => {
        const { commonDate } = state;
        let formIsValid = true;
        let errors = {};
        // if (!commonDate) {
        //     errors.commonDate = 'Date is required';
        //     formIsValid = false;
        // }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (!validations()) return;

        setBtnLoader(true);

        // Prepare data based on buttonState
        // const dateField = state.buttonState === 1 ? 'completionDate' : 'completionDate';
        // const requestData = { completionDate: state.commonDate || '' };
        // console.log(requestData, 'requestData');
        try {
            // Execute API calls
            const responses = await Promise.all(
                props.checkedVehicleList.map((q) =>
                    ContractSetAPIPauseCompleted({
                        contractVehicleID: q,
                        completionDate: state.commonDate || '',
                        resetContract: state.buttonState === 1 ? true : false
                    })
                )
            );

            // Check if all responses were successful
            const failedResponses = responses.filter((response) => !response.success);

            if (failedResponses.length === 0) {
                showToastSuccess('Vehicle delivery details saved successfully.');
                if (state.buttonState === 1 && props.fromStepper) {
                    history.push(`/controller/editVehicle/${props.vehicleID}?redirectLabel=Contract&isReset=true`);
                } else {
                    props.onClose(true);
                }
            } else {
                showToastError(`Error: ${failedResponses.length} items failed.`);
            }
        } catch (error) {
            // Catch any other unforeseen errors
            showToastError(`An unexpected error occurred: ${error.message}`);
        } finally {
            // Ensure the button loader is stopped in both success and error cases
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid component="label" item alignItems="center">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button style={{ fontSize: 12 }} variant={state.buttonState === 1 ? 'contained' : 'outlined'} onClick={() => handleFilter(true, 1)}>
                        Reset Contract
                    </Button>
                    <Button style={{ fontSize: 12 }} variant={state.buttonState === 2 ? 'contained' : 'outlined'} onClick={() => handleFilter(false, 2)}>
                        Complete Contract
                    </Button>
                </ButtonGroup>
            </Grid>

            {/* Conditionally Render Date Field */}
            {state.buttonState !== 0 && (
                <Grid item xs={12}>
                    <InputLabel shrink>{'Complete Date'}</InputLabel>
                    <DatePicker name="commonDate" placeholder={'Complete Date'} value={state.commonDate || ''} onChange={onFieldChange} />
                    <FormHelperText error>{state.errors.commonDate}</FormHelperText>
                </Grid>
            )}

            <Grid item xs={12}>
                <SecondaryButton isBtnLoader={btnLoader} fullWidth className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetPauseCompleted;
