import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { InputText, SecondaryButton, MandatoryField, formatters } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../controllerStyle.scss';
import { UpdateJobStatus, JobsApproveJob, JobsConfirmJob } from '../../../../../Core/Services/InternalUser/JOB/jobQueryService';

const JobEstimateModal = (props) => {
    const [state, setState] = useState({
        jobQueryMessage: '',
        jobQueryClosed: 1,
        jobEstimatedCost: null,
        showLoader: true,
        errors: {},
        approvOrder: props.customerOrderNo
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (props.title == 'Revise Estimate' && !state.jobQueryMessage) {
            err.jobQueryMessage = 'This field is requried';
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };

    const handleData = async (isAccept) => {
        let data = {
            jobID: props.jobQueryJobID,
            notes: state.jobQueryMessage,
            requestEstimate: false,
            markInProgress: false
        };
        let res = '';
        if (props.title == 'Submit Estimate') {
            data.jobEstimate = 1;
            res = await UpdateJobStatus(data);
        } else if (props.title == 'Revise Estimate') {
            if (validate()) {
                data.estimateRevised = true;
                res = await UpdateJobStatus(data);
            } else {
                return;
            }
        } else if (props.title == 'Set In Progress') {
            data.markInProgress = true;
            res = await UpdateJobStatus(data);
        } else if (props.title == 'Accept Estimate') {
            if (isAccept == '1') {
                data.acceptEstimate = isAccept;
                res = await UpdateJobStatus(data);
            } else {
                props.onClose(false);
                return;
            }
        } else if (props.title == 'Approve Order') {
            if (isAccept == '1') {
                errors.approvOrder = 'Approve Order is required';
                data.jobApprovedOrderNo = state.approvOrder;
                data.approved = true;
                state.approvOrder ? (res = await JobsApproveJob(data)) : setState((state) => ({ ...state, errors: errors }));
            } else {
                // errors.jobQueryMessage = 'Message is required';
                // data.approved = true;
                // state.jobQueryMessage ? (res = await JobsApproveJob(data)) : setState((state) => ({ ...state, errors: errors }));
                props.onClose(false);
                return;
            }
        } else if (props.title == 'Send for customer approval') {
            if (isAccept == '1') {
                res = await JobsConfirmJob({ id: props.jobQueryJobID });
                props.onClose(true);
            } else {
                props.onClose(false);
            }
            return;
        } else if (props.title == 'Send To Customer') {
            data.passToCustomer = true;
            res = await UpdateJobStatus(data);
        } else {
            alert('This Functionality is pending');
        }
        if (res.success) {
            showToastSuccess('Job saved sucessfully');
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
    };

    const inputChange = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormHelperText shrink>Order Date</FormHelperText>
                <Typography variant="button">{props.jobOrderDate}</Typography>
            </Grid>
            <Grid item xs={6}>
                <FormHelperText shrink>
                    {props.title == 'Send for customer approval' ||
                    props.title == 'Approve Order' ||
                    props.title == 'Revise Estimate' ||
                    props.title == 'Accept Estimate' ||
                    props.title == 'Send To Customer'
                        ? 'Job Reference'
                        : 'Order Number'}
                </FormHelperText>
                <Typography variant="button">
                    {props.jobOrderNo ? props.jobOrderNo : props.jobAgentOrderNo}
                    {/* {(props.title == 'Send for customer approval' || props.title == 'Approve Order'||props.title == 'Revise Estimate') ? `${props.jobAgentOrderNo}` : `${props.jobOrderNo}`} */}
                </Typography>
            </Grid>
            {props.title == 'Accept Estimate' ? (
                <Grid item xs={6}>
                    <FormHelperText>Estimate Cost</FormHelperText>
                    <Typography variant="button"> £{formatters.ToFixedDigit(props?.jobEstimatedCost)}</Typography>
                </Grid>
            ) : (
                <Grid item xs={6}>
                    <FormHelperText>Order Total</FormHelperText>
                    <Typography variant="button">£{formatters.ToFixedDigit(props?.jobOrderTotal)}</Typography>
                </Grid>
            )}
            <Grid item xs={6}>
                <FormHelperText shrink>Reg Number</FormHelperText>
                <Typography variant="button">{props.jobRegNumber}</Typography>
            </Grid>
            {props.title == 'Submit Estimate' && (
                <Grid item xs={12} className="mandatory-fields">
                    <Typography variant="caption">
                        Clicking the "Submit Estimate" button will submit cost for approval. Please note that the job will be locked and further changes to job
                        costing will not be allowed.
                    </Typography>
                    {props.jobOrderTotal == 0 && (
                        <Typography variant="body2" color="secondary">
                            (There is no cost set against the job, are you sure to submit with zero cost)
                        </Typography>
                    )}
                </Grid>
            )}
            {props.title == 'Approve Order' && (
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Order Acknowledgment Number"
                        type="text"
                        value={state.approvOrder}
                        name="approvOrder"
                        onChange={inputChange}
                        // focused={true}
                        autoFocus={true}
                    />
                    <FormHelperText error>{state.errors.approvOrder}</FormHelperText>
                </Grid>
            )}
            {props.title == 'Send To Customer' && (
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ color: 'red' }}>
                        Clicking the "Send to Customer" button below will send the estimate to customer for approval.
                    </Typography>
                </Grid>
            )}
            {props.title !== 'Submit Estimate' && props.title !== 'Send for customer approval' && (
                <Grid item xs={12} className={props.title == 'Revise Estimate' ? 'mandatory-fields' : ''}>
                    <InputLabel shrink required={props.title == 'Revise Estimate' ? true : false}>
                        Message
                    </InputLabel>
                    <InputText name="jobQueryMessage" rows={2} multiline onChange={inputChange} />
                    <FormHelperText error>{errors.jobQueryMessage}</FormHelperText>
                </Grid>
            )}
            {props.title == 'Send for customer approval' && (
                <Typography variant="body2" style={{ color: 'red' }}>
                    Clicking the Send button will send the job to customer for approval{' '}
                </Typography>
                //
            )}
            {props.title == 'Accept Estimate' ||
            props.title == 'Revise Estimate' ||
            props.title == 'Approve Order' ||
            props.title == 'Send for customer approval' ? (
                <>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton style={{ backgroundColor: '#147114' }} onClick={() => handleData(1)}>
                            {props.title == 'Approve Order'
                                ? 'Approve'
                                : props.title == 'Accept Estimate' || props.title == 'Estimate Update'
                                ? 'Accept'
                                : 'Send'}
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton style={{ backgroundColor: '#bf1212' }} onClick={() => handleData(-1)}>
                            {props.title == 'Send for customer approval' ? 'Cancel' : 'Cancel'}
                        </SecondaryButton>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton onClick={handleData}>{props.title}</SecondaryButton>
                </Grid>
            )}
        </Grid>
    );
};
export default JobEstimateModal;
