import React, { useEffect, useState } from 'react';
import { SelectBox, InputText, SecondaryButton, SecondaryCheckbox, MandatoryField, SimpleField, Multiselect } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getvehiclecheckchecksById, getVehicleCheckSection, postvehiclecheckchecksData } from '../../../../Core/Services/InternalUser/Vehicle/vehicleChecks';
import { FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getAllVehicleCheckSectionDropDown } from '../../../../Core/Services/InternalUser/vehicleCheckSection';
import { MultiselectWithCheckbox } from '../../../../Core/Controls/Autocomplete';

const VehicleCheckCheckMdal = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        inspectionSheetCheckID: null,
        inspectionSheetCheckDescription: '',
        inspectionSheetSectionID: props.addID,
        inspectionSheetCheckPosition: '',
        inspectionSheetCheckLegalRequirement: false,
        inspectionSheetCheckActive: true,
        inspectionSheetCheckFreeTextField: false,
        inspectionSheetCheckCanHidePhotoUpload: false,
        inspectionSheetCheckCanHideDefectDescription: false,
        inspectionSheetIMReference: '',
        dropdownList: [],
        errors: {},
        categoryList: [],
        checkVehicleCategoryIDs: props.sectionVehicleCategoryIDs || []
    });

    useEffect(() => {
        let fetchData = async () => {
            try {
                if (props.checkUserID) {
                    let res = await getvehiclecheckchecksById(props.checkUserID);

                    let Data = res.data.details[0];
                    if (res.success) {
                        setState((state) => ({
                            ...state,
                            inspectionSheetCheckID: Data.inspectionSheetCheckID,
                            inspectionSheetSectionID: Data.inspectionSheetCheckSectionID,
                            inspectionSheetCheckDescription: Data.inspectionSheetCheckDescription,
                            inspectionSheetCheckPosition: Data.inspectionSheetCheckPosition,
                            inspectionSheetIMReference: Data.inspectionSheetIMReference,
                            inspectionSheetCheckLegalRequirement: Data.inspectionSheetCheckLegalRequirement,
                            inspectionSheetCheckActive: Data.inspectionSheetCheckActive,
                            checkVehicleCategoryIDs: res.data.checkVehicleCategoryIDs,
                            inspectionSheetCheckFreeTextField: Data.inspectionSheetCheckFreeTextField,
                            inspectionSheetCheckCanHidePhotoUpload: Data.inspectionSheetCheckCanHidePhotoUpload,
                            inspectionSheetCheckCanHideDefectDescription: Data.inspectionSheetCheckCanHideDefectDescription
                        }));
                    }
                }

                // let dropDownRes = await getVehicleCheckSection();
                // const listss = dropDownRes.data.map((dropDownData) => ({
                //     id: dropDownData.inspectionSheetSectionID,
                //     name: dropDownData.inspectionSheetSectionDescription
                // }));
                // setState((st) => ({
                //     ...st,
                //     dropdownList: listss
                // }));

                let res2 = await getAllVehicleCheckSectionDropDown();

                let FilterCategory = [];
                if (props.sectionVehicleCategoryIDs.length > 0) {
                    props.sectionVehicleCategoryIDs.forEach((x) => {
                        let Arr = res2.data.list.find((id) => id.vehicleCategoryID === x);
                        FilterCategory.push(Arr);
                    });
                } else {
                    FilterCategory = res2.data.list;
                }

                setState((state) => ({
                    ...state,
                    categoryList: FilterCategory.map((item) => ({
                        id: item.vehicleCategoryID,
                        name: item.vehicleCategoryDescription
                    }))
                }));
            } catch (error) {}
        };

        return fetchData();
    }, [props.checkUserID, props.sectionVehicleCategoryIDs]);

    useEffect(() => {
        function fetchData() {
            try {
                if (state.checkVehicleCategoryIDs) {
                    const filterVehCat = state.categoryList.filter((w) => state.checkVehicleCategoryIDs.indexOf(w.id) > -1);
                    setState((st) => ({ ...st, categoryID: filterVehCat }));
                }
            } catch (error) {
                console.warn(error);
            }
        }
        return fetchData();
    }, [state.checkVehicleCategoryIDs, state.categoryList]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            let AllData = [...state.categoryList];
            setState((st) => ({ ...st, categoryID: AllData, checkVehicleCategoryIDs: AllData.map((k) => k.id) }));
        }
        if (type === 'clear') {
            setState((st) => ({ ...st, categoryID: [], checkVehicleCategoryIDs: [] }));
        }
    };
    const validations = () => {
        const { inspectionSheetCheckDescription, inspectionSheetCheckPosition, checkVehicleCategoryIDs } = state;
        let formIsValid = true;
        let errors = {};
        if (!inspectionSheetCheckDescription) {
            errors.inspectionSheetCheckDescription = 'Description is required';
            formIsValid = false;
        }
        if (!inspectionSheetCheckPosition) {
            errors.inspectionSheetCheckPosition = 'Position is required';
            formIsValid = false;
        }
        if (checkVehicleCategoryIDs.length === 0) {
            errors.vehicleCategory = 'Vehicle Categories is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const ModuleChange = (e, value) => {
        setState((st) => {
            const nst = { ...st, checkVehicleCategoryIDs: value.map((c) => c.id), categoryID: value };
            return nst;
        });
    };

    const submitHandler = async () => {
        if (validations()) {
            let formData = {
                inspectionSheetCheckID: state.inspectionSheetCheckID,
                inspectionSheetCheckSectionID: state.inspectionSheetSectionID,
                inspectionSheetCheckDescription: state.inspectionSheetCheckDescription,
                inspectionSheetIMReference: state.inspectionSheetIMReference,
                inspectionSheetCheckPosition: state.inspectionSheetCheckPosition,
                inspectionSheetCheckLegalRequirement: state.inspectionSheetCheckLegalRequirement,
                inspectionSheetCheckActive: state.inspectionSheetCheckActive,
                checkVehicleCategoryIDs: state.checkVehicleCategoryIDs,
                inspectionSheetSectionDescription: state.inspectionSheetSectionDescription,
                inspectionSheetCheckFreeTextField: state.inspectionSheetCheckFreeTextField,
                inspectionSheetCheckCanHidePhotoUpload: state.inspectionSheetCheckCanHidePhotoUpload,
                inspectionSheetCheckCanHideDefectDescription: state.inspectionSheetCheckCanHideDefectDescription
            };
            let res = await postvehiclecheckchecksData(formData);
            if (res.success) {
                showToastSuccess('Vehicle Check Checks Added Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    placeholder="Description"
                    name="inspectionSheetCheckDescription"
                    value={state.inspectionSheetCheckDescription}
                    onChange={handleInputChange}
                    errors={state.errors.inspectionSheetCheckDescription}
                />
            </Grid>
            {/* <Grid item xs={6} className="mandatory-fields" style={{ display: 'none' }}>
                <MandatoryField
                    inputLabel="Section"
                    name="inspectionSheetSectionID"
                    value={state.inspectionSheetSectionID}
                    List={state.dropdownList}
                    onChange={handleInputChange}
                />
            </Grid> */}
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Vehicle Categories
                </InputLabel>

                <MultiselectWithCheckbox
                    selectAll={randerSelectAll}
                    options={state.categoryList || []}
                    value={state.categoryID || []}
                    onChange={ModuleChange}
                    style={{ marginTop: -8 }}
                />
                {/* <SelectBox
                    name="vehicleCategoryDescription"
                    value={state.vehicleCategoryDescription || ' '}
                    onChange={handleInputChange}
                    List={state.categoryList}
                /> */}

                <FormHelperText error>{state.errors.vehicleCategory}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <SimpleField
                    inputLabel="IM Reference"
                    placeholder="IM Reference"
                    name="inspectionSheetIMReference"
                    value={state.inspectionSheetIMReference}
                    onChange={handleInputChange}
                />
            </Grid>

            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Position"
                    placeholder="Position"
                    name="inspectionSheetCheckPosition"
                    value={state.inspectionSheetCheckPosition}
                    onChange={handleInputChange}
                    errors={state.errors.inspectionSheetCheckPosition}
                />
            </Grid>
            {/* <Multiselect options={state.categoryList} value={state.categoryID} onChange={ModuleChange} style={{ marginTop: -8 }} /> */}
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetCheckLegalRequirement}
                    name="inspectionSheetCheckLegalRequirement"
                    onChange={handleCheckbox}
                    label="Legal Requirement"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetCheckFreeTextField}
                    name="inspectionSheetCheckFreeTextField"
                    onChange={handleCheckbox}
                    label="Free Text Field"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetCheckCanHideDefectDescription}
                    name="inspectionSheetCheckCanHideDefectDescription"
                    onChange={handleCheckbox}
                    label="Can Hide Defect Description?"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetCheckCanHidePhotoUpload}
                    name="inspectionSheetCheckCanHidePhotoUpload"
                    onChange={handleCheckbox}
                    label="Can Hide Image Upload?"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox checked={state.inspectionSheetCheckActive} name="inspectionSheetCheckActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6} className="submit_btn">
                <SecondaryButton onClick={submitHandler}> {props.checkUserID ? 'Update' : 'Add'} </SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton onClick={() => props.onClose()}>Cancel </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default VehicleCheckCheckMdal;
