import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SecondaryButton, SecondaryCheckbox, DatePicker } from '../../../../Core/Controls';
import SignaturePad from 'react-signature-canvas';
import './defect.scss';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import ClearIcon from '@material-ui/icons/Clear';
import { postTechnicianSignOff } from '../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { useToast } from '../../../../Core/Hooks/useToast';
import { Tooltip } from '@material-ui/core';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    inputComment: {
        '& .MuiInputBase-input': {
            fontWeight: 'bold',
            color: 'black'
        }
    }
}));

const SignOff = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        defectResolvedByName: props.displayName,
        errors: {},
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true
    });
    const { showToastSuccess, showToastError } = useToast();
    const signCanvas = useRef({});
    const { isLoggedIn, displayName, lastLogin, userRoles } = useLoginInfo();
    const isInternalUser = userRoles.includes('internal');

    useEffect(() => {
        if (props.jobData.vehicleJobProgressTechnicianSignOffSignature) {
            setState((st) => ({
                ...st,
                isDisabled: true,
                imgtechniciansign: true,
                defectResolvedSignature: props.jobData.vehicleJobProgressTechnicianSignOffSignature,
                defectResolvedNotes: props.jobData.vehicleJobProgressTechnicianSignOffNotes,
                vehicleJobProgressDateTechnicianSignOff: props.jobData.vehicleJobProgressDateTechnicianSignOff
            }));
        }
    }, []);

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                defectResolvedSignature: null
            }));
        } else {
            signCanvas.current.clear();
            setState((st) => ({
                ...st,
                defectResolvedSignature: null
            }));
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                defectResolvedSignature: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const InputChangeHandler = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const validations = () => {
        const { defectResolvedNotes, defectResolvedByName, defectResolvedSignature } = state;
        let formIsValid = true;
        let errors = {};
        if (!defectResolvedNotes) {
            errors.defectResolvedNotes = 'This field is required';
            formIsValid = false;
        }
        if (!defectResolvedByName) {
            errors.defectResolvedByName = 'This field is required';
            formIsValid = false;
        }
        if (!defectResolvedSignature) {
            errors.defectResolvedSignature = 'This field is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            let FormData = {
                defectID: props.defectID,
                jobID: props.jobData.vehicleJobProgressJobID,
                defectResolvedNotes: state.defectResolvedNotes,
                defectResolvedByName: state.defectResolvedByName,
                defectResolvedSignature: state.defectResolvedSignature
                // defectResolvedIPAddress: ''
            };
            let res = await postTechnicianSignOff(FormData);
            if (res.success) {
                showToastSuccess(`Engineer sign off added successfully`);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };
    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, defectResolvedSignature: AppStorage.getSignature() }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>{props.jobData.defect}</InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>Declaration</InputLabel>
                <Typography variant="body2">{props.declaration}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={`mandatory-fields ${classes.inputComment}`}>
                <InputLabel shrink>Comments</InputLabel>
                <InputText
                    multiline
                    rows={3}
                    placeholder="Comment"
                    value={state.defectResolvedNotes}
                    onChange={InputChangeHandler}
                    name="defectResolvedNotes"
                    disabled={state.isDisabled}

                    // style={{ color: state.defectResolvedNotes ? 'black' : '', fontWeight: state.defectResolvedNotes ? 'bold' : '' }}
                />
                <FormHelperText error>{state.errors.defectResolvedNotes}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Name</InputLabel>
                <InputText placeholder="Comment" value={state.defectResolvedByName} onChange={InputChangeHandler} name="defectResolvedByName" />
                <FormHelperText error>{state.errors.defectResolvedByName}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <div style={{ position: 'relative' }}>
                    <InputLabel
                        shrink
                        component="legend"
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        Signature
                        {state.signAvailable && !state.defectResolvedSignature ? (
                            <Tooltip title="Use default signature">
                                <BorderColorIcon onClick={useDefaultSig} style={{ margin: '0px 0px -5px 5px' }} />
                            </Tooltip>
                        ) : null}
                        {state.vehicleJobProgressDateTechnicianSignOff && `    (${moment(state.vehicleJobProgressDateTechnicianSignOff).format('LLL')})`}
                    </InputLabel>
                    <div
                        style={{
                            position: 'absolute',
                            right: 0
                        }}
                    >
                        {!state.isDisabled && (
                            <Tooltip title="Clear Signature">
                                <DeleteIcon onClick={clear} />
                            </Tooltip>
                        )}
                    </div>

                    {state.imgtechniciansign ? (
                        <img
                            src={state.defectResolvedSignature}
                            alt="my signature"
                            style={{
                                display: 'block',
                                margin: '0 auto',
                                border: '1px solid black',
                                width: '100%',
                                height: '165px'
                            }}
                        />
                    ) : (
                        <div className="sigContainer">
                            <SignaturePad
                                canvasProps={{ className: 'sigPad' }}
                                ref={signCanvas}
                                onEnd={() => {
                                    GetTechnicianSign();
                                }}
                            />
                        </div>
                    )}

                    <FormHelperText error>{state.errors.defectResolvedSignature}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                {props.showStepTech && (
                    <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler} disabled={state.isDisabled}>
                        Sign off
                    </SecondaryButton>
                )}
            </Grid>
        </Grid>
    );
};

export default SignOff;
