import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MandatoryField } from '../index';
import RoomIcon from '@material-ui/icons/Room';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { getPostCodeDetails } from '../../Services/InternalUser/postCodeService';
import { CircularProgress, Grid, List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    ul: {
        padding: 5
    },
    listSection: {
        backgroundColor: 'inherit'
    },
    customScroll: {
        width: '25.5%',
        boxShadow: '1px 1px 1px 1px #9E9E9E',
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        overflow: 'auto',
        maxHeight: 300,
        zIndex: 10,
        height: 'auto',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.1em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#1976d2',
            opacity: 5,
            outline: '1px solid slategrey'
        }
    }
}));

export const Postcode = (props) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const { name, value, onChange, errors, setVal } = props;
    const [postCodeList, setPostCodeList] = useState([]);
    const [code, setCode] = useState(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        async function fetchData() {
            if (code) {
                PostCodeValue();
            }
        }
        fetchData();
    }, [code]);

    const PostCodeValue = async () => {
        let TrimCode = code?.replace(/\s/g, '');
        let res = await getPostCodeDetails(TrimCode);
        if (res.success) {
            setPostCodeList(res.data);
            setLoader(false);
        } else {
            setPostCodeList([]);
            setLoader(false);
        }
    };

    const getVal = (x) => {
        let FilterVal = [...postCodeList];
        let value = FilterVal.find((m) => {
            return m.addressLine1 === x;
        });
        setVal(value);
        setShow(!show);
    };

    const hideList = () => {
        setShow(false);
    };

    const showList = () => {
        setPostCodeList([]);
        setCode(props.value);
        setShow(true);
        setLoader(true);
        PostCodeValue();
    };

    return (
        <Grid item xs={12} className="Maindiv">
            <MandatoryField
                inputLabel="Post Code"
                placeholder="Post Code"
                value={value}
                onChange={onChange}
                name={name}
                errors={errors}
                setVal={setVal}
                autoComplete="off"
                InputProps={{
                    endAdornment: props.value && (
                        <InputAdornment position="end">
                            <Tooltip title="Search Address" arrow>
                                <RoomIcon style={{ cursor: 'pointer' }} onClick={showList} />
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                {...props}
            />
            {show ? (
                <div style={{ width: '100%' }}>
                    <List className="customScroll">
                        {loader ? (
                            <ul
                                style={{
                                    paddingRight: 25
                                }}
                            >
                                <ListItem>
                                    <ListItemText>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', cursor: 'wait' }} />
                                        </div>
                                    </ListItemText>
                                </ListItem>
                            </ul>
                        ) : (
                            postCodeList.length === 0 && (
                                <ul
                                    style={{
                                        paddingRight: 25
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ marginLeft: 'auto', marginRight: 'auto', color: '#D0D0D0', cursor: 'default' }}>
                                                    No Address Found
                                                </div>
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                </ul>
                            )
                        )}
                        {postCodeList.length !== 0 && (
                            <ul className={classes.ul}>
                                {postCodeList.map((item, index) => (
                                    <ListItem
                                        style={{
                                            display: 'block',
                                            padding: 0,
                                            cursor: 'pointer',
                                            width: '100%'
                                        }}
                                        key={index}
                                        onClick={() => {
                                            getVal(item.addressLine1);
                                        }}
                                    >
                                        <ListItemText>
                                            {item.addressLine1}, &nbsp;{item.town}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </ul>
                        )}
                    </List>
                </div>
            ) : null}
        </Grid>
    );
};
