import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllVehicle = async (filterID) => {
    if (filterID === ':' || filterID === undefined || filterID === null) filterID = '';
    let res = await getWithAuth(`vehicles?mode=${filterID}`);
    return res;
};

export const getVehicleById = async (Id) => {
    let res = await getWithAuth(`vehicles/${Id}`);
    return res;
};

export const getVehicleScreenDropdowns = async () => {
    let res = await getWithAuth(`Vehicles/screenDropdowns`);
    return res;
};

export const getCustomersListDropdowns = async () => {
    let res = await getWithAuth(`schedules/Customers_List`);
    return res;
};

export const getSchedulePlannerDropdowns = async (custId) => {
    let res = await getWithAuth(`schedules/SchedulePlanner_Dropdown?CustomerID=${custId}`);
    return res;
};

export const addVehicle = async (data) => {
    let res = await postWithAuth('vehicles', data);
    return res;
};

export const getRegBasedData = async (data) => {
    let res = await getWithAuth(`Vehicles/DVLA/${data}`);
    return res;
};

export const getTitleData = async (id) => {
    let res = await getWithAuth(`Vehicles/VehicleGetTitleData/${id}`);
    return res;
};

export const getSchedulePlannerData = async (
    fromDate,
    toDate,
    customerID,
    depotsID,
    serviceAgentID,
    endUser = '',
    fileType,
    endUserWildCard = '',
    engineerUserID,
    scheduleEventID
) => {
    let res = await getWithAuth(
        `Schedules/SchedulePlanner?startDate=${fromDate}&endDate=${toDate}&custId=${customerID}&depot=${depotsID}&AgentID=${serviceAgentID}&endUser=${
            endUser || ''
        }&Filetype=${fileType}&endUserWildCard=${endUserWildCard}&EngineerUserID=${engineerUserID}&ScheduleEventID=${scheduleEventID}`,
        {
            responseType: 'blob',
            returnOrgRes: true
        }
    );

    return res;
};

export const archiveVehicle = async (vehicleID, date) => {
    let res = await postWithAuth('vehicles/archive', {
        VehicleID: vehicleID,

        Date: date
    });

    return res;
};

export const unArchiveVehicle = async (vehicleID, date) => {
    let res = await postWithAuth('vehicles/unarchive', {
        VehicleID: vehicleID,

        Date: date
    });

    return res;
};
export const postSetVehicleMOTandTax = async (data) => {
    let res = await postWithAuth(`Vehicles/SetVehicleMOTTAxDate`, data);
    return res;
};
export const getVehicleMOTandTax = async (id, regno) => {
    let res = await getWithAuth(`Vehicles/GetVehicleMOTTAxDate/${regno}/${id}`);
    return res;
};
