import { PULLINSPECTIONAPIDATA, CHECKBUTTON, HANDELADDCHECK, HANDELCHECK, HANDELCLOSE, HANDELROWCLICK } from '../../../Action/actionName';
export const initState = {
    open: false,
    selectedRowData: {},
    isAdd: true,
    showBred: true,
    isReload: null
};
export let reducer = function (state, action) {
    switch (action.type) {
        case PULLINSPECTIONAPIDATA:
            return { ...state, isReload: new Date() };
        case CHECKBUTTON:
            return {
                ...state,
                showRequiredDocumentsModal: action.showRequiredDocumentsModal,
                selectedRowData: action.payload,
                scheduleVehicleID: action.scheduleVehicleID
            };
        case HANDELCHECK:
            return { ...state, showEditChecks: true, selectedRowData: action.payload, showBred: false };
        case HANDELROWCLICK:
            return {
                ...state,
                selectedRowData: action.payload,
                showSaModal: action.showSaModal,
                showCustomerModal: action.showCustomerModal,
                showDepotModal: action.showDepotModal
            };
        case HANDELADDCHECK:
            return { ...state, showChecks: true, selectedRowData: action.payload };
        case HANDELCLOSE:
            return {
                ...state,
                showChecks: false,
                showEditChecks: false,
                open: false,
                showSaModal: false,
                showCustomerModal: false,
                showDepotModal: false,
                showRequiredDocumentsModal: false,
                selectedRowData: {},
                showBred: true
            };
        default:
            return { state };
    }
};
