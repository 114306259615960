import React from 'react';
import { BreadCrumbs, TabView, BackButton } from '../../../../../Core/Controls';
import EditDepotDash from './depotDashboard';
import { useHistory } from 'react-router-dom';
import Department from './Department';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import NoteDashboard from '../../Notes/index';
import DepotContacts from './Contacts';

export default function EditDepot(props) {
    let history = useHistory();
    const id = props.match.params.id;
    let checkhaveAccess = AppStorage.getAllowAcces();

    // const name = props.match.params.name;

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Depot', path: '/controller/Depots' }
    ];
    const closeScreen = () => {
        history.push(`/controller/Depots`);
    };
    const tabs = [
        {
            label: 'Dashboard',
            body: <EditDepotDash depotID={id} onFormClose={closeScreen} />
        },
        {
            label: 'Department',
            body: <Department depotID={id} onFormClose={closeScreen} />
        },
        {
            label: 'Contacts',
            body: <DepotContacts depotID={id} onFormClose={closeScreen} />
        },
        ...(checkhaveAccess?.CanViewNotes
            ? [
                  {
                      label: 'Notes',
                      body: <NoteDashboard frompage="Depots" depotID={id} noteRefsID={id} />
                  }
              ]
            : [])
    ];

    return (
        <div className="screen">
            <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                <BackButton onClick={closeScreen} />
                <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" m={5} />
            </div>
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
