import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, YesButton, NoButton, SelectBox, SimpleField } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import moment from 'moment';
import {
    ContractChangeOffHireLocation,
    ContractSetOffHire,
    ContractsStepper_DropDown,
    getLocatiOnOverRide
} from '../../../../../Core/Services/InternalUser/InternalDashService';

const ContractOffHireOverRide = (props) => {
    const [state, setState] = useState({
        errors: {},
        serviceAgents: [],
        currentAgents: []
    });
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(() => {
        let fetch = async () => {
            let res = await getLocatiOnOverRide(props.vehicleID);
            let currentAgents = res?.data?.currentAgents[0];
            setState((st) => ({
                ...st,
                serviceAgents: res.data.serviceAgents || [],
                currentAgentName: currentAgents?.currentagent,
                jobID: currentAgents?.jobID
            }));
        };
        return fetch();
    }, [props.vehicleID]);

    const validations = () => {
        const { contractOffHireDate } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractOffHireDate) {
            errors.contractOffHireDate = 'Date is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        // if (validations()) {
        setBtnLoader(true);
        let promises = props.checkedVehicleList.map((q) => {
            return ContractChangeOffHireLocation({
                jobID: state.jobID,
                contractVehicleID: props.vehicleID,
                offhireServiceAreaID: state.offhireServiceAreaID
            });
        });

        try {
            let responses = await Promise.all(promises);

            // Check if all responses are successful
            let allSuccessful = responses.every((response) => response.success);

            if (allSuccessful) {
                showToastSuccess('Vehicle delivery details saved successfully.');
                props.onClose(true);
            } else {
                // Find out which responses failed
                let failedResponses = responses.filter((response) => !response.success);
                showToastError(`Error: ${failedResponses.length} items failed.`);
            }
        } catch (error) {
            // Handle any other errors that may occur
            showToastError(`An error occurred: ${error.message}`);
        }
        setBtnLoader(false);
        // }
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Current Location </InputLabel>
                <SimpleField isDisabled={true} value={state.currentAgentName} />
                <FormHelperText error>{state.errors.contractOffHireAuthorityReference}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>New Location </InputLabel>
                <SelectBox List={state.serviceAgents} value={state.offhireServiceAreaID} onChange={onFieldChange} name="offhireServiceAreaID" />
                <FormHelperText error>{state.errors.contractOffHireAuthorityReference}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Change Location
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractOffHireOverRide;
