import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { postImportDepot } from '../../../../../Core/Services/InternalUser/ControllerService/customerService';
import { SecondaryButton, formatters } from '../../../../../Core/Controls';
import { InputLabel, Typography } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import downloadFile from '../../../../../Template/depotimportsample.xlsx';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid } from '../../../../../Core/Controls';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '25px',
        color: '#f50057',
        fontWeight: 600,
        paddingLeft: 9
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    }
}));

const columns = [
    { key: 'registration', name: 'Reg No.', width: '10%' },
    { key: 'depot', name: 'Depot', width: '40%' },
    { key: 'date', name: 'Date', width: '10%', formatter: formatters.Date_DDMMYYYY },
    { key: 'result', name: 'Result', width: '40%' }
];

const ImportDepot = (props) => {
    const [state, setState] = useState({
        errors: {},
        file: '',
        rows: [],
        showTable: false
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const classes = useStyles();

    const { showToastSuccess, showToastError } = useToast();

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const handleSubmit = async () => {
        if (state.fileName && state.selectedFile) {
            setBtnLoader(true);
            setState((st) => ({ ...st, uploading: true }));
            const data = new FormData();
            data.append(state.fileName, state.selectedFile);
            let res = await postImportDepot(props.customerID, data);
            if (res.success) {
                setState((st) => ({ ...st, rows: res.data.res }));
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    return (
        <Grid container spacing={2} className="screen mt_10">
            <TableContainer component={Paper} style={{ border: '1px solid rgba(0,0,0,0.2)' }} className="custom-scroll">
                <Table stickyHeader aria-label="sticky table" className={classes.paper}>
                    <TableBody style={{ height: '100%', overflow: 'auto' }}>
                        <StyledTableRow>
                            <StyledTableCell component="th">
                                <Typography color="secondary">
                                    <b>Upload Data File</b>
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <Typography>
                                    To import depots into your account, please{' '}
                                    <a href={downloadFile} download style={{ textDecoration: 'none' }}>
                                        download and complete the template spreadsheet
                                    </a>
                                    . Data MUST be in this format to be imported. Please note:
                                    <ul>
                                        <li>Dates must be in this format: dd/mm/yyyy </li>
                                        <li>
                                            The first row in the spreadsheet contains column headings and will be ignored - all other rows will considered as
                                            data to be imported.
                                        </li>
                                        <li>Depot will be set against the vehicle if there is a matching vehicle against {props.name}</li>
                                        <li>
                                            There is no option to undo an import-any mistakes or incorrect records will need to be corrected/deleted manually
                                        </li>
                                    </ul>
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <InputLabel shrink required>
                                    Data file
                                </InputLabel>
                                <input type="file" ID="fileSelect" accept=".xlsx, .xls, .csv" name="file" onChange={onFileSelect} />
                                <FormHelperText error>{state.errors.file}</FormHelperText>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <Typography>
                                    <b>Please check your spreadsheet has been completed correctly before continuing</b>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <SecondaryButton
                                                disabled={!state.selectedFile}
                                                isBtnLoader={btnLoader}
                                                onClick={handleSubmit}
                                                fullWidth
                                                className="submit_btn"
                                            >
                                                Import Depot
                                            </SecondaryButton>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <SecondaryButton onClick={props.onClose} fullWidth className="submit_btn">
                                                Cancel
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                    To avoid errors, please press button ONCE and wait for confirmation. The import process may take several minutes to complete
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {state.rows && state.rows.length > 0 && (
                <Grid item xs={12}>
                    <DataGrid rows={state.rows} columns={columns} paginate height={350} />
                </Grid>
            )}
        </Grid>
    );
};

export default ImportDepot;
