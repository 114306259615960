import { FormControlLabel, Grid, InputLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import InspectionImages from '../inspectionCheckImagesModal';
import { InputText } from '../../../../../../Core/Controls';
import { useContext } from 'react';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

export const InspectionBreakTest = ({ classes }) => {
    const { state, setState, handleInput } = useContext(InspectionCheCkContaxt);
    return (
        <Grid item direction="row" xs={12} sm={12} md={12}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} md={3}>
                                <InputLabel shrink style={{ marginTop: '11px' }}>
                                    Type of brake test
                                </InputLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <RadioGroup
                                    row
                                    aria-label="vehicleInspectionBrakeTestTypeID"
                                    name="vehicleInspectionBrakeTestTypeID"
                                    value={+state.vehicleInspectionBrakeTestTypeID}
                                    onChange={handleInput}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Roller Test" />
                                    <FormControlLabel value={2} control={<Radio />} label="Decelerometer" />
                                    <FormControlLabel value={3} control={<Radio />} label="Road Test" />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputLabel shrink>Brake Test Number</InputLabel>
                                <InputText
                                    placeholder="Brake test number"
                                    name="vehicleInspectionBrakeTestNumber"
                                    value={state.vehicleInspectionBrakeTestNumber}
                                    onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InspectionImages state={state} setState={setState} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        {' '}
                        <InputLabel shrink style={{ marginTop: '4px' }}>
                            Road Conditions
                        </InputLabel>
                        <InputText
                            multiline
                            rows={3}
                            placeholder="Road conditions"
                            name="vehicleInspectionRoadCondition"
                            value={state.vehicleInspectionRoadCondition}
                            onChange={handleInput}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
