import { AppStorage } from '../Basic/storage-service';

let checkhaveAccess = AppStorage.getAllowAcces();
let obj = {
    '/controller/customer': checkhaveAccess?.CanViewCustomers,
    '/JobParentScreen': checkhaveAccess?.CanViewJobs,
    '/controller/driver': checkhaveAccess?.CanViewDrivers,
    '/serviceagent': checkhaveAccess?.CanViewServiceAgents,
    '/InvoicesParentUrl': checkhaveAccess?.CanViewInvoices,
    '/company_details': checkhaveAccess?.CanViewCompanyDetails,
    '/DailyChecksParentUrl': checkhaveAccess?.CanViewDailyChecks,
    '/controller/Depots': checkhaveAccess?.CanViewDepots,
    '/controller/Vehicles/:all': checkhaveAccess?.CanViewVehicles,
    '/SchedulesParentUrl': checkhaveAccess?.CanViewSchedules,
    '/maintenance/breakdowns/:all': checkhaveAccess?.CanViewBreakdowns,
    '/maintenance/defects': checkhaveAccess?.CanViewDefects,
    '/controller/vor': checkhaveAccess?.CanViewVOR,
    '/Contracts_Maintenance': checkhaveAccess?.CanViewMaintenanceContracts,
    '/Contracts_Hire_Fleet': checkhaveAccess?.CanViewHireContracts,
    '/contractList': checkhaveAccess?.CanViewContracts
};

export const GetAllowMenus = (menus) => {
    let tempMenus = menus;
    Object.entries(obj).forEach((item) => {
        if (!item[1]) {
            console.log(item[0], item[1]);
            let jobIndex = tempMenus.findIndex((m) => m.screenURL === item[0]);
            if (jobIndex >= 0) {
                tempMenus.splice(jobIndex, 1);
            }
        }
    });
    return tempMenus;
};
