import React, { useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import '../../Controllers/controllerStyle.scss';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import AddIcon from '@material-ui/icons/Add';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { CustomChip, BreadCrumbs } from '../../../../Core/Controls';
import BuildIcon from '@material-ui/icons/Build';
import AddVor from '../../Controllers/VOR/AddEditVor';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';

const DefectDetails = (props) => {
    const { data } = props;
    const history = useHistory();
    const baseCrumb = [
        { name: 'Home', path: '/' },
        { name: 'Maintenance', active: true },
        { name: 'Defects', path: '' }
    ];

    const [state, setState] = useState({ show: false, showVor: false, vorVehicleID: null });

    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const isServiceagent = userRoles.includes('servicearea');
    const handleUpdate = () => {
        setState({ show: true });
    };

    const closetModal = (res) => {
        setState({ show: false, showCustomerModal: false, showDepotModal: false });
        res && props.pullDefectByID();
    };

    const handleAddPopUp = () => {
        setState((st) => ({ ...st, vorVehicleID: data.vorVehicleID, showVor: true }));
    };
    const closeAddPopUp = (flag, name) => {
        setState((st) => ({ ...st, showVor: false }));
        props.pullDefectByID();
    };

    const crumbs = useMemo(() => {
        const tempCols = [...baseCrumb];
        let actionCol = tempCols.find((element) => element.name === 'Defects');
        if (actionCol) {
            if (isServiceagent) {
                actionCol.path = '/serviceagent/defect';
            } else {
                actionCol.path = '/maintenance/defects';
            }
        }
        // if (!props.VehicleID) {
        //     tempCols.splice(1, 0, { key: 'customer', name: 'Customer Name', width: '8%' });

        //     tempCols.splice(2, 0, { key: 'defectRegNumber', name: 'Reg Number', width: '8%' });
        // }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         6,
        //         0,
        //         { key: 'defectDriverName', name: 'Driver Name', width: 110 },
        //         { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 }
        //     );
        //     // tempCols.splice(1, 0, { key: 'defectType', name: 'Defect Type', width: 130 });
        // }
        return tempCols;
    }, []);

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${data.defectVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleShowDetails = (val) => {
        setState({ [val]: true });
    };

    return (
        <>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                <div style={{ width: 'auto' }}>
                    <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                </div>
                &nbsp;&nbsp;
                <div style={{ width: 'auto' }}>
                    {data.vorVehicleID && <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="VOR" onClick={handleAddPopUp} />}
                </div>
            </div>
            <div className="controller-screen" style={{ margin: 4, marginTop: 15 }}>
                <div className="edit-controller-btn" style={{ position: 'relative' }}>
                    <Grid container spacing={1} className="grid-style grey-grid">
                        <Grid item xs={6} sm={3}>
                            <div className="controller-label">
                                <Typography variant="caption">Reg Number</Typography>
                            </div>
                            <Typography variant="button" onClick={handleEditClick} style={{ cursor: 'pointer' }}>
                                {data.defectRegNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div className="controller-label">
                                <Typography variant="caption">Make/Model</Typography>
                            </div>
                            <Typography variant="button">
                                {data.make}/{data.model}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} onClick={() => handleShowDetails('showCustomerModal')} style={{ cursor: 'pointer' }}>
                            <div className="controller-label">
                                <Typography variant="caption">Customer</Typography>
                            </div>
                            <Typography variant="button">{data.customer}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} onClick={() => handleShowDetails('showDepotModal')} style={{ cursor: 'pointer' }}>
                            <div className="controller-label">
                                <Typography variant="caption">Depot</Typography>
                            </div>
                            <Typography variant="button">{data.reportingDepot}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {data.defectNumberGenerated && !props.isAddJobBtnShow ? (
                                <div>
                                    {!isDriver && !isCustomer && (
                                        <CustomChip icon={AddIcon} label="Add Repair" style={{ fontWeight: 'bold' }} onClick={handleUpdate} />
                                    )}
                                </div>
                            ) : null}
                        </Grid> */}
                    </Grid>
                </div>
                {state.show ? (
                    <DialogComp title="Add Repair" onClose={() => closetModal(false)} maxWidth="sm">
                        <JobStepperScreen onClose={closetModal} userData={data} />
                    </DialogComp>
                ) : null}
                {state.showVor ? (
                    <DialogComp
                        title={`${data.vorVehicleID ? 'Update' : 'Add'} VOR - ${data.defectRegNumber}`}
                        // title={`Add Vor`}
                        onClose={() => closeAddPopUp(false)}
                        maxWidth="lg"
                        overflow="auto"
                    >
                        <AddVor VehicleID={data.vehicleID} update={state.vorVehicleID} vorVehicleID={state.vorVehicleID} onClose={closeAddPopUp} />
                    </DialogComp>
                ) : null}
                {state.showCustomerModal ? (
                    <DialogComp open={true} onClose={() => closetModal(false)} title={'Customer Details'} maxWidth="sm" fullWidth>
                        <CustomerDetails customerID={data.customerID} onFormSubmit={() => closetModal(false)} />
                    </DialogComp>
                ) : null}
                {state.showDepotModal ? (
                    <DialogComp open={true} onClose={() => closetModal(false)} title={'Depot Details'} maxWidth="sm" fullWidth>
                        <DepotDetails customerID={data.homeDepotID} onFormSubmit={() => closetModal(false)} />
                    </DialogComp>
                ) : null}
            </div>
        </>
    );
};

export default DefectDetails;
