import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { memo, useContext } from 'react';
import { EditInapectionContaxt } from './editInspectionProviderImports';

const setValue = (val, unit) => {
    if (val) {
        return (
            <>
                {val}
                <small>{unit}</small>
            </>
        );
    } else {
        return 'N/A';
    }
};

export const EditInspectionBrakePerformance = memo(({ classes }) => {
    const { state } = useContext(EditInapectionContaxt);
    return (
        <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
            <Grid item xs={12} md={12} lg={12}>
                <TableContainer component={Paper}>
                    <div
                        style={{
                            border: '1px solid rgba(0,0,0,0.2)',
                            padding: '3px 0px'
                        }}
                    >
                        <h3 className={classes.title}> Brake Performance</h3>
                    </div>
                    <Table aria-label="simple table" className={classes.paper}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">IM Reference</TableCell>
                                <TableCell align="left" width="50%">
                                    Item Inspected
                                </TableCell>
                                <TableCell align="left" width="20%">
                                    Efficiency
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell> 71</TableCell>
                                <TableCell> Service Brake Performance</TableCell>
                                <TableCell>
                                    <Typography variant="button"> {setValue(state.vehicleInspectionServiceBrakePerformance, '%')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> 72</TableCell>
                                <TableCell> Secondary Brake Performance</TableCell>
                                <TableCell>
                                    <Typography variant="button"> {setValue(state.vehicleInspectionSecondaryBrakePerformance, '%')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> 73</TableCell>
                                <TableCell> Parking Brake Performance</TableCell>
                                <TableCell>
                                    <Typography variant="button"> {setValue(state.vehicleInspectionParkingBrakePerformance, '%')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
});
