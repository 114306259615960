import { FormHelperText, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SecondaryButton, SelectBox, MandatoryField } from "../../../Core/Controls";
import { useToast } from "../../../Core/Hooks/useToast";
import "../../commonStyle.scss";
import { getAllVorTypeList, postVorTypeDetail } from "../../../Core/Services/InternalUser/VOR/vorTypeDetailService";
import { postVorSubCatData } from "../../../Core/Services/InternalUser/VOR/vorSubCatService";

const VorSubCatDialog = (props) => {
    const [state, setState] = useState({
        vorSubCategoryId: props.selectedRowData ? props.selectedRowData.vorSubCategoryId : null,
        vorSubCategoryCategoryId: props.focusedRow.vorTypeDetailID,
        vorSubCategoryDescription: props.selectedRowData ? props.selectedRowData.vorSubCategoryDescription : null,

        errors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validations = () => {
        const { vorSubCategoryDescription } = state;
        let formIsValid = true;
        let errors = {};

        if (vorSubCategoryDescription == "" || vorSubCategoryDescription == null) {
            errors.vorSubCategoryDescription = "Sub Category Description is required";
            formIsValid = false;
        }

        setState((state) => ({ ...state, errors: errors }));

        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            const data = {
                VORSubCategoryCategoryId: state.vorSubCategoryCategoryId,
                VORSubCategoryDescription: state.vorSubCategoryDescription
            };

            if (props.selectedRowData != null) {
                data.VORSubCategoryId = state.vorSubCategoryId;
            }

            let res = await postVorSubCatData(data);

            if (res.success) {
                showToastSuccess("VOR Sub Category Updated sucessfully");
                props.onClose();
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    const handleChange = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Description"
                        name="vorSubCategoryDescription"
                        placeholder="Description"
                        onChange={handleChange}
                        defaultValue={state.vorSubCategoryDescription}
                        errors={state.errors.vorSubCategoryDescription ? state.errors.vorSubCategoryDescription : null}
                    />
                </Grid>
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                        {props.selectedRowData == null ? "ADD" : "UPDATE"}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default VorSubCatDialog;
