import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import '../../Controllers/controllerStyle.scss';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { CustomChip, BreadCrumbs, BackButton } from '../../../../Core/Controls';
import AddVor from '../../Controllers/VOR/AddEditVor';
import BuildIcon from '@material-ui/icons/Build';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BreakDownDetails = (props) => {
    const { data } = props;
    const history = useHistory();
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Maintenance', active: true },
        { name: 'Breakdowns', path: '/maintenance/breakdowns/:all' }
    ];
    const [state, setState] = useState({ show: false, showVor: false, vorVehicleID: null });

    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const handleUpdate = () => {
        setState({ show: true });
    };

    const closetModal = (res) => {
        setState({ show: false, showCustomerModal: false, showDepotModal: false });
        res && props.pullBreackdownById();
    };

    const handleAddPopUp = () => {
        setState((st) => ({ ...st, vorVehicleID: data.vorVehicleID, showVor: true }));
    };
    const closeAddPopUp = (flag, name) => {
        setState((st) => ({ ...st, showVor: false }));
        props.pullBreackdownById();
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${data.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleShowDetails = (val) => {
        setState({ [val]: true });
    };
    const GoBack = () => {
        history.goBack();
    };
    return (
        <>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: -23 }}>
                <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                    <BackButton onClick={GoBack} />
                    <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                </div>
                &nbsp;&nbsp;
                <div style={{ width: 'auto' }}>
                    {data.vorVehicleID && !isDriver && (
                        <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="VOR" onClick={handleAddPopUp} tyle={{ width: '100%' }} />
                    )}
                </div>
            </div>
            <div className="controller-screen" style={{ margin: 4, marginTop: -22 }}>
                <div className="edit-controller-btn" style={{ position: 'relative' }}>
                    <Grid container spacing={1} className="mt_10" style={{ backgroundColor: '#f5f9f9' }}>
                        <Grid item xs={6} sm={3} onClick={handleEditClick} style={{ cursor: 'pointer' }}>
                            <div className="controller-label">
                                <Typography variant="caption">Reg Number</Typography>
                            </div>
                            <Typography variant="button" style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                {data.defectRegNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div className="controller-label">
                                <Typography variant="caption">Make/Model</Typography>
                            </div>
                            <Typography variant="button">
                                {data.make}/{data.model}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} onClick={() => handleShowDetails('showCustomerModal')} style={{ cursor: 'pointer' }}>
                            <div className="controller-label">
                                <Typography variant="caption">Customer</Typography>
                            </div>
                            <Typography variant="button" style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                {data.customer}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} onClick={() => handleShowDetails('showDepotModal')} style={{ cursor: 'pointer' }}>
                            <div className="controller-label">
                                <Typography variant="caption">Depot</Typography>
                            </div>
                            <Typography variant="button" style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                {data.reportingDepot}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {data.defectNumberGenerated && !props.isAddJobBtnShow ? (
                                <div>
                                    {!isDriver && !isCustomer && (
                                        <CustomChip style={{ fontWeight: 'bold' }} icon={AddIcon} label="Add Repair" onClick={handleUpdate} />
                                    )}
                                </div>
                            ) : null}
                        </Grid> */}
                    </Grid>
                </div>
                {state.show ? (
                    <DialogComp title="Add Repair" onClose={() => closetModal(false)} maxWidth="sm">
                        <JobStepperScreen onClose={closetModal} userData={data} />
                    </DialogComp>
                ) : null}
                {state.showVor ? (
                    <DialogComp
                        title={`${data.vorVehicleID ? 'Update' : 'Add'} VOR - ${data.defectRegNumber}`}
                        // title={`Add Vor`}
                        onClose={() => closeAddPopUp(false)}
                        maxWidth="lg"
                        overflow="auto"
                    >
                        <AddVor VehicleID={data.vehicleID} update={state.vorVehicleID} vorVehicleID={state.vorVehicleID} onClose={closeAddPopUp} />
                    </DialogComp>
                ) : null}
                {state.showCustomerModal ? (
                    <DialogComp open={true} onClose={() => closetModal(false)} title={'Customer Details'} maxWidth="sm" fullWidth>
                        <CustomerDetails customerID={data.customerID} onFormSubmit={() => closetModal(false)} />
                    </DialogComp>
                ) : null}
                {state.showDepotModal ? (
                    <DialogComp open={true} onClose={() => closetModal(false)} title={'Depot Details'} maxWidth="sm" fullWidth>
                        <DepotDetails customerID={data.homeDepotID} onFormSubmit={() => closetModal(false)} />
                    </DialogComp>
                ) : null}
            </div>
        </>
    );
};

export default BreakDownDetails;
