import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../../Core/Controls/Grid/index';
import { EditButton, formatters } from '../../../../../Core/Controls';
import DialogBox from './scheduleVehicleAdd';
import EditSchedule from './scheduleVehicleEdit';
import ScheduleNotes from './scheduleVehicleNotes';
import ScheduleBookingStatus from './scheduleBookingStatus';
import ScheduleBookingSection from './scheduleBookingSection';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import EventIcon from '@material-ui/icons/Event';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import WorkIcon from '@material-ui/icons/Work';
import '../../../../commonStyle.scss';
import './scheduleVehicleStyle.scss';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VehicleDailyChecks from '../VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../VehicleDailyChecks/editVehicleDailyCheck';
import BuildIcon from '@material-ui/icons/Build';
import { getScheduleVehicleById, deleteFutureUnCompletedSchedules } from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { CustomIconButton, SecondaryButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { SecondaryCheckbox } from '../../../../../Core/Controls';
import CompleteScheduleModal from '../../../Schedule/completeSchedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import ScheduleRevise from './scheduleRevise';
import SyncIcon from '@material-ui/icons/Sync';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RequiredDocumentsModal from '../../../../ServiceAgent/Schedules/requiredDocumentsModal';
import EventNoteIcon from '@material-ui/icons/EventNote';
import moment from 'moment';
import ReactDOM from 'react-dom';
import ServiceAgentDetails from '../../DetailsModal/serviceAgent';

// Date Due, Event, Booking Date, Completion Date, Agent, Status
const basecolumns = [
    { key: 'action', name: 'Action', width: 150, addFileUploaderIcon: true },
    { key: 'scheduleEventName', name: 'Event', width: 120 },
    { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
    { key: 'scheduleDateDue', name: 'Due', width: 90 },
    { key: 'scheduleRequestedBookingDate', name: 'Requested', width: 100 },
    { key: 'bookingDate', name: 'Booked', width: 90 },
    { key: 'scheduleCompletionDate', name: 'Completed', width: 100 },
    { key: 'customerCompanyName', name: 'Customer', width: 170 },
    { key: 'depotName', name: 'Depot', width: 140 },
    { key: 'scheduleBookingStatus', name: 'Status', width: 150 },
    { key: 'agentName', name: 'Service Area', width: 110 }
];
const ScheduleVehicle = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        showChecks: false,
        showEditChecks: false,
        showDelete: false,
        isDeleteFuture: false,
        scheduleID: null,
        data: [],
        isReload: null
    });
    const [openDialog, setOpenDialog] = useState({});
    const history = useHistory();
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        pullApiData();
    }, [props.scheduleVehicleID]);

    const reg = props.vehicleDisc ? props.vehicleDisc : '';

    useEffect(() => {
        if (props.isStepperReload) {
            pullApiData();
        }
    }, [props.isStepperReload]);

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'Ad') {
                newSt.showChecks = true;
                newSt.selectedRowData = ev;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
                newSt.selectedRowData = ev;
            }
            return newSt;
        });
    };

    const closeCheck = () => {
        setState((st) => ({
            ...st,
            showChecks: false,
            showEditChecks: false
        }));
        pullApiData();
        props.handleStepperContract && props.handleStepperContract(new Date());
    };

    const onSelectCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const deleteFutureUnComSchedules = async () => {
        setBtnLoader(true);
        let data = {
            scheduleVehicleID: props.scheduleVehicleID,
            scheduleEventID: state.data.scheduleEventID,
            isDeleteFuture: state.isDeleteFuture,
            scheduleID: state.data.scheduleID
        };
        let res = await deleteFutureUnCompletedSchedules(data);
        if (res.success) {
            showToastSuccess('Record deleted successfully');
            setState((st) => ({ ...st, showDelete: false }));
            pullApiData();
        } else {
            showToastError(res.message);
        }
        setBtnLoader(false);
    };
    const pullApiData = async () => {
        // let res = await getScheduleVehicleById(props.scheduleVehicleID);
        // setState((st) => ({
        //     ...st,
        //     rows: [...(res.data.schedules || [])],
        //     showLoader: false
        // }));
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.scheduleJobID}/${val?.scheduleVehicleID}`,
            search: '',
            state: ''
        });
    };
    const modelgridStyle = {
        styleCol: {
            cursor: 'pointer',
            fontStyle: 'italic',
            textDecorationLine: 'underline'
        },
        NoneCondition: {}
    };
    const renderEdit = (ev, row) => {
        return (
            <div>
                {/* {row?.displayNotes === 'Y' && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        className="modal-btn-update btn_space"
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )} */}
                {/* {row?.displayEditVehicleSchedule === 'Y' && checkhaveAccess?.CanUpdateSchedules && (
                    <span className="modal-btn-update  btn_space">
                        <EditButton
                            toolTipTitle="Edit Vehicle Schedule"
                            onClick={() => {
                                setState((st) => ({
                                    ...st,
                                    open: true,
                                    selectedRowData: row,
                                    isAdd: false
                                }));
                            }}
                        />
                    </span>
                )} */}
                {/* {row?.displayReviseSchedule === 'Y' && (
                    <CustomIconButton
                        icon={SyncIcon}
                        color="primary"
                        className="modal-btn-update"
                        onClick={() => {
                            checkButton(row, 'revise');
                        }}
                        toolTipTitle="Change Booking Date"
                    />
                )} */}
                {row?.scheduleBookingStatusID == 5 && (
                    <CustomIconButton
                        icon={PostAddIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'showRequiredDocumentsModal');
                        }}
                        toolTipTitle="Required Documents"
                    />
                )}

                {row?.displayJobDetails === 'Y' && checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        toolTipTitle="Job Details"
                        onClick={() => handleEditClick(row)}
                    />
                )}

                {/* {row?.displayCompleteSchedule === 'Y' && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        className="modal-btn-update btn_space"
                        color="primary"
                        onClick={() => {
                            checkButton(row, 'complete');
                        }}
                        toolTipTitle="Complete schedule"
                    />
                )} */}

                {/* {row?.displayBookingRequest === 'Y' && (
                    <CustomIconButton
                        icon={EventIcon}
                        className="modal-btn-update btn_space"
                        onClick={() => {
                            checkButton(row, 'status');
                        }}
                        toolTipTitle="Update Booking Request"
                    />
                )} */}

                {/* {row?.displayBookJob === 'Y' && (
                    <CustomIconButton
                        icon={EventNoteIcon}
                        color="primary"
                        className="modal-btn-update btn_space"
                        onClick={() => {
                            checkButton(row, 'section');
                        }}
                        toolTipTitle="Book Job"
                    />
                )} */}

                {row?.displayAddInspectionCheck === 'Y' && checkhaveAccess?.CanAddInspections && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(row, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                )}

                {row?.displayEditInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(row, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                )}

                {row?.displayDeleteSchedules === 'Y' && checkhaveAccess?.CanDeleteSchedules && (
                    <CustomIconButton
                        icon={DeleteIcon}
                        color="secondary"
                        className="modal-btn-update"
                        onClick={() => {
                            setState((st) => ({
                                ...st,
                                showDelete: true,
                                data: row
                            }));
                        }}
                        toolTipTitle="Delete future uncompleted schedules"
                    />
                )}
            </div>
        );
    };

    const dateFormatter = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '--:--';
    };
    const getRenderValue = (val, row) => {
        if (val === 'scheduleEventName') {
            return (
                <div
                    onClick={() => (row?.displayNotes === 'Y' && row?.isNote > 0 ? checkButton(row, 'notes') : '')}
                    style={row?.displayNotes === 'Y' && row?.isNote > 0 ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                >
                    {' '}
                    {row.scheduleEventName}
                </div>
            );
        } else if (val === 'scheduleRequestedBookingDate') {
            return (
                <div
                    style={row?.displayBookingRequest === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() => (row?.displayBookingRequest === 'Y' ? checkButton(row, 'status') : '')}
                >
                    {row?.displayBookingRequest === 'Y' && row.scheduleRequestedBookingDate
                        ? dateFormatter(row.scheduleRequestedBookingDate)
                        : row?.displayBookingRequest === 'Y'
                        ? 'Request'
                        : dateFormatter(row.scheduleRequestedBookingDate)}
                </div>
            );
        } else if (val === 'bookingDate') {
            return (
                <div
                    style={row?.displayBookJob === 'Y' || row?.displayReviseSchedule === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() =>
                        row?.displayBookJob === 'Y' ? checkButton(row, 'section') : row?.displayReviseSchedule === 'Y' ? checkButton(row, 'revise') : ''
                    }
                >
                    {row?.displayReviseSchedule === 'Y'
                        ? dateFormatter(row.bookingDate)
                        : row?.displayBookJob === 'Y'
                        ? 'Book Job'
                        : dateFormatter(row.bookingDate)}
                </div>
            );
        } else if (val === 'scheduleCompletionDate') {
            return (
                <div
                    style={row?.displayCompleteSchedule === 'Y' ? modelgridStyle.styleCol : modelgridStyle.NoneCondition}
                    onClick={() => (row?.displayCompleteSchedule === 'Y' ? checkButton(row, 'complete') : '')}
                >
                    {row?.displayCompleteSchedule === 'Y' ? 'Complete' : dateFormatter(row.scheduleCompletionDate)}
                </div>
            );
        } else {
            return '';
        }
    };
    const BasicColFormate =
        (clVal) =>
        ({ row, col }, grid) => {
            const spn = document.createElement('span');
            const lnk = getRenderValue(clVal, row);
            ReactDOM.render(lnk, spn);
            return spn;
        };
    const dueDateFormatter = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        let textColor = formatters.getScheduleColour(row.scheduleCompletionDate, row.scheduleDateDue, row.dayRange);
        const dueDate = row.scheduleDateDue;
        let show = row?.displayEditVehicleSchedule === 'Y' && checkhaveAccess?.CanUpdateSchedules;
        const HandeClick = () => {
            setState((st) => ({
                ...st,
                open: true,
                selectedRowData: row,
                isAdd: false
            }));
        };
        const lnk = (
            <div
                style={{
                    color: `${textColor}`,
                    fontWeight: 'bold',
                    cursor: show ? 'pointer' : '',
                    fontStyle: show ? 'italic' : '',
                    textDecorationLine: show ? 'underline' : ''
                }}
                onClick={() => (show ? HandeClick() : '')}
            >
                {dateFormatter(dueDate)}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => checkButton(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let dateDueCol = tempCols.find((e) => e.key === 'scheduleDateDue');
        let scheduleEventNameCol = tempCols.find((e) => e.key === 'scheduleEventName');
        let scheduleRequestedDatecol = tempCols.find((e) => e.key === 'scheduleRequestedBookingDate');
        let bookingDateCol = tempCols.find((e) => e.key === 'bookingDate');
        let scheduleCompletionDateCol = tempCols.find((e) => e.key === 'scheduleCompletionDate');
        if (scheduleCompletionDateCol) scheduleCompletionDateCol.formatter = BasicColFormate('scheduleCompletionDate');
        if (bookingDateCol) bookingDateCol.formatter = BasicColFormate('bookingDate');
        if (scheduleRequestedDatecol) scheduleRequestedDatecol.formatter = BasicColFormate('scheduleRequestedBookingDate');
        if (scheduleEventNameCol) scheduleEventNameCol.formatter = BasicColFormate('scheduleEventName');
        let agentCol = tempCols.find((element) => element.key === 'agentName');
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        if (dateDueCol) {
            dateDueCol.formatter = dueDateFormatter;
        }
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: {}
        }));
        res && pullApiData();
        res && props.handleStepperContract && props.handleStepperContract(new Date());
    };

    const closeTheDialog = (res) => {
        setOpenDialog({});
        res && pullApiData();
        res && props.handleStepperContract && props.handleStepperContract(new Date());
    };

    const checkButton = (ev, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: ev
        }));
        // pullApiData();
    };

    let getUrl = `Schedules?vehicleId=${props.scheduleVehicleID}`;
    let addBtnTitle = isCustomer ? '' : 'Add Vehicle Schedule';

    return (
        <div className="schedule_screen screen">
            {state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    onClose={closeCheck}
                    displayCheck={state.selectedRowData.displayCheck}
                    frompage={false}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks
                    checkID={state.selectedRowData.vehicleInspectionID}
                    displayCheck={state.selectedRowData.displayCheck}
                    onClose={closeCheck}
                    frompage={false}
                />
            ) : (
                <GijgoGrid
                    columns={columns}
                    idFieldName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'scheduleID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'ScheduleDocumentsRequired' : null}`}
                    btnTitle={addBtnTitle}
                    onBtnClick={addBtnClicked}
                    dataConverter={(res) => ({
                        records: res.data.schedules,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={getUrl}
                    downloadBtnName={`${reg}_Schedule`}
                />
            )}
            {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? `Add Schedule (${reg})` : `Edit Schedule (${reg})`}
                    maxWidth="sm"
                    name="addEdit"
                    overflow="auto"
                >
                    {state.isAdd ? (
                        <DialogBox
                            data={state}
                            isAdd={state.isAdd}
                            scheduleId={state.selectedRowData.scheduleID}
                            vehicleId={props.scheduleVehicleID}
                            serviceAgentid={props.serviceAgentid}
                            onClose={() => {
                                handleClose(true);
                            }}
                        />
                    ) : (
                        <EditSchedule
                            data={state}
                            isAdd={state.isAdd}
                            scheduleId={state.selectedRowData.scheduleID}
                            vehicleId={props.scheduleVehicleID}
                            onClose={() => {
                                handleClose(true);
                            }}
                        />
                    )}
                </DialogComp>
            ) : null}
            {openDialog.notes ? (
                <DialogComp title={`Schedule Notes (${reg})`} maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleNotes vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.status ? (
                <DialogComp title={`Customer Booking Request  (${reg})`} maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingStatus vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.section ? (
                <DialogComp title={`Booking Section  (${reg})`} maxWidth="md" onClose={() => closeTheDialog(false)}>
                    <ScheduleBookingSection vehicleId={props.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.complete ? (
                <DialogComp title={`Complete Schedule  (${reg})`} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <CompleteScheduleModal scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.revise ? (
                <DialogComp title={`Change Booking Date  (${reg})`} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <ScheduleRevise scheduleId={state.selectedRowData.scheduleID} rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.showRequiredDocumentsModal ? (
                <DialogComp open={true} onClose={() => closeTheDialog(false)} title={'Required Documents'} maxWidth="sm" fullWidth>
                    <RequiredDocumentsModal rowData={state.selectedRowData} onFormSubmit={closeTheDialog} />
                </DialogComp>
            ) : null}
            {state.showDelete ? (
                <DialogComp title="Delete" onClose={() => setState((st) => ({ ...st, showDelete: false }))} maxWidth="sm">
                    <Grid container spacing={0} style={{ marginTop: -12 }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                <Typography component="legend">
                                    <b> Date Due:{formatters.DateFormatter(state.data?.scheduleDateDue)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component="legend">
                                    <b>Event Name:{state.data?.scheduleEventName}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend">Are you sure you want to delete this record?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SecondaryCheckbox
                                    onChange={onSelectCheckbox}
                                    checked={state.isDeleteFuture}
                                    name="isDeleteFuture"
                                    value={state.isDeleteFuture}
                                    label="Delete Future Schedules?"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton isBtnLoader={btnLoader} onClick={deleteFutureUnComSchedules} fullWidth className="submit_btn">
                                    Submit
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={() => setState((st) => ({ ...st, showDelete: false }))} fullWidth className="submit_btn">
                                    Cancel
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
            {openDialog.showSaModal ? (
                <DialogComp open={true} onClose={() => closeTheDialog(false)} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={closeTheDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ScheduleVehicle;

//Please Ignore
{
    /* <Link to={`/controller/jobbookeddetail/${props.scheduleVehicleID}/${ev.row?.scheduleID}`}>
</Link> */
}
