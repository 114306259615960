import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useEffect, useContext, useState, lazy, Suspense, useCallback } from 'react';
import { getAllServiceAgentDash } from '../../../Core/Services/ServiceAgent/ServiceAgent';
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import { Link } from 'react-router-dom';
import '../ServiceCommon.scss';
import moment from 'moment';
import { colors } from '@material-ui/core';

const LazyComponent = lazy(() => import('../../Technician/dashboardUserInterface'));

const MainDashboard = (props) => (
    <Suspense fallback={<div>please Wait...</div>}>
        <LazyComponent {...props} />
    </Suspense>
);

const ServiceAgentDash = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true);

    const pullDashboardData = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const res = await getAllServiceAgentDash();

            if (res.success) {
                setDashboardData(res.data.dashboard[0]);
            } else {
                console.warn('Unexpected response:', res);
            }
        } catch (error) {
            console.error('API Error:', error.response.data);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }, []);

    useEffect(() => {
        pullDashboardData();
    }, [pullDashboardData]);

    const singleCardData = [
        {
            name: 'Not Started ',
            value: dashboardData.jobsNotStarted,
            // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
            path: '/serviceagent/OJ',
            params: { vehicleJobStatus: 'Not Started' },
            valueColor: '#0096FF'
        },
        {
            innerComponent: [
                { name: 'On Hire ', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'On Hire' } },
                { name: 'Off Hire ', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' } }
            ],

            lg: 3,
            isInnerComp: true
        },
        {
            innerComponent: [
                {
                    name: 'Breakdown',
                    value: dashboardData.breakdowns,
                    // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
                    path: '/maintenance/breakdowns/:all',
                    valueColor: 'red'
                },
                {
                    name: 'VOR',
                    value: dashboardData.vor,
                    //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/controller/vor',
                    valueColor: 'red'
                }
            ],
            lg: 3,
            isInnerComp: true
        }
    ];

    const groupCardData = [
        {
            title: 'MY JOBS',
            headBackground: '#FA7070',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',

            // path: '/serviceagent/OJ',
            items: [
                { label: 'All jobs', value: dashboardData.jobsTotal, path: '/serviceagent/OJ', params: { vehicleJobStatus: '' } },
                {
                    label: 'Awaiting Parts',
                    value: dashboardData.jobsAwaitingParts,
                    path: '/serviceagent/OJ',
                    params: { vehicleJobStatus: 'Awaiting Part' }
                },
                { label: 'Paused', value: dashboardData.jobsPaused, path: '/serviceagent/OJ', params: { vehicleJobStatus: 'Paused' } },
                { label: 'Work in progress', value: dashboardData.jobsWIP, path: '/serviceagent/OJ', params: { vehicleJobStatus: 'Work In Progress' } }
            ]
        },
        {
            title: 'EVENTS',
            // path: '/schedule/confirmedbookings',
            items: [
                {
                    label: 'Overdue',
                    value: dashboardData.scheduleOverdue,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=""&end=${moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')}`,
                    valueColor: 'red'
                },
                {
                    label: 'This Week',
                    value: dashboardData.scheduleThisWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().startOf('week').format('YYYY-MM-DD')}&end=${moment().endOf('week').format('YYYY-MM-DD')}`
                },
                {
                    label: 'Next Week',
                    value: dashboardData.scheduleNextWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('week')
                        .format('YYYY-MM-DD')}`
                },
                {
                    label: 'Future',
                    value: dashboardData.scheduleFuture,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(2, 'weeks').startOf('week').format('YYYY-MM-DD')}`
                }
            ]
        },
        {
            title: 'CONTRACT',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            // path: '/Contracts_Hire_Fleet',
            itemSize: {
                lg: 12,
                md: 12,
                sm: 12,
                xs: 6
            },

            items: [
                { label: 'Hire', value: dashboardData.hireActive, path: '/Contracts_Hire_Fleet', params: { status: '' } },
                { label: 'Maintenance', value: dashboardData.contractMaintenanceActive, path: '/Contracts_Maintenance' }
            ]
        },
        {
            title: 'WAREHOUSE',
            path: '/parts',
            items: [
                { label: 'Parts', value: dashboardData.warehouseParts },
                { label: 'On Order', value: 0 },
                { label: 'In Transit', value: 0 },
                { label: 'Back Order', value: 0 }
            ]
        },
        {
            title: 'Review',
            itemSize: {
                lg: 6,
                md: 4,
                sm: 4,
                xs: 6
            },
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            // path: '/parts',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            items: [
                { label: 'Job', value: dashboardData.jobsToReview },
                { label: 'Events', value: dashboardData.eventsToReview },
                { label: 'OutStanding Documents', value: dashboardData.outstandingDocuments, valueColor: 'red' }
            ]
        },
        {
            title: 'EVENTS',
            lg: 9,
            innerItem: 3,
            // path: '/parts',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            itemSize: {
                lg: 3,
                md: 4,
                sm: 6,
                xs: 6
            },
            cardSize: {
                lg: 9,
                md: 12,
                sm: 12,
                xs: 12
            },
            items: [
                {
                    label: 'Summer Service',
                    value: dashboardData.eventsSummerService,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=`,
                    params: { scheduleEventName: 'Summer Service' }
                },
                {
                    label: 'On Hire Inspection',
                    value: dashboardData.eventsOnHire,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=`,
                    params: { scheduleEventName: 'On Hire' }
                },
                {
                    label: 'Off Hire Inspection',
                    value: dashboardData.eventsOffHire,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=`,
                    params: { scheduleEventName: 'Off Hire' }
                },
                {
                    label: 'Inspections Current & Past Week',
                    value: dashboardData.eventsInspectionsCurrent,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`,
                    params: { scheduleEventName: 'DOT' }
                },
                {
                    label: 'Inspections Next week',
                    value: dashboardData.eventsInspectionsNextWeek,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('isoWeek')
                        .format('YYYY-MM-DD')}`,
                    params: { scheduleEventName: '' }
                },
                {
                    label: 'Tachograph Cali',
                    value: dashboardData.eventsTachographEvents,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    params: { scheduleEventName: 'Tachograph' }
                },
                {
                    label: 'Conformity Check',
                    value: dashboardData.eventsConformityCheckEvents,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    params: { scheduleEventName: 'Conformity' }
                },
                {
                    label: 'MOT',
                    value: dashboardData.eventsMOT,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    params: { scheduleEventName: 'MOT' }
                }
            ]
        }
    ];

    return (
        <div style={{ padding: 10 }}>
            <MainDashboard singleCardData={singleCardData} groupCardData={groupCardData} loading={loading} pullDashboardData={pullDashboardData} />
            {/* <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    {state.tilesData.map((option, index) => (
                        <Grid item key={option.id} xs={12} sm={6} md={4} lg={3} className="upper">
                            <Link
                                style={{ textDecoration: 'none' }}
                                color="inherit"
                                to={
                                    option.code == 'BO'
                                        ? '/maintenance/breakdowns/typebo'
                                        : option.code == 'BR'
                                        ? '/maintenance/breakdowns/typebr'
                                        : option.code == 'DD' || option.code == 'SD'
                                        ? '/'
                                        : `/serviceagent/${option.code}`
                                }
                            >
                                {option.title == 'Awaiting Approvals' ? (
                                    <LocalTaxiRoundedIcon className="icon_Style" />
                                ) : option.title == 'Breakdown Onsite' ? (
                                    <BuildRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Breakdown Roadside' ? (
                                    <LocalShippingRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Driver Defects' ? (
                                    <BuildRoundedIcon className="icon_Style" />
                                ) : option.title == 'Estimates Submitted' ? (
                                    <ExploreRoundedIcon className="yellow_iconStyle" />
                                ) : option.title == 'Open Jobs' ? (
                                    <WorkRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Queried Jobs' ? (
                                    <AssignmentIcon className="yellow_iconStyle" />
                                ) : option.title == 'Schedule Defects' ? (
                                    <PermDataSettingIcon className="red_iconStyle" />
                                ) : option.title == 'Test123' ? (
                                    <AirportShuttleIcon className="purple_iconStyle" />
                                ) : option.title == 'TestTS' ? (
                                    <AccountTreeIcon className="purple_iconStyle" />
                                ) : null}
                                <Card className={`c${index}`}>
                                    <CardContent className="cardContent">
                                        <Typography className=" numaricData">{option.cnt}</Typography>
                                        <Typography component="h1">{option.title}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container> */}
        </div>
    );
};
export default ServiceAgentDash;
