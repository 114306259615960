import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, FormHelperText } from "@material-ui/core";
import { InputText, SecondaryButton, MandatoryField } from "../../../Core/Controls";
import { getRetorqueById, postRetorqueData } from "../../../Core/Services/InternalUser/VOR/retorqueReasonsService";
import { useToast } from "../../../Core/Hooks/useToast";
import "../../commonStyle.scss";

function RetorqueReasonsModal(props) {
    const [state, setState] = useState({
        retorqueReasonID: null,
        retorqueReasonDescription: "",
        errors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.retorqueReasonID) {
            let res = await getRetorqueById(props.retorqueReasonID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    retorqueReasonID: res.data.retorqueReasonID,
                    retorqueReasonDescription: res.data.retorqueReasonDescription
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { retorqueReasonDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(retorqueReasonDescription)) {
            errors.retorqueReasonDescription = "Retorque Reason is required";
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const retorqueData = {
                retorqueReasonID: state.retorqueReasonID,
                retorqueReasonDescription: state.retorqueReasonDescription
            };
            let res = await postRetorqueData(retorqueData);
            if (res.success) {
                showToastSuccess("Retorque Reason Updated sucessfully");
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="retorqueReasonDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.retorqueReasonDescription}
                    errors={state.errors.retorqueReasonDescription}
                />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.retorqueReasonID ? "Update" : "Add"}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default RetorqueReasonsModal;
