import { Grid, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker, SecondaryButton, SimpleField } from '../../../../../../Core/Controls';
import { postJobMileage } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import moment from 'moment';
import { useToast } from '../../../../../../Core/Hooks/useToast';

const SetJobMileageScreen = ({ checkStatus, jobDetails, onClose, scheduleJobID }) => {
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        jobMileage: jobDetails.jobMileage || '',
        jobMileageDate: jobDetails.jobMileageDate || ''
    });

    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    const handleMileage = async () => {
        let data = {
            jobID: scheduleJobID,
            jobMileage: state.jobMileage,
            jobMileageDate: state.jobMileageDate
        };
        let res = await postJobMileage(data);
        if (res.success) {
            onClose(true);
            showToastSuccess('Job Mileage and Job Date Added Successfully ');
        } else {
            showToastError(res.message);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DatePicker
                    inputLabel="Job Mileage Date"
                    isRequired={true}
                    inputProps={{
                        max: new Date().toISOString().slice(0, 10)
                    }}
                    value={moment(state.jobMileageDate).format('YYYY-MM-DD')}
                    name="jobMileageDate"
                    onChange={valueChanged}
                    isDisabled={!checkStatus}
                />
            </Grid>

            <Grid item xs={12}>
                <SimpleField
                    inputLabel="Job Mileage"
                    type="number"
                    value={state.jobMileage}
                    name="jobMileage"
                    onChange={valueChanged}
                    isDisabled={!checkStatus}
                />
            </Grid>
            {!checkStatus ? null : (
                <Grid item xs={12}>
                    <InputLabel shrink>&nbsp;</InputLabel>
                    <SecondaryButton onClick={handleMileage} disabled={!jobDetails.isEditableFields}>
                        Set
                    </SecondaryButton>
                </Grid>
            )}
        </Grid>
    );
};

export default SetJobMileageScreen;
