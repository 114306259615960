import React, { useState, useEffect, useMemo } from 'react';
import { deleteLabourpartsJobsItem, getAllJobItems } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import {
    getJobDetailsById,
    getJobRequiredDocuments,
    postJobMileage,
    setBookingDate
} from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DataGrid from '../../../../../../Core/Controls/Grid';
import { AddButton, CustomChip, DatePicker, EditButton, formatters, SimpleField } from '../../../../../../Core/Controls';
import AddLabourModal from './addJobItem';
import '../../../../../commonStyle.scss';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import Grid from '@material-ui/core/Grid';
import { some } from 'lodash';
import SellerInvoiceModal from '../../JOBItems/JobInvoiceLines/sellerInvoice';
import PurchaseInvoice from '../../JOBItems/JobInvoiceLines/purchaseInvoice';
import JobItemHeaderDates from './../../JOBItems/jobItemHeaderDates';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import JobQueriesScreen from '../../jobQueriesScreen';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import _ from 'lodash';
import UpcomingEvents from '../../../Vehicles/ScheduleVehicle/upcomingEvents';
import { CustomIconButton, DeleteButton, SecondaryButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import BuildIcon from '@material-ui/icons/Build';
import VehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import CompleteScheduleModal from '../../../../Schedule/completeSchedule';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import LabourLineTableComp from './labourLineTable';
import GrainIcon from '@material-ui/icons/Grain';
import AddVor from '../../../VOR/AddEditVor';
import SearchIcon from '@material-ui/icons/Search';
import SetJobMileageScreen from './setJobMileage';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import DoneIcon from '@material-ui/icons/Done';
import { CustomButtonChip } from '../../../../../../Core/Controls/Inputs/DatePicker';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

function InternalJobItem(props) {
    const { jobDetails, setJobDetails } = props;
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        jobItemsList: [],
        loader: true,
        open: false,
        jobStatusID: '',
        showInvoice: false,
        refresherFlag: new Date(),
        errors: {},
        showOk: true,
        showSellerBtn: true,
        showSellerInvoice: false,
        showCreate: false,
        allowUpdate: jobDetails.isEditableFields,
        selectedJobItemID: null
    });
    const { userRoles } = useLoginInfo();

    const isEngineer = userRoles.includes('engineer');

    const base_columns = [
        {
            key: 'jobItemDescription',
            name: 'Description',
            // width: ,
            formatter: (params) => {
                return (
                    <div
                        style={{ cursor: params.row.allowUpdate === 'Y' ? 'pointer' : 'text', fontWeight: 600, width: '100%' }}
                        onClick={() => (params.row.allowUpdate === 'Y' ? btnHandler(params.row) : '')}
                    >
                        <div>{params.row.jobItemDescription}</div>
                        <div style={{ fontSize: '12px' }}>{params.row.vmrs}</div>
                    </div>
                );
            },
            cellClass: 'react-data-grid-cell-line-height'
        },
        {
            key: 'status',
            name: 'Status',
            width: 120
        },
        {
            key: 'hours',
            name: 'Hours',
            formatter: formatters.AlignRight,
            headerRenderer: formatters.ColumnHeaderAlignRight,
            width: 80
        },
        {
            key: 'costType',
            name: 'Cost type',
            width: 180
        },

        { key: 'chargeable', name: 'Chargeable', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight, width: '10%' },
        {
            key: 'nonchargeable',
            name: 'Non-Chargeable',
            formatter: formatters.NumberWithPound,
            headerRenderer: formatters.ColumnHeaderAlignRight,
            width: '14%'
        },
        {
            key: 'action',
            name: '',
            width: '1%'

            // addFileUploaderIcon: true
        }
        // { key: 'jobItemStatusDescription', name: 'Status' }
    ];

    useEffect(async () => {
        pullJobItems();
    }, [state.refresherFlag]);

    const pullJobItems = async () => {
        let res = await getAllJobItems(props.scheduleJobID);
        let res2 = res.data.vehicleJobItems;
        // .map((m) => {
        //     let customerLabour = m.jobItemLabHourRate * (1 + state.markup / 100);
        //     let Labour = (m.jobItemLabHourRate * m.jobItemLabourHours * (100 - m.jobItemLabourDiscount)) / 100;
        //     return {
        //        custTotal: m.jobItemCustomerLabourCost + m.jobItemCustomerPartsPrice,
        //         totalLabour: Labour,
        //         total: Labour + m.jobItemPartsprice - (m.jobItemCustomerLabourCost + m.jobItemCustomerPartsPrice),
        //         ...m
        //     };
        // });

        setState((state) => {
            const nst = { ...state };
            nst.jobItemsList = res2;
            nst.loader = false;
            nst.checkApprovedJob = some(res.data.vehicleJobItems, (r) => r.jobItemStatusID == 'A');

            return nst;
        });
    };

    const showVorModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            [res]: true
        }));
    };

    const closeVORModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            showVORPopup: false,
            showSaModal: false
        }));
        res && refreshScreen(true);
    };

    const btnHandler = (params) => {
        if (params.jobItemID) {
            setState((state) => ({
                ...state,
                open: true,
                jobItemID: params.jobItemID,
                vehicleID: params.vehicleID,
                vehicleRegNumber: params.vehicleRegNumber,
                allowUpdate: params.allowUpdate === 'Y' ? true : false
            }));
        } else {
            setState((state) => ({
                ...state,
                open: true
            }));
        }
    };

    const addLaburLineHandler = (params) => {
        setState((state) => ({ ...state, openLaburLine: true }));
    };
    const addJobMileageModal = (params) => {
        setState((state) => ({ ...state, showJobMileageModal: true }));
    };
    const closeLaburLineHandler = (res) => {
        setState((state) => ({ ...state, openLaburLine: false }));
        res && refreshScreen();
    };

    const createInvoiceHandler = (name) => {
        setState((state) => ({ ...state, [name]: true }));
    };

    const handleOpen = (row) => {
        setState((st) => ({ ...st, showCancelConfirmModal: !st.showCancelConfirmModal, selectedJobItemID: row.jobItemID }));
    };

    const closeCheck = () => {
        setState((st) => ({
            ...st,
            showChecks: false,
            showEditChecks: false,
            complete: false
        }));
        props.pullJobDetail();
        pullJobItems();
    };
    const handleDeletePart = async () => {
        let res = await deleteLabourpartsJobsItem(state.selectedJobItemID);
        if (res.success) {
            showToastSuccess('Job item removed successfully.');
            pullJobItems();
            setState((st) => ({ ...st, showCancelConfirmModal: false, selectedJobItemID: null }));
        } else {
            showToastError(res.message);
        }

        // pullApiData();
    };

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedRowData = ev;
            if (val === 'Ad') {
                newSt.showChecks = true;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
            }
            if (val === 'complete') {
                newSt.complete = true;
            }
            return newSt;
        });
    };

    const ActionButtons = (data) => {
        return (
            <div style={{ display: 'flex' }}>
                {/* <EditButton toolTipTitle="Edit Job Item" onClick={() => btnHandler(data.row)} /> */}

                {/* {data.row?.displayCompleteSchedule === 'Y' && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        className="modal-btn-update btn_space"
                        color="primary"
                        onClick={() => {
                            handleCheck(data.row, 'complete');
                        }}
                        toolTipTitle="Complete schedule"
                    />
                )} */}
                {/* {data.row.allowUpdate === 'Y'
                    ? data.row?.displayAddInspectionCheck === 'Y' &&
                      checkhaveAccess?.CanAddInspections && (
                          <CustomIconButton
                              icon={BuildIcon}
                              color="primary"
                              onClick={() => {
                                  handleCheck(data.row, 'Ad');
                              }}
                              toolTipTitle="Add Inspection Check"
                          />
                      )
                    : null}

                {data.row.allowUpdate === 'Y'
                    ? data.row?.displayEditInspectionCheck === 'Y' && (
                          <CustomIconButton
                              icon={BuildIcon}
                              color="secondary"
                              onClick={() => {
                                  handleCheck(data.row, 'Ed');
                              }}
                              toolTipTitle="Edit Inspection Check"
                          />
                      )
                    : null} */}
                {checkhaveAccess?.CanDeleteJobLines
                    ? data.row.canDeleteLine === 'Y' && <DeleteButton onClick={() => handleOpen(data.row)} toolTipTitle="Delete Job Item" />
                    : null}
            </div>
        );
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setJobDetails((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    const handleMileage = async () => {
        let data = {
            jobID: props.scheduleJobID,
            jobMileage: jobDetails.jobMileage,
            jobMileageDate: jobDetails.jobMileageDate
        };
        let res = await postJobMileage(data);
        if (res.success) {
            props.pullJobDetail();
            showToastSuccess('Job Mileage and Job Date Added Successfully ');
        } else {
            showToastError(res.message);
        }
    };

    let checkStatus = jobDetails.isEditableFields;

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
            // actionCol.headerRenderer = () => {
            //     if (checkStatus) {
            //         return (
            //             <Grid container alignItems="center" justify="space-between">
            //                 <Grid item>
            //                     <span style={{ marginBottom: '10px', marginLeft: '5px' }}></span>
            //                 </Grid>
            //                 {/* <Grid item>
            //                     <AddButton toolTipTitle="Add Job Item" onClick={btnHandler} />
            //                     <CustomIconButton
            //                         icon={SearchIcon}
            //                         className="modal-btn-update "
            //                         onClick={addLaburLineHandler}
            //                         toolTipTitle="Add Labour Line"
            //                     />

            //                     {checkhaveAccess?.canAddScheduleItemstoJob && (
            //                         <CustomIconButton
            //                             icon={UpdateIcon}
            //                             className="modal-btn-update "
            //                             onClick={() => createInvoiceHandler('showUpComingEvents')}
            //                             toolTipTitle="Upcoming Events"
            //                         />
            //                     )}
            //                 </Grid> */}
            //             </Grid>
            //         );
            //     } else {
            //         return null;
            //     }
            // };
        }

        if (isEngineer) {
            newColumn.splice(4, 2);
        }

        return newColumn;
    }, [checkStatus]);

    const closeModal = (res) => {
        setState((state) => ({
            ...state,
            open: false,
            jobItemID: null,
            showInvoice: false,
            showSellerInvoice: false,
            showUpComingEvents: false,
            showJobMileageModal: false
        }));
        res && refreshScreen();
    };

    const refreshScreen = () => {
        setState((st) => ({ ...st, refreshDependancy: new Date() }));
        props?.pullJobDetail();
        pullJobItems();
    };

    return (
        <div className="controller-screen">
            {/* {state.showSellerInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Sales Invoice" maxWidth="lg" fullScreen>
                    <SellerInvoiceModal onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )}
            {state.showInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Purchase Invoice" maxWidth="md">
                    <PurchaseInvoice onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )} */}
            {state.open ? (
                <AddLabourModal
                    onClose={closeModal}
                    vehicleID={state.vehicleID}
                    jobItemID={state.jobItemID}
                    Status={jobDetails.jobStatusID}
                    vehicleRegNumber={state.vehicleRegNumber}
                    jobItemJobID={props.scheduleJobID}
                    islabourRateSet={jobDetails.islabourRateSet}
                    labourRate={jobDetails.labourRate}
                    markup={jobDetails.markup}
                    ServiceAreaID={jobDetails.jobServiceAgentID}
                    enggID={jobDetails.jobEngineerUserID}
                    masterWarehouseCode={jobDetails.masterWarehouseCode}
                    checkStatus={state.allowUpdate}
                />
            ) : state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.vehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    onClose={closeCheck}
                    displayCheck={state.selectedRowData.displayCheck}
                    frompage={false}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks
                    checkID={state.selectedRowData.vehicleInspectionID}
                    displayCheck={state.selectedRowData.displayCheck}
                    onClose={closeCheck}
                    frompage={false}
                />
            ) : (
                <div style={{ marginTop: 8 }}>
                    {/* {jobDetails.jobStatusID == 'CA' && (
                        <Grid container spacing={1}>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Purchase Invoice"
                                    onClick={() => createInvoiceHandler('showInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Sales Invoice"
                                    onClick={() => createInvoiceHandler('showSellerInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                        </Grid>
                    )} */}
                    <Grid container item xs={12} spacing={2} alignItems="center">
                        {/* <Grid item xs>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                 
                                    <DatePicker
                                        inputLabel="Job Mileage Date"
                                        isRequired={true}
                                        inputProps={{
                                            max: new Date().toISOString().slice(0, 10)
                                        }}
                                        value={moment(jobDetails.jobMileageDate).format('YYYY-MM-DD')}
                                        name="jobMileageDate"
                                        onChange={valueChanged}
                                        isDisabled={!checkStatus}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <SimpleField
                                        inputLabel="Job Mileage"
                                        type="number"
                                        value={jobDetails.jobMileage}
                                        name="jobMileage"
                                        onChange={valueChanged}
                                        isDisabled={!checkStatus}
                                    />
                                </Grid>
                                {!checkStatus ? null : (
                                    <Grid item xs={12} sm={6} md={2} lg={1}>
                                        <InputLabel shrink>&nbsp;</InputLabel>
                                        <SecondaryButton onClick={handleMileage} disabled={!jobDetails.isEditableFields}>
                                            {' '}
                                            Add
                                        </SecondaryButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid> */}
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Add Job Line`}
                                    icon={AddIcon}
                                    toolTipTitle={`Add Job Line`}
                                    iconFontSize={16}
                                    className="btn-m"
                                    onClick={btnHandler}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Add Standard Line`}
                                    icon={SearchIcon}
                                    toolTipTitle={`Add Standard Line`}
                                    iconFontSize={16}
                                    className="btn-m"
                                    onClick={addLaburLineHandler}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && !jobDetails.vorVehicleID && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                    icon={AddIcon}
                                    toolTipTitle={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                    iconFontSize={16}
                                    className="btn-m"
                                    onClick={() => showVorModal('showVORPopup')}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Set Job Mileage`}
                                    icon={jobDetails.jobMileage ? DoneIcon : AddIcon}
                                    toolTipTitle={`Set Job Mileage`}
                                    iconFontSize={16}
                                    className="btn-m"
                                    onClick={addJobMileageModal}
                                    style={{ background: jobDetails.jobMileage ? 'green' : '', color: jobDetails.jobMileage ? 'white' : '' }}
                                />
                            </Grid>
                        )}

                        {/* <JobItemHeaderDates jobID={jobDetails.jobID} jobDetails={jobDetails} refreshScreen={refreshScreen} /> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
                            <DataGrid
                                columns={columns}
                                rows={state.jobItemsList}
                                isRowHovered={true}
                                rowHeight={50}
                                loadingData={state.loader}
                                height={475}
                                // idFieldName="jobItemID" // as from row 'id' is the property name contains actual id of record
                                // fileUploaderConfigName="JobItem"
                                gridRefresh={pullJobItems}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={5} style={{ paddingRight: '10px' }}>
                            <JobQueriesScreen
                                hideAddQuery={true}
                                VehicleID={props.VehicleID}
                                JobID={props.scheduleJobID}
                                fromPage="job"
                                refreshDependancy={jobDetails.refreshDependancy}
                                isDisabled={checkStatus}
                            />
                        </Grid>
                    </Grid>

                    {jobDetails.showVORPopup ? (
                        <DialogComp
                            title={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                            onClose={() => closeVORModal(false)}
                            maxWidth="lg"
                            overflow="auto"
                        >
                            <AddVor
                                VehicleID={jobDetails.jobVehicleID}
                                update={jobDetails.vorVehicleID}
                                vorVehicleID={jobDetails.vorVehicleID}
                                jobID={jobDetails.jobID}
                                onClose={closeVORModal}
                            />
                        </DialogComp>
                    ) : null}
                    {state.showUpComingEvents ? (
                        <DialogComp title={'Upcoming Events'} onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                            <UpcomingEvents vehicleID={jobDetails.jobVehicleID} fromPage="jobItem" />
                        </DialogComp>
                    ) : null}
                    {state.showJobMileageModal ? (
                        <DialogComp title={'Set Job Mileage'} onClose={() => closeModal(false)} maxWidth="sm" fullWidth>
                            <SetJobMileageScreen
                                jobDetails={jobDetails}
                                fromPage="jobItem"
                                checkStatus={checkStatus}
                                onClose={closeModal}
                                scheduleJobID={props.scheduleJobID}
                            />
                        </DialogComp>
                    ) : null}
                    {state.openLaburLine ? (
                        <DialogComp title={'Select Labour Line'} onClose={() => closeLaburLineHandler(false)} maxWidth="md" fullWidth>
                            <LabourLineTableComp JobID={props.scheduleJobID} onClose={closeLaburLineHandler} />
                        </DialogComp>
                    ) : null}
                    {state.complete ? (
                        <DialogComp title={`Complete Schedule  (${jobDetails.jobRegNumber})`} maxWidth="sm" onClose={() => closeCheck(false)}>
                            <CompleteScheduleModal scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeCheck} />
                        </DialogComp>
                    ) : null}

                    {state.showCancelConfirmModal ? (
                        <DialogComp title={'Confirmation'} onClose={handleOpen} maxWidth="lg">
                            <ConfirmationModal
                                message={'Are you sure you want to delete this job item?'}
                                handleCancel={handleOpen}
                                handleSubmit={handleDeletePart}
                                isAlert={true}
                                alertType={'error'}
                            />
                        </DialogComp>
                    ) : null}
                </div>
            )}
        </div>
    );
}
export default InternalJobItem;
