import { FormHelperText, Grid, Typography, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { formatters } from '../../../../../Core/Controls';
import '../../../../commonStyle.scss';
import '../../controllerStyle.scss';
import { getPastJobByVMRSCategoryNSubCategory } from '../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import DataGrid from '../../../../../Core/Controls/Grid';
import ReactDOM from 'react-dom';

const PastJobs = (props) => {
    const [state, setState] = useState({ pastJobs: [] });

    useEffect(async () => {
        if (props.vehicleID && props.jobId && props.VMRSCategoryID && props.VMRSSubCategoryID) {
            let res = await getPastJobByVMRSCategoryNSubCategory(props.jobId, props.vehicleID, props.VMRSCategoryID, props.VMRSSubCategoryID);
            let data = res.data?.details;
            if (data) {
                setState((st) => ({
                    ...st,
                    pastJobs: data
                }));
            }
        }
    }, []);

    const handleEditClick = (row) => {
        props.history.push({
            pathname: `/controller/jobTabScreen/${row?.jobID}/${props.vehicleID}`,
            state: 'jobs'
        });
    };
    const columns = [
        { key: 'jobDate', name: 'Date', width: 110, formatter: formatters.Date_DDMMMYYYY },
        {
            key: 'orderNo',
            name: 'Job Ref',
            width: 91,
            formatter: (params) => {
                return (
                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                        <a
                            style={{ textDecoration: 'none', color: 'black' }}
                            href={`/controller/jobTabScreen/${params?.row?.jobID}/${props.vehicleID}/1`}
                            target="_blank"
                        >
                            {params.row.orderNo}
                        </a>
                    </div>
                );
            }
        },
        { key: 'labourLine', name: 'Description' },
        {
            key: 'labour',
            name: 'Labour',
            width: 94,
            formatter: formatters.NumberWithPound,
            headerRenderer: formatters.ColumnHeaderAlignRight
        },
        { key: 'parts', name: 'Parts', width: 100, formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight },
        { key: 'agent', name: 'Service Area' }
    ];
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
                <DataGrid enableSearch={true} columns={columns} offset={530} height="calc(100vh)" rows={state.pastJobs} loadingData={state.loader} />
            </Grid>
        </Grid>
    );
};
export default PastJobs;
