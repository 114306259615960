import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleJob, getAllJob } from '../../../../../Core/Services/InternalUser/JOB/jobService';
import DataGrid from '../../../../../Core/Controls/Grid';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { EditButton, BreadCrumbs, formatters } from '../../../../../Core/Controls';
// import JobScreenModal from './JobScreenModal';
import { Link } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import ReactDOM from 'react-dom';
import CustomerDetails from '../../DetailsModal/customerDetails';
import DepotDetails from '../../DetailsModal/depotDetails';
import ServiceAgentDetails from '../../DetailsModal/serviceAgent';

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'jobAgentOrderNo', name: 'Job Ref' },
    { key: 'serviceAgentName', name: 'Service Area' },
    { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depot' },
    { key: 'jobPartsTotal', name: 'Parts', formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'jobLabourTotal', name: 'Labour', formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'jobOrderTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right' }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Unassigned Jobs', active: true }
];

function UnassignedJob(props) {
    const history = useHistory();
    const [state, setState] = useState({
        jobList: [],
        loader: true,
        open: false
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    useEffect(async () => {
        pullJobList();
    }, []);
    const pullJobList = async () => {
        // let res = await getAllJob();
        // let data = res.data.vehicleJobDetails.filter((unassign) => unassign.jobStatusID == 'AU');
        setState((st) => ({
            ...st,
            // jobList: data,
            loader: false
        }));
    };
    const jobHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${props.VehicleID ? props.VehicleID : val?.vehicleID}`,
            search: '',
            state: 'jobs'
        });
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
            </div>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false,
            showDepotModal: false,
            showSaModal: false
        }));
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        let customerNameCol = newColumn.find((element) => element.key === 'customerCompanyName');
        let depotNameCol = newColumn.find((element) => element.key === 'depotName');
        let agentNameCol = newColumn.find((element) => element.key === 'serviceAgentName');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }

        return newColumn;
    });

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullJobList();
    };
    const gridBtnTitle = !props.VehicleID ? '' : 'Add Job';
    const getUrl = 'VehicleJob?jobStatusID=AU';
    return (
        <div className="screen">
            <>
                <BreadCrumbs crumbs={crumbs} />
                {/* <DataGrid
                    columns={columns}
                    rows={state.jobList}
                    isRowHovered={true}
                    enableSearch={true}
                    loadingData={state.loader}
                    btnTitle={gridBtnTitle}
                    onBtnClick={jobHandler}
                    paginate
                    offset={210}
                /> */}
                <GijgoGrid
                    columns={columns}
                    btnTitle={gridBtnTitle}
                    onBtnClick={jobHandler}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={getUrl}
                />
            </>
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.jobDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default UnassignedJob;
