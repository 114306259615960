import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../commonStyle.scss';
import {
    getVehicleBreakdownDropdowns,
    postVehicleBreakdown,
    getVehicleBreakdownsbyID,
    getBreakDownDropDown
} from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import CustomizedSteppers from '../BreackdownDefectStepper/defectBreackDownStepper';
import { formatters } from '../../../../Core/Controls';
import BreakDownEditDetails from './BreakDownEditDetails';
import BreakDownDetails from './BreakDownDetails';
import { FromLoader } from '../../../../Core/Loader/formLoader';

let domainName = window.location.host;
const trimedhost = domainName.includes('localhost') ? 'localhost' : domainName;

const EditBreakdownLink = (props) => {
    const { BreakDownID, VehicleID } = props.match.params;

    const [state, setState] = useState({
        defectID: null,
        breakDownType: 'BR',
        driverName: '',
        driverPhone: null,
        breakDownLocation: '',
        problemReported: '',
        replacementvehicleRequired: false,
        defectPG9Issued: false,
        mobilityUsed: 0,
        mobilityRequested: 0,
        reportingDepot: null,
        callOutRequired: false,
        defectVOR: false,
        policeOnScene: 0,
        passengersInvolved: false,
        accDamage: false,
        vehicleLoaded: false,
        urgentCallOut: false,
        policeOnScene: false,
        mobilityRequested: false,
        mobilityUsed: false,
        vehicleLoadTypesList: [],
        depotsList: [],
        vehicleJobProgress: [],
        errors: {},
        showJob: false,
        loadType: 1,
        showMap: false,
        loadTypeValue: '',
        isRefresh: '',
        isLoader: true,
        defectJobStatusCode: ''
        // showMessage: false
    });

    const pullBreackdownById = async () => {
        let res = await getVehicleBreakdownsbyID(BreakDownID);
        let data = res.data.details[0];

        let vehicleJobProgressDates = res.data.vehicleJobProgressDates;
        if (data.defectID) {
            setState((st) => ({
                ...st,
                defectID: data?.defectID,
                defectType: data?.defectType,
                vehicleID: data?.defectVehicleID,
                breakDownType: data?.defectTypeCode,
                mileage: data?.defectMileage,
                reportingDepot: data?.defectReportingDepotID,
                breakDownLocation: data?.defectLocation,
                reportedPhone: data?.defectContactNumber,
                reportedBy: data?.defectContactName,
                driverName: data?.defectDriverName,
                driverPhone: data?.defectDriverTelephone,
                problemReported: data?.defectDescription,
                callOutRequired: data?.defectCallOutRequired,
                urgentCallOut: data?.defectUrgentCallOut,
                putOnVOR: data?.defectVOR,
                policeOnScene: data?.defectPoliceOnScene,
                passengersInvolved: data?.defectPassengersInvolved,
                numberOfPassenger: data?.defectNumberOfPassengers,
                accDamage: data?.defectAccidentDamage,
                iNSREF: data?.defectInsuranceReference,
                replacementvehicleRequired: data?.defectReplacementVehicle,
                domainName: data?.domainName,
                vehicleLoaded: data?.defectVehicleLoaded,
                loadType: data?.defectVehicleLoadTypeID,
                mobilityRequested: data?.defectMobilityRequested,
                mobilityUsed: data?.defectMobilityUsed,
                PG9Issued: data?.defectPG9Issued,
                defectNumberGenerated: data?.defectNumberGenerated,
                defectResolvedNotes: data?.defectResolvedNotes,
                reportingDepotName: data?.reportingDepot,
                vehicleLoadTypeDescription: data?.vehicleLoadTypeDescription,
                defectRegNumber: data?.defectRegNumber,
                customer: data?.customer,
                reportingDepot: data?.reportingDepot,
                make: data?.make,
                model: data?.model,
                vorVehicleID: data?.vorVehicleID,
                customerID: data?.customerID,
                homeDepotID: data?.homeDepotID,
                defectReportingDepotID: data?.defectReportingDepotID,
                isRecoveryRequired: data?.isRecoveryRequired,
                isChargeble: data?.isChargeble,
                caseNumber: data?.caseNumber,
                etaDate: data?.etaDate,
                defectJobStatusCode: data?.defectJobStatusCode,
                //
                vehicleJobProgress: vehicleJobProgressDates,
                defectDateCreated: formatters.DateTimeFormatter(data?.defectDateCreated),
                defectDateCallOutArrived: formatters.DateTimeFormatter(data?.defectDateCallOutArrived),
                defectDateVehicleRecovered: formatters.DateTimeFormatter(data?.defectDateVehicleRecovered),
                defectDateResolved: formatters.DateTimeFormatter(data?.defectDateResolved),
                defectDateSignOff: formatters.DateTimeFormatter(data?.defectDateSignOff),
                fileCount: data?.fileCount,
                isRefresh: new Date(),
                isLoader: false,
                defectLocation: data.defectLocation,
                defectDateETA: vehicleJobProgressDates[0].defectDateETA
            }));
        } else {
            setState((st) => ({
                ...st,
                isLoader: false,
                showMessage: true
            }));
        }
    };

    useEffect(async () => {
        if (BreakDownID) {
            pullBreackdownById();
        }
        if (VehicleID) {
            let res = await getVehicleBreakdownDropdowns(VehicleID || props.VehicleID);
            setState((st) => ({
                ...st,
                vehicleLoadTypesList: res.data.vehicleLoadTypes,
                loadTypeValue: res.data.vehicleLoadTypes[0],
                depotsList: res.data.depots,
                vehicleID: props.VehicleID || VehicleID,
                domainName: trimedhost
            }));
        } else {
            let res = await getBreakDownDropDown();
            setState((st) => ({
                ...st,
                vehicleLoadTypesList: res.data.vehicleLoadTypes,
                loadTypeValue: res.data.vehicleLoadTypes[0],
                depotsList: res.data.depots,
                domainName: trimedhost
            }));
        }
    }, []);

    return (
        <div className="controller-screen" style={{ marginTop: 15, padding: 20 }}>
            {/* {state.showMessage ? (
                <h1>No data available</h1>
            ) :*/}
            {state.isLoader ? (
                <FromLoader />
            ) : (
                <>
                    <BreakDownDetails
                        data={state}
                        isAddJobBtnShow={props.isAddJobBtnShow}
                        pullBreackdownById={pullBreackdownById}
                        onClose={() => props.onClose()}
                    />
                    <Grid container spacing={1}>
                        {state.vehicleJobProgress?.map((a, index) => (
                            <Grid item xs={12} key={index}>
                                <CustomizedSteppers data={a} userData={state} onStepperSubmit={pullBreackdownById} fromPage={true} />
                            </Grid>
                        ))}

                        <Grid container>
                            <BreakDownEditDetails data={state} onClose={props.onClose} pullBreackdownById={pullBreackdownById} />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default EditBreakdownLink;
