import React, { useState, useEffect, useRef } from 'react';
import { FailButton, InputText, NAButton, PassButton, SecondaryButton, SelectBox, MButton, Accordion, UploadWithBadge } from '../../../../../Core/Controls';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    InputLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    CircularProgress,
    FormHelperText,
    Badge,
    Hidden
} from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SignaturePad from 'react-signature-canvas';

import '../../controllerStyle.scss';
import { getEditDriverChekssById, postvehiclecheckchecksData, PostImage, getImageByID } from '../../../../../Core/Services/InternalUser/Vehicle/driverVehicle';
import moment from 'moment';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../controllerStyle.scss';
import ImageModal from './imageModal';
import _ from 'lodash';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '25px',
        color: '#f50057',
        fontWeight: 600,
        paddingLeft: 9
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    sign: {
        border: '1px solid grey',
        height: 180
    }
}));

const CheckButtons = {
    1: PassButton,
    2: FailButton,
    3: NAButton,
    5: MButton
};

const statusObj = { 1: 'Unreviewed', 2: 'Satisfactory', 3: 'Safety Item Defect', 4: 'Repair Required', 5: 'Monitor' };

const EditDriverDailyChecks = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const { displayName } = useLoginInfo();
    const classes = useStyles();
    const [state, setState] = useState({
        vehicleID: props.VehicleID,
        vehicleDailyCheckCheckTypeID: 1,
        vehicleDailyCheckStatusId: null,
        vehicleDailyCheckDateExpire: null,
        vehicleDailyCheckDriverComment: '',
        vehicleDailyCheckDriverId: null,
        vehicleDailyCheckId: props.chekcsID,
        selectAll: false,
        flag: false,
        vehicleDailyCheckCheckerName: displayName,
        submitArray: []
    });

    useEffect(async () => {
        if (props.chekcsID) {
            let res = await getEditDriverChekssById(props.chekcsID);
            if (res.success) {
                res.data.driverDailyChecks.map((x) => {
                    setState((st) => ({
                        ...st,
                        regNumber: x.vehicleRegNumber,
                        modelID: x.vehicleModel,
                        makeID: x.vehicleMake,
                        vehicleID: x.vehicleID,
                        vehicleDailyCheckId: props.chekcsID,
                        vehicleDailyCheckStatusId: x.vehicleDailyCheckStatusId,
                        vehicleDailyCheckCheckTypeID: x.vehicleDailyCheckCheckTypeID,
                        date: moment(x.vehicleDailyCheckDateStarted).format('YYYY-MM-DD hh:mm'),
                        vehicleDailyCheckDateExpire: x.vehicleDailyCheckDateExpire,
                        vehicleDailyCheckReportedUserID: x.vehicleDailyCheckReportedUserID,
                        vehicleDailyCheckDriverName: x.vehicleDailyCheckDriverName,
                        vehicleDailyCheckDriverId: x.vehicleDailyCheckDriverId,
                        vehicleDailyCheckMileage: x.vehicleDailyCheckMileage,
                        vehicleDailyCheckDriverComment: x.vehicleDailyCheckDriverComment,
                        vehicleDailyCheckCheckerName: x.vehicleDailyCheckCheckerName,
                        driverDailyCheckResultsVM: x.driverDailyCheckResultsVM,
                        imgtechniciansign: x.vehicleDailyCheckCreatorSignature,
                        signImg: x.vehicleDailyCheckCompletedBySignature,
                        vehicleDailyCheckReviewerComment: x.vehicleDailyCheckReviewerComment
                    }));
                });
                const checkStatus = res.data.vehicleDailyCheckResultStatus.map((x) => ({
                    id: x.id,
                    name: x.name
                }));
                const reportedUser = res.data.reportedUser.map((x) => ({
                    id: x.id,
                    name: x.name
                }));
                const checkType = res.data.dailyCheckTypes.map((m) => ({
                    id: m.id,
                    name: m.name
                }));
                const checkQueList = res.data.driverDailyCheckResult;
                const checkOptions = res.data.driverDailyCheckOptions;

                setState((st) => ({
                    ...st,
                    checklist: checkQueList,
                    checkOptions: checkOptions,
                    checkType: checkType,
                    reportedUser: reportedUser,
                    checkStatus: checkStatus
                }));
            }
        }
    }, []);

    const signCanvas = useRef({});

    const clear = () => {
        setState((st) => {
            let newSt = { ...st };
            if (state.flag) {
                newSt.flag = false;
                newSt.vehicleDailyCheckCompletedBySignature = null;
            } else {
                newSt.vehicleDailyCheckCompletedBySignature = null;
                signCanvas.current.clear();
            }

            return newSt;
        });
    };

    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                vehicleDailyCheckCompletedBySignature: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleStatus = (id) => (e) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.vehicleDailyCheckSectionID === id);
        changedArray[objIndex][e.target.name] = e.target.value;
        setState((st) => ({ ...st, checklist: changedArray }));
    };

    const setCommonStatus = (e) => {
        let changedArray = [...state.checklist].map((p) => {
            return {
                ...p,
                vehicleDailyCheckResultsStatusId: e.target.value
            };
        });
        setState((st) => ({ ...st, checklist: changedArray }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};

        let changedArray = [...state.checklist].map((p) => {
            if (p.vehicleDailyCheckResultsStatusId == 1) {
                formIsValid = false;
                return {
                    ...p,
                    statusError: 'Please Select Status'
                };
            } else {
                return {
                    ...p,
                    statusError: ''
                };
            }
        });

        if (!state.vehicleDailyCheckReviewerComment) {
            errors.vehicleDailyCheckReviewerComment = 'Reviewer comment is required';
            formIsValid = false;
        }
        if (!state.signImg) {
            if (!state.vehicleDailyCheckCompletedBySignature) {
                errors.vehicleDailyCheckCompletedBySignature = 'Signature is required';
                formIsValid = false;
            }
        }

        setState((st) => ({
            ...st,
            errors: errors,
            checklist: changedArray
        }));
        return formIsValid;
    };
    const handleNote = (id) => (e) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.vehicleDailyCheckSectionID === id);
        changedArray[objIndex][e.target.name] = e.target.value;
        setState((st) => ({ ...st, checklist: changedArray }));
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, flag: true, vehicleDailyCheckCompletedBySignature: AppStorage.getSignature() }));
    };

    const handleSubmit = async () => {
        let driverDailyCheckResultsVM = state.checklist.map((m) => ({
            vehicleDailyCheckSectionID: m.vehicleDailyCheckSectionID,
            vehicleDailyCheckResultsVehicleDailyCheckOptionId: m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                ? m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                : null,
            vehicleDailyCheckResultsNotes: m.vehicleDailyCheckResultsNotes ? m.vehicleDailyCheckResultsNotes : null,
            vehicleDailyCheckResultsAssesementNotes: m.vehicleDailyCheckResultsAssesementNotes ? m.vehicleDailyCheckResultsAssesementNotes : null,
            vehicleDailyCheckResultsDefectID: m.vehicleDailyCheckResultsDefectID ? m.vehicleDailyCheckResultsDefectID : null,
            vehicleDailyCheckResultsStatusId: m.vehicleDailyCheckResultsStatusId
        }));
        if (validations()) {
            setBtnLoader(true);
            let formData = {
                vehicleID: state.vehicleID,
                vehicleDailyCheckId: state.vehicleDailyCheckId,
                vehicleDailyCheckStatusId: state.vehicleDailyCheckStatusId,
                vehicleDailyCheckCheckTypeID: state.vehicleDailyCheckCheckTypeID,
                vehicleDailyCheckDateStarted: state.date,
                vehicleDailyCheckDateExpire: state.vehicleDailyCheckDateExpire,
                vehicleDailyCheckReportedUserID: state.vehicleDailyCheckReportedUserID,
                vehicleDailyCheckDriverName: state.vehicleDailyCheckDriverName,
                vehicleDailyCheckDriverId: state.vehicleDailyCheckDriverId,
                vehicleDailyCheckMileage: state.vehicleDailyCheckMileage,
                vehicleDailyCheckDriverComment: state.vehicleDailyCheckDriverComment,
                vehicleDailyCheckCheckerName: state.vehicleDailyCheckCheckerName,
                driverDailyCheckResultsVM: driverDailyCheckResultsVM,
                vehicleDailyCheckCreatorSignature: state.imgtechniciansign,
                vehicleDailyCheckCompletedBySignature: state.vehicleDailyCheckCompletedBySignature,
                vehicleDailyCheckReviewerComment: state.vehicleDailyCheckReviewerComment
            };
            let res = await postvehiclecheckchecksData(formData);
            if (res.success) {
                showToastSuccess('Driver Daily checks updated successfully ');
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                props.onClose(false);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };

    //Images Handler .....

    const imageHandler = async (driverDailyCheckResultID, checkDescription) => {
        let res = await getImageByID(driverDailyCheckResultID);
        if (res.data.list.length == 0) {
            let changedArray = [...state.checklist];
            let objIndex = state.checklist.findIndex((x) => x.vehicleDailyCheckResultId === driverDailyCheckResultID);
            setState((st) => ({
                ...st,
                showImageModal: true,
                driverDailyCheckResultID: driverDailyCheckResultID,
                selectedImages: changedArray[objIndex].images || [],
                checkDescription: checkDescription
            }));
        } else {
            setState((st) => ({
                ...st,
                showImageModal: true,
                driverDailyCheckResultID: driverDailyCheckResultID,
                selectedImages: res.data.list.map((k) => k.vehicleDailyCheckPhotoObject),
                checkDescription: checkDescription
            }));
        }
    };

    const onImageChange = (imageList, addUpdateIndex) => {
        let changedArray = [...state.checklist];
        let FilterimagesList;
        let objIndex = state.checklist.findIndex((x) => x.vehicleDailyCheckResultId === state.driverDailyCheckResultID);

        if (`${objIndex}`) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].image = FilterimagesList;
        }
        let uniqueImages = FilterimagesList.filter((g) => {
            let isImage = state.selectedImages.find((w) => w == g);
            if (!isImage) {
                return g;
            }
        });
        setState((st) => ({ ...st, checklist: changedArray, selectedImages: FilterimagesList, submitArray: [...state.submitArray, ...uniqueImages] }));
    };

    const sumbitImage = async () => {
        let tempArray = [...state.submitArray];

        let data = {
            VehicleDailyCheckPhotoID: null,
            VehicleDailyCheckPhotoResultID: state.driverDailyCheckResultID,
            VehicleDailyCheckPhotoObject: tempArray
        };
        let res = await PostImage(data);
        if (res.success) {
            let changedArray = [...state.checklist];
            let objIndex = state.checklist.findIndex((x) => x.vehicleDailyCheckResultId === state.driverDailyCheckResultID);
            changedArray[objIndex].basicCount = changedArray[objIndex].image.length;
            showToastSuccess('image Uploaded successfully');
            setState((st) => ({
                ...st,
                showImageModal: false,
                checklist: changedArray
            }));
        }
    };

    return (
        <div style={{ marginTop: 12 }}>
            {state.checkType ? (
                <Grid container spacing={2}>
                    {/* Disabled fields........... */}
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2} xs={12} direction="row" justify="space-evenly">
                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Reg Number.</InputLabel>
                                    <Typography variant="button"> {state.regNumber}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <InputLabel shrink>Make/Model</InputLabel>
                                    <Typography variant="button">
                                        {state.makeID}&nbsp;
                                        {state.modelID}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Date </InputLabel>
                                    <Typography variant="button">{state.date}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Check Type</InputLabel>
                                    {state.checkType.map((m) => {
                                        if (state.vehicleDailyCheckCheckTypeID === m.id) {
                                            return <Typography variant="button">{m.name}</Typography>;
                                        }
                                    })}
                                </Grid>
                            </Grid>

                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Driver Name</InputLabel>
                                    <Typography variant="button">{state.vehicleDailyCheckDriverName}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <InputLabel shrink>Checker Name</InputLabel>
                                    <Typography variant="button">{state.vehicleDailyCheckCheckerName}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Defect Reported to </InputLabel>
                                    {state.reportedUser.map((x) => {
                                        if (x.id === state.vehicleDailyCheckReportedUserID) {
                                            return <Typography variant="button"> {x.name}</Typography>;
                                        }
                                    })}
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} maxwidth="sm">
                                    <InputLabel shrink>Odometer Reading</InputLabel>
                                    <Typography variant="button">{state.vehicleDailyCheckMileage}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Checks quetion table.......... */}
                    <Grid item xs={12} md={12} style={{ marginTop: 5 }}>
                        <Grid container xs={12} direction="row" justify="space-evenly">
                            <Accordion header={'Driver Daily Checks Result'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" style={{ backgroundColor: '#F5F5F5' }}>
                                            <Grid item xs={12} md={2} style={{ padding: '8px 20px' }}>
                                                Result Legend{' '}
                                            </Grid>
                                            {state.checkOptions.map((m) => {
                                                const Comp = CheckButtons[m.vehicleDailyCheckOptionId];
                                                return (
                                                    <Grid item xs={6} md={2} style={{ padding: '8px 20px' }}>
                                                        <Grid container alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Comp state={true} disabled={true}>
                                                                    <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                </Comp>
                                                            </Grid>
                                                            <Grid item>{m.vehicleDailyCheckOptionDescription}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer
                                            component={Paper}
                                            style={{ minHeight: 100, height: 'auto', maxHeight: 440, border: '1px solid rgba(0,0,0,0.2)' }}
                                            className="custom-scroll"
                                        >
                                            <Table stickyHeader aria-label="sticky table" className={classes.paper}>
                                                <TableHead>
                                                    <TableRow>
                                                        <Hidden only={['md', 'lg', 'xl']}>
                                                            <StyledTableCell width="100%">
                                                                <Grid container justify="space-between">
                                                                    <Grid item>Check</Grid>
                                                                    <Grid item>
                                                                        <select name="statusCode" onChange={setCommonStatus}>
                                                                            {state.checkStatus.map((o) => {
                                                                                return <option value={`${o.id}`}>{o.name}</option>;
                                                                            })}
                                                                        </select>
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </Hidden>
                                                        <Hidden only={['sm', 'xs']}>
                                                            <StyledTableCell width="20%">Check</StyledTableCell>
                                                            <StyledTableCell align="center" width="5%">
                                                                Result
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" width="5%">
                                                                Upload
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left" width="25%">
                                                                Defect Assessment and Rectification
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left" width="15%">
                                                                <Grid container justify="space-between">
                                                                    <Grid item>Status</Grid>
                                                                    <Grid item>
                                                                        <select name="statusCode" onChange={setCommonStatus}>
                                                                            {state.checkStatus.map((o) => {
                                                                                return <option value={`${o.id}`}>{o.name}</option>;
                                                                            })}
                                                                        </select>
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </Hidden>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ height: 500, overflow: 'auto' }}>
                                                    {state.checklist.map((x) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <Hidden only={['md', 'lg', 'xl']}>
                                                                    <StyledTableCell width="100%">
                                                                        <Grid container spacing={2}>
                                                                            <Grid item container spacing={1} justify={'space-between'} xs={12}>
                                                                                <Grid item sm={8}>
                                                                                    {x.dailyCheckSectionDescription}
                                                                                    <FormHelperText error>{x.vehicleDailyCheckResultsNotes}</FormHelperText>
                                                                                </Grid>
                                                                                <Grid item sm={2} style={{ display: 'flex' }}>
                                                                                    {state.checkOptions.map((m) => {
                                                                                        const Comp = CheckButtons[m.vehicleDailyCheckOptionId];
                                                                                        const checks = x.vehicleDailyCheckResultsVehicleDailyCheckOptionId;
                                                                                        if (checks === m.vehicleDailyCheckOptionId) {
                                                                                            return (
                                                                                                <Comp
                                                                                                    state={
                                                                                                        x.vehicleDailyCheckResultsVehicleDailyCheckOptionId ===
                                                                                                        m.vehicleDailyCheckOptionId
                                                                                                    }
                                                                                                    disabled={true}
                                                                                                >
                                                                                                    <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                                                </Comp>
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                    <UploadWithBadge
                                                                                        direction="right"
                                                                                        style={{ marginLeft: 4 }}
                                                                                        onClick={() =>
                                                                                            imageHandler(
                                                                                                x.vehicleDailyCheckResultId,
                                                                                                x.dailyCheckSectionDescription
                                                                                            )
                                                                                        }
                                                                                        count={x.photosUploaded || x.basicCount}
                                                                                        state={x.photosUploaded > 0 || x.basicCount > 0}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <InputLabel shrink> Defect Assessment and Rectification</InputLabel>
                                                                                {state.signImg ? (
                                                                                    <Typography variant="button">
                                                                                        {x.vehicleDailyCheckResultsAssesementNotes || 'N/A'}
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <InputText
                                                                                        placeholder=" Defect Assessment and Rectification"
                                                                                        name="vehicleDailyCheckResultsAssesementNotes"
                                                                                        onChange={handleNote(x.vehicleDailyCheckSectionID)}
                                                                                        value={x.vehicleDailyCheckResultsAssesementNotes}
                                                                                        disabled={state.signImg}
                                                                                        style={{ color: 'black' }}
                                                                                    />
                                                                                )}
                                                                                <InputLabel shrink style={{ marginTop: 5 }}>
                                                                                    {' '}
                                                                                    Status
                                                                                </InputLabel>
                                                                                {state.signImg ? (
                                                                                    <Typography variant="button">
                                                                                        {statusObj[x.vehicleDailyCheckResultsStatusId]}
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <SelectBox
                                                                                        insertEmpty={false}
                                                                                        onChange={handleStatus(x.vehicleDailyCheckSectionID)}
                                                                                        value={x.vehicleDailyCheckResultsStatusId}
                                                                                        name="vehicleDailyCheckResultsStatusId"
                                                                                        List={state.checkStatus}
                                                                                        disabled={state.signImg}
                                                                                    />
                                                                                )}
                                                                                <FormHelperText error>{x.statusError}</FormHelperText>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </StyledTableCell>
                                                                </Hidden>
                                                                <Hidden only={['sm', 'xs']}>
                                                                    <StyledTableCell component="th">
                                                                        {x.dailyCheckSectionDescription}
                                                                        <br />
                                                                        <FormHelperText error>{x.vehicleDailyCheckResultsNotes}</FormHelperText>
                                                                    </StyledTableCell>
                                                                    {state.checkOptions.map((m) => {
                                                                        const Comp = CheckButtons[m.vehicleDailyCheckOptionId];
                                                                        const checks = x.vehicleDailyCheckResultsVehicleDailyCheckOptionId;
                                                                        if (checks === m.vehicleDailyCheckOptionId) {
                                                                            return (
                                                                                <StyledTableCell align="center">
                                                                                    <Comp
                                                                                        state={
                                                                                            x.vehicleDailyCheckResultsVehicleDailyCheckOptionId ===
                                                                                            m.vehicleDailyCheckOptionId
                                                                                        }
                                                                                        disabled={true}
                                                                                    >
                                                                                        <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                                    </Comp>
                                                                                </StyledTableCell>
                                                                            );
                                                                        }
                                                                    })}
                                                                    <StyledTableCell align="center">
                                                                        <UploadWithBadge
                                                                            onClick={() =>
                                                                                imageHandler(x.vehicleDailyCheckResultId, x.dailyCheckSectionDescription)
                                                                            }
                                                                            count={x.photosUploaded || x.basicCount}
                                                                            state={x.photosUploaded > 0 || x.basicCount > 0}
                                                                        />
                                                                    </StyledTableCell>

                                                                    <StyledTableCell align="left">
                                                                        <InputText
                                                                            placeholder=" Defect Assessment and Rectification"
                                                                            name="vehicleDailyCheckResultsAssesementNotes"
                                                                            onChange={handleNote(x.vehicleDailyCheckSectionID)}
                                                                            value={x.vehicleDailyCheckResultsAssesementNotes}
                                                                            disabled={state.signImg}
                                                                        />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <SelectBox
                                                                            insertEmpty={false}
                                                                            onChange={handleStatus(x.vehicleDailyCheckSectionID)}
                                                                            value={x.vehicleDailyCheckResultsStatusId}
                                                                            name="vehicleDailyCheckResultsStatusId"
                                                                            List={state.checkStatus}
                                                                            disabled={state.signImg}
                                                                        />
                                                                        <FormHelperText error>{x.statusError}</FormHelperText>
                                                                    </StyledTableCell>
                                                                </Hidden>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Accordion>
                        </Grid>
                    </Grid>
                    {/*driver details...................*/}
                    <Grid item xs={12} style={{ marginTop: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="mandatory-fields">
                                <InputLabel shrink> Reviewer comments/notes</InputLabel>
                                <InputText
                                    multiline
                                    rows={3}
                                    placeholder="Reviewer comments/notes"
                                    name="vehicleDailyCheckReviewerComment"
                                    onChange={handleInputs}
                                    disabled={state.signImg}
                                    value={state.vehicleDailyCheckReviewerComment}
                                />
                                <FormHelperText error>{state.errors?.vehicleDailyCheckReviewerComment}</FormHelperText>
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={12} md={6} style={{ marginTop: 6 }}>
                                <div>
                                    <div style={{ position: 'relative' }}>
                                        <InputLabel shrink>Driver/Checker Signature </InputLabel>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 0
                                            }}
                                        ></div>
                                        {state.imgtechniciansign ? (
                                            <div className={classes.sign}>
                                                <img src={state.imgtechniciansign} alt="my signature" className="sigPad" />
                                            </div>
                                        ) : (
                                            <div className={classes.sign}></div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mandatory-fields">
                                <div>
                                    <div style={{ position: 'relative' }}>
                                        <InputLabel shrink>
                                            Signature
                                            {!state.signImg ? (
                                                <Tooltip title="Use default signature">
                                                    <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                </Tooltip>
                                            ) : null}
                                        </InputLabel>

                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 0
                                            }}
                                        >
                                            {!state.signImg ? (
                                                <Tooltip title="Clear Signature">
                                                    <DeleteIcon
                                                        onClick={clear}
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginTop: 5
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                        {state.signImg ? (
                                            <div className={classes.sign}>
                                                <img src={state.signImg} alt="my signature" className="sigPad" />
                                            </div>
                                        ) : state.flag ? (
                                            <div className={classes.sign}>
                                                <img src={state.vehicleDailyCheckCompletedBySignature} alt="my signature" className="sigPad" />
                                            </div>
                                        ) : (
                                            <div className={classes.sign}>
                                                <SignaturePad
                                                    ref={signCanvas}
                                                    canvasProps={{ className: 'sigPad' }}
                                                    onEnd={() => {
                                                        GetTechnicianSign();
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <FormHelperText error>{state.errors?.vehicleDailyCheckCompletedBySignature}</FormHelperText>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    {state.signImg ? null : (
                        <Grid item xs={12} sm={6} style={{ marginTop: 5 }}>
                            <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                                sign off
                            </SecondaryButton>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={state.signImg ? 12 : 6} style={{ marginTop: 5 }}>
                        <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            ) : (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            )}
            {state.showImageModal ? (
                // <DialogComp title="Upload Image" onClose={() => setState((st) => ({ ...st, show: false }))} maxWidth="lg">
                <ImageModal
                    images={state.selectedImages}
                    onImageChange={onImageChange}
                    submitImage={sumbitImage}
                    disabled={state.signImg}
                    inspectionDesc={state.checkDescription}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showImageModal: false,
                            selectedImages: [],
                            driverDailyCheckResultID: null,
                            checkDescription: '',
                            submitArray: []
                        }))
                    }
                    maxNumber={20}
                />
            ) : // </DialogComp>
            null}
        </div>
    );
};

export default EditDriverDailyChecks;
