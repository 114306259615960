import { createContext, useEffect, useState } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { emailValidation } from '../../../Core/Basic/StaticService';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import {
    addNewEnquiry,
    getEnquiryScreenDropdowns,
    getEnquiryById,
    getEnquiryNotes
} from '../../../Core/Services/InternalUser/ControllerService/EnquiriService';
export const EnquiriesScreenContext = createContext();

export const EnquiriesProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    let history = useHistory();

    const [state, setState] = useState({
        errors: {},
        leadID: null,
        leadCompanyName: '',
        leadMainContactEmail: '',
        leadFleetSize: null,
        leadAdditionalInformation: '',
        leadAddress1: '',
        leadAddress2: '',
        leadMainContactPhone: '',
        leadMainContactName: '',
        leadPostcode: '',
        leadCounty: '',
        leadCountryCode: '',
        leadCity: '',
        leadReferrerName: '',
        leadReferrerEmail: '',
        leadStatusID: null,
        branchName: '',
        notesList: [],
        typeOfVehicleOptionId: null,
        leadLostReasonID: null,
        leadDateCreated: null,
        leadOutcomeDate: null
    });

    const validations = () => {
        const { leadMainContactEmail, leadMainContactPhone, leadCompanyName, leadReferrerName, leadMainContactName } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(leadReferrerName)) {
            errors.leadReferrerName = 'Name is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(leadCompanyName)) {
            errors.leadCompanyName = 'Company Name is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(leadMainContactName)) {
            errors.leadMainContactName = 'Contact Name is required';
            formIsValid = false;
        }
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!emailValidation(leadMainContactEmail) && /[^\s]/.test(leadMainContactEmail) && !isNull(leadMainContactEmail)) {
            errors.leadMainContactEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!phoneReg.test(leadMainContactPhone)) {
            errors.leadMainContactPhone = 'Invalid Phone Number';
            formIsValid = false;
        }
        if (!tempOptions.leadReferrerBranchID) {
            errors.leadReferrerBranchID = 'Branch is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const [optionsState, setOptionState] = useState({
        leadTypeOfBusinessList: [],
        branchList: [],
        typeOfVehicleOption: [],
        leadLostReasonsList: [],
        leadStatusList: []
    });

    const [tempOptions, setTempOptions] = useState({
        leadTypeOfBusiness: null,
        leadReferrerBranchID: null,
        typeOfVehicleOption: [],
        typeOfVehicleValue: []
    });

    useEffect(async () => {
        let res = await getEnquiryScreenDropdowns();
        const typeOfvehicle = res?.data?.leadVehicleTypes.map((m) => ({ id: m.id, name: m.id1 }));
        setOptionState((st) => ({
            ...st,
            leadTypeOfBusinessList: res?.data?.businessTypes,
            branchList: res?.data?.branches,
            leadLostReasonsList: res?.data?.leadLostReasons,
            leadStatusList: res?.data?.leadStatus,
            typeOfVehicleOption: typeOfvehicle
        }));
        pullEnquiryByID();
        pullNotes();
    }, []);

    const ID = props?.match?.params?.id;
    const pullEnquiryByID = async () => {
        if (ID !== '0') {
            let res = await getEnquiryById(ID);
            const data = res?.data?.details[0];
            setState((st) => ({
                ...st,
                branchName: data?.branchName,
                leadLiveDate: data?.leadLiveDate,
                leadAdditionalInformation: data?.leadAdditionalInformation,
                leadAddress1: data?.leadAddress1,
                leadAddress2: data?.leadAddress2,
                leadCity: data?.leadCity,
                leadCompanyName: data?.leadCompanyName,
                leadDateCreated: data?.leadDateCreated,
                leadCountryCode: data?.leadCountryCode,
                leadCounty: data?.leadCounty,
                leadFleetSize: data?.leadFleetSize,
                leadStatusID: data?.leadStatusID,
                leadReferrerBranchID: data?.leadReferrerBranchID,
                leadReferrerEmail: data?.leadReferrerEmail,
                leadReferrerName: data?.leadReferrerName,
                leadLostReasonID: data?.leadLostReasonID,
                leadMainContactEmail: data?.leadMainContactEmail,
                leadMainContactName: data?.leadMainContactName,
                leadMainContactPhone: data?.leadMainContactPhone,
                leadPostcode: data?.leadPostcode,
                leadID: data?.leadID,
                leadTypeOfBusiness: data?.leadTypeOfBusiness,
                leadOutcomeDate: data?.leadOutcomeDate,
                typeOfVehicleOption: res.data?.leadSelectedVehicleTypes.map((m) => m.leadSelectedVehicleTypeID),
                leadSelectedVehicleTypes: res.data?.leadSelectedVehicleTypes || []
            }));
        }
    };

    useEffect(() => {
        let newSt = {};
        if (state.leadTypeOfBusiness) {
            newSt.leadTypeOfBusiness = optionsState.leadTypeOfBusinessList.find(({ id }) => id === state.leadTypeOfBusiness);
        }
        if (state.leadReferrerBranchID) {
            newSt.leadReferrerBranchID = optionsState.branchList.find(({ id }) => id === state.leadReferrerBranchID);
        }
        if (state.typeOfVehicleOption) {
            newSt.typeOfVehicleValue = optionsState.typeOfVehicleOption.filter((w) => state.typeOfVehicleOption.indexOf(w.id) > -1);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [optionsState, state.leadTypeOfBusiness, state.leadReferrerBranchID, state.typeOfVehicleOption]);

    const pullNotes = async () => {
        if (ID !== '0') {
            let res = await getEnquiryNotes(ID);
            setState((st) => ({ ...st, notesList: res?.data?.list }));
        }
    };

    const typeOfVehicleChange = (e, value) => {
        setTempOptions((st) => ({ ...st, typeOfVehicleValue: value }));
        setState((st) => {
            const nst = { ...st, typeOfVehicleOptionId: value.map((c) => c.id) };
            return nst;
        });
    };

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const handleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let data = {
                leadID: state.leadID,
                leadCompanyName: state.leadCompanyName,
                leadMainContactEmail: state.leadMainContactEmail,
                leadFleetSize: state.leadFleetSize,
                leadAdditionalInformation: state.leadAdditionalInformation,
                leadCountryCode: state.leadCountryCode,
                leadTypeOfBusiness: tempOptions.leadTypeOfBusiness?.id || null,
                leadMainContactPhone: state.leadMainContactPhone,
                leadMainContactName: state.leadMainContactName,
                leadAddress2: state.leadAddress2,
                leadAddress1: state.leadAddress1,
                leadPostcode: state.leadPostcode,
                leadCounty: state.leadCounty,
                leadCity: state.leadCity,
                leadReferrerName: state.leadReferrerName,
                leadReferrerEmail: state.leadReferrerEmail,
                leadReferrerBranchID: tempOptions.leadReferrerBranchID?.id || null,
                leadSelectedVehicleTypesVM:
                    tempOptions.typeOfVehicleValue?.map((m) => ({
                        id: m.id
                    })) || []
            };
            let res = await addNewEnquiry(data);
            if (res.success) {
                showToastSuccess(res.message || 'Data saved successfully');
                history.goBack();
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };

    const GotoEditMode = () => {
        history.push(`/Enquiries/add/${state.leadID}`);
    };

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            leadAddress1: s.addressLine1,
            leadAddress2: s.addressLine2,
            leadCounty: s.county,
            leadCity: s.town,
            leadPostcode: s.postCode
        }));
    };

    return (
        <EnquiriesScreenContext.Provider
            value={{
                state,
                btnLoader,
                handleSubmit,
                inputChange,
                singleSelectChange,
                optionsState,
                tempOptions,
                setVal,
                typeOfVehicleChange,
                GotoEditMode,
                pullEnquiryByID,
                pullNotes
            }}
        >
            {props.children}
        </EnquiriesScreenContext.Provider>
    );
};
