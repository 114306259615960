import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { ContractSetDeliveryDate, ContractsStepper_DropDown } from '../../../../../Core/Services/InternalUser/InternalDashService';

import moment from 'moment';

const ContractSetDeliveryDateModal = (props) => {
    console.log(props, 'props');
    const [state, setState] = useState({
        errors: {},
        contractDeliveryDate: '', //moment().format('YYYY-MM-DD')
        contractDeliveryCharge: null
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await ContractsStepper_DropDown();
        setState((st) => ({
            ...st,
            companies: res.data.companies
        }));
    }, []);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { contractDeliveryDate, contractDeliveryCharge, transportCompanyID } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractDeliveryDate) {
            errors.contractDeliveryDate = 'Date is required';
            formIsValid = false;
        }
        // if (!contractDeliveryCharge) {
        //     errors.contractDeliveryCharge = 'Delivery Charge is required';
        //     formIsValid = false;
        // }
        if (!transportCompanyID?.id) {
            errors.transportCompanyID = 'Company is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        console.log(state);
        if (validations()) {
            setBtnLoader(true);
            let promises = props.checkedVehicleList.map((q) => {
                return ContractSetDeliveryDate({
                    contractVehicleID: q,
                    contractDeliveryDate: state.contractDeliveryDate,
                    // contractDeliveryCharge: state.contractDeliveryCharge,
                    transportCompanyID: state.transportCompanyID?.id
                });
            });

            try {
                let responses = await Promise.all(promises);

                // Check if all responses are successful
                let allSuccessful = responses.every((response) => response.success);

                if (allSuccessful) {
                    showToastSuccess('Vehicle delivery details saved successfully.');
                    props.onClose(true);
                } else {
                    // Find out which responses failed
                    let failedResponses = responses.filter((response) => !response.success);
                    showToastError(`Error: ${failedResponses.length} items failed.`);
                }
            } catch (error) {
                // Handle any other errors that may occur
                showToastError(`An error occurred: ${error.message}`);
            }
            setBtnLoader(false);
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Delivery Date</InputLabel>
                <DatePicker name="contractDeliveryDate" placeholder="Delivery Date" value={state.contractDeliveryDate || ''} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.contractDeliveryDate}</FormHelperText>
            </Grid>
            {/* <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Delivery Charge</InputLabel>
                <InputText
                    placeholder="Delivery Charge"
                    value={state.contractDeliveryCharge}
                    onChange={onFieldChange}
                    name="contractDeliveryCharge"
                    type="number"
                />
                <FormHelperText error>{state.errors.contractDeliveryCharge}</FormHelperText>
            </Grid> */}
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Transport Company</InputLabel>
                <SingleSelect options={state.companies} value={state.transportCompanyID} onChange={singleSelectChange('transportCompanyID')} />
                <FormHelperText error>{state.errors.transportCompanyID}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetDeliveryDateModal;
