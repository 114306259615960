import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid, EditButton, formatters } from '../../../../../Core/Controls';
import { getUpcomingEvents, mergeJobToMaster } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import moment from 'moment';

const JobSingleNote = ({ note }) => {
    const { scheduleName, bookingStatus, dateDue } = note;

    return (
        <>
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic', cursor: 'pointer', padding: '5px 10px' }}>
                    {`${scheduleName} (${bookingStatus}) on ${moment(dateDue).format('DD-MMM-YYYY')}` || 'N/A'}
                </div>
            </div>
        </>
    );
};

const UpcomingEvents = (props) => {
    // const dataFormatter = (data, row) => {
    //     return (
    //         <Grid container justify="center">
    //             <CustomIconButton icon={MergeTypeIcon} toolTipTitle="Add to the current job" forceEnabled={true} onClick={() => jobMerge(data.row)} />
    //         </Grid>
    //     );
    // };

    // const columns = [
    //     { key: 'dateDue', name: 'Date', formatter: formatters.Date_DDMMMYYYY, width: 130 },
    //     { key: 'scheduleName', name: 'Event Name' }

    //     // {
    //     //     key: 'scheduleID',
    //     //     name: 'Action',
    //     //     formatter: dataFormatter,
    //     //     width: 20
    //     // }
    // ];

    // if (props.fromPage !== 'jobItem') {
    //     columns.splice(2, 0, { key: 'bookingStatus', name: 'Status', width: 250 });
    // }

    const { showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        showLoader: true,
        upcomingEventsList: []
    });

    // const jobMerge = async (jobData) => {
    //     let data = {
    //         scheduleID: jobData.scheduleID,
    //         jobID: props.jobID
    //     };
    //     let res = await mergeJobToMaster(data);
    //     if (res.success) {
    //         showToastSuccess('Successfully added to the job');
    //         pullUpcomingEventsAndUpdateState();
    //     }
    //     if (!res.success) {
    //         showToastError(res.message);
    //     }
    // };

    const pullUpcomingEventsAndUpdateState = async () => {
        let res = await getUpcomingEvents(props.vehicleID);
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, upcomingEventsList: res.data.vehicleUncompletedSchedulesList }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullUpcomingEventsAndUpdateState();
    }, []);

    return (
        <div>
            {state.upcomingEventsList?.map((n, i) => (
                <div style={{ background: i % 2 ? '#F6EEEC' : '#ECF4F6', padding: '5px 10px' }}>
                    <JobSingleNote key={i} note={n} />
                </div>
            ))}
            {/* <DataGrid rows={state.upcomingEventsList} columns={columns} loadingData={state.showLoader} height={props.height || 300} /> */}
        </div>
    );
};
export default UpcomingEvents;
