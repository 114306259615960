import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getScheduleVehicleById = async (Id, custId, depotId, year, serviceAgentID, engineerUserID, scheduleEventID) => {
    let res = await getWithAuth(
        `Schedules?vehicleId=${Id}?CustomerID=${custId || ''}&DepotID=${depotId || ''}&Year=${year || ''}&ServiceAgentID=${
            serviceAgentID || ''
        }&EngineerUserID=${engineerUserID}&ScheduleEventID=${scheduleEventID}`
    );
    return res;
};

export const getScheduleByStatus = async (Id) => {
    let res = await getWithAuth(`Schedules?vehicleId=&status=${Id}`);
    return res;
};

export const getSchedulesById = async (Id) => {
    let res = await getWithAuth(`Schedules/${Id}`);
    return res;
};

export const getAllScheduleEvents = async (Id) => {
    let res = await getWithAuth(`ScheduleEvents`);
    return res;
};

export const getAllScheduleDropdowns = async (Id) => {
    let res = await getWithAuth(`Schedules/ScheduleAddEditDropdowns?VehicleID=${Id}`);
    return res;
};

export const postSchedule = async (data) => {
    let res = await postWithAuth('Schedules', data);
    return res;
};

export const getAllDropDowns = async (Id) => {
    let res = await getWithAuth(`Schedules/ScheduleBookingscreenDropdowns`);
    return res;
};

export const getAllNotesById = async (Id) => {
    let res = await getWithAuth(`ScheduleNote?ScheduleNoteScheduleID=${Id}`);
    return res;
};

export const postScheduleNotes = async (data) => {
    let res = await postWithAuth('ScheduleNote', data);
    return res;
};

export const getScheduleBookingSectionById = async (Id) => {
    let res = await getWithAuth(`Schedules/JobBookingDetail/${Id}`);
    return res;
};

export const postScheduleBookingSection = async (data) => {
    let res = await postWithAuth('Schedules/UpdateScheduleBooking', data);
    return res;
};

export const getScheduleRequestBookingById = async (Id) => {
    let res = await getWithAuth(`Schedules/RequestBookingDetail/${Id}`);
    return res;
};

export const postScheduleRequestBooking = async (data) => {
    let res = await postWithAuth('Schedules/UpdateScheduleBookingRequest', data);
    return res;
};

export const getCompleteScheduleDropdowns = async () => {
    let res = await getWithAuth(`Schedules/ScheduleCompleteDropdowns`);
    return res;
};

export const postCompletedSchedule = async (data) => {
    let res = await postWithAuth('Schedules/ScheduleComplete', data);
    return res;
};

export const deleteFutureUnCompletedSchedules = async (data) => {
    let res = await postWithAuth('Schedules/DeleteFutureUnCompletedSchedules', data);
    return res;
};

export const postScheduleReceived = async (data) => {
    let res = await postWithAuth('Schedules/ScheduleDoc_SetReceived', data);
    return res;
};
export const postScheduleChangeBookingDate = async (data) => {
    let res = await postWithAuth('Schedules/Schedule_ChangeBookingDate', data);
    return res;
};
