import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import { SecondaryButton, MandatoryField, DateTimePicker, SimpleField, SelectBox, SecondaryCheckbox } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { FuelPurchaseProvider, FuelPurchaseScreenContext } from './FuelPurchaseProvider';
import { AppStorage } from '../../../../Core/Basic/storage-service';

function FuelPurchaseModal(props) {
    const { state, btnLoader, handleSubmit, inputChange, handleCheckbox, dateFieldsChange } = useContext(FuelPurchaseScreenContext);
    let checkhaveAccess = AppStorage.getAllowAcces();
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Purchase Date
                </InputLabel>
                <DateTimePicker placeholder="purchaseDate" value={state.purchaseDate} onChange={inputChange} name="purchaseDate" />
                <FormHelperText error>{state.errors.purchaseDate}</FormHelperText>
            </Grid>
            <Grid item xs={3} className="mandatory-fields">
                <MandatoryField type="number" inputLabel="Litres" name="liters" placeholder="Litres" onChange={inputChange} value={state.liters} />
                <FormHelperText error>{state.errors.liters}</FormHelperText>
            </Grid>
            <Grid item xs={3} className="mandatory-fields">
                <MandatoryField type="number" inputLabel="Cost" name="cost" placeholder="Cost" onChange={inputChange} value={state.cost} />
                <FormHelperText error>{state.errors.cost}</FormHelperText>
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Payment Method
                </InputLabel>
                <SelectBox name="paymentMethod" value={state.paymentMethod} onChange={inputChange} List={state.paymentMethodList} />
                <FormHelperText error>{state.errors.paymentMethod}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <SimpleField type="number" inputLabel="Mileage" name="mileage" placeholder="Mileage" onChange={inputChange} value={state.mileage} />
            </Grid>
            <Grid item xs={12}>
                <MandatoryField multiline rows={3} inputLabel="Notes" name="notes" placeholder="Notes" onChange={inputChange} value={state.notes} />
                <FormHelperText error>{state.errors.notes}</FormHelperText>
            </Grid>
            {props.fuelID && (
                <Grid item xs={6} sm={6} className="checkbox-font" mt={5}>
                    <SecondaryCheckbox checked={state.fuelPurchaseActive} name="fuelPurchaseActive" onChange={handleCheckbox} label="Is Active ?" />
                </Grid>
            )}
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} disabled={!checkhaveAccess?.CanUpdateVehicleFuelRecords}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}
const Screen = (props) => {
    return (
        <FuelPurchaseProvider {...props}>
            <FuelPurchaseModal {...props} />
        </FuelPurchaseProvider>
    );
};
export default Screen;
