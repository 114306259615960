import React, { useState, useEffect, useMemo } from 'react';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import JobScreenModal from '../Controllers/JOBS/JobScreenModal';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import JobQueryModal from '../Controllers/JOBS/JobStatusBtn/JobQuery';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ReactDOM from 'react-dom';
import DepotDetails from '../Controllers/DetailsModal/depotDetails';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails';
import ServiceAgentDetails from '../Controllers/DetailsModal/serviceAgent';
import { AppStorage } from '../../../Core/Basic/storage-service';

const base_columns = [
    // { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
    { key: 'orderNo', name: 'Order No', width: 140 },
    { key: 'agentName', name: 'Service Area', width: 150 },
    { key: 'depotName', name: 'Depot', width: 250 },
    {
        key: 'partsTotal',
        name: 'Parts',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    },
    {
        key: 'labourTotal',
        name: 'Labour',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    },
    {
        key: 'orderTotal',
        name: 'Total',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Deleted Records', active: true },
    { name: 'Deleted Jobs', active: true }
];

function DeletedJobs(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();

    const history = useHistory();
    let getUrl = `VehicleJob/DeletedJob`;
    const [state, setState] = useState({
        jobList: [],
        loader: true,
        open: false
    });
    useEffect(async () => {
        pullJobList();
    }, []);

    const pullJobList = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const jobHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: '',
            state: 'jobs'
        });
    };

    const handleShowQueries = (val) => {
        setState((state) => ({ ...state, showJobQuery: true, jobID: val?.jobID }));
    };

    const closeTheDialog = (val) => {
        setState((state) => ({ ...state, showJobQuery: false, jobID: '' }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={SpeakerNotesIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Show Queries"
                    onClick={() => handleShowQueries(row)}
                />
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
            </div>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.cn}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        let agentNameCol = newColumn.find((element) => element.key === 'agentName');
        let depotNameCol = newColumn.find((element) => element.key === 'depotName');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        newColumn.splice(0, 0, { key: 'RegNo', name: 'Reg No', width: 90, formatter: renderRegNumberEdit });

        newColumn.splice(5, 0, { key: 'cn', name: 'Customer', width: 270, formatter: renderCustomerName });

        return newColumn;
    }, []);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullJobList();
    };
    const gridBtnTitle = 'Add Job';
    return (
        <div className={'screen' + ' controller-screen'} style={{ marginTop: 8 }}>
            {state.open && (
                <DialogComp open={state.open} onClose={() => closeModal(false)} title="Add Job" maxWidth="md">
                    <JobScreenModal VehicleID={props.VehicleID} onClose={() => closeModal(true)} />
                </DialogComp>
            )}
            <BreadCrumbs crumbs={crumbs} />
            {state.showJobQuery && (
                <DialogComp title="Job Queries" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobQueryModal title="" jobQueryJobID={state.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            <GijgoGrid
                columns={columns}
                btnTitle={gridBtnTitle}
                onBtnClick={jobHandler}
                idFieldName={`${checkhaveAccess?.CanUploadJobDocuments ? 'jobID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanUploadJobDocuments ? 'JobScreen' : null}`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={getUrl}
            />
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.jobDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default DeletedJobs;
