import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { SecondaryButton, MandatoryField } from '../../../../Core/Controls';
import { getVehicleTypesById, postVehicleTypesData } from '../../../../Core/Services/InternalUser/vehicleTypeService';
import { SecondaryCheckbox, SelectBox } from '../../../../Core/Controls';
import { getAllVehicleCategories } from '../../../../Core/Services/InternalUser/vehicleCategoryService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function VehicleTypeModal(props) {
    const [state, setState] = useState({
        vehicleTypeID: null,
        vehicleTypeDescription: '',
        vehicleTypeCategoryID: '',
        vehicleTypeList: [],
        vehicleTypeActive: true,
        VehicleTypeRFLApplicable: true,
        VehicleTypeInsuranceApplicable: true,
        VehicleTypeMOTApplicable: true,
        VehicleTypeFuelCardApplicable: true,
        vehicleTypeMileageApplicable: false,
        errors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.vehicleTypeID) {
            let res = await getVehicleTypesById(props.vehicleTypeID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    vehicleTypeID: res.data.vehicleTypeID,
                    vehicleTypeDescription: res.data.vehicleTypeDescription,
                    vehicleTypeCategoryID: res.data.vehicleTypeCategoryID,
                    vehicleTypeActive: res.data.vehicleTypeActive,
                    VehicleTypeRFLApplicable: res.data.vehicleTypeRFLApplicable,
                    VehicleTypeInsuranceApplicable: res.data.vehicleTypeInsuranceApplicable,
                    VehicleTypeMOTApplicable: res.data.vehicleTypeMOTApplicable,
                    VehicleTypeFuelCardApplicable: res.data.vehicleTypeFuelCardApplicable,
                    vehicleTypeMileageApplicable: res.data.vehicleTypeMileageApplicable
                }));
            }
        }
        let vehicleCategory = await getAllVehicleCategories();
        if (vehicleCategory.success) {
            const vehiclename = vehicleCategory.data?.list?.map((item) => ({
                id: item.vehicleCategoryID,
                name: item.vehicleCategoryDescription
            }));
            setState((st) => ({
                ...st,
                vehicleTypeList: vehiclename
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { vehicleTypeDescription, vehicleTypeCategoryID } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(vehicleTypeDescription)) {
            errors.vehicleTypeDescription = 'Vehicle Type Description is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(vehicleTypeCategoryID)) {
            errors.vehicleTypeCategoryID = 'Vehicle Category is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const vehicleData = {
                vehicleTypeID: state.vehicleTypeID,
                vehicleTypeDescription: state.vehicleTypeDescription,
                vehicleTypeCategoryID: state.vehicleTypeCategoryID,
                vehicleTypeActive: state.vehicleTypeActive,
                VehicleTypeRFLApplicable: state.VehicleTypeRFLApplicable,
                VehicleTypeInsuranceApplicable: state.VehicleTypeInsuranceApplicable,
                VehicleTypeMOTApplicable: state.VehicleTypeMOTApplicable,
                VehicleTypeFuelCardApplicable: state.VehicleTypeFuelCardApplicable,
                vehicleTypeMileageApplicable: state.vehicleTypeMileageApplicable
            };
            let res = await postVehicleTypesData(vehicleData);
            if (res.success) {
                showToastSuccess('VehicleType Updated sucessfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Tittle"
                    name="vehicleTypeDescription"
                    placeholder="Title"
                    onChange={handleInputChange}
                    value={state.vehicleTypeDescription}
                    errors={state.errors.vehicleTypeDescription}
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Vehicle Category
                </InputLabel>
                <SelectBox name="vehicleTypeCategoryID" value={state.vehicleTypeCategoryID || ' '} onChange={handleInputChange} List={state.vehicleTypeList} />
                <FormHelperText error>{state.errors.vehicleTypeCategoryID}</FormHelperText>
            </Grid>

            <Grid item xs={6} md={4}>
                <SecondaryCheckbox checked={state.VehicleTypeRFLApplicable} name="VehicleTypeRFLApplicable" onChange={handleCheckbox} label="RFL Applicable?" />
            </Grid>
            <Grid item xs={6} md={4}>
                <SecondaryCheckbox
                    checked={state.VehicleTypeInsuranceApplicable}
                    name="VehicleTypeInsuranceApplicable"
                    onChange={handleCheckbox}
                    label="Insurance Applicable?"
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <SecondaryCheckbox checked={state.VehicleTypeMOTApplicable} name="VehicleTypeMOTApplicable" onChange={handleCheckbox} label="MOT Applicable?" />
            </Grid>
            <Grid item xs={6} md={4}>
                <SecondaryCheckbox
                    checked={state.VehicleTypeFuelCardApplicable}
                    name="VehicleTypeFuelCardApplicable"
                    onChange={handleCheckbox}
                    label="Fuel Card Applicable?"
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <SecondaryCheckbox
                    checked={state.vehicleTypeMileageApplicable}
                    name="vehicleTypeMileageApplicable"
                    onChange={handleCheckbox}
                    label="Mileage Applicable?"
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <SecondaryCheckbox checked={state.vehicleTypeActive} name="vehicleTypeActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.vehicleTypeID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default VehicleTypeModal;
