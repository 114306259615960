import { FormHelperText, Grid, Typography } from '@material-ui/core';
import React from 'react';

const JobDetailsInfo = (props) => {
    const { jobDetails } = props;

    return (
        <div className="controller-screen">
            <Grid container spacing={0} className="mt_10">
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} style={{ padding: '16px' }}>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Service Area</FormHelperText>
                            <Typography variant="button">{jobDetails?.serviceAgentName}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Job Depot</FormHelperText>
                            <Typography variant="button">{jobDetails.depotName}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Customer Order No</FormHelperText>
                            <Typography variant="button">{jobDetails.jobCustomerOrderNo}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText>Estimated Cost</FormHelperText>
                            <Typography variant="button">{jobDetails.jobEstimatedCost}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Proforma Invoice Net</FormHelperText>
                            <Typography variant="button">{jobDetails.jobProformaInvNet}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Order Limit</FormHelperText>
                            <Typography variant="button">{jobDetails.jobLimit}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Authority Reference</FormHelperText>
                            <Typography variant="button">{jobDetails.jobAuthorityRef}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Mileage Date</FormHelperText>
                            <Typography variant="button">{jobDetails.jobMileageDate}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Mileage</FormHelperText>
                            <Typography variant="button">{jobDetails.jobMileage}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Approved By</FormHelperText>
                            <Typography variant="button">{jobDetails.approvedBy}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Approved Date</FormHelperText>
                            <Typography variant="button">{jobDetails.jobApprovedDate}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormHelperText shrink>Approved Order No</FormHelperText>
                            <Typography variant="button">{jobDetails.jobApprovedOrderNo}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default JobDetailsInfo;
