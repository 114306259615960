import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllNotes = async (frompage, VehicleID = 0, DriverID = 0, ServiceAgentID = 0, customerID = 0, JobID = 0, defectID = 0) => {
    if (frompage === 'Vehicles') {
        let res = await getWithAuth(`note/notelist?notefrompage=${frompage}&VehicleID=${VehicleID}`);
        return res;
    }
    if (frompage === 'Defects') {
        let res = await getWithAuth(`VehicleDefectNotes?DefectID=${defectID}`);
        return res;
    }
    if (frompage === 'Drivers') {
        let res = await getWithAuth(`note/notelist?notefrompage=${frompage}&DriverID=${DriverID}`);
        return res;
    }
    if (frompage === 'ServiceAgents') {
        let res = await getWithAuth(`note/notelist?notefrompage=${frompage}&ServiceAgentID=${ServiceAgentID}`);
        return res;
    }
    if (frompage === 'Customers') {
        let res = await getWithAuth(`note/notelist?notefrompage=${frompage}&customerID=${customerID}`);
        return res;
    }
    if (frompage === 'Jobs') {
        let res = await getWithAuth(`note/notelist?notefrompage=${frompage}&JobID=${JobID}`);
        return res;
    }
};

export const getServiceAgentJobTracking = async (id, noteID) => {
    let res = await getWithAuth(`VehicleJob/ServiceAgentJobTracking?ServiceAgentID=${id}&NoteID=${noteID}`);
    return res;
};

export const getNotesStickButtons = async () => {
    let res = await getWithAuth('note/GetStickyDisPlayType');
    return res;
};

export const addUpdateNote = async (data) => {
    let res = await postWithAuth('note', data);
    return res;
};

export const getNoteById = async (id, frompage, VehicleID = 0, noteRefsID = 0) => {
    if (frompage === 'Vehicles') {
        let res = await getWithAuth(`note/NoteDetails?Id=${id}&notefrompage=${frompage}&VehicleID=${VehicleID}`);
        return res;
    } else {
        let res = await getWithAuth(`note/NoteDetails?Id=${id}&notefrompage=${frompage}&NoteRefsID=${noteRefsID}`);
        return res;
    }
};

export const getAllStickies = async (frompage, VehicleID = 0, ServiceAgentID = 0, customerID = 0, DriverID = 0, noteRefsGuidID = 0, DepotsID = 0) => {
    if (frompage === 'Vehicle') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&vehicleID=${VehicleID}`);
        return res;
    }
    if (frompage === 'Drivers') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&DriverID=${DriverID}`);
        return res;
    }
    if (frompage === 'ServiceAgent') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&ServiceID=${ServiceAgentID}`);
        return res;
    }
    if (frompage === 'Customers') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&customerID=${customerID}`);
        return res;
    }
    if (frompage === 'Jobs') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&noteRefsGuidID=${noteRefsGuidID}`);
        return res;
    }
    if (frompage === 'Depots') {
        let res = await getWithAuth(`Note/GetStickyNote?notefrompage=${frompage}&depotID=${DepotsID}`);
        return res;
    }
};
