import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, FormHelperText } from "@material-ui/core";
import { InputText, SecondaryButton, MandatoryField } from "../../../Core/Controls";
import { getVorById, postVorData } from "../../../Core/Services/InternalUser/VOR/vorTypesService";
import { useToast } from "../../../Core/Hooks/useToast";
import "../../commonStyle.scss";

function VorModal(props) {
    const [state, setState] = useState({
        vorTypeID: null,
        vorTypeDescription: "",
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.vorTypeID) {
            let res = await getVorById(props.vorTypeID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    vorTypeID: res.data.vorTypeID,
                    vorTypeDescription: res.data.vorTypeDescription
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { vorTypeDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(vorTypeDescription)) {
            errors.vorTypeDescription = " Vor Type is required";
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const vorData = {
                vorTypeID: state.vorTypeID,
                vorTypeDescription: state.vorTypeDescription
            };
            let res = await postVorData(vorData);
            if (res.success) {
                showToastSuccess("VOR Type Updated sucessfully");
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="vorTypeDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.vorTypeDescription}
                    errors={state.errors.vorTypeDescription}
                />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.vorTypeID ? "Update" : "Add"}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default VorModal;
