import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@material-ui/core';
import { InputText } from '../../../../../../Core/Controls';
import { useContext } from 'react';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

export const InspectionRoadTest = ({ Paper, classes }) => {
    const { state, handleInputs } = useContext(InspectionCheCkContaxt);
    return (
        <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
            <TableContainer component={Paper}>
                <div
                    style={{
                        border: '1px solid rgba(0,0,0,0.2)'
                    }}
                >
                    <h3 className={classes.title}>
                        Road Test &nbsp;<small>(Brake Temperature Assessment)</small>
                    </h3>
                </div>
                <Table aria-label="simple table" className={classes.paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="20%">Side</TableCell>
                            <TableCell align="left">Axle 1</TableCell>
                            <TableCell align="left">Axle 2</TableCell>
                            <TableCell align="left">Axle 3</TableCell>
                            <TableCell align="left">Axle 4</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th">
                                <b>O/S</b>
                            </TableCell>

                            <TableCell>
                                <InputText placeholder="°C" name="osBrakeTemperatureAxle" value={state.osBrakeTemperatureAxle} onChange={handleInputs(1)} />
                            </TableCell>
                            <TableCell>
                                <InputText placeholder="°C" name="osBrakeTemperatureAxle" value={state.osBrakeTemperatureAxle} onChange={handleInputs(2)} />
                            </TableCell>
                            <TableCell>
                                <InputText
                                    placeholder="°C"
                                    npm
                                    start
                                    name="osBrakeTemperatureAxle"
                                    value={state.osBrakeTemperatureAxle}
                                    onChange={handleInputs(3)}
                                />
                            </TableCell>
                            <TableCell>
                                <InputText placeholder="°C" name="osBrakeTemperatureAxle" value={state.osBrakeTemperatureAxle} onChange={handleInputs(4)} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">
                                <b>N/S</b>
                            </TableCell>

                            <TableCell>
                                <InputText placeholder="°C" name="nsBrakeTemperatureAxle" value={state.nsBrakeTemperatureAxle} onChange={handleInputs(1)} />
                            </TableCell>
                            <TableCell>
                                <InputText placeholder="°C" name="nsBrakeTemperatureAxle" value={state.nsBrakeTemperatureAxle} onChange={handleInputs(2)} />
                            </TableCell>
                            <TableCell>
                                <InputText placeholder="°C" name="nsBrakeTemperatureAxle" value={state.nsBrakeTemperatureAxle} onChange={handleInputs(3)} />
                            </TableCell>
                            <TableCell>
                                <InputText placeholder="°C" name="nsBrakeTemperatureAxle" value={state.nsBrakeTemperatureAxle} onChange={handleInputs(4)} />
                            </TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};
