import { FormHelperText, Grid, InputLabel, Paper, Tooltip, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { EditInapectionContaxt } from './editInspectionProviderImports';
import { InputText } from '../../../../../../Core/Controls';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';

export const EditSInspectionSignOFF = ({ classes }) => {
    const { state, portalSettings, handleInput, useDefaultSig, clear, GetTechnicianSign, signCanvas, flags } = useContext(EditInapectionContaxt);
    return (
        <>
            <Grid container item xs={12}>
                <Paper className={classes.paper}>
                    {flags.displayCustomerDeclaration === 0 ? (
                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Sign Off</h3>
                            </Grid>
                            <Grid container spacing={1} justify="space-between">
                                <Grid item xs={12} sm={12} className="mandatory-fields">
                                    <div
                                        className={classes.headings}
                                        style={{ marginTop: -30 }}
                                        dangerouslySetInnerHTML={{
                                            __html: portalSettings.portalSettingSignOffManagerDeclaration
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} style={{ marginTop: 9 }}>
                                    <InputLabel shrink>Comment</InputLabel>
                                    {state.vehicleInspectionStatusID === 2 ? (
                                        <Typography variant="h6">{state.vehicleInspectionSignedOffComment} </Typography>
                                    ) : (
                                        <InputText
                                            style={{ marginTop: 9 }}
                                            multiline
                                            rows={7}
                                            placeholder="Comment"
                                            name="vehicleInspectionSignedOffComment"
                                            value={state.vehicleInspectionSignedOffComment}
                                            onChange={handleInput}
                                        />
                                    )}
                                    <FormHelperText error>{state.errors?.vehicleInspectionSignedOffComment}</FormHelperText>
                                </Grid>
                                <Grid item spacing={2} xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink error>
                                                Signature
                                                {!state.hideSignOff && state.signAvailable && state.vehicleInspectionStatusID !== 2 ? (
                                                    <Tooltip title="Use default signature">
                                                        <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                    </Tooltip>
                                                ) : null}
                                            </InputLabel>
                                            {state.showbtn ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0
                                                    }}
                                                >
                                                    <Tooltip title="Clear Signature">
                                                        <DeleteIcon
                                                            onClick={clear}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            ) : null}
                                            {state.vehicleInspectionStatusID === 4 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 5,
                                                        bottom: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-end'
                                                    }}
                                                >
                                                    <Typography variant="body1">{state.vehicleInspectionSignedOffName}</Typography>
                                                    <Typography variant="button">
                                                        {moment(state.vehicleInspectionSignedOffDate).format('DD/MM/YYYY HH:MM')}
                                                    </Typography>
                                                </div>
                                            ) : null}{' '}
                                            <div className="workflow-job-details">
                                                {state.signOffsignature ? (
                                                    <div className="sigContainer">
                                                        <img src={state.signOffsignature} style={{ width: '100%', height: '100%' }} alt="my signature" />
                                                    </div>
                                                ) : (
                                                    <div className="sigContainer">
                                                        <SignaturePad
                                                            ref={signCanvas}
                                                            canvasProps={{ className: 'sigPad' }}
                                                            onEnd={() => {
                                                                GetTechnicianSign();
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <FormHelperText error>{state.errors?.vehicleInspectionSignedOffSignature}</FormHelperText>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Sign Off</h3>
                            </Grid>
                            <Grid container spacing={1} justify="space-between" alignItems="flex-end">
                                <Grid item xs={12}>
                                    {flags.displayCustomerDeclaration === 1 && (
                                        // <h3 className={classes.headings}>
                                        <div
                                            style={{ marginTop: -20 }}
                                            className={classes.headings}
                                            dangerouslySetInnerHTML={{ __html: portalSettings.portalSettingCustomerOnHireDeclaration }}
                                        />
                                        // </h3>
                                    )}
                                    {flags.displayCustomerDeclaration === 2 && (
                                        // <h3 className={classes.headings}>{portalSettings.portalSettingCustomerOffHireDeclaration}</h3>
                                        <div
                                            style={{ marginTop: -20 }}
                                            className={classes.headings}
                                            dangerouslySetInnerHTML={{ __html: portalSettings.portalSettingCustomerOffHireDeclaration }}
                                        />
                                    )}
                                </Grid>
                                <Grid item container spacing={1} xs={12} md={6}>
                                    <Grid item xs={12} md={12} lg={12} style={{ marginTop: 5 }}>
                                        <InputLabel shrink> Comment</InputLabel>
                                        <InputText
                                            multiline
                                            rows={4}
                                            placeholder="comment"
                                            name="vehicleInspectionSignedOffComment"
                                            value={state.vehicleInspectionSignedOffComment}
                                            onChange={handleInput}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid rgba(0,0,0,0.2)',
                                                marginTop: 6
                                            }}
                                        >
                                            <Typography variant="body1">{state.vehicleDailyCheckCheckerName}</Typography>
                                            <Typography variant="button">{moment(state.vehicleInspectionDateInspected).format('DD/MM/YYYY HH:MM')}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel shrink error>
                                            {' '}
                                            Customer Name
                                        </InputLabel>
                                        <InputText
                                            placeholder="Customer Name"
                                            name="vehicleInspectionSignedOffName"
                                            onChange={handleInput}
                                            value={state.vehicleInspectionSignedOffName}
                                        />
                                        <FormHelperText error>{state.errors?.vehicleInspectionSignedOffName}</FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item spacing={2} xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink error>
                                                Signature
                                                {!state.hideSignOff && state.signAvailable && state.vehicleInspectionStatusID !== 2 ? (
                                                    <Tooltip title="Use default signature">
                                                        <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                    </Tooltip>
                                                ) : null}
                                            </InputLabel>
                                            {state.showbtn ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0
                                                    }}
                                                >
                                                    <Tooltip title="Clear Signature">
                                                        <DeleteIcon
                                                            onClick={clear}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            ) : null}
                                            {state.vehicleInspectionStatusID === 4 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 5,
                                                        bottom: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-end'
                                                    }}
                                                >
                                                    <Typography variant="body1">{state.vehicleInspectionSignedOffName}</Typography>
                                                    <Typography variant="button">
                                                        {moment(state.vehicleInspectionSignedOffDate).format('DD/MM/YYYY HH:MM')}
                                                    </Typography>
                                                </div>
                                            ) : null}{' '}
                                            <div className="workflow-job-details">
                                                {state.signOffsignature ? (
                                                    <div className="sigContainer">
                                                        <img src={state.signOffsignature} style={{ width: '100%', height: '100%' }} alt="my signature" />
                                                    </div>
                                                ) : (
                                                    <div className="sigContainer">
                                                        <SignaturePad
                                                            ref={signCanvas}
                                                            canvasProps={{ className: 'sigPad' }}
                                                            onEnd={() => {
                                                                GetTechnicianSign();
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <FormHelperText error>{state.errors?.vehicleInspectionSignedOffSignature}</FormHelperText>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>
        </>
    );
};
