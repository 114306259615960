import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditNotes from '../Notes/addEditNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getAllJobReminders } from '../../../../Core/Services/InternalUser/ControllerService/jobReminderService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import ServiceAgentDetails from '../DetailsModal/serviceAgent.jsx';
import CustomerDetails from '../DetailsModal/customerDetails.jsx';
import DepotDetails from '../DetailsModal/depotDetails.jsx';
import ReactDOM from 'react-dom';
import AddEditJobsNotes from '../../Controllers/Notes/addEditNotes';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true },
    { name: 'Job Notes', active: true }
];
const basecolumns = [
    { key: 'action', name: 'Action', width: 80 },
    { key: 'regNumber', name: 'Reg No', width: 100 },
    { key: 'actionDate', name: 'Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 130 },
    // { key: 'notes', name: 'Note' },
    // { key: 'title', name: 'Title' },
    { key: 'rem', name: 'Reminder Information', width: 300 },
    { key: 'orderNo', name: 'Job Ref', width: 130 },
    { key: 'jobDescription', name: 'Job Description', width: 200 },
    { key: 'depotName', name: 'Depot', width: 150 },
    { key: 'customer', name: 'Customer', width: 200 },
    { key: 'agentName', name: 'Service Area', width: 120 },
    { key: 'userName', name: 'User', width: 120 }
];

const JobReminder = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isReload: null
    });
    useEffect(async () => {
        pullApiData();
    }, []);

    const pullApiData = async () => {
        // let res = await getAllJobReminders();
        setState((st) => ({
            ...st,
            isReload: new Date(),
            showLoader: false
        }));
    };

    const concatNoteTitle = (param) => {
        return `${param.row.title} [${param.row.notes}]`;
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/jobTabScreen/${val.jobID}/${val.vehicleID}`,
            search: '',
            state: 'jobrem'
        });
    };

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                {checkhaveAccess?.CanViewNotes && (
                    <CustomIconButton
                        icon={AssignmentIcon}
                        toolTipTitle="Edit Note"
                        onClick={() => {
                            setState((st) => ({ ...st, selectedRowData: row, open: true }));
                        }}
                    />
                )}
                {/* <Link to={{ pathname: `/controller/jobTabScreen/${row.jobID}/${row.vehicleID}`, state: 'jobrem' }} style={{ marginLeft: '5px' }}> */}
                {checkhaveAccess?.CanViewJobs && <CustomIconButton icon={WorkIcon} toolTipTitle="Job Details" onClick={() => handleEditClick(row)} />}
                {/* </Link> */}
            </div>
        );
    };

    const dialogTitle = () => {
        return (
            <div>
                Edit Note{' '}
                <span style={{ color: 'red' }}>
                    {state.selectedRowData.orderNo} {state.selectedRowData.regNumber}
                </span>
            </div>
        );
    };

    const handleEditRegClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };
    const handelRowCick = (row, name) => {
        // let res = await getAllJobReminders();
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'bold', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'bold', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'bold', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let noteTitle = tempCols.find((e) => e.key === 'rem');
        let agentNameCol = tempCols.find((element) => element.key === 'agentName');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let regNumberCol = tempCols.find((element) => element.key === 'regNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (noteTitle) {
            noteTitle.formatter = concatNoteTitle;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        return tempCols;
    }, []);

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const handleNotesClose = (res) => {
        setState((st) => ({
            ...st,
            open: false
        }));
        res && pullApiData();
    };

    let getUrl = `VehicleJob/JobReminders`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            {/* <DataGrid rows={state.rows} columns={columns} enableSearch={true} loadingData={state.showLoader} paginate offset={230} /> */}
            <GijgoGrid
                columns={columns}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={getUrl}
            />

            {state.open ? (
                <DialogComp open={true} onClose={handleClose} title={'Defect Notes'}>
                    <AddEditJobsNotes
                        onClose={() => handleNotesClose(false)}
                        NoteID={state.selectedRowData.jobNotesID}
                        JobID={state.selectedRowData.jobID}
                        frompage="Jobs"
                        onFormSubmit={handleNotesClose}
                        titleModal={dialogTitle()}
                    />
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.vehicleCustomerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default JobReminder;
