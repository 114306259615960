import React, { useState, useEffect, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import ServiceAgentDetails from '../DetailsModal/serviceAgent.jsx';
import CustomerDetails from '../DetailsModal/customerDetails.jsx';
import DepotDetails from '../DetailsModal/depotDetails.jsx';
import ReactDOM from 'react-dom';
import AddEditNotes from '../../Controllers/Notes/jobNotes'; //'./addEditNotes';
import BuildIcon from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { Grid, Switch } from '@material-ui/core';
import AddEditJobsNotes from '../JOBS/JobDetails/Internal/addUpdateJobNotes';
const getCrumbs = (code) => {
    let temp = {};
    let final = [{ name: 'Home', path: '/' }];
    switch (code) {
        case 'BR':
            temp = { name: 'Breakdown Roadside', active: true };
            break;
        case 'DD':
            temp = { name: 'Job Defects', active: true };
            break;
        case 'BO':
            temp = { name: 'Breakdown Onsite', active: true };
            break;
        default:
            temp = { name: 'Job Tracking', active: true };
    }

    final.push(temp);
    return final;
};
const basecolumns = [
    { key: 'action', name: 'Action', width: 100 },
    { key: 'regNumber', name: 'Reg No', width: 75 },
    { key: 'actionDate', name: 'Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 130 },
    { key: 'notes', name: 'Reminder Information', width: 280 },
    { key: 'number', name: 'Number', width: 80 },
    {
        key: 'type',
        name: 'Type',
        width: 80
    },
    { key: 'depotName', name: 'Depot', width: 110 },
    { key: 'customer', name: 'Customer', width: 230 },
    { key: 'agentName', name: 'Service Area', width: 100 },
    { key: 'userName', name: 'User', width: 100 }
];

const JobTracking = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isReload: null,
        filterFlag: false
    });
    let history = useHistory();

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date(),
            showLoader: false
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val.jobID}/${val.vehicleID}`,
            state: 'jobrem'
        });
    };
    const handleEditDefect = (val) => {
        history.push({
            pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.vehicleID}`,
            state: 'Edit'
        });
    };

    const handleEditBreakdown = (val) => {
        history.push({
            pathname: `/maintenance/breakdowns/Edit/${val?.defectID}/${val?.vehicleID}`,
            state: 'Edit'
        });
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };
    const openNotesModal = (row) => {
        let newSt = {};
        if (row.type == 'Job') newSt.showJobNotes = true;
        else newSt.open = true;
        newSt.selectedRowData = row;
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };
    const renderEdit = (ev, row) => {
        return (
            <div
                style={
                    {
                        // textAlign: 'center'
                    }
                }
            >
                {checkhaveAccess?.CanViewNotes && (
                    <CustomIconButton
                        icon={AssignmentIcon}
                        toolTipTitle={row.type == 'Job' ? 'Job Note' : 'Edit Note'}
                        onClick={() => {
                            openNotesModal(row);
                        }}
                    />
                )}
                {checkhaveAccess?.CanViewJobs && <CustomIconButton icon={WorkIcon} toolTipTitle="Job Details" onClick={() => handleEditClick(row)} />}
                {row.defectID && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        // onClick={() => handleUpdate(row, null, 'modalEditDetail')}
                        onClick={() => (row.type === 'D' ? handleEditDefect(row) : handleEditBreakdown(row))}
                        toolTipTitle={`${row.type === 'D' ? 'Edit Defect' : 'Edit Breakdown'} `}
                    />
                )}
            </div>
        );
    };

    const dialogTitle = () => {
        return (
            <span>
                Edit Note{' '}
                <span style={{ color: 'red' }}>
                    {state.selectedRowData.orderNo} {state.selectedRowData.regNumber}
                </span>
            </span>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let agentNameCol = tempCols.find((element) => element.key === 'agentName');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let regNumberCol = tempCols.find((element) => element.key === 'regNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        return tempCols;
    }, []);

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false,
            showJobNotes: false
        }));
    };

    const handleNotesClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            showJobNotes: false
        }));
        res && pullApiData();
    };
    let crumbs = getCrumbs(props.id);
    let getUrl = `VehicleJob/JobTracking?DefectTypeCode=${props.id || ''}&all=${state.filterFlag}`;

    const handleChange = (event) => {
        const { name, checked } = event.target;
        let tempSt = {};
        if (name === 'filterFlag') {
            tempSt = {
                filterFlag: checked
            };
        }

        setState((st) => ({ ...st, ...tempSt }));
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container item alignItems="center" justify="flex-end">
                <Grid item>Filter : Due</Grid>
                <Grid item>
                    <Switch checked={state.filterFlag} onChange={handleChange} value="checked" name="filterFlag" />
                </Grid>
                <Grid item>All</Grid>
            </Grid>
            <GijgoGrid
                columns={columns}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={getUrl}
            />
            {state.open ? (
                <AddEditNotes
                    onClose={() => handleNotesClose(false)}
                    NoteID={state.selectedRowData.noteID}
                    defectID={state.selectedRowData.defectID}
                    noteRefsGuidID={state.selectedRowData.defectID}
                    frompage="Defects"
                    onFormSubmit={handleNotesClose}
                    titleModal={dialogTitle()}
                    notesTitle="Defects"
                    agentName={state.selectedRowData.agentName}
                    agentNameID={state.selectedRowData.jobServiceAgentID}
                />
            ) : null}
            {state.showJobNotes ? (
                <AddEditJobsNotes
                    showHistory={true}
                    orderNo={state.selectedRowData.orderNo}
                    regNo={state.selectedRowData.regNumber}
                    id={state.selectedRowData.noteID}
                    onClose={() => handleNotesClose(false)}
                    jobID={state.selectedRowData.jobID}
                    onFormSubmit={handleNotesClose}
                />
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.vehicleCustomerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.depotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default JobTracking;
