import { CircularProgress, makeStyles, Button, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { InputText } from '..';
import { some, chunk } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import FileUploaderButton from '../GenericFileUploader';
import { isValidConfig } from '../GenericFileUploader/FileUploaderConfig';
import '../../../Components/commonStyle.scss';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import { SortByAlphaTwoTone } from '@material-ui/icons';
import { CustomChip } from '../Inputs/DatePicker';

const EmptyRowsRenderer = (props) => {
    return (
        <div className="empty-row">
            {props.loadingData ? (
                <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
                    <CircularProgress color="inherit" />
                </div>
            ) : (
                <div style={{ textAlign: 'center', width: '100%', marginTop: 20, fontWeight: 500 }}>No Data Found.</div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        gridContainer: {
            marginTop: 8,
            '& .empty-row': {
                marginTop: theme.spacing(1),
                textAlign: 'center'
            },
            '& .grid-filter': {
                position: 'relative',
                display: 'inline-block',
                marginBottom: 8,
                width: '100%',
                '& > label': {
                    position: 'absolute',
                    right: 10,
                    top: 8,
                    zIndex: -99,
                    color: '#d3d3d3'
                }
            }
        }
    };
});

let searchTimer;
let defaultRowHeight = 45;
let defautltRowHeaderHeight = 45;
const DataGrid = (props) => {
    const [state, setState] = useState({
        rows: [],
        columns: [],
        filteredRows: [],
        filterText: '',
        rowHeight: defaultRowHeight,
        headerRowHeight: defautltRowHeaderHeight,
        pagedIndex: 1,
        pagedData: [],
        rowPerPage: props.rowPerPage || 10
    });
    const [sortColumns, setSortColumns] = useState([]);
    const [finalRow, setFinalRow] = useState([]);
    const classes = useStyles();
    const gridFileCountRefresh = () => {
        props.gridRefresh && props.gridRefresh();
    };
    useEffect(() => {
        const rows = props.rows || [];
        const rowHeight = props.rowHeight || defaultRowHeight;
        const headerRowHeight = props.headerRowHeight || defautltRowHeaderHeight;
        setState((st) => ({ ...st, rows: rows, rowHeight: rowHeight, headerRowHeight: headerRowHeight }));
    }, [props.rows, props.rowHeight, props.headerRowHeight]);

    useEffect(() => {
        let cols = props.columns.map((c) => ({ ...c }));
        if (cols.length > 0 && props.idFieldName && props.fileUploaderConfigName && isValidConfig(props.fileUploaderConfigName)) {
            const actionCol = cols.find((c) => c.addFileUploaderIcon);
            if (actionCol) {
                const prevFor = actionCol.formatter;
                var newFormatter = (params) => {
                    return (
                        <div>
                            <Grid container spacing={2}>
                                <Grid item className="ml-5">
                                    {
                                        <FileUploaderButton
                                            fileCount={params.row.fileCount}
                                            recordId={params.row[props.idFieldName]}
                                            fileUploaderConfigName={props.fileUploaderConfigName}
                                            gridRelaod={gridFileCountRefresh}
                                        />
                                    }
                                </Grid>
                                <Grid item>{prevFor(params)}</Grid>
                            </Grid>
                        </div>
                    );
                };
                actionCol.formatter = newFormatter;
            } else {
                cols.push({
                    name: '',
                    width: 70,
                    formatter: (params) => (
                        <FileUploaderButton
                            fileCount={params.row.fileCount}
                            recordId={params.row[props.idFieldName]}
                            fileUploaderConfigName={props.fileUploaderConfigName}
                            gridRelaod={gridFileCountRefresh}
                        />
                    )
                });
            }
        }
        setState((st) => ({
            ...st,
            columns: cols.map((m) => {
                return { ...m, cellClass: `${m.cellClass} normalLineHeight` };
            })
        }));
    }, [props.columns]);

    const gridStyle = useMemo(() => {
        return {
            height: props.height ? props.height : props.offset ? `calc(100vh - ${props.offset}px)` : 'calc(100vh - 190px)',
            width: props.width || 'auto',
            marginTop: props.enableSearch ? 8 : 0,
            fontSize: 15
            // overflowY: 'hidden'
        };
    }, [props.height, props.width, props.enableSearch]);

    const setFilterText = (e) => {
        const vl = e.target.value;
        if (props.getFilterVal) {
            props.getFilterVal(vl);
        }
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { rows, filterText } = newSt;
            let filterd = [];
            filterd = [...rows];
            if (props.enableSearch && filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filteredRows = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.rows]);

    //logic for sorting array (works for 7.0.0-canary.49 version React data grid)
    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return state.filteredRows;

        const getComparator = (sortColumn) => {
            return (a, b) => {
                if ((a[sortColumn] || '') < (b[sortColumn] || '')) {
                    return -1;
                }
                if ((a[sortColumn] || '') > (b[sortColumn] || '')) {
                    return 1;
                }
                return 0;
            };
        };

        const sortedRows = [...state.filteredRows];
        sortedRows.sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey, sort.type);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
        return sortedRows;
    }, [state.pagedIndex, sortColumns, state.filteredRows]);

    //Logic for Pagination till useEffect
    function splitIntoChunk(arr, size) {
        let tempo = chunk(arr, size);
        setState((st) => ({ ...st, pagedData: tempo }));
    }

    const handleChange = (event, value) => {
        setState((st) => ({ ...st, pagedIndex: value }));
    };

    useEffect(() => {
        const array = sortedRows || [];
        const size = state.rowPerPage || 10;
        splitIntoChunk(array, size);
    }, [sortedRows, state.rowPerPage]);

    let { isReadOnlyUser } = useLoginInfo();

    useEffect(() => {
        let finalRows = sortedRows;
        if (props.paginate) {
            if (state.pagedData.length > 0) {
                if (state.pagedData[state.pagedIndex - 1]?.length > 0) {
                    finalRows = state.pagedData[state.pagedIndex - 1];
                } else {
                    finalRows = state.pagedData[0];
                    setState((st) => ({ ...st, pagedIndex: 1 }));
                }
            } else {
                finalRows = [];
            }
        }
        setFinalRow(finalRows || []);
    }, [state.pagedIndex, state.pagedData, sortedRows]);

    const rPGChanged = (event) => {
        let { value } = event.target;
        if (value < 10 || value > 50) {
            event.preventDefault();
        } else {
            setState((st) => ({ ...st, rowPerPage: value }));
        }
    };

    let isGridBtn = props.btnTitle || props.secondBtnTitle || props.mapBtnToolTipTitle || props.enableSearch;

    return (
        <div className={isGridBtn && classes.gridContainer}>
            <Grid container spacing={1}>
                {props.enableSearch && (
                    <Grid item xs={12} md={5} sm={6} className="height-58">
                        <div className="grid-filter">
                            <InputText
                                forceEditable
                                placeholder="Search"
                                onChange={setFilterText}
                                value={state.filterText}
                                autoFocus={true}
                                className={props.searchBoxClassName || ''}
                            />
                            <label>
                                Showing {state.rows.length ? `${state.filteredRows.length}/` : ``}
                                {state.rows.length}
                            </label>
                        </div>
                    </Grid>
                )}
                {!isReadOnlyUser && (
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        sm={6}
                        className="gridadd-btn "
                        alignItems="center"
                        style={isGridBtn && { paddingTop: 7, paddingBottom: 14 }}
                    >
                        {props.btnTitle && (
                            <Grid item className="height-45">
                                <Tooltip title={props.btnTitle} arrow>
                                    <CustomChip
                                        icon={AddIcon}
                                        label={props.btnTitle}
                                        onClick={props.onBtnClick}
                                        style={{ fontWeight: 'bold', marginRight: '5px' }}
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                        {props.secondBtnTitle && (
                            <Grid item className="height-45">
                                <Tooltip title={props.secondBtnTitle} arrow>
                                    <CustomChip
                                        icon={props.secondBtnIcon || AddIcon}
                                        label={props.secondBtnTitle}
                                        onClick={props.onSecondBtnClick}
                                        style={{ fontWeight: 'bold' }}
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                        {props.mapBtnToolTipTitle && (
                            <Grid item className="height-45">
                                <Tooltip title={props.mapBtnToolTipTitle} arrow>
                                    <CustomChip
                                        icon={props.mapBtnIcon}
                                        label={props.mapBtnLabel}
                                        onClick={props.onMapBtnClick}
                                        style={{ fontWeight: 'bold' }}
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            <ReactDataGrid
                columns={state.columns}
                rows={finalRow}
                emptyRowsRenderer={() => EmptyRowsRenderer(props)}
                rowHeight={state.rowHeight}
                headerRowHeight={state.headerRowHeight}
                className="custom-scroll"
                style={gridStyle}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true
                }}
                sortColumns={sortColumns}
                onSortColumnsChange={setSortColumns}
                onRowClick={props.onRowClick || null}
            />
            {props.paginate ? (
                <Grid item xs={12} style={{ paddingTop: '5px' }}>
                    Page No:&nbsp;{state.pagedData.length > 0 ? state.pagedIndex : '0'}
                    &nbsp;&nbsp;&nbsp;Rows Per Page:&nbsp;
                    <TextField
                        type="number"
                        size="small"
                        value={state.rowPerPage}
                        onChange={rPGChanged}
                        inputProps={{ max: 50, min: 10 }}
                        style={{ width: '35px' }}
                    />
                    <Pagination
                        onChange={handleChange}
                        count={state.pagedData.length}
                        showFirstButton
                        showLastButton
                        page={state.pagedIndex}
                        color="secondary"
                        variant="outlined"
                        style={{ float: 'right' }}
                    />
                </Grid>
            ) : null}
        </div>
    );
};

export default DataGrid;
