import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { ContractSetArrived, ContractSetResumeContract } from '../../../../../Core/Services/InternalUser/InternalDashService';

import moment from 'moment';

const ContractSetResume = (props) => {
    console.log(props, 'props');
    const [state, setState] = useState({
        errors: {},
        resumeDate: '',
        //  moment().format('YYYY-MM-DD')
        contractDeliveryCharge: null
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { resumeDate } = state;
        let formIsValid = true;
        let errors = {};
        if (!resumeDate) {
            errors.resumeDate = 'Date is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        console.log(state);
        if (validations()) {
            setBtnLoader(true);
            let promises = props.checkedVehicleList.map((q) => {
                return ContractSetResumeContract({
                    contractVehicleID: q,
                    resumeDate: state.resumeDate
                });
            });

            try {
                let responses = await Promise.all(promises);

                // Check if all responses are successful
                let allSuccessful = responses.every((response) => response.success);

                if (allSuccessful) {
                    showToastSuccess('Vehicle delivery details saved successfully.');
                    props.onClose(true);
                } else {
                    // Find out which responses failed
                    let failedResponses = responses.filter((response) => !response.success);
                    showToastError(`Error: ${failedResponses.length} items failed.`);
                }
            } catch (error) {
                // Handle any other errors that may occur
                showToastError(`An error occurred: ${error.message}`);
            }
            setBtnLoader(false);
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Resume Date</InputLabel>
                <DatePicker name="resumeDate" placeholder="Resume Date" value={state.resumeDate || ''} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.resumeDate}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetResume;
