import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleJob, getAllJob } from '../../../../Core/Services/InternalUser/JOB/jobService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import JobScreenModal from '../../../InternalUser/Controllers/JOBS/JobScreenModal';
import { Link } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'jobAgentOrderNo', name: 'Job Ref' },
    { key: 'jobDescription', name: 'Job Description' },
    // { key: 'serviceAgentName', name: 'Service Agent' },
    { key: 'depotName', name: 'Depot' },
    {
        key: 'jobPartsTotal',
        name: 'Parts',
        formatter: formatters.NumberWithPound,
        align: 'right'
    },
    {
        key: 'jobLabourTotal',
        name: 'Labour',
        formatter: formatters.NumberWithPound,
        align: 'right'
    },
    { key: 'jobOrderTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right' }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'All Jobs', active: true }
];

function CustomerJobScreen(props) {
    const [state, setState] = useState({
        jobList: [],
        loader: true,
        open: false,
        isReload: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const pullJobList = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date(),
            loader: false
        }));
    };

    const jobHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };

    const handleEditClick = (row) => {
        props.history.push({
            pathname: `/controller/jobTabScreen/${row?.jobID}/${props.VehicleID ? props.VehicleID : row?.jobVehicleID}`,
            state: 'jobs'
        });
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {/* <Link
                    to={{
                        pathname: `/controller/jobTabScreen/${data.row?.jobID}/${props.VehicleID ? props.VehicleID : data.row?.jobVehicleID}`,
                        state: 'jobs'
                    }}
                > */}
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}{' '}
                {/* </Link> */}
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        if (!props.VehicleID) {
            newColumn.splice(1, 0, { key: 'jobRegNumber', name: 'Reg No' });
        }
        return newColumn;
    }, []);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullJobList();
    };

    const gridBtnTitle = !props.VehicleID ? '' : 'Add Job';

    return (
        <div className={`${!props.VehicleID && 'screen'}` + ' controller-screen'} style={{ marginTop: 8 }}>
            {state.open ? (
                <DialogComp open={state.open} onClose={() => closeModal(false)} title="Add Job" maxWidth="md">
                    <JobScreenModal VehicleID={props.VehicleID} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : (
                <>
                    {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}

                    <GijgoGrid
                        btnTitle={gridBtnTitle}
                        onBtnClick={jobHandler}
                        getUrl={`VehicleJob?VehicleID=${props.VehicleID || ''}`}
                        columns={columns}
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
}
export default CustomerJobScreen;
