import { Grid, IconButton, InputLabel, Tooltip, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { SingleSelect } from '../../../Core/Controls';
import { AppStorage } from '../../../Core/Basic/storage-service';
import HolidayModal from './setHolidayModal';
import { getDropDownHolidayUser, getHolidayDataByID } from '../../../Core/Services/ServiceAgent/Holiday.js';
import DialogComp from '../../../Core/Dialog/DialogComp.jsx';
import './calender.scss';
import moment from 'moment';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo.js';

let ColorObj = {
    1: { color: '#FBCEB1', name: 'Morning', txtColor: 'black' },
    2: { color: '#A45A52', name: 'Afternoon', txtColor: 'white' },
    3: { color: '#A45A52', name: 'Full day', txtColor: 'white' }
};
// let ColorList = [
//     { color: '#FBCEB1', name: 'Morning', txtColor: 'black' },
//     { color: '#A45A52', name: 'Afternoon', txtColor: 'white' },
//     { color: '#9e1b32', name: 'Full day', txtColor: 'white' }
// ];

const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const Month = ({ monthName, year, monthIndex, handleOpen, holidayList, isUserPermission }) => {
    // Get the number of days in the current month
    const daysInMonth = moment([year, monthIndex]).daysInMonth();

    // Get the first day of the month (adjusted for Monday start)
    const firstDay = moment([year, monthIndex, 1]).day();
    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1; // Adjust for Monday start

    const today = moment(); // Current day

    const days = [];

    // Create empty slots for the days before the start of the month
    for (let i = 0; i < adjustedFirstDay; i++) {
        days.push(<div className="empty" key={`empty-${i}`}></div>);
    }

    // Create day elements for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
        const isToday = today.isSame(moment([year, monthIndex, day]), 'day');
        const currentDay = moment([year, monthIndex, day]).day();
        const adjustedDay = currentDay === 0 ? 6 : currentDay - 1; // Adjust for Monday start

        let indexObject = holidayList.find((k) => moment(k.holidayDate).format('YYYY-MM-DD') === moment([year, monthIndex, day]).format('YYYY-MM-DD')) || {};
        const isWeekend =
            // adjustedDay === 5 ||
            adjustedDay === 6; // 5 is Saturday, 6 is Sunday

        days.push(
            <Grid
                container
                alignContent="center"
                className="day"
                key={day}
                style={{
                    background: isWeekend
                        ? '#9e1b32'
                        : !indexObject.success && isToday
                        ? '#009E60'
                        : indexObject.success
                        ? ColorObj[indexObject.holidayType].color
                        : '#E5E4E2',
                    color: isWeekend ? 'white' : isToday ? 'white' : indexObject.success ? ColorObj[indexObject.holidayType].txtColor : 'black',
                    fontWeight: isToday ? 800 : 600
                }}
                onClick={isUserPermission ? () => handleOpen(moment([year, monthIndex, day]).format('YYYY-MM-DD'), indexObject) : () => {}}
            >
                <Grid item>
                    {' '}
                    {!indexObject.success ? (
                        day
                    ) : (
                        <abbr title={indexObject.holidayNotes} style={{ textDecoration: 'none' }}>
                            {day}
                        </abbr>
                    )}
                </Grid>
            </Grid>
        );
    }

    return (
        <div className="month">
            <div style={{ fontSize: 20, fontWeight: 700, padding: 5 }}>{monthName}</div>
            <div className="days-grid">
                <div className="day-name">Mon</div>
                <div className="day-name">Tue</div>
                <div className="day-name">Wed</div>
                <div className="day-name">Thu</div>
                <div className="day-name">Fri</div>
                <div className="day-name">Sat</div>
                <div className="day-name">Sun</div>
                {days}
            </div>
        </div>
    );
};

export default function HolidayShiftCalender() {
    const { userRoles } = useLoginInfo();
    const isServiceAgent = userRoles.includes('servicearea');
    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');
    const isEngineer = userRoles.includes('engineer');

    let isUserPermission = AppStorage.getAllowAcces().CanViewHolidayLeave || isinternal;
    const [state, setState] = useState({
        openModal: false,
        userList: [],
        year: { id: moment().year(), name: moment().year().toString() },
        yearList: [],
        user: {},
        holidayList: [],
        modalData: {}
    });

    useEffect(() => {
        if (state.userList.length > 0) setState((st) => ({ ...st, user: state.userList[0] }));
    }, [state.userList]);

    const getLastFiveYears = () => {
        const years = [];
        const currentYear = 2024;

        for (let i = 0; i < 10; i++) {
            years.push(currentYear + i);
        }

        return years.map((val) => ({
            id: val,
            name: val.toString()
        }));
    };

    // let BgColor = AppStorage.getPortalSetting().portalHeaderBackgroundColour;

    const handleOpen = (date, data) => {
        setState((st) => ({ ...st, openModal: !st.openModal, selectedDate: date || '', modalData: data || {} }));
    };
    const handleclose = (res) => {
        setState((st) => ({ ...st, openModal: false, selectedDate: '', modalData: {} }));
        res && pullData();
    };

    useEffect(() => {
        let fetchData = async () => {
            let dropdownRes = await getDropDownHolidayUser();
            try {
                if (dropdownRes.success)
                    setState((st) => ({
                        ...st,
                        userList: dropdownRes?.data?.list || [],
                        yearList: getLastFiveYears()
                    }));
            } catch (error) {
                console.warn('fail to fetch data', error);
            }
        };
        return fetchData();
    }, []);

    const pullData = useCallback(async () => {
        try {
            let res = await getHolidayDataByID(state?.user?.id, state?.year?.id);
            if (res.success) {
                setState((st) => ({ ...st, holidayList: res.data.list || [], error: null }));
            }
        } catch (error) {
            console.error('Error fetching holiday data:', error);
        }
    }, [state?.user?.id, state?.year?.id]);

    useEffect(() => {
        if (state?.user?.id && state?.year?.id) {
            pullData();
        }
    }, [pullData, state?.user?.id, state?.year?.id]);

    const WorkCategoryChange = (nm) => (e, val) => {
        setState((state) => ({
            ...state,
            [nm]: val
        }));
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} xl={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Users"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.userList}
                        value={state.user}
                        onChange={WorkCategoryChange('user')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Year"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.yearList}
                        value={state.year}
                        onChange={WorkCategoryChange('year')}
                    />
                </Grid>
            </Grid>
            {state?.user?.id && state?.year?.id ? (
                <>
                    {/* <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {ColorList?.map((k) => {
                            return (
                                <Grid item>
                                    <IconButton
                                        style={{
                                            // fontWeight: 900,
                                            background: k.color,
                                            borderRadius: 0,
                                            height: 20,
                                            width: '100%',
                                            padding: 10,
                                            fontSize: 12
                                        }}
                                    >
                                        {' '}
                                        <div item style={{ fontSize: 13, fontWeight: 600, color: k.txtColor }}>
                                            {k.name}
                                        </div>
                                    </IconButton>
                                </Grid>
                                // </div>
                            );
                        })}
                    </Grid> */}
                    <Grid container spacing={2}>
                        <div className="calendar">
                            {monthList.map((month, index) => (
                                <Month
                                    key={index}
                                    monthName={month}
                                    year={state.year.id}
                                    monthIndex={index}
                                    handleOpen={handleOpen}
                                    holidayList={state.holidayList}
                                    isUserPermission={isUserPermission}
                                />
                            ))}
                        </div>
                    </Grid>
                </>
            ) : (
                <Grid container justify="center">
                    <Grid>
                        {' '}
                        <h3> {!state?.user?.id ? 'Please select a user User' : 'Please select Year'} </h3>
                    </Grid>
                </Grid>
            )}

            {state.openModal ? (
                <DialogComp onClose={handleOpen} title={'Add Holiday'} maxWidth="sm" fullWidth>
                    <HolidayModal selectedDate={state.selectedDate} user={state.user.id} userData={state.modalData} onClose={handleclose} />
                </DialogComp>
            ) : null}
        </div>
    );
}
