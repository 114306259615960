import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { FormHelperText, InputLabel, FormControlLabel, Typography } from '@material-ui/core';
import {
    SecondaryButton,
    SecondaryCheckbox,
    Multiselect,
    MandatoryField,
    SimpleField,
    YesButton,
    NoButton,
    DatePicker,
    DateTimePicker,
    TimePicker,
    SelectBox,
    Postcode,
    SingleSelect,
    BackButton
} from '../../../Core/Controls';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AlarmIcon from '@material-ui/icons/Alarm';
import Tooltip from '@material-ui/core/Tooltip';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ServiceAgentProvider as StateProvider, ServiceAgentScreenContext } from './ServiceAgentProvider';
import { AppStorage } from '../../../Core/Basic/storage-service';

const YesNoRadio = (props) => {
    return (
        <Grid container xs={12}>
            <Grid container item xs={12}>
                <InputLabel shrink>{props.label}</InputLabel>
            </Grid>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" disabled={props.disabled} />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" disabled={props.disabled} />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

const FlaggedBox = (props) => {
    useEffect(() => {
        !props.chkValue && props.resetInput(props.txtName);
    }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const AddEditServiceAgent = (props) => {
    const { state, submitHandler, inputChange, setAllTime, selectHandler, ModuleChange, selectedData, resetInput, setVal, singleSelectChange, btnLoader } =
        useContext(ServiceAgentScreenContext);
    let checkhaveAccess = AppStorage.getAllowAcces();
    return (
        <>
            <BackButton onClick={props.onFormSubmit} />
            <Grid container direction={'row'} spacing={2} className="controller-screen">
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Name"
                        fullWidth
                        placeholder="Service Area Name"
                        value={state.serviceAgentName}
                        onChange={inputChange}
                        name="serviceAgentName"
                        errors={state.errors.serviceAgentName}
                        disabled={state.isDisable}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SimpleField
                        inputLabel="Account Code"
                        fullWidth
                        placeholder="Account Code"
                        value={state.serviceAgentAccountCode}
                        onChange={inputChange}
                        name="serviceAgentAccountCode"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentAccountCode}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    {/* <SimpleField
                    inputLabel="Post Code"
                    fullWidth
                    placeholder="Post Code"
                    value={state.serviceAgentPostCode}
                    onChange={inputChange}
                    name="serviceAgentPostCode"
                    disabled={state.isDisable}
                />
                <FormHelperText error>{state.errors.serviceAgentPostCode}</FormHelperText> */}
                    <Postcode
                        value={state.serviceAgentPostCode}
                        onChange={inputChange}
                        name="serviceAgentPostCode"
                        errors={state.errors.serviceAgentPostCode}
                        setVal={setVal}
                        disabled={state.isDisable}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Address 1"
                        fullWidth
                        placeholder="Address 1"
                        value={state.serviceAgentAddress1}
                        onChange={inputChange}
                        name="serviceAgentAddress1"
                        errors={state.errors.serviceAgentAddress1}
                        disabled={state.isDisable}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Address 2"
                        fullWidth
                        placeholder="Address 2"
                        value={state.serviceAgentAddress2}
                        onChange={inputChange}
                        name="serviceAgentAddress2"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentAddress2}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Address 3"
                        fullWidth
                        placeholder="Address 3"
                        value={state.serviceAgentCounty}
                        onChange={inputChange}
                        name="serviceAgentCounty"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentCounty}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="City"
                        fullWidth
                        placeholder="City"
                        value={state.serviceAgentCity}
                        onChange={inputChange}
                        name="serviceAgentCity"
                        errors={state.errors.serviceAgentCity}
                        disabled={state.isDisable}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Email"
                        fullWidth
                        placeholder="Email"
                        value={state.serviceAgentEmail}
                        onChange={inputChange}
                        name="serviceAgentEmail"
                        errors={state.errors.serviceAgentEmail}
                        disabled={state.isDisable}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Phone Number"
                        fullWidth
                        placeholder="Phone Number"
                        value={state.serviceAgentPhone}
                        onChange={inputChange}
                        name="serviceAgentPhone"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentPhone}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Fax"
                        fullWidth
                        placeholder="Fax"
                        value={state.serviceAgentFax}
                        onChange={inputChange}
                        name="serviceAgentFax"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentFax}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="WebSite"
                        fullWidth
                        placeholder="WebSite"
                        value={state.serviceAgentWebSite}
                        onChange={inputChange}
                        name="serviceAgentWebSite"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentWebSite}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                    <InputLabel InputLabel="" shrink>
                        Service Area Start Date
                    </InputLabel>
                    <DatePicker value={state.serviceAgentStartDate} onChange={inputChange} name="serviceAgentStartDate" disabled={state.isDisable} />

                    <FormHelperText error>{state.errors.serviceAgentStartDate}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                    <SimpleField
                        inputLabel="Nominal Code"
                        fullWidth
                        placeholder="Nominal Code"
                        value={state.serviceAgentNominalCode}
                        onChange={inputChange}
                        name="serviceAgentNominalCode"
                        disabled={state.isDisable}
                    />
                    <FormHelperText error>{state.errors.serviceAgentNominalCode}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Settings
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                {/* <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Labour Rate"
                                        fullWidth
                                        placeholder="Labour Rate"
                                        value={state.serviceAgentLabourRate}
                                        onChange={inputChange}
                                        name="serviceAgentLabourRate"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentLabourRate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Out Of Hours Labour Rate"
                                        fullWidth
                                        placeholder="Out Of Hours Labour Rate"
                                        value={state.serviceAgentOutOfHoursLabourRate}
                                        onChange={inputChange}
                                        name="serviceAgentOutOfHoursLabourRate"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentOutOfHoursLabourRate}</FormHelperText>
                                </Grid> */}

                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Maintenance Contract Ref"
                                        fullWidth
                                        placeholder="Maintenance Contract Ref"
                                        value={state.serviceAgentMaintenanceContractRef}
                                        onChange={inputChange}
                                        name="serviceAgentMaintenanceContractRef"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentMaintenanceContractRef}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                                    <InputLabel required shrink>
                                        Currency
                                    </InputLabel>
                                    <SingleSelect
                                        options={state.serviceAgentCurrencyIDList}
                                        value={state.serviceAgentCurrencyIDValue}
                                        onChange={singleSelectChange('serviceAgentCurrencyIDValue')}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentCurrencyIDValue ? 'This field is required' : null}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Service Manager Name"
                                        fullWidth
                                        placeholder="Service Manager Name"
                                        value={state.serviceAgentServiceManagerName}
                                        onChange={inputChange}
                                        name="serviceAgentServiceManagerName"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentServiceManagerName}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Service Manager Phone"
                                        fullWidth
                                        placeholder="Service Manager Phone"
                                        value={state.serviceAgentServiceManagerPhone}
                                        onChange={inputChange}
                                        name="serviceAgentServiceManagerPhone"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentServiceManagerPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Service Manager Email"
                                        fullWidth
                                        placeholder="Service Manager Email"
                                        value={state.serviceAgentServiceManagerEmail}
                                        onChange={inputChange}
                                        name="serviceAgentServiceManagerEmail"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentServiceManagerEmail}</FormHelperText>
                                </Grid>
                                {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Order Email"
                                    fullWidth
                                    placeholder="Order Email"
                                    value={state.serviceAgentOrderEmail}
                                    onChange={inputChange}
                                    name="serviceAgentOrderEmail"
                                />
                                <FormHelperText error>{state.errors.serviceAgentOrderEmail}</FormHelperText>
                            </Grid> */}

                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        label="Active?"
                                        name="serviceAgentActive"
                                        value={state.serviceAgentActive}
                                        onChange={inputChange}
                                        disabled={state.isDisable}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        label="Handling Defects?"
                                        name="handlingDefects"
                                        value={state.handlingDefects}
                                        onChange={inputChange}
                                        disabled={state.isDisable}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        label="Create Inspection Sheets?"
                                        name="hasInspectionSheetControl"
                                        value={state.hasInspectionSheetControl}
                                        onChange={inputChange}
                                        disabled={state.isDisable}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*  */}
                <Grid item xs={12} md={4} sm={6}>
                    <InputLabel shrink style={{ marginBottom: '8px' }}>
                        Payment Terms
                    </InputLabel>
                    <SingleSelect
                        options={state.serviceAgentPaymentDaysList || []}
                        value={state.serviceAgentPaymentDaysValue}
                        onChange={singleSelectChange('serviceAgentPaymentDaysValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputLabel InputLabel="" shrink>
                        Service Area Facilities
                    </InputLabel>
                    <Multiselect options={state.serviceAgentFacilitieslist} value={selectedData.modules} onChange={ModuleChange} disabled={state.isDisable} />
                </Grid>
                {/*  */}
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Notifications
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Order Confirmation Name"
                                        fullWidth
                                        placeholder="Order Confirmation Name"
                                        value={state.serviceAgentOrderConfirmationName}
                                        onChange={inputChange}
                                        name="serviceAgentOrderConfirmationName"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentOrderConfirmationName}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Order Confirmation Phone"
                                        fullWidth
                                        placeholder="Order Confirmation Phone"
                                        value={state.serviceAgentOrderConfirmationPhone}
                                        onChange={inputChange}
                                        name="serviceAgentOrderConfirmationPhone"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentOrderConfirmationPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FlaggedBox
                                        label="Order Confirmation Email"
                                        chkName="serviceAgentSendOrderConfirmation"
                                        chkValue={state.serviceAgentSendOrderConfirmation}
                                        onCheckChange={selectHandler}
                                        txtName="serviceAgentOrderConfirmationEmail"
                                        txtValue={state.serviceAgentOrderConfirmationEmail}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentOrderConfirmationEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Booking Name"
                                        fullWidth
                                        placeholder="Booking Name"
                                        value={state.serviceAgentBookingName}
                                        onChange={inputChange}
                                        name="serviceAgentBookingName"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentBookingName}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <SimpleField
                                        inputLabel="Booking Phone"
                                        fullWidth
                                        placeholder="Booking Phone"
                                        value={state.serviceAgentBookingPhone}
                                        onChange={inputChange}
                                        name="serviceAgentBookingPhone"
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentBookingPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FlaggedBox
                                        label="Booking Email"
                                        chkName="serviceAgentSendBookingEmail"
                                        chkValue={state.serviceAgentSendBookingEmail}
                                        onCheckChange={selectHandler}
                                        txtName="serviceAgentBookingEmail"
                                        txtValue={state.serviceAgentBookingEmail}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentBookingEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FlaggedBox
                                        label="Send Job Approval Required"
                                        chkName="serviceAgentSendJobApprovalReceived"
                                        chkValue={state.serviceAgentSendJobApprovalReceived}
                                        onCheckChange={selectHandler}
                                        txtName="serviceAgentobApprovalReceivedEmail"
                                        txtValue={state.serviceAgentobApprovalReceivedEmail}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentobApprovalReceivedEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FlaggedBox
                                        label="Job Update Email"
                                        chkName="serviceAgentSendJobUpdateEmail"
                                        chkValue={state.serviceAgentSendJobUpdateEmail}
                                        onCheckChange={selectHandler}
                                        txtName="serviceAgentJobUpdateEmail"
                                        txtValue={state.serviceAgentJobUpdateEmail}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentJobUpdateEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <FlaggedBox
                                        label="Send Query Response Received"
                                        chkName="serviceAgentSendQueryResponseReceived"
                                        chkValue={state.serviceAgentSendQueryResponseReceived}
                                        onCheckChange={selectHandler}
                                        txtName="serviceAgentQueryResponseReceivedEmail"
                                        txtValue={state.serviceAgentQueryResponseReceivedEmail}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.errors.serviceAgentQueryResponseReceivedEmail}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*  */}

                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" color="secondary">
                        Opening Times
                    </Typography>
                    <hr />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container item spacing={1} xs={12}>
                        {/* <Grid item xs={0} sm={3}></Grid> */}
                        <Grid component={Box} item xs={3} sm={3} display={{ xs: 'none', sm: 'block' }}></Grid>
                        {/* <Grid item xs={3} sm={3}>
                        Open
                    </Grid> */}
                        <Grid component={Box} item xs={3} sm={3} display={{ xs: 'none', sm: 'block' }}>
                            <span>Open</span>
                        </Grid>
                        <Grid container item xs={6} sm={3} justify="space-between">
                            <span>Opening Time</span>
                            <Tooltip title="Click to set Monday opening time on all days" aria-label="add">
                                <span style={{ marginRight: '4%' }}>
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={state.isDisable ? null : () => setAllTime('open')}
                                        disabled={state.serviceAgentMondayOpeningTime ? false : true}
                                    >
                                        <AlarmIcon fontSize="inherit" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid container item xs={6} sm={3} justify="space-between">
                            Closing Time
                            <Tooltip title="Click to set Monday close time on all days" aria-label="add">
                                <span style={{ marginRight: '4%' }}>
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={state.isDisable ? null : () => setAllTime('close')}
                                        disabled={state.serviceAgentMondayClosingTime ? false : true}
                                    >
                                        <AlarmIcon fontSize="inherit" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Monday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenMonday"
                                value={state.serviceAgentOpenMonday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentMondayOpeningTime"
                                value={state.serviceAgentMondayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentMondayClosingTime"
                                value={state.serviceAgentMondayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Tuesday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenTuesday"
                                value={state.serviceAgentOpenTuesday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentTuesdayOpeningTime"
                                value={state.serviceAgentTuesdayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentTuesdayClosingTime"
                                value={state.serviceAgentTuesdayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Wednesday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenWednesday"
                                value={state.serviceAgentOpenWednesday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentWednesdayOpeningTime"
                                value={state.serviceAgentWednesdayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentWednesdayClosingTime"
                                value={state.serviceAgentWednesdayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Thursday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenThursday"
                                value={state.serviceAgentOpenThursday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentThursdayOpeningTime"
                                value={state.serviceAgentThursdayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentThursdayClosingTime"
                                value={state.serviceAgentThursdayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Friday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenFriday"
                                value={state.serviceAgentOpenFriday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentFridayOpeningTime"
                                value={state.serviceAgentFridayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentFridayClosingTime"
                                value={state.serviceAgentFridayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Saturday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenSaturday"
                                value={state.serviceAgentOpenSaturday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentSaturdayOpeningTime"
                                value={state.serviceAgentSaturdayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentSaturdayClosingTime"
                                value={state.serviceAgentSaturdayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Sunday
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenSunday"
                                value={state.serviceAgentOpenSunday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentSundayOpeningTime"
                                value={state.serviceAgentSundayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentSundayClosingTime"
                                value={state.serviceAgentSundayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12} alignItems="center">
                        <Grid item xs={6} sm={3}>
                            Bank Holiday
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <YesNoRadio
                                label=""
                                name="serviceAgentOpenBankHoliday"
                                value={state.serviceAgentOpenBankHoliday}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentBankHolidayOpeningTime"
                                value={state.serviceAgentBankHolidayOpeningTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TimePicker
                                name="serviceAgentBankHolidayClosingTime"
                                value={state.serviceAgentBankHolidayClosingTime}
                                onChange={inputChange}
                                disabled={state.isDisable}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton onClick={submitHandler} disabled={!checkhaveAccess?.CanUpdateServiceAgents || state.isDisable} isBtnLoader={btnLoader}>
                            Save
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton forceEnabled={true} fullWidth variant="contained" color="secondary" onClick={props.onFormSubmit}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </>
            </Grid>
        </>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddEditServiceAgent {...props} />
        </StateProvider>
    );
};
export default Screen;
