import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getAllReports = async () => {
    let res = await getWithAuth('UserReports');
    return res;
};

export const getAllDropdowns = async () => {
    let res = await getWithAuth(`UserReports/ReportsDetailsDropDown`);
    return res;
};

export const getReportsById = async (Id) => {
    let res = await getWithAuth(`UserReports/ReportsDetailsGetDetails/${Id}`);
    return res;
};

export const ReportsDetailsSave = async (data) => {
    let res = await postWithAuth('UserReports/ReportsDetailsSave', data);
    return res;
};

export const ReportsColumnsGetDetails = async (id) => {
    let res = await getWithAuth(`UserReports/UserReportsColumnsGetDetails/${id}`);
    return res;
};

export const ReportsColumnsSave = async (data) => {
    let res = await postWithAuth('UserReports/UserReportsColumnsSave', data);
    return res;
};

export const ReportSelectionsGetDetails = async (id) => {
    let res = await getWithAuth(`UserReports/UserReportSelectionsGetDetails/${id}`);
    return res;
};

export const ReportSelectionsSave = async (data) => {
    let res = await postWithAuth(`UserReports/UserReportSelectionsSave`, data);
    return res;
};

export const UserReportSequenceSave = async (data) => {
    let res = await postWithAuth(`UserReports/UserReportSequenceSave`, data);
    return res;
};

export const GetReportSequence = async (id) => {
    let res = await getWithAuth(`UserReports/UserReportSequenceGetDetails/${id}`);
    return res;
};

export const ReportCombinedSave = async (data) => {
    let res = await postWithAuth(`UserReports/UserReportCombinedSave`, data);
    return res;
};

export const GetFilterdReport = async (id) => {
    let res = await getWithAuth(`UserReports/GetReportList/${id}`);
    return res;
};
export const GetReportExcelDFile = async (id) => {
    let res = await getWithAuth(`UserReports/GetExcelReport/${id}`, { responseType: 'blob', returnOrgRes: true });
    return res;
};

export const deleteReport = async (id) => {
    let res = await postWithAuth(`UserReports/UserReport_Delete?UserReportID=${id}`, {});
    return res;
};
