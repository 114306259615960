import React, { useContext } from 'react';
import { DatePicker, InputText, SecondaryButton, SimpleField, MandatoryField } from '../../../../../Core/Controls';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

import { RecallProvider as StateProvider, RecallScreenContext } from './reCallProvider';

const AddRecall = (props) => {
    const { state, inputChange, handleSubmit } = useContext(RecallScreenContext);
    let checkhaveAccess = AppStorage.getAllowAcces();

    return (
        <div className='"screen'>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <InputLabel shrink error>
                        Recall Number
                    </InputLabel>
                    <SimpleField onChange={inputChange} name="recallNumber" value={state?.recallNumber} autoFocus={true} placeholder="Recall number" />
                    <FormHelperText error>{state.error.recallNumber}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel shrink error>
                        Recall Date
                    </InputLabel>
                    <DatePicker onChange={inputChange} name="recallDate" value={state?.recallDate} />
                    <FormHelperText error>{state.error.recallDate}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel shrink>Expiry Date</InputLabel>
                    <DatePicker onChange={inputChange} name="recallExpiryDate" value={state?.recallExpiryDate} />
                    <FormHelperText error>{state.error.recallExpiryDate}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Description
                    </InputLabel>
                    <InputText multiline rows={4} onChange={inputChange} name="recallDescription" value={state?.recallDescription} placeholder="Description" />
                    <FormHelperText error>{state.error.recallDescription}</FormHelperText>
                </Grid>

                <Grid item xs={6}>
                    <SecondaryButton disabled={!checkhaveAccess?.CanUpdateVehicleRecalls} onClick={() => handleSubmit('add')}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={props.onClose}> Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddRecall {...props} />
        </StateProvider>
    );
};
export default Screen;
