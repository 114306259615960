import { Button, ButtonGroup, Grid, Switch } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import HireFleetLink from './HireFleetLinkModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import LinkIcon from '@material-ui/icons/Link';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import moment from 'moment';

var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Hire Fleet', path: '/', active: true }
];

const baseColumns = [
    // {
    //     key: 'action',
    //     name: 'Action',
    //     width: 80
    //     // addFileUploaderIcon: true
    // },
    {
        key: 'regNo',
        name: 'Reg Number',
        width: '15%'
        // events: {
        //     click: function (e) {
        //         props.history.push(`/controller/editVehicle/${e.data.record.vehicleID}`);
        //     }
        // }
    },
    { key: 'ref', name: 'Ref', width: '10%' },
    { key: 'vehicleType', name: 'Vehicle Type', width: '15%' },
    { key: 'customer', name: 'Customer', width: '15%' },
    { key: 'depot', name: 'Depot', width: '15%' },
    {
        key: 'startDate',
        name: 'Start Date',
        formatter: formatters.Date_DDMMYYYY,
        width: '15%'
    },
    { key: 'onSiteDate', name: 'Onsite Date', formatter: formatters.Date_DDMMYYYY, width: '15%' },
    { key: 'earliestOffHire', name: 'Earliest Off Hire', formatter: formatters.Date_DDMMYYYY, width: '20%' },
    { key: 'endDate', name: 'End', formatter: formatters.Date_DDMMYYYY, width: '15%' },
    { key: 'serviceAgentName', name: 'Service Area', width: '15%' },
    { key: 'tech', name: 'Technician', width: '15%' },
    // { key: 'purchaseOrder', name: 'Purchase Order', width: '15%' },
    { key: 'rate', name: 'Rate', formatter: formatters.NumberWithPound, width: '15%', align: 'right' },
    { key: 'status', name: 'Status', width: '15%' }
];

const HireFleetScreen = (props) => {
    const query = new URLSearchParams(props.history.location.search);
    const isFreeStock = query.get('isFreeStock');
    const isContractID = query.get('contractID');

    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');

    const [state, setState] = useState({
        filterFlag: !isFreeStock,
        checkedVehicleList: [],
        buttonState: 1
    });

    let checkhaveAccess = AppStorage.getAllowAcces();

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: true
        }));
    };

    let getUrl = `HireFleetContract/HireFleet_List?FreeStock=${state.filterFlag}`;

    const handleModal = (res, ContractID = '') => {
        setState((st) => {
            const newSt = { ...st };
            newSt.showModal = false;
            // if (res) newSt.isReload = new Date();
            return newSt;
        });
        if (res) {
            props.history.push({
                pathname: `/contractList/editcontractList/${ContractID}`,
                search: ``
            });
        }
    };

    const getVal = async (gridRef, recList) => {
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newList = val.map((m, i) => {
            let getValues = m - 1;
            return recList[getValues];
        });

        setState((st) => ({
            ...st,
            showModal: true,
            checkedVehicleList: newList
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable icon={LinkIcon} onClick={() => getVal(gridRef, valueList)} style={{ fontWeight: 'bold' }} toolTipTitle={'Link Contract'} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: `?redirectLabel=Contract`
        });
    };

    const renderRegNo = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row, 'showCustomerModal')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let regNoCol = tempCols.find((element) => element.key === 'regNo');

        if (regNoCol) {
            regNoCol.formatter = renderRegNo;
        }
        return tempCols;
    }, []);

    const onRowDataBound = (e, $row, id, record) => {
        if (record.contractID) {
            var checkboxCell = $row.find('input[type="checkbox"][data-role="selectRow"]').closest('td');
            checkboxCell.empty().append('<span></span>');
        }
        if (record.offHireDate) {
            $row.css('background-color', `#5AA57D`);
        }
        if (!record.offHireDate && moment(record.earliestOffHire).isBefore(moment())) {
            $row.css('background-color', `#A55A5A`);
        }
        if (!record.offHireDate && moment(record.earliestOffHire).isAfter(moment())) {
            $row.css('background-color', `#7D7D7D`);
        }
    };

    const onCellDataBound = (e, $row, id, column, record) => {
        if (record.offHireDate) {
            $row.css('color', `#F5F5F5`);
        }
        if (!record.offHireDate && moment(record.earliestOffHire).isBefore(moment())) {
            $row.css({
                color: '#F5F5F5'
            });
        }
        if (!record.offHireDate && moment(record.earliestOffHire).isAfter(moment())) {
            $row.css({
                color: '#F5F5F5'
            });
        }
    };

    // const handleFilter = (cond) => {
    //     setState((st) => ({
    //         ...st,
    //         activeToggle: cond
    //     }));
    // };

    const handleFilter = (cond, val) => {
        setState((st) => ({
            ...st,
            buttonState: val,
            filterFlag: cond
        }));
    };

    return (
        <div className="screen">
            <Grid container>
                {/* <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    <Grid component="label" item className="childSwitchBtnContainer" alignItems="center">
                        <Grid container alignItems="center">
                            <Grid item>Freestock</Grid>
                            <Grid item>
                                <Switch checked={state.filterFlag} onChange={handleChange} value="checked" name="filterFlag" />
                            </Grid>
                            <Grid item>All</Grid>
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    {!isEngineer && (
                        <Grid component="label" item alignItems="center">
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 1 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(true, 1)}
                                >
                                    Hired
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 2 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(false, 2)}
                                >
                                    Freestock
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 3 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('', 3)}
                                >
                                    All
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}
                </Grid>

                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={'Link Contarct'}
                    // onBtnClick={handleAdd}
                    isReload={state.isReload}
                    selectionType={checkhaveAccess.CanAddHireContracts ? 'multiple' : ''}
                    selectionMethod={checkhaveAccess.CanAddHireContracts ? 'checkbox' : ''}
                    showCheckboxColFilterBtn={checkhaveAccess.CanAddHireContracts ? mailButton : ''}
                    onRowDataBound={(e, $row, id, record) => onRowDataBound(e, $row, id, record)}
                    onCellDataBound={(e, $row, id, column, record) => onCellDataBound(e, $row, id, column, record)}
                    // idFieldName="serviceAgentID"
                    // fileUploaderConfigName="ServiceAgentScreen"
                    // mapBtnToolTipTitle={mapBtnTitle}
                    // mapBtnIcon={RoomIcon}
                    // mapBtnLabel={mapBtnTitle}
                    // onMapBtnClick={handleShowLocation}
                />
            </Grid>
            {state.showModal ? (
                <DialogComp title={'Setup Vehicle Hire Contract'} onClose={() => handleModal(false)} maxWidth="xl" fullWidth overflow="auto" fullScreen>
                    <HireFleetLink onClose={handleModal} selectedRecords={state.checkedVehicleList || []} contractID={isContractID} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default HireFleetScreen;
