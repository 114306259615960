import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Multiselect, SecondaryButton, SelectBox, MandatoryField, SimpleField, YesButton, NoButton } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';
import { getAllDropdowns, postScheduleEvents, getScheduleEventsById } from '../../../../Core/Services/InternalUser/scheduleEventsService';

const firstEventList = [
    { id: 1, name: 'Date From First Registration' },
    { id: 2, name: 'Date From Initial Certificate' }
];

const nextEventList = [
    { id: 1, name: 'Fixed Interval' },
    { id: 2, name: 'Date From Last Event' }
];

const ScheduleEventDialog = (props) => {
    const [selectedData, setSelectedData] = useState({
        multi: []
    });
    const [state, setState] = useState({
        scheduleEventName: '',
        scheduleEventInterval: '',
        scheduleEventTimeToFirst: '',
        scheduleEventMilesDue: '',
        scheduleEventFirstEvent: '',
        scheduleEventNextEvent: '',
        scheduleEventDayRange: '',
        vmrsCategory: '',
        vmrsSubCategory: '',
        nominalCode: null,
        scheduleEventInternalType: '',
        eventDocsIds: null,
        dropList: [],
        scheduleEventIntervalPeriodCode: '',
        scheduleEventDisplayInspectionLink: '',
        scheduleEventDisplayResult: false,
        allowedDayRangeList: [],
        scheduleEventInspectionTypeID: null,
        scheduleEventStandardLineIDValue: null,
        scheduleEventStandardLineID: null,
        scheduleEventDisplayPreviousDefects: false,
        scheduleEventCopyTyreData: false,
        scheduleEventLinkedEventID: null,
        scheduleEventStartEventLabel: '',
        scheduleEventEndEventLabel: '',
        scheduleEventDisplayCustomerDeclaration: null,
        customerDeclarationList: [
            { id: 0, name: 'None' },
            { id: 1, name: 'Onhire Declaration' },
            { id: 2, name: 'Offhire Declaration' }
        ]
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const [btnLoader, setBtnLoader] = useState(false);

    const pullDropList = async () => {
        let res = await getAllDropdowns();
        setState((st) => ({
            ...st,
            dropList: res.data
        }));
    };

    useEffect(async () => {
        if (!props.data.isAdd) {
            let res = await getScheduleEventsById(props.data.selectedRowData.scheduleEventID);
            let temp = res.data;
            setState((st) => ({
                ...st,
                scheduleEventID: temp.scheduleEventID,
                scheduleEventName: temp.scheduleEventName,
                scheduleEventInterval: temp.scheduleEventInterval,
                scheduleEventTimeToFirst: temp.scheduleEventTimeToFirst,
                scheduleEventMilesDue: temp.scheduleEventMilesDue,
                scheduleEventFirstEvent: temp.scheduleEventFirstEvent,
                scheduleEventNextEvent: temp.scheduleEventNextEvent,
                scheduleEventDayRange: temp.scheduleEventDayRange,
                vmrsCategory: temp.vmrsCategory,
                vmrsSubCategory: temp.vmrsSubCategory,
                nominalCode: temp.nominalCode,
                scheduleEventInternalType: temp.scheduleEventInternalType,
                eventDocsIds: temp.eventDocsIds || [],
                scheduleEventIntervalPeriodCode: temp.scheduleEventIntervalPeriodCode,
                scheduleEventDisplayInspectionLink: temp.scheduleEventDisplayInspectionLink,
                scheduleEventDisplayResult: temp.scheduleEventDisplayResult,
                scheduleEventReminderInterval: temp.scheduleEventReminderInterval,
                scheduleEventReminderMilesDue: temp.scheduleEventReminderMilesDue,
                scheduleEventReminderPeriodCode: temp.scheduleEventReminderPeriodCode,
                scheduleEventCreateNewEvent: temp.scheduleEventCreateNewEvent,
                scheduleEventInspectionTypeID: temp.scheduleEventInspectionTypeID,
                scheduleEventStandardLineID: temp.scheduleEventStandardLineID,
                scheduleEventDisplayPreviousDefects: temp.scheduleEventDisplayPreviousDefects,
                scheduleEventCopyTyreData: temp.scheduleEventCopyTyreData,
                scheduleEventLinkedEventID: temp.scheduleEventLinkedEventID,
                scheduleEventStartEventLabel: temp.scheduleEventStartEventLabel,
                scheduleEventEndEventLabel: temp.scheduleEventEndEventLabel,
                scheduleEventDisplayCustomerDeclaration: temp.scheduleEventDisplayCustomerDeclaration
            }));
        }
        pullDropList();
    }, []);

    useEffect(() => {
        if (state.eventDocsIds) {
            const a = state.eventDocsIds.map((i) => Number(i));
            const selectedModule = state.dropList.eventDocs?.filter((w) => a.indexOf(w.id) > -1);
            setSelectedData((st) => ({ ...st, multi: selectedModule }));
        }
    }, [state.dropList.eventDocs, state.eventDocsIds]);

    useEffect(() => {
        if (state.vmrsCategory) {
            const subModule = state.dropList.vmrsSubCategory?.filter((x) => x.vmrsCategory == state.vmrsCategory);
            setState((st) => ({
                ...st,
                vmrsSubCategoryList: subModule
            }));
        }
    }, [state.dropList.vmrsSubCategory, state.vmrsCategory]);

    // useEffect(() => {
    //     if (state.scheduleEventStandardLineID) {
    //         const subModule = state.dropList.standardLines?.filter((x) => x.id == state.scheduleEventStandardLineID);
    //         setState((st) => ({
    //             ...st,
    //             scheduleEventStandardLineIDValue: subModule
    //         }));
    //     }
    // }, [state.dropList.standardLines, state.scheduleEventStandardLineID]);

    useEffect(() => {
        const subModule = state.dropList.allowedDayRange?.map((x) => {
            return { id: x.allowedDayRangeID, name: x.allowedDayRangeDescription };
        });
        setState((st) => ({
            ...st,
            allowedDayRangeList: subModule || []
        }));
    }, [state.dropList.allowedDayRange]);

    const multiChange = (e, value) => {
        setSelectedData((st) => ({ ...st, multi: value }));
        setState((st) => {
            const nst = { ...st, eventDocsIds: value.map((c) => c.id) };
            return nst;
        });
    };
    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(state.scheduleEventName)) {
            err.scheduleEventName = true;
            noErrors = false;
        }

        if (+state.scheduleEventTimeToFirst < 0) {
            err.scheduleEventTimeToFirstN = true;
            noErrors = false;
        } else if (!/[^\s]/.test(state.scheduleEventTimeToFirst)) {
            err.scheduleEventTimeToFirst = true;
            noErrors = false;
        }

        if (!/[^\s]/.test(state.scheduleEventDayRange)) {
            err.scheduleEventDayRange = true;
            noErrors = false;
        }

        if (!(/[^\s]/.test(state.scheduleEventInterval) || /[^\s]/.test(state.scheduleEventMilesDue))) {
            err.bothNeeded = true;
            noErrors = false;
        } else {
            if (+state.scheduleEventInterval < 0) {
                err.scheduleEventIntervalN = true;
                noErrors = false;
            }
            if (+state.scheduleEventMilesDue < 0) {
                err.scheduleEventMilesDueN = true;
                noErrors = false;
            }
        }
        if (!/[^\s]/.test(state.scheduleEventFirstEvent)) {
            err.scheduleEventFirstEvent = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleEventNextEvent)) {
            err.scheduleEventNextEvent = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.vmrsCategory)) {
            err.vmrsCategory = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.vmrsSubCategory)) {
            err.vmrsSubCategory = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleEventInternalType)) {
            err.scheduleEventInternalType = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleEventIntervalPeriodCode)) {
            err.scheduleEventIntervalPeriodCode = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleEventStartEventLabel) && state.scheduleEventLinkedEventID) {
            err.scheduleEventStartEventLabel = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleEventEndEventLabel) && state.scheduleEventLinkedEventID) {
            err.scheduleEventEndEventLabel = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        validate();
        if (validate()) {
            setBtnLoader(true);
            let response = await postScheduleEvents({
                scheduleEventID: state.scheduleEventID || null,
                scheduleEventTimeToFirst: +state.scheduleEventTimeToFirst,
                scheduleEventInterval: +state.scheduleEventInterval,
                scheduleEventMilesDue: +state.scheduleEventMilesDue,
                scheduleEventInternalType: state.scheduleEventInternalType,
                scheduleEventFirstEvent: state.scheduleEventFirstEvent || null,
                scheduleEventName: state.scheduleEventName,
                scheduleEventNextEvent: state.scheduleEventNextEvent || null,
                vmrsCategory: state.vmrsCategory || null,
                vmrsSubCategory: state.vmrsSubCategory || null,
                // nominalCode: state.nominalCode || '',
                scheduleEventDayRange: +state.scheduleEventDayRange,
                eventDocsIds: state.eventDocsIds?.length > 0 ? state.eventDocsIds : null,
                scheduleEventIntervalPeriodCode: state.scheduleEventIntervalPeriodCode,
                scheduleEventDisplayInspectionLink: state.scheduleEventDisplayInspectionLink,
                scheduleEventDisplayResult: state.scheduleEventDisplayResult,

                scheduleEventReminderInterval: state.scheduleEventReminderInterval,
                scheduleEventReminderMilesDue: state.scheduleEventReminderMilesDue,
                scheduleEventReminderPeriodCode: state.scheduleEventReminderPeriodCode,
                scheduleEventCreateNewEvent: state.scheduleEventCreateNewEvent,
                scheduleEventInspectionTypeID: state.scheduleEventInspectionTypeID,
                scheduleEventStandardLineID: state.scheduleEventStandardLineID,
                scheduleEventDisplayPreviousDefects: state.scheduleEventDisplayPreviousDefects,
                scheduleEventCopyTyreData: state.scheduleEventCopyTyreData,
                scheduleEventLinkedEventID: state.scheduleEventLinkedEventID,
                scheduleEventStartEventLabel: state.scheduleEventStartEventLabel,
                scheduleEventEndEventLabel: state.scheduleEventEndEventLabel,
                scheduleEventDisplayCustomerDeclaration: state.scheduleEventDisplayCustomerDeclaration
            });
            if (response) {
                response.success
                    ? showToastSuccess(props.data.isAdd ? 'Schedule Events added successfully' : 'Schedule Events updated successfully')
                    : showToastError(response.message || 'Something went wrong');
                props.onClose();
            } else {
                showToastError('Something went wrong');
            }
            setBtnLoader(false);
        }
    };
    const valueChanged = (ev) => {
        const { name, value } = ev.target;
        if (name == 'scheduleEventLinkedEventID') {
            setState((st) => ({
                ...st,
                [name]: value,
                scheduleEventStartEventLabel: '',
                scheduleEventEndEventLabel: ''
            }));
        } else {
            setState((st) => ({
                ...st,
                [name]: value
            }));
        }
        setErrors({});
    };

    const yesNoClicked = (val) => (ev) => {
        let temp = ev.target;
        if (val === 1) {
            if (temp.innerHTML.toUpperCase() == 'YES') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayInspectionLink: true
                }));
            } else if (temp.innerHTML.toUpperCase() == 'NO') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayInspectionLink: false
                }));
            }
        }
        if (val === 2) {
            if (temp.innerHTML.toUpperCase() == 'YES') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayResult: true
                }));
            } else if (temp.innerHTML.toUpperCase() == 'NO') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayResult: false
                }));
            }
        }
        if (val === 3) {
            if (temp.innerHTML.toUpperCase() == 'YES') {
                setState((st) => ({
                    ...st,
                    scheduleEventCreateNewEvent: true
                }));
            } else if (temp.innerHTML.toUpperCase() == 'NO') {
                setState((st) => ({
                    ...st,
                    scheduleEventCreateNewEvent: false
                }));
            }
        }
        if (val === 4) {
            if (temp.innerHTML.toUpperCase() == 'YES') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayPreviousDefects: true
                }));
            } else if (temp.innerHTML.toUpperCase() == 'NO') {
                setState((st) => ({
                    ...st,
                    scheduleEventDisplayPreviousDefects: false
                }));
            }
        }
        if (val === 5) {
            if (temp.innerHTML.toUpperCase() == 'YES') {
                setState((st) => ({
                    ...st,
                    scheduleEventCopyTyreData: true
                }));
            } else if (temp.innerHTML.toUpperCase() == 'NO') {
                setState((st) => ({
                    ...st,
                    scheduleEventCopyTyreData: false
                }));
            }
        }
    };

    console.log(state, 'state');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink required>
                        Event Type
                    </InputLabel>
                    <SelectBox
                        name="scheduleEventInternalType"
                        value={state.scheduleEventInternalType || ''}
                        onChange={valueChanged}
                        List={state.dropList?.eventInternalTypes || []}
                    />
                    <FormHelperText error>{errors.scheduleEventInternalType ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={8} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Event Name"
                        name="scheduleEventName"
                        placeholder="Event Name"
                        defaultValue={props.data.selectedRowData.scheduleEventName || ''}
                        onChange={valueChanged}
                        errors={errors.scheduleEventName ? 'This field is required' : null}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        First Event
                    </InputLabel>
                    <SelectBox name="scheduleEventFirstEvent" value={state.scheduleEventFirstEvent || ''} onChange={valueChanged} List={firstEventList} />
                    <FormHelperText error>{errors.scheduleEventFirstEvent ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Time To First Event"
                        type="number"
                        name="scheduleEventTimeToFirst"
                        placeholder="0"
                        defaultValue={props.data.selectedRowData.scheduleEventTimeToFirst || ''}
                        onChange={valueChanged}
                        errors={
                            errors.scheduleEventTimeToFirstN ? 'Negative value not allowed' : errors.scheduleEventTimeToFirst ? 'This field is required' : null
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Interval Period
                    </InputLabel>
                    <SelectBox
                        name="scheduleEventIntervalPeriodCode"
                        value={state.scheduleEventIntervalPeriodCode || ''}
                        onChange={valueChanged}
                        List={state.dropList.eventIntervalPeriods || []}
                    />
                    <FormHelperText error>{errors.scheduleEventIntervalPeriodCode ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Next Event
                    </InputLabel>
                    <SelectBox name="scheduleEventNextEvent" value={state.scheduleEventNextEvent || ''} onChange={valueChanged} List={nextEventList} />
                    <FormHelperText error>{errors.scheduleEventNextEvent ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Event Interval"
                        type="number"
                        name="scheduleEventInterval"
                        placeholder="Event Interval"
                        defaultValue={props.data.selectedRowData.scheduleEventInterval || ''}
                        onChange={valueChanged}
                        errors={
                            errors.scheduleEventIntervalN
                                ? 'Negative value not allowed'
                                : errors.bothNeeded
                                ? 'Both Event Interval and Event Miles Due can not be empty at same time'
                                : null
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SimpleField
                        inputLabel="Event Miles Due"
                        type="number"
                        name="scheduleEventMilesDue"
                        placeholder="Event Miles Due"
                        value={state.scheduleEventMilesDue || ''}
                        onChange={valueChanged}
                        errors={
                            errors.scheduleEventMilesDueN
                                ? 'Negative value not allowed'
                                : errors.bothNeeded
                                ? 'Both Event Interval and Event Miles Due can not be empty at same time'
                                : null
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Allowed Day Range
                    </InputLabel>
                    <SelectBox
                        insertEmpty={false}
                        name="scheduleEventDayRange"
                        value={state.scheduleEventDayRange}
                        onChange={valueChanged}
                        List={state.allowedDayRangeList}
                    />
                    <FormHelperText error>{errors.scheduleEventDayRange ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VMRS Category
                    </InputLabel>
                    <SelectBox name="vmrsCategory" value={state.vmrsCategory || ''} onChange={valueChanged} List={state.dropList?.vmrsCategory || []} />
                    <FormHelperText error>{errors.vmrsCategory ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VMRS Sub Category
                    </InputLabel>
                    <SelectBox name="vmrsSubCategory" value={state.vmrsSubCategory || ''} onChange={valueChanged} List={state.vmrsSubCategoryList || []} />
                    <FormHelperText error>{errors.vmrsSubCategory ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Inspection Type</InputLabel>
                    <SelectBox
                        name="scheduleEventInspectionTypeID"
                        value={state.scheduleEventInspectionTypeID || ''}
                        onChange={valueChanged}
                        List={state.dropList?.inspectionSheetDocs || []}
                    />
                    {/* <Grid container item spacing={1}>
                        <Grid item xs={4} md={4}>
                            <YesButton
                                name="scheduleEventDisplayInspectionLink"
                                state={state.scheduleEventDisplayInspectionLink}
                                data={true}
                                onClick={yesNoClicked(1)}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <NoButton
                                name="scheduleEventDisplayInspectionLink"
                                state={state.scheduleEventDisplayInspectionLink}
                                data={false}
                                onClick={yesNoClicked(1)}
                            />
                        </Grid>
                    </Grid> */}
                    <FormHelperText error>{errors.scheduleEventNextEvent ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel required shrink>
                        Standard Lines
                    </InputLabel>
                    <SelectBox
                        name="scheduleEventStandardLineID"
                        value={state.scheduleEventStandardLineID || ''}
                        onChange={valueChanged}
                        List={state.dropList.standardLines || []}
                    />
                    <FormHelperText error>{errors.scheduleEventStandardLineID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Display Result on Completion?</InputLabel>
                    <Grid container item spacing={1}>
                        <Grid item xs={4} md={4}>
                            <YesButton
                                name="scheduleEventDisplayResult"
                                state={state.scheduleEventDisplayResult}
                                data={true}
                                onClick={yesNoClicked(2)}

                                // onClick={() => setState((st) => ({ ...st, scheduleEventDisplayResult: true }))}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <NoButton
                                name="scheduleEventDisplayResult"
                                state={state.scheduleEventDisplayResult}
                                data={false}
                                onClick={yesNoClicked(2)}
                                // onClick={() => setState((st) => ({ ...st, scheduleEventDisplayResult: false }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Create New Event on Completion?</InputLabel>
                    <Grid container item spacing={1}>
                        <Grid item xs={4} md={4}>
                            <YesButton
                                name="scheduleEventCreateNewEvent"
                                state={state.scheduleEventCreateNewEvent}
                                data={true}
                                onClick={yesNoClicked(3)}

                                // onClick={() => setState((st) => ({ ...st, scheduleEventCreateNewEvent: true }))}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <NoButton
                                name="scheduleEventCreateNewEvent"
                                state={state.scheduleEventCreateNewEvent}
                                data={false}
                                onClick={yesNoClicked(3)}
                                // onClick={() => setState((st) => ({ ...st, scheduleEventCreateNewEvent: false }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Display Previous Defects?</InputLabel>
                    <Grid container item spacing={1}>
                        <Grid item xs={4} md={4}>
                            <YesButton
                                name="scheduleEventDisplayPreviousDefects"
                                state={state.scheduleEventDisplayPreviousDefects}
                                data={true}
                                onClick={yesNoClicked(4)}

                                // onClick={() => setState((st) => ({ ...st, scheduleEventCreateNewEvent: true }))}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <NoButton
                                name="scheduleEventDisplayPreviousDefects"
                                state={state.scheduleEventDisplayPreviousDefects}
                                data={false}
                                onClick={yesNoClicked(4)}
                                // onClick={() => setState((st) => ({ ...st, scheduleEventCreateNewEvent: false }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Copy Tyre Data? </InputLabel>
                    <Grid container item spacing={1}>
                        <Grid item xs={4} md={4}>
                            <YesButton
                                name="scheduleEventCopyTyreData"
                                state={state.scheduleEventCopyTyreData}
                                data={true}
                                onClick={yesNoClicked(5)}

                                // onClick={() => setState((st) => ({ ...st, scheduleEventCopyTyreData: true }))}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <NoButton
                                name="scheduleEventCopyTyreData"
                                state={state.scheduleEventCopyTyreData}
                                data={false}
                                onClick={yesNoClicked(5)}
                                // onClick={() => setState((st) => ({ ...st, scheduleEventCreateNewEvent: false }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Linked Event</InputLabel>
                    <SelectBox
                        name="scheduleEventLinkedEventID"
                        value={state.scheduleEventLinkedEventID || ''}
                        onChange={valueChanged}
                        List={state.dropList?.linkedEvents || []}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className={state.scheduleEventLinkedEventID ? 'mandatory-fields' : ''}>
                    <InputLabel required={state.scheduleEventLinkedEventID ? true : false} shrink>
                        Start Event Label
                    </InputLabel>
                    <SimpleField
                        name="scheduleEventStartEventLabel"
                        placeholder="Start Event Label"
                        value={state.scheduleEventStartEventLabel || ''}
                        onChange={valueChanged}
                    />
                    <FormHelperText error>{errors.scheduleEventStartEventLabel ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className={state.scheduleEventLinkedEventID ? 'mandatory-fields' : ''}>
                    <InputLabel required={state.scheduleEventLinkedEventID ? true : false} shrink>
                        End Event Label
                    </InputLabel>
                    <SimpleField
                        name="scheduleEventEndEventLabel"
                        placeholder="End Event Label"
                        value={state.scheduleEventEndEventLabel || ''}
                        onChange={valueChanged}
                    />
                    <FormHelperText error>{errors.scheduleEventEndEventLabel ? 'This field is required' : null}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Display Customer Declaration</InputLabel>
                    <SelectBox
                        name="scheduleEventDisplayCustomerDeclaration"
                        value={state.scheduleEventDisplayCustomerDeclaration || ''}
                        onChange={valueChanged}
                        insertEmpty={false}
                        List={state.customerDeclarationList}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <SimpleField
                        inputLabel="Nominal Code"
                        name="nominalCode"
                        placeholder="Nominal Code"
                        defaultValue={props.data.selectedRowData.nominalCode || ''}
                        onChange={valueChanged}
                    />
                </Grid> */}

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary">
                                Reminder
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Before due"
                                name="scheduleEventReminderInterval"
                                placeholder="Before due"
                                value={state.scheduleEventReminderInterval || ''}
                                onChange={valueChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink>Period</InputLabel>
                            <SelectBox
                                name="scheduleEventReminderPeriodCode"
                                value={state.scheduleEventReminderPeriodCode || ''}
                                onChange={valueChanged}
                                List={state.dropList.eventIntervalPeriods || []}
                            />
                            <FormHelperText error>{errors.scheduleEventReminderPeriodCode ? 'This field is required' : null}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Mileage before due"
                                name="scheduleEventReminderMilesDue"
                                placeholder="Mileage before due"
                                value={state.scheduleEventReminderMilesDue || ''}
                                onChange={valueChanged}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel shrink>Documents Required</InputLabel>
                    <Multiselect
                        name="eventDocsIds"
                        placeholder="Documents Required"
                        options={state.dropList?.eventDocs || []}
                        value={selectedData.multi || []}
                        onChange={multiChange}
                    />
                </Grid>
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                        {props.data.isAdd ? 'ADD' : 'UPDATE'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default ScheduleEventDialog;
