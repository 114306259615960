import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { getCostTypesById, postCostTypesData } from '../../../../Core/Services/InternalUser/costTypeService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function CostTypesModal(props) {
    const [state, setState] = useState({
        costTypeCode: '',
        costTypeDescription: '',
        costTypeActive: true,
        costTypeRechargeToCustomer: true,
        costTypeVisibleInDropdown: true,
        costTypeAllowRechargeUpdate: true,
        costTypePaySupplier: true,
        costTypeAddPartMileage: 0,
        costTypeLabourRate: 0,
        costTypeLabourInternalRate: 0,
        IsAdd: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.data) {
            let res = await getCostTypesById(props.data.costTypeCode);
            const data = res.data?.list[0];
            setState((state) => ({
                ...state,

                costTypeCode: data.costTypeCode,
                costTypeDescription: data.costTypeDescription,
                costTypeActive: data.costTypeActive,
                costTypeRechargeToCustomer: data.costTypeRechargeToCustomer,
                costTypeVisibleInDropdown: data.costTypeVisibleInDropdown,
                costTypeAllowRechargeUpdate: data.costTypeAllowRechargeUpdate,
                costTypePaySupplier: data.costTypePaySupplier,
                code: data.costTypeCode,
                IsAdd: data.IsAdd,
                costTypeAddPartMileage: data.costTypeAddPartMileage,
                costTypeLabourRate: data.costTypeLabourRate,
                costTypeLabourInternalRate: data.costTypeLabourInternalRate
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { costTypeDescription, costTypeCode } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        let errorMessage = '';
        if (!emptyStrigPattern.test(costTypeDescription)) {
            errors.costTypeDescription = 'Cost Type Description is required';
            errorMessage = errorMessage.concat(errors.costTypeDescription) + '\n';
            // console.log(errorMessage.concat(errors.costTypeDescription));
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(costTypeCode)) {
            errors.costTypeCode = 'Cost Type is required';
            errorMessage = errorMessage.concat(errors.costTypeCode) + '\n';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        if (errorMessage) {
            alert(errorMessage);
        }
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const AllData = {
                costTypeCode: state.costTypeCode,
                costTypeDescription: state.costTypeDescription,
                costTypeActive: state.costTypeActive,
                costTypeRechargeToCustomer: state.costTypeRechargeToCustomer,
                costTypeVisibleInDropdown: state.costTypeVisibleInDropdown,
                costTypeAllowRechargeUpdate: state.costTypeAllowRechargeUpdate,
                costTypePaySupplier: state.costTypePaySupplier,
                isAdd: state.code ? false : true,
                costTypeAddPartMileage: state.costTypeAddPartMileage,
                costTypeLabourRate: state.costTypeLabourRate,
                costTypeLabourInternalRate: state.costTypeLabourInternalRate
            };
            setBtnLoader(true);
            let res = await postCostTypesData(AllData);
            if (res.success) {
                showToastSuccess('Cost Type Saved Successfully');
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={9} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="costTypeDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.costTypeDescription}
                    errors={state.errors.costTypeDescription}
                    autoFocus={true}
                />
            </Grid>
            <Grid item xs={3} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Cost Type"
                    name="costTypeCode"
                    placeholder="Cost Type"
                    onChange={handleInputChange}
                    value={state.costTypeCode}
                    errors={state.errors.costTypeCode}
                    disabled={!!state.code}
                />
            </Grid>
            <Grid item xs={9}>
                <SimpleField
                    inputLabel="Customer Rate"
                    name="costTypeLabourRate"
                    placeholder="Customer Rate"
                    onChange={handleInputChange}
                    value={state.costTypeLabourRate}
                    errors={state.errors.costTypeLabourRate}
                />
            </Grid>
            <Grid item xs={3}>
                <SimpleField
                    inputLabel="Internal Rate"
                    name="costTypeLabourInternalRate"
                    placeholder="Internal Rate"
                    onChange={handleInputChange}
                    value={state.costTypeLabourInternalRate}
                    errors={state.errors.costTypeLabourInternalRate}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox
                    checked={state.costTypeRechargeToCustomer}
                    name="costTypeRechargeToCustomer"
                    onChange={handleCheckbox}
                    label="Recharge To Customer?"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox
                    checked={state.costTypeAllowRechargeUpdate}
                    name="costTypeAllowRechargeUpdate"
                    onChange={handleCheckbox}
                    label="Allow Recharge Update?"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox
                    checked={state.costTypeVisibleInDropdown}
                    name="costTypeVisibleInDropdown"
                    onChange={handleCheckbox}
                    label="Visible in dropdown?"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox checked={state.costTypePaySupplier} name="costTypePaySupplier" onChange={handleCheckbox} label="Pay to Supplier?" />
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <SecondaryCheckbox checked={state.costTypeAddPartMileage} name="costTypeAddPartMileage" onChange={handleCheckbox} label="Add Part Mileage?" />
            </Grid> */}

            <Grid item xs={12} md={6}>
                <SecondaryCheckbox checked={state.costTypeActive} name="costTypeActive" onChange={handleCheckbox} label="Is Active?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.data ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default CostTypesModal;
