import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../Core/Controls/Grid/index';
import { getJobsByScreenCode } from '../../../Core/Services/ServiceAgent/serviceAgentJobsService';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import JobQueryModal from '../../InternalUser/Controllers/JOBS/JobStatusBtn/JobQuery';
import DialogComp from '../../../Core/Dialog/DialogComp';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../Core/Basic/storage-service';
import ReactDOM from 'react-dom';

const base_columns = [
    { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg No', width: 120 },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 120 },
    { key: 'agentOrderNo', name: 'Job Ref', width: 140 },
    { key: 'jobDescription', name: 'Job Description' },
    // { key: 'serviceAgentName', name: 'Service Agent' },
    // { key: 'depotName', name: 'Depots' },
    {
        key: 'partsTotal',
        name: 'Parts',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 100
    },
    {
        key: 'labourTotal',
        name: 'Labour',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 100
    },
    {
        key: 'orderTotal',
        name: 'Total',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 120
    },
    { key: 'customerCompanyName', name: 'Customer', width: 180 }
];

const getCrumbs = (code, isServiceAgent) => {
    let temp = {};
    let final = [
        { name: 'Home', path: '/' },
        { name: code == 'ER' ? 'Estimates' : 'Jobs', active: true }
    ];
    if (code == 'PP' || code == 'AR' || code == 'FR') {
        temp = { name: 'Awaiting Approvals', active: true };
    } else if (code == 'BO') {
        temp = { name: 'Breakdown Onsite', active: true };
    } else if (code == 'BR') {
        temp = { name: 'Breakdown Roadside', active: true };
    } else if (code == 'DD') {
        temp = { name: 'Driver Defects', active: true };
    } else if (code == 'EP') {
        temp = { name: `${isServiceAgent ? 'Estimates Pending' : 'Awaiting Approval'}`, active: true };
    } else if (code == 'ER') {
        temp = { name: 'Estimate Revised', active: true };
    } else if (code == 'SD') {
        temp = { name: 'Schedule Defects', active: true };
    } else if (code == 'EC') {
        temp = { name: 'Estimate Under Customer Review', active: true };
    } else if (code == 'QJ' || code == 'JQ') {
        temp = { name: 'Queried Jobs', active: true };
    } else if (code == 'EA') {
        temp = { name: 'Estimates Accepted', active: true };
    } else if (code == 'EQ') {
        temp = { name: 'Estimates Queried', active: true };
    } else if (code == 'CA') {
        temp = { name: 'Approved', active: true };
    } else if (code == 'JC') {
        temp = { name: 'Job Completed', active: true };
    } else {
        temp = { name: 'Open Jobs', active: true };
    }
    final.push(temp);
    return final;
};
const CommonJobsScreen = (props) => {
    const [state, setState] = useState({ showLoader: true, open: false, rows: [], isReload: null });
    const history = useHistory();
    const { userRoles } = useLoginInfo();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const isServiceAgent = userRoles.includes('servicearea');

    useEffect(async () => {
        pullApiData();
    }, []);

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.jobVehicleID}`
        });
    };

    const handleShowQueries = (val) => {
        setState((state) => ({ ...state, showJobQuery: true, jobID: val?.jobID }));
    };

    const closeTheDialog = (val) => {
        setState((state) => ({ ...state, showJobQuery: false, jobID: '' }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={SpeakerNotesIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Show Queries"
                    onClick={() => handleShowQueries(row)}
                />
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" onClick={() => handleEditClick(row)} toolTipTitle="Edit Job" />
                )}
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        let actionjobDateCol = newColumn.find((item) => item.key === 'jobDate');
        if (actionjobDateCol || true) {
            actionjobDateCol.formatter = ({ row, col }, grid) => {
                const spn = document.createElement('span');
                const lnk = (
                    <div style={{ textAlign: 'center' }}>
                        {row.bookingDate ? formatters.YearFormatter(row.bookingDate) : formatters.YearFormatter(row.jobDate)}
                    </div>
                );
                ReactDOM.render(lnk, spn);
                return spn;
            };
        }
        // if (!isServiceAgent) {
        //     newColumn.splice(1, 0, { key: 'serviceAgentName', name: 'Service Agent' });
        // }
        return newColumn;
    }, []);

    const crumbs = getCrumbs(props.screenCode, isServiceAgent);

    const jobHandler = () => {
        alert('hello');
    };

    const closeModal = () => {
        setState((st) => ({ ...st, open: false }));
    };
    let getUrl = `ServiceAgentJobs?JobStatusID=${props.screenCode}`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.schedules,
                    total: res.data.total
                })}
                columns={columns}
                idFieldName={`${checkhaveAccess?.CanUploadJobDocuments ? 'jobID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanUploadJobDocuments ? 'JobScreen' : null}`}
                getUrl={getUrl}
                // btnTitle={gridBtnTitle}
                onBtnClick={jobHandler}
                isReload={state.isReload}
            />
            {state.showJobQuery && (
                <DialogComp title="Job Queries" maxWidth="sm" onClose={closeTheDialog}>
                    <JobQueryModal title="" jobQueryJobID={state.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
        </div>
    );
};
export default CommonJobsScreen;
