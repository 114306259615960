import React, { useState, useCallback, useEffect } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { InputLabel, Grid, Typography, RadioGroup, Radio, FormControlLabel, FormHelperText } from '@material-ui/core';
import { MandatoryField, SelectBox, SimpleField, DatePicker, SecondaryButton, formatters } from '../../../../../Core/Controls';
import { getFleetInsurancesByID, postFleetInsurances } from '../../../../../Core/Services/InternalUser/fleetInsurance';
import { FromLoader } from '../../../../../Core/Loader/formLoader';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
const AddFleetInsurance = (props) => {
    const [state, setState] = useState({
        fleetInsuranceCustomerID: props.customerID,
        fleetInsuranceId: props.fleetInsuranceId,
        fleetInsurancePremiumFrequency: null,
        fleetInsuranceUseBusiness: false,
        fleetInsuranceDriversAgency: false,
        fleetInsuranceUseSDP: false,
        fleetInsuranceUseCarriage: false,
        errors: {},
        showLoader: props.fleetInsuranceId ? true : false
    });
    const [btnLoader, setBtnLoader] = useState(false);
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastSuccess, showToastError } = useToast();

    const handleInput = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => {
            let state = { ...st };
            if (value === 'true' && type.toLowerCase() === 'radio') {
                state[name] = true;
            } else if (value === 'false' && type.toLowerCase() === 'radio') {
                state[name] = false;
            } else {
                state[name] = value;
            }

            return state;
        });
    }, []);

    let premiumFrequency = [
        {
            id: 'A',
            name: 'Annual'
        },
        {
            id: 'M',
            name: 'Monthly'
        }
    ];

    useEffect(async () => {
        if (props.fleetInsuranceId) {
            let res = await getFleetInsurancesByID(props.fleetInsuranceId);
            if (res.success) {
                let data = res.data?.list[0];
                setState((st) => ({
                    ...st,
                    fleetInsuranceId: data.fleetInsuranceId,
                    fleetInsuranceCustomerID: data.fleetInsuranceCustomerID,
                    fleetInsuranceInsurerName: data.fleetInsuranceInsurerName,
                    fleetInsuranceInsurerContactName: data.fleetInsuranceInsurerContactName,
                    fleetInsuranceInsurerTelephone: data.fleetInsuranceInsurerTelephone,
                    fleetInsuranceInsurerEmail: data.fleetInsuranceInsurerEmail,
                    fleetInsurancePolicyNumber: data.fleetInsurancePolicyNumber,
                    fleetInsuranceBrokerName: data.fleetInsuranceBrokerName,
                    fleetInsuranceBrokerContactName: data.fleetInsuranceBrokerContactName,
                    fleetInsuranceBrokerTelephone: data.fleetInsuranceBrokerTelephone,
                    fleetInsuranceBrokerEmail: data.fleetInsuranceBrokerEmail,
                    fleetInsuranceStartDate: formatters.DateFormatter(data.fleetInsuranceStartDate),
                    fleetInsuranceExpiryDate: formatters.DateFormatter(data.fleetInsuranceExpiryDate),
                    fleetInsuranceReviewFrom: formatters.DateFormatter(data.fleetInsuranceReviewFrom),
                    fleetInsurancePremium: data.fleetInsurancePremium,
                    fleetInsuranceBusinessType: data.fleetInsuranceBusinessType,
                    fleetInsuranceBusinessEstablished: data.fleetInsuranceBusinessEstablished,
                    fleetInsuranceUseSDP: data.fleetInsuranceUseSDP,
                    fleetInsuranceUseBusiness: data.fleetInsuranceUseBusiness,
                    fleetInsuranceUseCarriage: data.fleetInsuranceUseCarriage,
                    fleetInsuranceUseOther: data.fleetInsuranceUseOther,
                    fleetInsuranceExcessAccidentDamage: data.fleetInsuranceExcessAccidentDamage,
                    fleetInsuranceExcessFireAndTheft: data.fleetInsuranceExcessFireAndTheft,
                    fleetInsuranceExcessWindcreen: data.fleetInsuranceExcessWindcreen,
                    fleetInsuranceDriversUnder25: data.fleetInsuranceDriversUnder25,
                    fleetInsuranceDrivers25to29: data.fleetInsuranceDrivers25to29,
                    fleetInsuranceDrivers30orOver: data.fleetInsuranceDrivers30orOver,
                    fleetInsuranceDriversAgency: data.fleetInsuranceDriversAgency,
                    fleetInsuranceNotes: data.fleetInsuranceNotes,
                    fleetInsuranceMIDUpdatedBy: data.fleetInsuranceMIDUpdatedBy,
                    fleetInsurancePremiumFrequency: data.fleetInsurancePremiumFrequency,
                    showLoader: false
                }));
            }
        }
    }, []);

    const validation = () => {
        const { fleetInsuranceInsurerName, fleetInsurancePolicyNumber, fleetInsuranceExpiryDate, fleetInsurancePremiumFrequency } = state;
        let errors = {};
        let isValid = true;
        if (!fleetInsuranceInsurerName) {
            errors.fleetInsuranceInsurerName = 'Insurer Name is required';
            isValid = false;
        }
        if (!fleetInsurancePolicyNumber) {
            errors.fleetInsurancePolicyNumber = 'Policy Number is required';
            isValid = false;
        }
        if (!fleetInsuranceExpiryDate) {
            errors.fleetInsuranceExpiryDate = 'Renew Date is required';
            isValid = false;
        }
        if (!fleetInsurancePremiumFrequency) {
            errors.fleetInsurancePremiumFrequency = 'Premium Frequency is required';
            isValid = false;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                fleetInsuranceId: state.fleetInsuranceId || null,
                fleetInsuranceCustomerID: state.fleetInsuranceCustomerID || null,
                fleetInsuranceInsurerName: state.fleetInsuranceInsurerName || null,
                fleetInsuranceInsurerContactName: state.fleetInsuranceInsurerContactName || null,
                fleetInsuranceInsurerTelephone: state.fleetInsuranceInsurerTelephone || null,
                fleetInsuranceInsurerEmail: state.fleetInsuranceInsurerEmail || null,
                fleetInsurancePolicyNumber: state.fleetInsurancePolicyNumber || null,
                fleetInsuranceBrokerName: state.fleetInsuranceBrokerName || null,
                fleetInsuranceBrokerContactName: state.fleetInsuranceBrokerContactName || null,
                fleetInsuranceBrokerTelephone: state.fleetInsuranceBrokerTelephone || null,
                fleetInsuranceBrokerEmail: state.fleetInsuranceBrokerEmail || null,
                fleetInsuranceStartDate: formatters.DateFormatter(state.fleetInsuranceStartDate) || null,
                fleetInsuranceExpiryDate: formatters.DateFormatter(state.fleetInsuranceExpiryDate) || null,
                fleetInsuranceReviewFrom: formatters.DateFormatter(state.fleetInsuranceReviewFrom) || null,
                fleetInsurancePremium: state.fleetInsurancePremium || null,
                fleetInsuranceBusinessType: state.fleetInsuranceBusinessType || null,
                fleetInsuranceBusinessEstablished: state.fleetInsuranceBusinessEstablished || null,
                fleetInsuranceUseSDP: state.fleetInsuranceUseSDP,
                fleetInsuranceUseBusiness: state.fleetInsuranceUseBusiness,
                fleetInsuranceUseCarriage: state.fleetInsuranceUseCarriage,
                fleetInsuranceUseOther: state.fleetInsuranceUseOther || null,
                fleetInsuranceExcessAccidentDamage: state.fleetInsuranceExcessAccidentDamage || null,
                fleetInsuranceExcessFireAndTheft: state.fleetInsuranceExcessFireAndTheft || null,
                fleetInsuranceExcessWindcreen: state.fleetInsuranceExcessWindcreen || null,
                fleetInsuranceDriversUnder25: state.fleetInsuranceDriversUnder25 || null,
                fleetInsuranceDrivers25to29: state.fleetInsuranceDrivers25to29 || null,
                fleetInsuranceDrivers30orOver: state.fleetInsuranceDrivers30orOver || null,
                fleetInsuranceDriversAgency: state.fleetInsuranceDriversAgency,
                fleetInsuranceNotes: state.fleetInsuranceNotes || null,
                fleetInsuranceMIDUpdatedBy: state.fleetInsuranceMIDUpdatedBy || null,
                fleetInsurancePremiumFrequency: state.fleetInsurancePremiumFrequency || null
            };

            let res = await postFleetInsurances(data);
            if (res.success) {
                showToastSuccess('Fleet Insurance added successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(true);
            }
            setBtnLoader(false);
        }
    };

    return (
        <div>
            {state.showLoader ? (
                <FromLoader />
            ) : (
                <Grid container direction="row" spacing={2}>
                    <Grid item container spacing={2} md={12} xs={12} lg={12}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Insurer & Policy Details{' '}
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={3} className="mandatory-fields">
                            <MandatoryField
                                type="text"
                                inputLabel="Insurer Name"
                                name="fleetInsuranceInsurerName"
                                value={state.fleetInsuranceInsurerName}
                                onChange={handleInput}
                                placeholder="Insurer name"
                                errors={state.errors.fleetInsuranceInsurerName}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs={3} className="mandatory-fields">
                            <MandatoryField
                                type="text"
                                inputLabel="Policy Number"
                                name="fleetInsurancePolicyNumber"
                                value={state.fleetInsurancePolicyNumber}
                                onChange={handleInput}
                                placeholder="Policy number"
                                errors={state.errors.fleetInsurancePolicyNumber}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleField
                                type="text"
                                inputLabel="Insurer Contact Name"
                                name="fleetInsuranceInsurerContactName"
                                value={state.fleetInsuranceInsurerContactName}
                                onChange={handleInput}
                                placeholder="Insurer contact name"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleField
                                type="text"
                                inputLabel="Telephone No."
                                name="fleetInsuranceInsurerTelephone"
                                value={state.fleetInsuranceInsurerTelephone}
                                onChange={handleInput}
                                placeholder="Telephone no."
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleField
                                type="email"
                                inputLabel="Email Address"
                                name="fleetInsuranceInsurerEmail"
                                value={state.fleetInsuranceInsurerEmail}
                                onChange={handleInput}
                                placeholder="Email address"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleField
                                type="text"
                                name="fleetInsuranceMIDUpdatedBy"
                                inputLabel="MID Updated By"
                                value={state.fleetInsuranceMIDUpdatedBy}
                                onChange={handleInput}
                                placeholder="MID update by"
                            />
                        </Grid>{' '}
                        <Grid item xs={3}>
                            <InputLabel shrink>Policy Start Date</InputLabel>
                            <DatePicker
                                name="fleetInsuranceStartDate"
                                onChange={handleInput}
                                value={state.fleetInsuranceStartDate}
                                InputProps={{ inputProps: { max: `${state.fleetInsuranceExpiryDate}` } }}
                            />
                        </Grid>
                        <Grid item xs={3} className="mandatory-fields">
                            <InputLabel shrink>Renewal Date *</InputLabel>
                            <DatePicker
                                onChange={handleInput}
                                name="fleetInsuranceExpiryDate"
                                value={state.fleetInsuranceExpiryDate}
                                InputProps={{ inputProps: { min: `${state.fleetInsuranceStartDate}` } }}
                            />
                            <FormHelperText error>{state.errors.fleetInsuranceExpiryDate}</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel shrink>Review From Date</InputLabel>
                            <DatePicker
                                onChange={handleInput}
                                name="fleetInsuranceReviewFrom"
                                value={state.fleetInsuranceReviewFrom}
                                InputProps={{ inputProps: { min: `${state.noteStickyDisplayFromDate}` } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleField
                                type="number"
                                inputLabel="Premium Cost"
                                name="fleetInsurancePremium"
                                value={state.fleetInsurancePremium}
                                onChange={handleInput}
                                placeholder="premium cost"
                            />
                        </Grid>
                        <Grid item xs={3} className="mandatory-fields">
                            <InputLabel required shrink>
                                Premium Frequency
                            </InputLabel>
                            <SelectBox
                                name="fleetInsurancePremiumFrequency"
                                onChange={handleInput}
                                List={premiumFrequency}
                                value={state.fleetInsurancePremiumFrequency}
                            />
                            <FormHelperText error>{state.errors.fleetInsurancePremiumFrequency}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} md={12} xs={12} lg={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Broker Details
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="text"
                                inputLabel="Broker Name / Agency Name"
                                name="fleetInsuranceBrokerName"
                                value={state.fleetInsuranceBrokerName}
                                onChange={handleInput}
                                placeholder="Broker Name / Agency Name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="text"
                                inputLabel="Contact Name"
                                name="fleetInsuranceBrokerContactName"
                                value={state.fleetInsuranceBrokerContactName}
                                onChange={handleInput}
                                placeholder="Contact Name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="text"
                                inputLabel="Telephone No."
                                name="fleetInsuranceBrokerTelephone"
                                value={state.fleetInsuranceBrokerTelephone}
                                onChange={handleInput}
                                placeholder="Telephone no."
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="email"
                                inputLabel="Email Address"
                                name="fleetInsuranceBrokerEmail"
                                value={state.fleetInsuranceBrokerEmail}
                                onChange={handleInput}
                                placeholder="Email address"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} md={12} xs={12} lg={6} justifyContent="center">
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Driver Details{' '}
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="number"
                                inputLabel="Number or Percentage of Driver under 25"
                                name="fleetInsuranceDriversUnder25"
                                value={state.fleetInsuranceDriversUnder25}
                                onChange={handleInput}
                                placeholder="Number or Percentage of Driver under 25"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="number"
                                inputLabel="Number or Percentage of Driver 25-29"
                                name="fleetInsuranceDrivers25to29"
                                value={state.fleetInsuranceDrivers25to29}
                                onChange={handleInput}
                                placeholder="Number or Percentage of Driver 25-29"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleField
                                type="number"
                                inputLabel="Number or Percentage of Driver Over 30"
                                name="fleetInsuranceDrivers30orOver"
                                value={state.fleetInsuranceDrivers30orOver}
                                onChange={handleInput}
                                placeholder="Number or Percentage of Driver Over 30"
                            />
                        </Grid>
                        <Grid tem xs={6}>
                            <InputLabel shrink style={{ marginLeft: 9, marginTop: 10 }}>
                                {' '}
                                Are Agency Drivers Employed?
                            </InputLabel>

                            <RadioGroup
                                row
                                aria-label="fleetInsuranceDriversAgency"
                                name="fleetInsuranceDriversAgency"
                                value={state.fleetInsuranceDriversAgency}
                                onChange={handleInput}
                                style={{ marginLeft: 9 }}
                            >
                                <FormControlLabel checked={state.fleetInsuranceDriversAgency === true} value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={state.fleetInsuranceDriversAgency === false} value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} md={12} xs={12} lg={12}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Proposer Details{' '}
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="text"
                                inputLabel="Type of Trade/ Business"
                                name="fleetInsuranceBusinessType"
                                value={state.fleetInsuranceBusinessType}
                                onChange={handleInput}
                                placeholder="Type of Trade/ Business"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="number"
                                inputLabel="Year Established"
                                name="fleetInsuranceBusinessEstablished"
                                value={state.fleetInsuranceBusinessEstablished}
                                onChange={handleInput}
                                placeholder="Year Established"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="number"
                                inputLabel="Excess: Accidental Damage"
                                name="fleetInsuranceExcessAccidentDamage"
                                value={state.fleetInsuranceExcessAccidentDamage}
                                onChange={handleInput}
                                placeholder="Excess: Accidental Damage"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="number"
                                inputLabel="Excess: Fire and Theft(E) "
                                name="fleetInsuranceExcessFireAndTheft"
                                value={state.fleetInsuranceExcessFireAndTheft}
                                onChange={handleInput}
                                placeholder="Excess: Fire and Theft(E)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="number"
                                inputLabel="Excess:Windscreen(£) "
                                name="fleetInsuranceExcessWindcreen"
                                value={state.fleetInsuranceExcessWindcreen}
                                onChange={handleInput}
                                placeholder="Excess:Windscreen(£)"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Vehicle Use
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink> Social Domestic and Pleasure</InputLabel>
                            <RadioGroup
                                row
                                aria-label="fleetInsuranceUseSDP "
                                name="fleetInsuranceUseSDP"
                                value={state.fleetInsuranceUseSDP}
                                onChange={handleInput}
                            >
                                <FormControlLabel checked={state.fleetInsuranceUseSDP === true} value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={state.fleetInsuranceUseSDP === false} value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink> Business Class 3 / Carriage of Own Goods</InputLabel>
                            <RadioGroup
                                row
                                aria-label="fleetInsuranceUseBusiness"
                                name="fleetInsuranceUseBusiness"
                                value={state.fleetInsuranceUseBusiness}
                                onChange={handleInput}
                            >
                                <FormControlLabel checked={state.fleetInsuranceUseBusiness === true} value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={state.fleetInsuranceUseBusiness === false} value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink> Carriage of Goods for Hire & Reward</InputLabel>
                            <RadioGroup
                                row
                                aria-label="fleetInsuranceUseCarriage"
                                name="fleetInsuranceUseCarriage"
                                value={state.fleetInsuranceUseCarriage}
                                onChange={handleInput}
                            >
                                <FormControlLabel checked={state.fleetInsuranceUseCarriage === true} value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={state.fleetInsuranceUseCarriage === false} value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SimpleField
                                type="text"
                                inputLabel=" Other(please state)"
                                name="fleetInsuranceUseOther"
                                value={state.fleetInsuranceUseOther}
                                onChange={handleInput}
                                placeholder="Vehicle Use: Other(please state)"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleField
                                multiline
                                rows={3}
                                inputLabel="Notes"
                                name="fleetInsuranceNotes"
                                value={state.fleetInsuranceNotes}
                                onChange={handleInput}
                                placeholder="Notes"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton
                            fullWidth
                            isBtnLoader={btnLoader}
                            className="submit_btn"
                            onClick={handleSubmit}
                            disabled={!checkhaveAccess?.CanUpdateCustomerFleetInsurance}
                        >
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={props.onClose} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default AddFleetInsurance;
