import React from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryCheckbox } from '../../../../../Core/Controls';
import '../../controllerStyle.scss';

const FlagFieldsModal = (props) => {
    const { jobFields, handleCheckbox } = props;

    return (
        <>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.vehicleRFLResponsible} name="vehicleRFLResponsible" onChange={handleCheckbox} label="RFL?" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.euro4Compliant} name="euro4Compliant" onChange={handleCheckbox} label="Euro4 Compliant?" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.RDE2Compliant} name="RDE2Compliant" onChange={handleCheckbox} label="RDE2 Compliant?" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.shuntVehicle} name="shuntVehicle" onChange={handleCheckbox} label="Shunt Vehicle?" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox
                            checked={jobFields.vehicleV5CRegistrationUptoDate}
                            value={jobFields.vehicleV5CRegistrationUptoDate}
                            onChange={handleCheckbox}
                            name="vehicleV5CRegistrationUptoDate"
                            label="V5C Registration Up to Date?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox
                            checked={jobFields.vehicleCoveredByFleetInsurance}
                            name="vehicleCoveredByFleetInsurance"
                            onChange={handleCheckbox}
                            label="Covered By Fleet Insurance?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.vehicleIsSORN} name="vehicleIsSORN" onChange={handleCheckbox} label="Is SORN?" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SecondaryCheckbox checked={jobFields.vehicleMOTRequired} name="vehicleMOTRequired" onChange={handleCheckbox} label="MOT Required?" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FlagFieldsModal;
