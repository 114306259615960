import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    SelectBox,
    SecondaryButton,
    SecondaryCheckbox,
    BreadCrumbs,
    MandatoryField,
    SimpleField,
    YesButton,
    NoButton,
    Postcode,
    SingleSelect
} from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';

import { getDepartmentById, postDepartment } from '../../../../../../Core/Services/InternalUser/ControllerService/depotsDepartmentService';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';

const AddEditDepartment = (props) => {
    const [state, setState] = useState({ departmentID: null });
    const [error, setError] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const handledepotFields = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (props.departmentID) {
            let res = await getDepartmentById(props.departmentID);
            let data = res.data?.details[0];

            if (res.success) {
                setState((st) => ({
                    ...st,
                    departmentID: data.departmentID,
                    departmentDepotID: data.departmentDepotID,
                    departmentName: data.departmentName,
                    departmentAddress1: data.departmentAddress1,
                    departmentAddress2: data.departmentAddress2,
                    departmentTown: data.departmentTown,
                    departmentCounty: data.departmentCounty,
                    departmentPostCode: data.departmentPostCode,
                    departmentPhone: data.departmentPhone,
                    departmentContactName: data.departmentContactName,
                    departmentContactEmail: data.departmentContactEmail
                }));
            }
        }
    }, []);

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            departmentAddress1: s.addressLine1,
            departmentAddress2: s.addressLine2,
            departmentTown: s.town,
            departmentCounty: s.county,
            departmentPostCode: s.postCode
        }));
    };

    const validation = () => {
        let isValid = true;
        let errors = {};
        if (!state.departmentName) {
            errors.name = 'Name Field is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                departmentID: props.departmentID ? props.departmentID : null,
                departmentDepotID: props.depotID,
                departmentName: state.departmentName,
                departmentAddress1: state.departmentAddress1,
                departmentAddress2: state.departmentAddress2,
                departmentTown: state.departmentTown,
                departmentCounty: state.departmentCounty,
                departmentPostCode: state.departmentPostCode,
                departmentPhone: state.departmentPhone,
                departmentContactName: state.departmentContactName,
                departmentContactEmail: state.departmentContactEmail
            };
            let res = await postDepartment(data);
            if (res.departmentResponse.success) {
                showToastSuccess(`Department ${props.departmentID ? 'Updated' : 'Added'} Successfully`);
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError('Department Fail to Add ');
                // props.onClose(false);
                setBtnLoader(false);
            }
        }
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Name"
                    placeholder="Department Name"
                    value={state.departmentName}
                    onChange={handledepotFields}
                    name="departmentName"
                    errors={error.name}
                    autoFocus={true}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Contact Name"
                    placeholder="Contact Name"
                    value={state.departmentContactName}
                    onChange={handledepotFields}
                    name="departmentContactName"
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    type="tel"
                    inputLabel="Phone"
                    placeholder="Phone"
                    value={state.departmentPhone}
                    onChange={handledepotFields}
                    name="departmentPhone"
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    type="text"
                    inputLabel="Email"
                    placeholder="Email"
                    value={state.departmentContactEmail}
                    onChange={handledepotFields}
                    name="departmentContactEmail"
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <Postcode value={state.departmentPostCode} onChange={handledepotFields} name="departmentPostCode" setVal={setVal} />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Address1"
                    placeholder="Address1"
                    value={state.departmentAddress1 || ''}
                    onChange={handledepotFields}
                    name="departmentAddress1"
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Address2"
                    placeholder="Address2"
                    value={state.departmentAddress2 || ''}
                    onChange={handledepotFields}
                    name="departmentAddress2"
                />
            </Grid>

            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField inputLabel="Town" placeholder="Town" value={state.departmentTown || ''} onChange={handledepotFields} name="departmentTown" />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="County"
                    placeholder="County"
                    value={state.departmentCounty || ''}
                    onChange={handledepotFields}
                    name="departmentCounty"
                />
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} disabled={!checkhaveAccess?.CanUpdateDepotDepartments}>
                    {' '}
                    {props.departmentID ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton forceEnabled={true} onClick={props.onClose}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddEditDepartment;
