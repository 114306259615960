import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { getJourneyById, postJourney, journeyDropDown } from '../../../../../Core/Services/InternalUser/ControllerService/journeyService';
import { SelectBox, DateTimePicker, InputText, SecondaryButton, MandatoryField } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';

import moment from 'moment';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const JourneysAddEditModal = (props) => {
    const [state, setState] = useState({
        journeyActive: true,
        journeyDriverID: null,
        journeyID: null,
        journeyVehicleID: props.VehicleID,
        journeyEndLocationCoordinates: null,
        journeyStartLocationCoordinates: null,
        journeyEndOdometerReading: null,
        errors: {},
        driversList: [],
        typeList: [],
        date: moment().format('YYYY-MM-DDTHH:mm')
    });
    const [btnLoader, setBtnLoader] = useState(false);
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.journeyID) {
            let res = await getJourneyById(props.journeyID);
            let data = res.data;
            setState((st) => ({
                ...st,
                journeyID: data.journeyID,
                journeyVehicleID: data.journeyVehicleID,
                journeyDriverID: data.journeyDriverID,
                journeyTypeID: data.journeyTypeID,
                journeyDateStarted: moment(data.journeyDateStarted).format('YYYY-MM-DDTHH:mm'),
                journeyDateEnded: moment(data.journeyDateEnded).format('YYYY-MM-DDTHH:mm'),
                journeyStartOdometerReading: data.journeyStartOdometerReading,
                journeyEndOdometerReading: data.journeyEndOdometerReading,
                journeyStartLocation: data.journeyStartLocation,
                journeyStartLocationCoordinates: data.journeyStartLocationCoordinates,
                journeyEndLocation: data.journeyEndLocation,
                journeyEndLocationCoordinates: data.journeyEndLocationCoordinates,
                journeyActive: data.journeyActive,
                journeyNote: data.journeyNote
            }));
        }
        let dropDownRes = await journeyDropDown();
        if (dropDownRes.success) {
            const driversList = dropDownRes.data.drivers.map((m) => ({
                id: m.id,
                name: m.name
            }));
            const typeList = dropDownRes.data.journeyTypes.map((m) => ({
                id: m.id,
                name: m.name
            }));

            setState((st) => ({ ...st, driversList: driversList, typeList: typeList }));
        }
    }, []);

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!state.journeyDriverID) {
            errors.journeyDriverID = 'Driver is required';
            formIsValid = false;
        }
        if (!state.journeyTypeID) {
            errors.journeyTypeID = 'Journey Type is required ';
            formIsValid = false;
        }
        if (!state.journeyDateStarted) {
            errors.journeyDateStarted = `Start date is required`;
            formIsValid = false;
        }
        if (!state.journeyDateEnded) {
            errors.journeyDateEnded = 'End date is required';
            formIsValid = false;
        }
        if (!state.journeyStartOdometerReading) {
            errors.journeyStartOdometerReading = 'Start Odometer is required';
            formIsValid = false;
        }
        if (!state.journeyEndOdometerReading) {
            errors.journeyEndOdometerReading = 'End Odometer is required';
            formIsValid = false;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const duration = moment(state.journeyDateEnded).diff(state.journeyDateStarted, 'minute');
    var Hours = Math.floor(duration / 60);
    var minutes = duration % 60;
    const setDuration = state.journeyDateEnded && duration > 0 ? `${Hours}:${minutes}` : duration < 0 ? 'End time must be later than the start time' : null;

    const distance = state.journeyEndOdometerReading ? state.journeyEndOdometerReading - state.journeyStartOdometerReading : null;

    const HandleSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            let journeyData = {
                journeyID: state.journeyID,
                journeyVehicleID: state.journeyVehicleID,
                journeyTypeID: state.journeyTypeID,
                journeyDriverID: state.journeyDriverID,
                journeyDateStarted: state.journeyDateStarted,
                journeyDateEnded: state.journeyDateEnded,
                journeyStartOdometerReading: state.journeyStartOdometerReading,
                journeyEndOdometerReading: state.journeyEndOdometerReading,
                journeyStartLocation: state.journeyStartLocation,
                journeyStartLocationCoordinates: state.journeyStartLocationCoordinates,
                journeyEndLocation: state.journeyEndLocation,
                journeyEndLocationCoordinates: state.journeyEndLocationCoordinates,
                journeyActive: state.journeyActive,
                journeyNote: state.journeyNote
            };

            let res = await postJourney(journeyData);
            if (res.success) {
                showToastSuccess('Journey added successfully');
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                // props.onClose(false);
                setBtnLoader(false);
            }
        }
    };
    return (
        <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>Driver</InputLabel>
                    <SelectBox name="journeyDriverID" value={state.journeyDriverID} onChange={handleInputs} List={state.driversList} autoFocus={true} />
                    <FormHelperText error>{state.errors.journeyDriverID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>Journey</InputLabel>
                    <SelectBox name="journeyTypeID" value={+state.journeyTypeID} onChange={handleInputs} List={state.typeList} />
                    <FormHelperText error>{state.errors.journeyTypeID}</FormHelperText>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>Start Date/Time</InputLabel>
                    <DateTimePicker
                        name="journeyDateStarted"
                        value={state.journeyDateStarted}
                        InputProps={{ inputProps: { max: `${state.date}` } }}
                        onChange={handleInputs}
                    />
                    <FormHelperText error>{state.errors.journeyDateStarted}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>End Date/Time</InputLabel>
                    <DateTimePicker
                        name="journeyDateEnded"
                        autoComplete="off"
                        InputProps={{ inputProps: { min: `${state.journeyDateStarted}`, max: `${state.date}` } }}
                        value={state.journeyDateEnded}
                        onChange={handleInputs}
                    />
                    <FormHelperText error>{state.errors.journeyDateEnded}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {setDuration ? (
                        <>
                            <InputLabel shrink>Duration</InputLabel>
                            <Typography varient="body2" color="secondary" style={{ marginTop: '8px', marginLeft: '10px' }}>
                                {setDuration}
                            </Typography>
                        </>
                    ) : null}
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>Start Odometer</InputLabel>
                    <InputText type="number" name="journeyStartOdometerReading" value={state.journeyStartOdometerReading} onChange={handleInputs} />
                    <FormHelperText error>{state.errors.journeyStartOdometerReading}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>End Odometer</InputLabel>
                    <InputText type="number" name="journeyEndOdometerReading" value={state.journeyEndOdometerReading} onChange={handleInputs} />
                    <FormHelperText error>{state.errors.journeyEndOdometerReading}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {distance ? (
                        <>
                            <InputLabel shrink>Distance</InputLabel>
                            <Typography varient="body2" color="secondary" style={{ marginTop: '8px', marginLeft: '10px' }}>
                                {distance > 0 ? distance : 'End odometer reading must be greater than start odometer reading '}
                            </Typography>
                        </>
                    ) : null}
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Location: Start</InputLabel>
                    <InputText name="journeyStartLocation" value={state.journeyStartLocation} onChange={handleInputs} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Location: End</InputLabel>
                    <InputText name="journeyEndLocation" value={state.journeyEndLocation} onChange={handleInputs} />
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={8}>
                    <InputLabel shrink>Notes</InputLabel>
                    <InputText multiline rows={3} name="journeyNote" value={state.journeyNote} onChange={handleInputs} />
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton isBtnLoader={btnLoader} onClick={HandleSubmit} disabled={!checkhaveAccess?.CanUpdateVehicleJourneys}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default JourneysAddEditModal;
