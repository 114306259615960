import { Grid, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { DateTimePicker, SecondaryButton, SingleSelect } from '../../../../../../Core/Controls';
import { Alert } from '@material-ui/lab';
import { postJobStepSignOff } from '../../../../../../Core/Services/InternalUser/ControllerService/jobStepperService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { useHistory } from 'react-router-dom';

const JobTechSignOff = ({ handleNext, jobDetails, isEngineer, isDisabled, isServiceAgent }) => {
    const { showToastWarning, showToastError, showToastSuccess } = useToast();

    const [btnLoader, setBtnLoader] = useState(false);
    const history = useHistory();

    const handleSubmit = async () => {
        setBtnLoader(true);
        let res = await postJobStepSignOff(jobDetails.jobID);
        if (res.success) {
            showToastSuccess(res.data.message);
            history.push('/');
            setBtnLoader(false);
            handleNext();
        } else {
            showToastError(res.message);
            setBtnLoader(false);
        }
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        {isEngineer || isServiceAgent
                            ? 'Please click the Sign Off button below if you have completed the job'
                            : 'Please login as technician/service area to sign off the job'}
                    </Alert>
                </Grid>
                {(isEngineer || isServiceAgent) && isDisabled && (
                    <Grid item xs={12}>
                        <SecondaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                            Sign Off
                        </SecondaryButton>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default JobTechSignOff;
