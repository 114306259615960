import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getAllInternalDash } from '../../../Core/Services/InternalUser/InternalDashService';
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';
import { Link } from 'react-router-dom';
import '../InternalDash.scss';
import { makeStyles } from '@material-ui/core/styles';
import RoomIcon from '@material-ui/icons/Room';
import CustomerList from './CustomerList';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { SecondaryButton } from '../../../Core/Controls';
import FilterListIcon from '@material-ui/icons/FilterList';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CheckIcon from '@material-ui/icons/Check';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DashboardCard from './dashboardCard';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import { SetWidgets } from './widgetSetting';
import { Grow, Hidden } from '@material-ui/core';
// import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { Tooltip } from '@material-ui/core';
import { AppContext } from '../../../Core/App/PageLevel/AppContextProvider';
import { getAllDashboardWidgets } from '../../../Core/Services/dasboardWidgetsService';
import { FromLoader } from '../../../Core/Loader/formLoader';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { usePortalSettings } from '../../../Core/Hooks/usePortalSettings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { AppStorage } from '../../../Core/Basic/storage-service';
import FilterCustomerList from '../../../Core/App/FilteredCustomerList';
import { Assignment } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        width: '90%'
    }
});

let gridStyle = {
    1: 12,
    2: 6,
    3: 3
};

let redColor = '#ff4757';
let redShadowColor = 'rgb(255 32 54 / 53%)';
let yellowColor = '#ffc312';
let yellowShadowColor = 'rgba(255, 236, 30, 0.75)';
let purpleColor = '#7d38df';
let purpleShadowColor = 'rgba(200, 61, 255, 0.596)';
let greenColor = '#009432';
let greenShadowColor = 'rgba(0, 148, 50, 0.53)';
let blueColor = '#1127ee';
let blueShadowColor = '#909bf7';

const InternalDashboard = (props) => {
    const portalSettings = usePortalSettings();
    const classes = useStyles();
    const [state, setState] = useState({
        rows_new: [],
        tilesData: [],
        showFilter: false,
        buttonID: 2,
        widgetList: [],
        contractList: []
    });
    const { commonState, dashBoardDataReload, getDashBoardData } = useContext(AppContext);

    const [widgets, setWidgets] = useState({});

    const [checked, setChecked] = React.useState(true);

    // const handleChange = () => {
    //     setChecked((prev) => !prev);
    // };

    let DowntimeDash = [
        {
            Icon: DirectionsCarIcon,
            numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.breakdowns || 0,
            subTittle: 'Breakdown',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/breakdowns/:all'
        },
        // {
        //     Icon: DirectionsCarIcon,
        //     numberIcon: state.tilesData.defectPercent,
        //     title: state.tilesData.defects || 0,
        //     subTittle: 'Defects',
        //     background: redColor,
        //     showDowBackground: redShadowColor,
        //     path: '/maintenance/defects'
        // },
        {
            Icon: DirectionsCarIcon,
            numberIcon: state.tilesData.vorPercent,
            title: state.tilesData.vor || 0,
            subTittle: 'VOR',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: {
                pathname: `/controller/vor`,
                query: { FromDash: true }
            }
        },
        {
            Icon: LocalTaxiRoundedIcon,
            // numberIcon: state.tilesData.vorPercent,
            title: state.tilesData.estimateCount || 0,
            subTittle: 'Estimates',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/EP'
        }
    ];

    let MaintananceEvent = [
        {
            Icon: DirectionsCarIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.serviceCount || 0,
            subTittle: 'Services',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/schedules/S'
        },
        {
            Icon: DirectionsCarIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.motCount || 0,
            subTittle: 'MOTs',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/schedules/M'
        },
        {
            Icon: BuildIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.inspectionCount || 0,
            subTittle: 'Inspections',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/schedules/I'
        },
        {
            Icon: FormatListBulletedIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.otherCount || 0,
            subTittle: 'Others',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/maintenance/schedules/O'
        }
    ];

    let FleetDash = [
        {
            Icon: EmojiTransportationIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.taxCount || 0,
            subTittle: 'Tax',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/controller/Vehicles/T'
        },
        {
            Icon: CreditCardIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.insuranceCount || 0,
            subTittle: 'Insurance',
            background: purpleColor,
            showDowBackground: purpleShadowColor,
            path: '/controller/Vehicles/I'
        },
        {
            Icon: CheckIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.complianceCount || 0,
            subTittle: 'Compliance',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/schedule/outstandingdocuments'
        },
        {
            Icon: NotificationsActiveIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.reminderCount || 0,
            subTittle: 'Reminders',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/controller/jobreminder'
        }
    ];

    let FleetController = [
        {
            Icon: LocalShippingIcon,
            // numberIcon: ,
            title: state.tilesData.trackingCountBreakdownRoadside || 0,
            subTittle: 'Breakdown  Roadside',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/jobtracking/BR'
        },
        {
            Icon: DirectionsCarIcon,
            // numberIcon: ,
            title: state.tilesData.trackingCountBreakdownOnsite || 0,
            subTittle: 'Breakdown  Onsite',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/jobtracking/BO'
        },
        {
            Icon: BuildIcon,
            // numberIcon: ,
            title: state.tilesData.trackingCountDefects || 0,
            subTittle: 'Job Defects',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/jobtracking/DD'
        },

        {
            Icon: NotificationsActiveIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.trackingCountAll || 0,
            subTittle: 'Job Tracking',
            background: blueColor,
            showDowBackground: blueShadowColor,
            path: '/jobtracking'
        }
    ];

    let FleetSchedule = [
        {
            Icon: ErrorIcon,
            // numberIcon: ,
            title: state.tilesData.bookingsRequested || 0,
            subTittle: 'Bookings Requested',
            background: yellowColor,
            showDowBackground: yellowShadowColor,
            path: '/schedule/bookingsrequsted'
        },
        {
            Icon: CheckIcon,
            // numberIcon: ,
            title: state.tilesData.bookingsAccepted || 0,
            subTittle: 'Bookings Accepted',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/schedule/acceptedbyagents'
        },

        {
            Icon: ClearIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.bookingsRejected || 0,
            subTittle: 'Bookings Rejected',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/schedule/bookingsrejected'
        },
        {
            Icon: BuildIcon,
            // numberIcon: ,
            title: state.tilesData.bookingsConfirmed || 0,
            subTittle: 'Bookings Confirmed',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/schedule/confirmedbookings'
        }
    ];

    let Jobs = [
        {
            Icon: ErrorIcon,
            // numberIcon: ,
            title: state.tilesData.jobsUnassigned || 0,
            subTittle: 'Jobs Unassigned',
            background: yellowColor,
            showDowBackground: yellowShadowColor,
            path: '/serviceagent/AU'
        },
        {
            Icon: CheckIcon,
            // numberIcon: ,
            title: state.tilesData.jobsOpen || 0,
            subTittle: 'Jobs Open',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/OJ'
        },

        {
            Icon: CheckIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.jobsInQuery || 0,
            subTittle: 'Jobs In Query',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/QJ'
        },
        {
            Icon: CheckIcon,
            // numberIcon: ,
            title: state.tilesData.jobsAwaitingApprovals || 0,
            subTittle: 'Jobs Awaiting Approvals',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/serviceagent/AR'
        }
    ];
    let DriverCheck = [
        {
            Icon: ClearIcon,
            // numberIcon: ,
            title: state.tilesData.dailyChecksFailed || 0,
            subTittle: 'Failed',
            background: redColor,
            showDowBackground: redShadowColor,
            path: '/internalDailyChecks/failed'
        },
        {
            Icon: ErrorIcon,
            // numberIcon: ,
            title: state.tilesData.dailyChecksMonitored || 0,
            subTittle: 'Monitor',
            background: yellowColor,
            showDowBackground: yellowShadowColor,
            path: '/internalDailyChecks/monitor'
        },

        {
            Icon: CheckIcon,
            // numberIcon: state.tilesData.breakdownPercent,
            title: state.tilesData.dailyChecksPassed || 0,
            subTittle: 'Passed',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/internalDailyChecks/passed'
        },
        {
            Icon: BuildIcon,
            // numberIcon: ,
            title: state.tilesData.dailyChecksUnreviewed || 0,
            subTittle: 'Unreviewed',
            background: greenColor,
            showDowBackground: greenShadowColor,
            path: '/internalDailyChecks/unreviwed'
        }
    ];

    // let Enquiry = [
    //     {
    //         Icon: CheckIcon,
    //         // numberIcon: ,
    //         title: state.tilesData.leadsLiveEnquiries || 0,
    //         subTittle: 'Live Enquires',
    //         background: greenColor,
    //         showDowBackground: greenShadowColor,
    //         path: '/enquiries/live_enquiry'
    //     },
    //     {
    //         Icon: ErrorIcon,
    //         // numberIcon: ,
    //         title: state.tilesData.leadsLost || 0,
    //         subTittle: 'Lost Enquires',
    //         background: redColor,
    //         showDowBackground: redShadowColor,
    //         path: '/enquiries/lost_enquiry'
    //     },

    //     {
    //         Icon: CheckIcon,
    //         // numberIcon: state.tilesData.breakdownPercent,
    //         title: state.tilesData.leadsNewEnquiries || 0,
    //         subTittle: 'New Enquires',
    //         background: greenColor,
    //         showDowBackground: greenShadowColor,
    //         path: '/enquiries/new_enquiry'
    //     },
    //     {
    //         Icon: CheckIcon,
    //         // numberIcon: ,
    //         title: state.tilesData.leadsSuccessful || 0,
    //         subTittle: 'Successful Enquires',
    //         background: greenColor,
    //         showDowBackground: greenShadowColor,
    //         path: '/enquiries/successful_enquiry'
    //     }
    // ];

    let ReportList = [
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'VOR',
            subTittle: ' Reporting',

            path: '/controller/VorReporting/'
        },
        // {
        //     button: true,
        //     Icon: DashboardIcon,
        //     background: blueColor,
        //     showDowBackground: blueShadowColor,
        //     title: 'Defect',
        //     subTittle: 'Reporting',
        //     path: '/maintenance/defect/reporting/'
        // },
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'Breakdown',
            subTittle: 'Reporting ',
            path: '/maintenance/breakdown/reporting/'
        },
        {
            button: true,
            Icon: DashboardIcon,
            background: blueColor,
            showDowBackground: blueShadowColor,
            title: 'Job',
            subTittle: ' Reporting ',
            path: '/job/job_Reporting'
        }
    ];

    let VehicleContract = [
        {
            subTittle: 'Awaiting Summer Service',
            title: state.tilesData.vehicleContract_AwaitingSummerServiceCount || 0,
            path: {
                pathname: `/ContractStatus/${1}`
                // search: `tab_VehicleContrctStatus_tabs=${1}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Summer Service Completed',
            title: state.tilesData.vehicleContract_SummerServiceCompletedCount || 0,
            path: {
                pathname: `/ContractStatus/${2}`
                // search: `tab_VehicleContrctStatus_tabs=${2}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Awaiting Delivery',
            title: state.tilesData.vehicleContract_AwaitingDeliveryCount || 0,
            path: {
                pathname: `/ContractStatus/${3}`
                // search: `tab_VehicleContrctStatus_tabs=${3}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Collected',
            title: state.tilesData.vehicleContract_CollectedCount || 0,
            path: {
                pathname: `/ContractStatus/${4}`
                // search: `tab_VehicleContrctStatus_tabs=${4}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Vehicle Onsite',
            title: state.tilesData.vehicleContract_VehicleOnsiteCount || 0,
            path: {
                pathname: `/ContractStatus/${5}`
                // search: `tab_VehicleContrctStatus_tabs=${5}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'On Hire',
            title: state.tilesData.vehicleContract_OnHireCount || 0,
            path: {
                pathname: `/ContractStatus/${6}`
                // search: `tab_VehicleContrctStatus_tabs=${6}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Off Hire',
            title: state.tilesData.vehicleContract_OffHireCount || 0,
            path: {
                pathname: `/ContractStatus/${7}`
                // search: `tab_VehicleContrctStatus_tabs=${7}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Ready for collection',
            title: state.tilesData.vehicleContract_ReadyforcollectionCount || 0,
            path: {
                pathname: `/ContractStatus/${8}`
                // search: `tab_VehicleContrctStatus_tabs=${8}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Collection Arranged',
            title: state.tilesData.vehicleContract_CollectionArrangedCount || 0,
            path: {
                pathname: `/ContractStatus/${9}`
                // search: `tab_VehicleContrctStatus_tabs=${9}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        },
        {
            subTittle: 'Awaiting Factory Inspection',
            title: state.tilesData.vehicleContract_AwaitingFactoryInspectionCount || 0,
            path: {
                pathname: `/ContractStatus/${10}`
                // search: `tab_VehicleContrctStatus_tabs=${10}`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: DirectionsCarIcon
        }
    ];

    let EventSignedOFF = [
        {
            subTittle: 'Sent',
            title: state.tilesData.docSent || 0,
            path: {
                pathname: `/EventsSignedOff`,
                search: `docSent=1`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: Assignment
        },
        {
            subTittle: 'Not Sent',
            title: state.tilesData.docNotSent || 0,
            path: {
                pathname: `/EventsSignedOff`,
                search: `docSent=0`
            },
            background: redColor,
            showDowBackground: redShadowColor,
            Icon: Assignment
        }
    ];
    let setList = {
        1: DowntimeDash,
        2: MaintananceEvent,
        3: FleetDash,
        4: FleetController,
        5: FleetSchedule,
        6: Jobs,
        7: DriverCheck,
        // 8: Enquiry,
        15: ReportList,
        17: VehicleContract,
        18: EventSignedOFF
    };

    const pullDashboardData = async () => {
        if (commonState.dashBoardData) {
            let res = commonState.dashBoardData;
            let filteredList = [];
            const cards = res.data?.dashboard;
            const filterdCustomer = res.data?.filterdCustomer;
            if (filterdCustomer?.length > 0) {
                filteredList = filterdCustomer.map((item) => {
                    return <div>{item.customerCompanyName}</div>;
                });
            }
            setState((st) => ({
                ...st,
                tilesData: cards[0],
                filteredCustomerList: filteredList,
                isFilterList: filteredList?.length > 0 ? true : false
            }));
        }
    };

    const pullTheme = async () => {
        let dashRes = await getAllDashboardWidgets('dashboard');
        setState((st) => ({
            ...st,
            widgetList: dashRes.data.displayStyles
        }));
    };

    useEffect(async () => {
        await getDashBoardData();
        pullTheme();
    }, []);

    useEffect(async () => {
        if (props.new) {
            window.location.reload();
        }
    }, [props.new]);

    useEffect(async () => {
        await pullDashboardData();
    }, [commonState]);

    useEffect(async () => {
        if (portalSettings.portalSettingDashboardRefreshTime > 0) {
            const intervalId = await setInterval(() => {
                getDashBoardData();
            }, portalSettings.portalSettingDashboardRefreshTime * 1000);
            return () => clearInterval(intervalId);
        }
    }, [portalSettings.portalSettingDashboardRefreshTime]);

    const showFilterModal = (val) => {
        setState((st) => ({
            ...st,
            showFilter: false
        }));
        val && pullDashboardData();
    };

    const handleFilterModal = () => {
        setState((st) => ({
            ...st,
            showFilter: true
        }));
    };
    const handleFullScreenModal = () => {
        setState((st) => ({
            ...st,
            showDashboardFullScreen: true
        }));
    };
    const closeFullScreenModal = () => {
        setState((st) => ({
            ...st,
            showDashboardFullScreen: false
        }));
    };

    return (
        <div className="screen" style={{ background: 'rgba(0,0,0,0.001)', overFlow: 'none' }}>
            <Grid container spacing={1}>
                {state.showFilter && (
                    <DialogComp title=" Customer Filters" onClose={() => showFilterModal(false)} maxWidth="md">
                        <CustomerList onClose={showFilterModal} />
                    </DialogComp>
                )}

                <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
                    <Tooltip title={'Full Screen'} arrow>
                        <Avatar
                            aria-label="recipe"
                            onClick={handleFullScreenModal}
                            // className={classes.avatar}
                            style={{
                                backgroundColor: blueColor,
                                boxShadow: ` 0px 0px 0px 8px ${blueShadowColor}`,
                                cursor: 'pointer',
                                marginBottom: 20
                            }}
                        >
                            <FullscreenIcon />
                        </Avatar>
                    </Tooltip>
                    <Tooltip title={state.isFilterList ? <div>{[...state.filteredCustomerList]}</div> : 'Filters'} arrow>
                        <Avatar
                            aria-label="recipe"
                            onClick={handleFilterModal}
                            // className={classes.avatar}
                            style={{
                                backgroundColor: state.isFilterList ? blueColor : redColor,
                                boxShadow: state.isFilterList ? ` 0px 0px 0px 8px ${blueShadowColor}` : ` 0px 0px 0px 8px ${redShadowColor}`,
                                cursor: 'pointer'
                            }}
                        >
                            <FilterListIcon />
                        </Avatar>
                    </Tooltip>
                </div>
                {state.showDashboardFullScreen ? (
                    <DialogComp open={true} onClose={() => console.log('opopo')} fullScreen hideCloseAction={true}>
                        <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
                            <Tooltip title={'Close Screen'} arrow>
                                <Avatar
                                    aria-label="recipe"
                                    onClick={closeFullScreenModal}
                                    // className={classes.avatar}
                                    style={{
                                        backgroundColor: blueColor,
                                        boxShadow: ` 0px 0px 0px 8px ${blueShadowColor}`,
                                        cursor: 'pointer',
                                        marginBottom: 15
                                    }}
                                >
                                    <FullscreenExitIcon />
                                </Avatar>
                            </Tooltip>
                        </div>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            // justify="space-between"
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="mainContainer"
                            style={{ overflow: 'auto', height: '100%' }}
                        >
                            {state.widgetList?.length > 0 ? (
                                state.widgetList.map((m, i) => {
                                    let gs = gridStyle[m.displayStyleID];
                                    let list = setList[m.widgetUsedID];
                                    return (
                                        <Grid item xs={12} sm={12} md={gs === 3 ? 6 : gs} lg={gs === 3 ? 6 : gs} style={{ padding: '6px 8px' }} xl={gs} key={i}>
                                            <DashboardCard
                                                list={list || []}
                                                themeID={m.themeID}
                                                heading={m.widgetName}
                                                gridstyleID={m.displayStyleID}
                                                widgetID={m.widgetUsedID}
                                                hoverColor={m.hover}
                                                userWidgetID={m.userWidgetID}
                                                pullDashboardData={pullDashboardData}
                                            />
                                        </Grid>
                                    );
                                })
                            ) : (
                                <div style={{ width: '100%' }}>
                                    <FromLoader />
                                </div>
                            )}
                        </Grid>
                    </DialogComp>
                ) : (
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        // justify="space-between"
                        alignItems="center"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="mainContainer"
                    >
                        {state.widgetList?.length > 0 ? (
                            state.widgetList.map((m, i) => {
                                let gs = gridStyle[m.displayStyleID];
                                let list = setList[m.widgetUsedID];
                                return (
                                    <Grid item xs={12} sm={12} md={gs === 3 ? 6 : gs} lg={gs === 3 ? 6 : gs} style={{ padding: '6px 8px' }} xl={gs} key={i}>
                                        <DashboardCard
                                            list={list || []}
                                            themeID={m.themeID}
                                            heading={m.widgetName}
                                            gridstyleID={m.displayStyleID}
                                            widgetID={m.widgetUsedID}
                                            hoverColor={m.hover}
                                            userWidgetID={m.userWidgetID}
                                            pullDashboardData={pullTheme}
                                        />
                                    </Grid>
                                );
                            })
                        ) : (
                            <div style={{ width: '100%' }}>
                                <FromLoader />
                            </div>
                        )}
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default InternalDashboard;
