import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';

import moment from 'moment';
import { ContractSetVehiclePicked, ContractsStepper_DropDown } from '../../../../../Core/Services/InternalUser/InternalDashService';

const ContractSetVehiclePickedModal = (props) => {
    const { contractSummerServiceAreaID } = props;
    const [state, setState] = useState({
        errors: {},
        contractServiceAreaID: null,
        contractEngineerUserID: null,
        contractColletionDate: moment().format('YYYY-MM-DD'),
        contractCollectionCharge: null,
        transportCompanyID: null,
        serviceAgents: []
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await ContractsStepper_DropDown();
        setState((st) => ({
            ...st,
            companies: res.data.companies || [],
            serviceAgents: res.data.serviceAgents || [],
            technicians: res.data.technicians || []
        }));
    }, []);

    useEffect(() => {
        if (contractSummerServiceAreaID && state.serviceAgents?.length > 0) {
            let contractServiceAreaID = state.serviceAgents.find(({ id }) => id === contractSummerServiceAreaID) || null;
            setState((st) => ({
                ...st,
                contractServiceAreaID: contractServiceAreaID || {}
            }));
        }
    }, [contractSummerServiceAreaID, state.serviceAgents]);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { contractColletionDate, contractServiceAreaID, contractEngineerUserID, contractCollectionCharge, transportCompanyID } = state;
        let formIsValid = true;
        let errors = {};
        // if (!contractCollectionCharge) {
        //     errors.contractCollectionCharge = 'Delivery Charge is required';
        //     formIsValid = false;
        // }
        if (!transportCompanyID?.id) {
            errors.transportCompanyID = 'Company is required';
            formIsValid = false;
        }
        if (!contractColletionDate) {
            errors.contractColletionDate = 'Date is required';
            formIsValid = false;
        }
        if (!contractServiceAreaID?.id) {
            errors.contractServiceAreaID = 'Service area is required';
            formIsValid = false;
        }
        // if (!contractEngineerUserID?.id) {
        //     errors.contractEngineerUserID = 'Engineer is required';
        //     formIsValid = false;
        // }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            let promises = props.checkedVehicleList.map((q) => {
                return ContractSetVehiclePicked({
                    contractVehicleID: q,
                    contractColletionDate: state.contractColletionDate,
                    // contractCollectionCharge: state.contractCollectionCharge,
                    transportCompanyID: state.transportCompanyID?.id,
                    contractServiceAreaID: state.contractServiceAreaID?.id || null
                    // contractEngineerUserID: state.contractEngineerUserID?.id || null
                });
            });

            try {
                let responses = await Promise.all(promises);

                // Check if all responses are successful
                let allSuccessful = responses.every((response) => response.success);

                if (allSuccessful) {
                    showToastSuccess('Vehicle delivery details saved successfully.');
                    props.onClose(true);
                } else {
                    // Find out which responses failed
                    let failedResponses = responses.filter((response) => !response.success);
                    showToastError(`Error: ${failedResponses.length} items failed.`);
                }
            } catch (error) {
                // Handle any other errors that may occur
                showToastError(`An error occurred: ${error.message}`);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Contract Collection Date</InputLabel>
                <DatePicker
                    name="contractColletionDate"
                    placeholder="Contract Colletion Date"
                    value={state.contractColletionDate || ''}
                    onChange={onFieldChange}
                />
                <FormHelperText error>{state.errors.contractColletionDate}</FormHelperText>
            </Grid>

            {/* <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Collection Charge</InputLabel>
                <InputText
                    placeholder="Collection Charge"
                    value={state.contractCollectionCharge}
                    onChange={onFieldChange}
                    name="contractCollectionCharge"
                    type="number"
                />
                <FormHelperText error>{state.errors.contractCollectionCharge}</FormHelperText>
            </Grid> */}
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Transport Company</InputLabel>
                <SingleSelect options={state.companies} value={state.transportCompanyID} onChange={singleSelectChange('transportCompanyID')} />
                <FormHelperText error>{state.errors.transportCompanyID}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Summer Service Area</InputLabel>
                <SingleSelect options={state.serviceAgents} value={state.contractServiceAreaID} onChange={singleSelectChange('contractServiceAreaID')} />
                <FormHelperText error>{state.errors.contractServiceAreaID}</FormHelperText>
            </Grid>
            {/* <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Engineer</InputLabel>
                <SingleSelect options={state.technicians} value={state.contractEngineerUserID} onChange={singleSelectChange('contractEngineerUserID')} />
                <FormHelperText error>{state.errors.contractEngineerUserID}</FormHelperText>
            </Grid> */}

            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetVehiclePickedModal;
