import React, { useEffect, useMemo, useState } from 'react';
import '../../../../Components/commonStyle.scss';
import { BreadCrumbs, DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { deleteReport, getAllReports } from '../../../../Core/Services/InternalUser/ReportsService';
import AddReportScreen from './AddReportScreen';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { PrimaryButton, CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { Modal, Button, Typography } from '@material-ui/core';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { SecondaryCheckbox } from '../../../../Core/Controls';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 90
    },
    {
        key: 'userReportName',
        name: 'Report Name'
    },
    {
        key: 'reportCatalogueName',
        name: 'Catalogue Name'
    },
    {
        key: 'reportScheduleTitle',
        name: 'Schedule'
    },
    {
        key: 'userReportDateCreated',
        name: 'Date Created',
        formatter: formatters.Date_DDMMMYYYY_HHMM
    },
    {
        key: 'userReportSendEmail',
        name: 'Send Email'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Reports', path: '/', active: true },
    { name: 'My Reports', path: '/', active: true }
];

const ReportsListScreen = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        modalToggle: false,
        showLoader: true,
        reportsList: [],
        userReportID: null,
        isReload: null,
        showDelete: false,
        userReportName: null
    });

    const pullReportsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleDelete = async () => {
        let res = await deleteReport(state.userReportID);
        if (res.success) {
            setState((st) => ({
                ...st,
                showDelete: false,
                userReportName: null,
                userReportID: null
            }));
            showToastSuccess(res.data?.message || 'Reported deleted successfully');
            pullReportsAndUpdateState();
        } else {
            showToastError(res.message || 'Something went wrong');
        }
    };
    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            userReportID: record
        }));
    };

    const closeReportModal = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        res && pullReportsAndUpdateState();
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/reports/reportlist/${val.userReportName}/${val.userReportID}`
        });
    };

    const dataFormatter = (val, row) => {
        return (
            <Grid container justify="space-around" alignItems="center">
                <Grid item>
                    <EditButton onClick={() => handleAdd(row.userReportID)} toolTipTitle="Edit Report" />
                </Grid>
                <Grid item>
                    <AssignmentIcon
                        color="secondary"
                        toolTipTitle="Edit Report"
                        onClick={() => handleEditClick(row)}
                        style={{ marginTop: '8px', cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item>
                    <CustomIconButton
                        icon={DeleteIcon}
                        color="secondary"
                        className="modal-btn-update"
                        toolTipTitle="Delete Report"
                        onClick={() => {
                            setState((st) => ({
                                ...st,
                                showDelete: true,
                                userReportName: row.userReportName,
                                userReportID: row.userReportID
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const newCrumbs = useMemo(() => {
        const tempCols = [...crumbs];
        let actionCol = tempCols.find((element) => element.name === 'Reports List');
        if (actionCol) {
            actionCol.onClick = closeReportModal;
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            <BreadCrumbs crumbs={newCrumbs} />

            {state.modalToggle ? (
                <>
                    <br />
                    <AddReportScreen onClose={closeReportModal} userReportID={state.userReportID} />
                </>
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl="UserReports"
                        btnTitle="Add Report"
                        onBtnClick={() => handleAdd(null)}
                        isReload={state.isReload}
                    />
                </>
            )}
            {state.showDelete ? (
                <DialogComp title={`Delete Report - ${state.userReportName}`} onClose={() => setState((st) => ({ ...st, showDelete: false }))} maxWidth="sm">
                    <Grid container spacing={0} style={{ marginTop: -12 }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography component="legend">Are you sure you want to delete this report?</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <PrimaryButton onClick={handleDelete} fullWidth className="submit_btn">
                                    Yes
                                </PrimaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={() => setState((st) => ({ ...st, showDelete: false }))} fullWidth className="submit_btn">
                                    No
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </div>
    );
};
export default ReportsListScreen;
