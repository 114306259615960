import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { addDepot, updateDepot, getDepotById, getDropdown } from '../../../../../Core/Services/InternalUser/ControllerService/depotsService';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import {
    SelectBox,
    SecondaryButton,
    SecondaryCheckbox,
    BreadCrumbs,
    MandatoryField,
    SimpleField,
    YesButton,
    NoButton,
    Postcode,
    SingleSelect
} from '../../../../../Core/Controls';
import { emailValidation, validatePostCode } from '../../../../../Core/Basic/StaticService';
import { isNull } from 'lodash';
import Paper from '@material-ui/core/Paper';
import '../../controllerStyle.scss';
// import InvoiceDetails from '../../Depots/invoiceFields';
import { DepotProvider, DepotScreenContext } from './depotProvider';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const FlaggedBox = (props) => {
    useEffect(() => {
        !props.chkValue && props.resetInput(props.txtName);
    }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const ScheduleDetails = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const {
        scheduleFields,
        handleSubmit,
        scheduleDetailsChange,
        handleCheckbox,
        closeModal,
        handledepotFields,
        handledepotCheckbox,
        depotFields,
        handleActive,
        resetInput,
        setVal,
        optionsState,
        tempOptions,
        singleSelectChange,
        btnLoader,
        invoiceFieldsChange,
        invoiceFields,
        setInvoiceVal,
        resetInvoiceInput,
        invoiceCheckBox
    } = useContext(DepotScreenContext);
    const { showToastSuccess, showToastError } = useToast();

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Vehicles', active: true },
        { name: 'Depot', active: true }
    ];

    const countryList = [
        { id: 'GB', name: 'GB' },
        { id: 'IE', name: 'IE' }
    ];

    return (
        <div className="controller-screen" style={{ marginTop: 8 }}>
            {!props.depotID && <BreadCrumbs crumbs={crumbs} />}
            <br />
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container" style={{ padding: '8px' }}> */}
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <InputLabel required shrink>
                                Customers
                            </InputLabel>
                            <SingleSelect
                                options={optionsState.customerList}
                                value={tempOptions.depotCustomerValue}
                                onChange={singleSelectChange('depotCustomerValue')}
                            />
                            <FormHelperText error>{depotFields.errors.depotCustomerID}</FormHelperText>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Depot Name"
                                placeholder="Depot Name"
                                value={depotFields.name}
                                onChange={handledepotFields}
                                name="name"
                                errors={depotFields.errors.name}
                            />
                            {/* <FormHelperText error>{depotFields.errors.name}</FormHelperText> */}
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <InputLabel required shrink>
                                Country Code
                            </InputLabel>
                            <SelectBox name="depotCountryCode" value={depotFields.depotCountryCode} onChange={handledepotFields} List={countryList} />
                            <FormHelperText error>{depotFields.errors.depotCountryCode}</FormHelperText>
                        </Grid> */}
                        <Grid item xs={12} sm={4} className="mandatory-fields">
                            <InputLabel required shrink>
                                Account Code{' '}
                            </InputLabel>
                            <SimpleField placeholder="Account Code" value={depotFields.depotAccountCode} onChange={handledepotFields} name="depotAccountCode" />
                            <FormHelperText error>{depotFields.errors.depotAccountCode}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Address1"
                                placeholder="Address1"
                                value={depotFields.address1}
                                onChange={handledepotFields}
                                name="address1"
                                errors={depotFields.errors.address1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Address2"
                                placeholder="Address2"
                                value={depotFields.address2}
                                onChange={handledepotFields}
                                name="address2"
                                errors={depotFields.errors.address2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Town"
                                placeholder="Town"
                                value={depotFields.depotTown}
                                onChange={handledepotFields}
                                name="depotTown"
                                errors={depotFields.errors.depotTown}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {/* <SimpleField
                                inputLabel="Post Code"
                                placeholder="Post Code "
                                value={depotFields.depotPostCode}
                                onChange={handledepotFields}
                                name="depotPostCode"
                                // errors={depotFields.errors.depotPostCode}
                            />
                            <FormHelperText error>{depotFields.errors.depotPostCode}</FormHelperText> */}
                            <Postcode
                                value={depotFields.depotPostCode}
                                onChange={handledepotFields}
                                name="depotPostCode"
                                setVal={setVal}
                                errors={depotFields.errors.depotPostCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="County"
                                placeholder="County"
                                value={depotFields.depotCounty || ''}
                                onChange={handledepotFields}
                                name="depotCounty"
                                errors={depotFields.errors.depotCounty}
                            />{' '}
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Email"
                                placeholder="Email"
                                type="email"
                                value={depotFields.depotEmail || ''}
                                onChange={handledepotFields}
                                name="depotEmail"
                                errors={depotFields.errors.depotEmail}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Customers Ref"
                                placeholder="Customers Ref "
                                value={depotFields.depotCustomersRef}
                                onChange={handledepotFields}
                                name="depotCustomersRef"
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Telephone"
                                placeholder="Telephone"
                                type="tel"
                                value={depotFields.depotTelephone}
                                onChange={handledepotFields}
                                name="depotTelephone"
                                errors={depotFields.errors.depotTelephone}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Contact Number"
                                placeholder="Contact Number"
                                value={depotFields.depotContact}
                                onChange={handledepotFields}
                                name="depotContact"
                                errors={depotFields.errors.depotContact}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <InputLabel shrink required>
                                Service Area
                            </InputLabel>
                            <SingleSelect
                                options={optionsState.serviceAgentsList}
                                value={tempOptions.depotServiceAgentValue}
                                onChange={singleSelectChange('depotServiceAgentValue')}
                            />
                            <FormHelperText error>{depotFields.errors.depotServiceAgentID}</FormHelperText>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                            <InputLabel shrink required>
                                Secondary Service Area
                            </InputLabel>
                            <SingleSelect
                                options={optionsState.serviceAgentsList}
                                value={tempOptions.depotAgentSecondaryAgentValue}
                                onChange={singleSelectChange('depotAgentSecondaryAgentValue')}
                            />
                            <FormHelperText error>{depotFields.errors.depotAgentSecondaryAgentID}</FormHelperText>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>First Day of Week</InputLabel>
                            <SelectBox value={depotFields.firstDayofWeek} onChange={handledepotFields} name="firstDayofWeek" List={[]} />
                        </Grid> */}
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    <Typography color="secondary">Notes</Typography>
                    <SimpleField
                        multiline
                        rows={8}
                        // inputLabel="Notes"
                        placeholder="Notes"
                        value={depotFields.depotNotes}
                        onChange={handledepotFields}
                        name="depotNotes"
                        // errors={state.errors.contractDescription}
                    />
                </Grid>
                {/* <InvoiceDetails
                    invoiceCheckBox={invoiceCheckBox}
                    invoiceFieldsChange={invoiceFieldsChange}
                    invoiceFields={invoiceFields}
                    setVal={setInvoiceVal}
                    depotFields={depotFields}
                    resetInput={resetInvoiceInput}
                /> */}

                {/* <Grid item xs={12}> */}
                {/* <Typography variant="h5" color="secondary">
                        Schedule Planner Options
                    </Typography>
                    <hr /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow"> */}
                {/* <Paper className="controller-container" style={{ padding: '8px' }}> */}
                {/* <Grid container spacing={1}> */}
                {/* <Grid item xs={12} sm={6} md={3} className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.mergeSchedules} name="mergeSchedules" onChange={handleCheckbox} label="Merge Schedule?" />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6} md={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.displayISOWeek}
                                name="displayISOWeek"
                                onChange={handleCheckbox}
                                label="Display ISO Week Number?"
                            />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6} md={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.addPaperworkReceived}
                                name="addPaperworkReceived"
                                onChange={handleCheckbox}
                                label="Add Paperwork Received?"
                            />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6} md={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.trafficStyleColourCoding}
                                name="trafficStyleColourCoding"
                                onChange={handleCheckbox}
                                label="Traffic Style Colour Coding ?"
                            />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Max Schedule Merge Range</InputLabel>
                            <SimpleField placeholder="Weeks" type="number" value={depotFields.mergeRange} onChange={handledepotFields} name="mergeRange" />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6}> */}
                {/* <SimpleField
                                inputLabel="Schedule Planner Email"
                                placeholder="Planner Email"
                                type="email"
                                value={depotFields.depotSchedulePlannerEmail || ''}
                                onChange={handledepotFields}
                                name="depotSchedulePlannerEmail"
                                errors={depotFields.errors.depotSchedulePlannerEmail}
                            /> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Defect Notification Email"
                                placeholder="Defect Notification Email"
                                value={depotFields.depotDefectNotificationEmail}
                                onChange={handledepotFields}
                                name="depotDefectNotificationEmail"
                            />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Schedule Booking Mode</InputLabel>
                            <SingleSelect
                                options={optionsState.scheduleBookingModeList}
                                value={tempOptions.depotScheduleBookingModeValue}
                                onChange={singleSelectChange('depotScheduleBookingModeValue')}
                            />
                        </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Third Party Planner Email Address</InputLabel>
                            <SimpleField
                                placeholder="Third Party Planner Email Address"
                                name="depotThirdPartyPlannerEmailAddress"
                                value={depotFields.depotThirdPartyPlannerEmailAddress}
                                onChange={handledepotFields}
                            />
                        </Grid> */}
                {/* </Grid> */}
                {/* </Paper> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Booking Preference
                    </Typography>
                    <hr />
                </Grid> */}
                {/* <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow"> */}
                {/* <Paper className="controller-container"> */}
                {/* <Grid container spacing={1} item> */}
                {/* <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.sunday} name="sunday" onChange={handleCheckbox} label="Sunday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.monday} name="monday" onChange={handleCheckbox} label="Monday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.tuesday} name="tuesday" onChange={handleCheckbox} label="Tuesday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.wednesday} name="wednesday" onChange={handleCheckbox} label="Wednesday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.thursday} name="thursday" onChange={handleCheckbox} label="Thursday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.friday} name="friday" onChange={handleCheckbox} label="Friday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.saturday} name="saturday" onChange={handleCheckbox} label="Saturday" />
                        </Grid> */}
                {/* </Grid> */}
                {/* </Paper> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Contact Details
                    </Typography>
                    <hr />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="CPC Holder Name"
                                placeholder="CPC Holder Name"
                                value={depotFields.cPCHolderName}
                                onChange={handledepotFields}
                                name="cPCHolderName"
                                errors={depotFields.errors.cPCHolderName}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="CPC Holder Email"
                                placeholder="CPC Holder Email"
                                type="email"
                                value={depotFields.cPCHolderEmail}
                                onChange={handledepotFields}
                                name="cPCHolderEmail"
                                errors={depotFields.errors.cPCHolderEmail}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink></InputLabel>
                            <SimpleField
                                inputLabel="Additional Planning Emails"
                                placeholder="Additional Planning Emails"
                                type="email"
                                value={depotFields.depotAdditionalPlanningEmails}
                                onChange={handledepotFields}
                                name="depotAdditionalPlanningEmails"
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Secondary Email"
                                placeholder="Secondary Email"
                                type="email"
                                value={depotFields.secondaryEmail}
                                onChange={handledepotFields}
                                name="secondaryEmail"
                                errors={depotFields.errors.secondaryEmail}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Contact Name"
                                placeholder="Contact Name"
                                value={depotFields.contactName}
                                onChange={handledepotFields}
                                name="contactName"
                                errors={depotFields.contactName}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Contact Mobile"
                                placeholder="Contact Mobile"
                                value={depotFields.depotContactMobile}
                                onChange={handledepotFields}
                                name="depotContactMobile"
                                errors={depotFields.errors.depotContactMobile}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Secondary Contact Number"
                                placeholder="Secondary Contact Number"
                                value={depotFields.depotSecondaryContact}
                                onChange={handledepotFields}
                                name="depotSecondaryContact"
                                errors={depotFields.errors.depotSecondaryContact}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Account Name"
                                placeholder="Account Name"
                                value={depotFields.depotAccountName}
                                onChange={handledepotFields}
                                name="depotAccountName"
                                errors={depotFields.errors.depotAccountName}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Account Email"
                                placeholder="Account Email"
                                type="email"
                                value={depotFields.depotAccountEmail}
                                onChange={handledepotFields}
                                name="depotAccountEmail"
                                errors={depotFields.errors.depotAccountEmail}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Gate Access Code"
                                placeholder="Gate Access Code"
                                value={depotFields.depotGateAccessCode}
                                onChange={handledepotFields}
                                name="depotGateAccessCode"
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Opening Times"
                                placeholder="Opening Times"
                                value={depotFields.depotOpeningTimes}
                                onChange={handledepotFields}
                                name="depotOpeningTimes"
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Key Holder1 Name"
                                placeholder="Key Holder1 Name"
                                value={depotFields.depotKeyHolder1Name}
                                onChange={handledepotFields}
                                name="depotKeyHolder1Name"
                                errors={depotFields.errors.depotKeyHolder1Name}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Key Holder1 Number"
                                placeholder="Key Holder1 Number"
                                type="email"
                                value={depotFields.depotKeyHolder1Number}
                                onChange={handledepotFields}
                                name="depotKeyHolder1Number"
                                errors={depotFields.errors.depotKeyHolder1Number}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Key Holder2 Name"
                                placeholder="Key Holder2 Name"
                                value={depotFields.depotKeyHolder2Name}
                                onChange={handledepotFields}
                                name="depotKeyHolder2Name"
                                errors={depotFields.errors.depotKeyHolder2Name}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Key Holder2 Number"
                                placeholder="Key Holder2 Number"
                                type="email"
                                value={depotFields.depotKeyHolder2Number}
                                onChange={handledepotFields}
                                name="depotKeyHolder2Number"
                                errors={depotFields.errors.depotKeyHolder2Number}
                            />
                        </Grid> */}
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Notification Details
                    </Typography>
                    <hr />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} md={4} sm={6}> */}
                        {/* <FlaggedBox
                                label="Order Confirmation Email"
                                chkName="depotIsOrderConfirmation"
                                chkValue={depotFields.depotIsOrderConfirmation}
                                onCheckChange={handleCheckbox}
                                txtName="depotOrderConfirmationEmail"
                                txtValue={depotFields.depotOrderConfirmationEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            /> */}
                        {/* <FormHelperText error>{depotFields.errors.depotOrderConfirmationEmail}</FormHelperText> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Booking Email"
                                chkName="depotIsBookingEmail"
                                chkValue={depotFields.depotIsBookingEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotBookingEmail"
                                txtValue={depotFields.depotBookingEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotBookingEmail}</FormHelperText>
                        </Grid> */}
                        {/* <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Send Job Approval Required"
                                chkName="depotIsSendJobApprovalRequired"
                                chkValue={depotFields.depotIsSendJobApprovalRequired}
                                onCheckChange={handleCheckbox}
                                txtName="depotSendJobApprovalRequiredEmail"
                                txtValue={depotFields.depotSendJobApprovalRequiredEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotSendJobApprovalRequiredEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Job Update Email"
                                chkName="depotIsJobUpdateEmail"
                                chkValue={depotFields.depotIsJobUpdateEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotJobUpdateEmail"
                                txtValue={depotFields.depotJobUpdateEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotJobUpdateEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Send Query Response Received"
                                chkName="depotIsSendQueryResponseReceived"
                                chkValue={depotFields.depotIsSendQueryResponseReceived}
                                onCheckChange={handleCheckbox}
                                txtName="depotSendQueryResponseReceivedEmail"
                                txtValue={depotFields.depotSendQueryResponseReceivedEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotSendQueryResponseReceivedEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Breakdown Notification Email"
                                chkName="depotIsSendBreakdownEmail"
                                chkValue={depotFields.depotIsSendBreakdownEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotBreakdownNotificationEmail"
                                txtValue={depotFields.depotBreakdownNotificationEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotBreakdownNotificationEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Defect Notification Email"
                                chkName="depotIsSendDefectEmail"
                                chkValue={depotFields.depotIsSendDefectEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotDefectNotificationEmail"
                                txtValue={depotFields.depotDefectNotificationEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotDefectNotificationEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Overdue Reminder Email"
                                chkName="depotSendOverdueReminderEmail"
                                chkValue={depotFields.depotSendOverdueReminderEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotOverdueReminderEmail"
                                txtValue={depotFields.depotOverdueReminderEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotOverdueReminderEmail}</FormHelperText>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="Notification Email"
                                placeholder="Breakdown Notification Email"
                                type="email"
                                value={depotFields.depotBreakdownNotificationEmail}
                                onChange={handledepotFields}
                                name="depotBreakdownNotificationEmail"
                                errors={depotFields.errors.depotBreakdownNotificationEmail}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} md={4} sm={6}>
                            <SimpleField
                                inputLabel="Vehicle Status Update Email"
                                placeholder="Vehicle Status Update Email"
                                type="email"
                                value={depotFields.depotVehicleStatusUpdateEmail}
                                onChange={handledepotFields}
                                name="depotVehicleStatusUpdateEmail"
                                errors={depotFields.errors.depotVehicleStatusUpdateEmail}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <SimpleField
                                inputLabel="Timed Job Status Update Email"
                                placeholder=" Timed Job Status Update Email"
                                type="email"
                                value={depotFields.depotTimedVehicleStatusUpdateEmail}
                                onChange={handledepotFields}
                                name="depotTimedVehicleStatusUpdateEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SimpleField
                                inputLabel="PCN Email"
                                placeholder="PCN Email"
                                type="email"
                                value={depotFields.depotPCNEmail}
                                onChange={handledepotFields}
                                name="depotPCNEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>VOR Mobile Number </InputLabel>
                            <SimpleField
                                placeholder="VOR Mobile Numbers"
                                value={depotFields.depotVORMobileNumbers}
                                onChange={handledepotFields}
                                name="depotVORMobileNumbers"
                            />
                        </Grid> */}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h5" color="secondary">
                            Active
                        </Typography>
                        <hr />
                    </Grid> */}
                    {/* <Grid item spacing={2} xs={12} md={12} lg={12} className="grid-paper-shadow"> */}
                    {/* <Grid item xs={1}>
                            <YesButton state={depotFields.depotNotInUse} onClick={() => handleActive(true)}></YesButton>
                        </Grid>
                        <Grid item xs={1}>
                            <NoButton state={depotFields.depotNotInUse} onClick={() => handleActive(false)}></NoButton>
                        </Grid> */}
                    {/* <Grid item container spacing={1} xs={12}> */}
                    {/* <Grid item xs={6} md={1}> */}
                    {/* <YesButton state={depotFields.depotNotInUse} onClick={() => handleActive(true)}></YesButton> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={6} md={1}> */}
                    {/* <NoButton state={depotFields.depotNotInUse} onClick={() => handleActive(false)}></NoButton> */}
                    {/* </Grid> */}
                    {/* </Grid> */}
                    {/* </Grid> */}
                    {/* </Paper> */}

                    <Grid item container xs={12} spacing={1} justify="space-between" style={{ marginTop: 8 }}>
                        <Grid item xs={6}>
                            <SecondaryButton
                                onClick={handleSubmit}
                                className="submit_btn"
                                isBtnLoader={btnLoader}
                                disabled={!getAccessParam['CanUpdateDepots']}
                            >
                                Save
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton className="submit_btn" onClick={() => props.onFormClose()}>
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <DepotProvider {...props}>
            <ScheduleDetails {...props} />
        </DepotProvider>
    );
};
export default Screen;
