import React, { useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { SecondaryButton, DataGrid } from '../../../Core/Controls';
import { InputLabel, Typography, FormHelperText } from '@material-ui/core';
import { useToast } from '../../../Core/Hooks/useToast';
import { Backup } from '@material-ui/icons';
import { postImportAddress } from '../../../Core/Services/InternalUser/postCodeService';

const columns = [
    { key: 'addressName', name: 'Name', width: '8%' },
    { key: 'addressCode', name: 'Code', width: '4%' },
    { key: 'addressLine1', name: 'Address1', width: '9%' },
    { key: 'addressLine2', name: 'Address2', width: '6%' },
    // { key: 'addressLine3', name: 'Address3', width: '6%' },
    { key: 'addressTown', name: 'City', width: '7%' },
    { key: 'addressCounty', name: 'County', width: '5%' },
    // { key: 'addressCountry', name: 'Country ', width: '5%' },
    { key: 'addressPostCode', name: 'Post Code', width: '7%' },
    { key: 'addressTelephone', name: 'Telephone', width: '7%' },
    // { key: 'addressFax', name: 'Fax', width: '7%' },
    // { key: 'addressContactName', name: 'Contact Name', width: '10%' },
    { key: 'addressEmail', name: 'Email', width: '7%' },
    { key: 'result', name: 'Result', width: '12%' }
];

const ImportAddress = (props) => {
    const [state, setState] = useState({
        errors: {},
        file: '',
        rows: [],
        showTable: false
    });

    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const handleSubmit = async () => {
        if (state.fileName && state.selectedFile) {
            setBtnLoader(true);
            setState((st) => ({ ...st, uploading: true }));
            const data = new FormData();
            data.append(state.fileName, state.selectedFile);
            let res = await postImportAddress(data);
            if (res.success) {
                setState((st) => ({ ...st, rows: res.data.res }));
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    return (
        <Grid container spacing={2} className="screen mt_10">
            <Grid item xs={12}>
                <InputLabel required>Upload file</InputLabel>
                <Tooltip title="Upload Document">
                    <label>
                        <Backup color="secondary" />
                        <input multiple type="file" onChange={onFileSelect} style={{ display: 'none' }} accept=".xlsx, .xls, .csv" />
                    </label>
                </Tooltip>
                <FormHelperText error>{state.errors.file}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={2}>
                            <SecondaryButton disabled={!state.selectedFile} isBtnLoader={btnLoader} onClick={handleSubmit} fullWidth className="submit_btn">
                                Import Address
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                            <SecondaryButton onClick={() => props.onClose(false)} fullWidth className="submit_btn">
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <DataGrid rows={state.rows} columns={columns} paginate height={610} />
            </Grid>
        </Grid>
    );
};

export default ImportAddress;
