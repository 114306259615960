import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    SelectBox,
    SecondaryButton,
    SecondaryCheckbox,
    BreadCrumbs,
    MandatoryField,
    SimpleField,
    YesButton,
    NoButton,
    Postcode,
    SingleSelect
} from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';

import {
    getDepartmentById,
    getDepotContactsById,
    postDepartment,
    postDepotContacts
} from '../../../../../../Core/Services/InternalUser/ControllerService/depotsDepartmentService';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';

const AddEditDepotContacts = (props) => {
    const [state, setState] = useState({ depotContactID: props.depotContactID || null });
    const [error, setError] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const handledepotFields = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (props.depotContactID) {
            let res = await getDepotContactsById(props.depotContactID);
            let data = res.data?.details[0];

            if (res.success) {
                setState((st) => ({
                    ...st,
                    depotContactID: data.depotContactID,
                    depotContactName: data.depotContactName,
                    depotContactEmail: data.depotContactEmail,
                    depotContactPhone: data.depotContactPhone,
                    depotContactCompanyName: data.depotContactCompanyName
                }));
            }
        }
    }, []);

    const validation = () => {
        let isValid = true;
        let errors = {};
        if (!state.depotContactName) {
            errors.name = 'Name Field is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                ////
                depotContactDepotID: props.depotID,
                depotContactID: state.depotContactID,
                depotContactName: state.depotContactName,
                depotContactEmail: state.depotContactEmail,
                depotContactPhone: state.depotContactPhone,
                depotContactCompanyName: state.depotContactCompanyName
            };
            let res = await postDepotContacts(data);
            if (res?.data?.success) {
                showToastSuccess(`Contact ${state.depotContactID ? 'Updated' : 'Added'} Successfully`);
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError('Contact Fail to Add ');
                // props.onClose(false);
                setBtnLoader(false);
            }
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} sm={6} lg={4} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Name"
                            placeholder="Name"
                            value={state.depotContactName}
                            onChange={handledepotFields}
                            name="depotContactName"
                            errors={error.name}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} lg={4}>
                        <SimpleField
                            inputLabel="Company Name"
                            placeholder="Company Name"
                            value={state.depotContactCompanyName}
                            onChange={handledepotFields}
                            name="depotContactCompanyName"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} lg={4}>
                        <SimpleField
                            type="text"
                            inputLabel="Email"
                            placeholder="Email"
                            value={state.depotContactEmail}
                            onChange={handledepotFields}
                            name="depotContactEmail"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} lg={4}>
                        <SimpleField
                            type="tel"
                            inputLabel="Phone"
                            placeholder="Phone"
                            value={state.depotContactPhone}
                            onChange={handledepotFields}
                            name="depotContactPhone"
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit} disabled={!checkhaveAccess?.CanUpdateDepotDepartments}>
                            {state.depotContactID ? 'Update' : 'Add'}
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton forceEnabled={true} onClick={() => props.onClose(false)}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddEditDepotContacts;
