import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import {
    InputText,
    SecondaryButton,
    DatePicker,
    MandatoryField,
    Multiselect,
    DataGrid,
    formatters,
    SimpleField,
    DateTimePicker,
    SingleSelect
} from '../../../../Core/Controls';
import { SelectBox } from '../../../../Core/Controls';
import { addVor, getDropDownForVor, getVorByID, getVorNotesById } from '../../../../Core/Services/InternalUser/VOR/addEditVor';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';
import moment from 'moment';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';

const basecolumns = [
    { key: 'createdOn', width: '15%', name: 'Date', formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'userName', width: ' 15%', name: 'User' },
    { key: 'vorLogComment', width: '70%', name: 'Comments' }
];

const AddVor = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        errors: {},
        workAccomplishedCodes: [],
        technicianFailureCodes: [],
        AllvorTypeDetails: [],
        AllvorSubCategoryList: [],
        vorSubCategoryList: [],
        vorTypeList: [],
        vorTypeDetailList: [],
        branchIds: [],
        moduleIds: [],
        customerIds: [],
        technicianFailureCodeIDs: [],
        technicianFailureCode: [],
        workAccomplishedCodesId: [],
        showLoader: true,
        vorVehicleEstimatedEndDate: null,
        isReadOnly: false,
        faultTypes: [],
        defectFaultTypes: []
    });

    const [masterData, setMasterData] = useState({
        technicianFailureCode: [],
        workAccomplishedCodes: []
    });

    const [selectedData, setSelectedData] = useState({
        technicianFailureCode: [],
        workAccomplishedCodes: [],
        modules: []
    });

    const [tempOptions, setTempOptions] = useState({
        vorVehicleTypeIDValue: null,
        vorVehicleTypeDetailIDValue: null,
        vorSubCategoryIdValue: null
    });

    const workAccomplishedChange = (e, value) => {
        setSelectedData((st) => ({ ...st, workAccomplishedCodes: value }));
        setState((st) => {
            const nst = { ...st, workAccomplishedCodesId: value.map((c) => c.id) };
            return nst;
        });
    };
    const technicianFailureChange = (e, value) => {
        setSelectedData((st) => ({ ...st, technicianFailureCode: value }));
        setState((st) => {
            const nst = { ...st, technicianFailureCodeIDs: value.map((c) => c.id) };
            return nst;
        });
    };

    useEffect(() => {
        if (props.update) {
            const selectedtechnicianFailureCode = masterData.technicianFailureCode?.filter((w) => state.technicianFailureCodeIDs?.indexOf(w.id) > -1);
            const selectedworkAccomplishedCodes = masterData.workAccomplishedCodes?.filter((w) => state.workAccomplishedCodesId?.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                technicianFailureCode: selectedtechnicianFailureCode,
                workAccomplishedCodes: selectedworkAccomplishedCodes
            }));
        }
    }, [state.technicianFailureCodeIDs, masterData.technicianFailureCode, state.workAccomplishedCodesId, masterData.workAccomplishedCodes]);

    useEffect(async () => {
        let res = await getDropDownForVor(props.VehicleID);
        if (res.success) {
            setState((st) => ({
                ...st,
                vorTypeList: res.data.vorTypes.map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription })),
                AllvorSubCategoryList: res.data.vorSubCategories,
                AllvorTypeDetails: res.data.vorTypeDetails,
                faultTypes: res.data.faultTypes
            }));
            setMasterData((st) => ({
                workAccomplishedCodes: res.data.workAccomplishedCodes.map((w) => ({
                    id: w.workAccomplishedCodeID,
                    name: w.workAccomplishedDescription
                })),
                technicianFailureCode: res.data.technicianFailureCodes.map((w) => ({
                    id: w.technicianFailureCode,
                    name: w.technicianFailureDescription
                }))
            }));
            if (props.fromPage) {
                setTempOptions((st) => ({
                    ...st,
                    vorVehicleTypeIDValue: res.data?.vorTypes
                        .filter((order) => order.vorTypeID === (props.fromPage == 'Breakdown' ? 2 : props.fromPage == 'Defect' ? 4 : ''))
                        .map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription }))[0]
                }));
            }
        }

        if (props.update) {
            let resById = await getVorByID(props.vorVehicleID || '');
            if (resById.success) {
                setState((st) => ({
                    ...st,
                    vorVehicleID: resById.data.vorVehicleID,
                    vorVehicleVehicleID: resById.data.vorVehicleVehicleID,
                    vorVehicleLocation: resById.data.vorVehicleLocation,
                    vorVehicleDateStarted: moment(resById.data.vorVehicleDateStarted).format('YYYY-MM-DDTHH:mm'),
                    vorVehicleDateEnded: resById.data.vorVehicleDateEnded ? moment(resById.data.vorVehicleDateEnded).format('YYYY-MM-DDTHH:mm') : null,
                    vorVehicleTypeID: resById.data.vorVehicleTypeID,
                    vorVehicleTypeDetailID: resById.data.vorVehicleTypeDetailID,
                    vorVehicleEstimatedEndDate: formatters.DateFormatter(resById.data.vorVehicleEstimatedEndDate),
                    vorAdditionalComment: resById.data.vorAdditionalComment,
                    technicianFailureCodeIDs: resById.data.technicianFailureCode,
                    workAccomplishedCodesId: resById.data.workAccomplishedCodes,
                    vorSubCategoryId: resById.data.vorSubCategoryId,
                    isReadOnly: resById.data.vorVehicleDateEnded ? true : false,
                    defectFaultTypes: resById.data.faultIds || []
                }));
                setTempOptions((st) => ({
                    ...st,
                    vorVehicleTypeIDValue: res.data?.vorTypes
                        .filter((order) => order.vorTypeID === resById.data.vorVehicleTypeID)
                        .map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription }))[0],

                    vorVehicleTypeDetailIDValue: res.data.vorTypeDetails
                        .filter((v) => v.vorTypeDetailID === resById.data.vorVehicleTypeDetailID)
                        .map((p) => ({ id: p.vorTypeDetailID, name: p.vorTypeDetailDescription }))[0],
                    vorSubCategoryIdValue: res.data.vorSubCategories
                        .filter((v) => v.vorSubCategoryId === resById.data.vorSubCategoryId)
                        .map((p) => ({ id: p.vorSubCategoryId, name: p.vorSubCategoryDescription }))[0]
                }));
            }
            let resVorNote = await getVorNotesById(props.vorVehicleID || '');
            if (resVorNote.success) {
                setState((st) => ({ ...st, notesGrid: resVorNote.data, showLoader: false }));
            }
        }
    }, []);

    useEffect(() => {
        if (state.defectFaultTypes) {
            const selectedFaultTypes = state.faultTypes.filter((w) => state.defectFaultTypes.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedFaultTypes
            }));
        }
    }, [state.defectFaultTypes, state.faultTypes]);

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setState((st) => {
            const nst = { ...st, defectFaultTypes: value.map((c) => c.id) };
            return nst;
        });
    };

    useEffect(() => {
        let filterTypeDetails = [];
        if (tempOptions.vorVehicleTypeIDValue) {
            filterTypeDetails = state.AllvorTypeDetails.filter((v) => {
                return v.vorTypeDetailTypeID == tempOptions.vorVehicleTypeIDValue?.id;
            }).map((l) => ({ id: l.vorTypeDetailID, name: l.vorTypeDetailDescription }));
        }

        let filterSubCatagoryList = state.AllvorSubCategoryList.filter((v) => {
            return v.vorSubCategoryCategoryId == tempOptions.vorVehicleTypeDetailIDValue?.id;
        }).map((l) => ({ id: l.vorSubCategoryId, name: l.vorSubCategoryDescription }));

        setState((st) => ({ ...st, vorTypeDetailList: filterTypeDetails, vorSubCategoryList: filterSubCatagoryList }));
    }, [tempOptions.vorVehicleTypeIDValue, tempOptions.vorVehicleTypeDetailIDValue]);

    const validations = () => {
        const {
            vorVehicleTypeID,
            vorVehicleTypeDetailID,
            vorVehicleDateStarted,
            vorVehicleEstimatedEndDate,
            vorVehicleLocation,
            vorAdditionalComment,
            vorSubCategoryId
        } = state;

        const { vorVehicleTypeIDValue, vorVehicleTypeDetailIDValue, vorSubCategoryIdValue } = tempOptions;

        let formIsValid = true;
        let errors = {};
        if (!vorVehicleTypeIDValue) {
            errors.vorVehicleTypeID = 'VOR Type is required';
            formIsValid = false;
        }
        if (!vorVehicleTypeDetailIDValue) {
            errors.vorVehicleTypeDetailID = 'VOR Reason is required';
            formIsValid = false;
        }
        if (!vorVehicleDateStarted) {
            errors.vorVehicleDateStarted = 'Start Date is required';
            formIsValid = false;
        }
        if (!vorVehicleLocation) {
            errors.vorVehicleLocation = 'Location is required';
            formIsValid = false;
        }
        if (!vorSubCategoryIdValue) {
            errors.vorSubCategoryId = 'VOR Sub-Category is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        let data = {};
        if (validations()) {
            setBtnLoader(true);
            if (props.update) {
                data = {
                    vorVehicleID: state.vorVehicleID,
                    vorVehicleVehicleID: state.vorVehicleVehicleID,
                    vorVehicleLocation: state.vorVehicleLocation,
                    vorVehicleDateStarted: state.vorVehicleDateStarted,
                    vorVehicleDateEnded: state.vorVehicleDateEnded,
                    vorVehicleTypeID: tempOptions.vorVehicleTypeIDValue?.id,
                    vorVehicleTypeDetailID: tempOptions.vorVehicleTypeDetailIDValue?.id,
                    vorVehicleEstimatedEndDate: state.vorVehicleEstimatedEndDate,
                    vorAdditionalComment: state.vorAdditionalComment,
                    vorSubCategoryId: tempOptions.vorSubCategoryIdValue?.id,
                    technicianFailureCode: state.technicianFailureCodeIDs,
                    workAccomplishedCodes: state.workAccomplishedCodesId,
                    faultIds: selectedData.modules.map(({ id }) => id)
                };
            } else {
                data = {
                    vorVehicleVehicleID: props.VehicleID,
                    vorVehicleTypeID: tempOptions.vorVehicleTypeIDValue?.id,
                    vorVehicleTypeDetailID: tempOptions.vorVehicleTypeDetailIDValue?.id,
                    vorVehicleDateStarted: state.vorVehicleDateStarted,
                    vorVehicleEstimatedEndDate: state.vorVehicleEstimatedEndDate,
                    vorVehicleLocation: state.vorVehicleLocation,
                    vorAdditionalComment: state.vorAdditionalComment,
                    vorSubCategoryId: tempOptions.vorSubCategoryIdValue?.id,
                    faultIds: selectedData.modules.map(({ id }) => id),
                    defectID: props.defectID ? props.defectID : '',
                    jobID: props.jobID ? props.jobID : ''
                };
            }
            let res = await addVor(data);
            if (res.success) {
                showToastSuccess(`${props.update ? 'VOR Updated Successfully' : 'VOR Added Successfully'}`);
                props.onClose(true);
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        return tempCols;
    }, []);

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => {
            let newSt = { ...st, [nm]: val };
            if (nm === 'vorVehicleTypeIDValue') {
                newSt.vorVehicleTypeDetailIDValue = null;
            }
            if (nm === 'vorVehicleTypeDetailIDValue') {
                newSt.vorSubCategoryIdValue = null;
            }
            return newSt;
        });
    };
    const RenderSingelNote = (props) => {
        return (
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic' }}>
                    {props.note.vorLogComment}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right' }}>
                    {props.note.userName} @ {moment(props.note.createdOn).format('LLL')}
                </div>
            </div>
        );
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Type
                    </InputLabel>
                    <SingleSelect
                        options={state.vorTypeList}
                        value={tempOptions.vorVehicleTypeIDValue}
                        onChange={singleSelectChange('vorVehicleTypeIDValue')}
                        disabled={state.isReadOnly}
                        autoFocus={true}
                    />
                    <FormHelperText error>{state.errors.vorVehicleTypeID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Reason
                    </InputLabel>
                    <SingleSelect
                        options={state.vorTypeDetailList}
                        value={tempOptions.vorVehicleTypeDetailIDValue}
                        onChange={singleSelectChange('vorVehicleTypeDetailIDValue')}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorVehicleTypeDetailID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Sub Category
                    </InputLabel>
                    <SingleSelect
                        options={state.vorSubCategoryList}
                        value={tempOptions.vorSubCategoryIdValue}
                        onChange={singleSelectChange('vorSubCategoryIdValue')}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorSubCategoryId}</FormHelperText>
                </Grid>
                {/* <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Start Date
                    </InputLabel>
                    <DatePicker placeholder="VOR Start Date" value={state.vorVehicleDateStarted} onChange={inputChange} name="vorVehicleDateStarted" />
                    <FormHelperText error>{state.errors.vorVehicleDateStarted}</FormHelperText>
                </Grid> */}

                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <InputLabel shrink>VOR Start Date</InputLabel>
                    <DateTimePicker
                        placeholder="VOR Start Date"
                        value={state.vorVehicleDateStarted}
                        onChange={inputChange}
                        name="vorVehicleDateStarted"
                        inputProps={{
                            max: new Date().toISOString().slice(0, 16)
                        }}
                        // InputProps={{ inputProps: { max: `${state.arrivedDate}` } }}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorVehicleDateStarted}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Location"
                        placeholder="Location"
                        value={state.vorVehicleLocation}
                        onChange={inputChange}
                        name="vorVehicleLocation"
                        errors={state.errors.vorVehicleLocation}
                        disabled={state.isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputLabel shrink>Estimated Completion Date</InputLabel>
                    <DatePicker
                        // inputProps={{
                        //     min: new Date().toISOString().slice(0, 10)
                        // }}
                        placeholder="Estimated Completion Date"
                        value={state.vorVehicleEstimatedEndDate}
                        onChange={inputChange}
                        name="vorVehicleEstimatedEndDate"
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorVehicleEstimatedEndDate}</FormHelperText>
                </Grid>
                {!props.update && (
                    <Grid item xs={12}>
                        <InputLabel shrink>Fault Category</InputLabel>
                        <Multiselect options={state.faultTypes} value={selectedData.modules} onChange={ModuleChange} style={{ marginTop: -8 }} />
                    </Grid>
                )}
                {props.update && (
                    // <Grid item xs={12} sm={4}>
                    //     <InputLabel shrink>VOR End Date</InputLabel>
                    //     <DatePicker placeholder="VOR End Date" value={state.vorVehicleDateEnded} onChange={inputChange} name="vorVehicleDateEnded" />
                    //     <FormHelperText error>{state.errors.vorVehicleDateEnded}</FormHelperText>
                    // </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputLabel shrink>VOR End Date</InputLabel>
                        <DateTimePicker
                            placeholder="VOR End Date"
                            value={state.vorVehicleDateEnded}
                            onChange={inputChange}
                            name="vorVehicleDateEnded"
                            InputProps={{ inputProps: { min: `${state.vorVehicleDateStarted}` } }}
                            disabled={state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.vorVehicleDateEnded}</FormHelperText>
                    </Grid>
                )}
                {props.update && (
                    <Grid item xs={12} md={8} lg={8}>
                        <InputLabel shrink>Fault Category</InputLabel>
                        <Multiselect options={state.faultTypes} value={selectedData.modules} onChange={ModuleChange} style={{ marginTop: -8 }} />
                    </Grid>
                )}
                {props.update && (
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Engineer Failure Code</InputLabel>
                        <Multiselect
                            options={masterData.technicianFailureCode}
                            onChange={technicianFailureChange}
                            value={selectedData.technicianFailureCode}
                            disabled={state.isReadOnly}
                        />
                    </Grid>
                )}
                {props.update && (
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Work Accomplished Codes</InputLabel>
                        <Multiselect
                            options={masterData.workAccomplishedCodes}
                            value={selectedData.workAccomplishedCodes}
                            onChange={workAccomplishedChange}
                            disabled={state.isReadOnly}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <SimpleField
                        inputLabel="Notes"
                        placeholder="Notes"
                        multiline
                        rows={3}
                        name="vorAdditionalComment"
                        fullWidth
                        onChange={inputChange}
                        value={state.vorAdditionalComment}
                        disabled={state.isReadOnly}
                    />
                </Grid>
                {props.update ? (
                    <Grid item xs={6} className={`${props.update ? '' : 'submit_btn'}`}>
                        <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler} disabled={state.isReadOnly || isCustomer}>
                            Update
                        </SecondaryButton>
                    </Grid>
                ) : (
                    <Grid item xs={6} className={`${props.update ? '' : 'submit_btn'}`}>
                        <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                            Add
                        </SecondaryButton>
                    </Grid>
                )}
                <Grid item xs={6} className={`${props.update ? '' : 'submit_btn'}`}>
                    <SecondaryButton forceEnabled={true} onClick={() => props.onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
                {props.update && (
                    <Grid item xs={12} className="notes_grid">
                        <Grid item xs={12} style={{ padding: '5px', minHeight: '460px' }} className="grid-style">
                            {state.notesGrid?.map((n, i) => (
                                <>
                                    <RenderSingelNote key={i} note={n} />
                                    <hr style={{ opacity: '30%' }} />
                                </>
                            ))}
                        </Grid>
                        <DataGrid rows={state.notesGrid} columns={columns} height={550} loadingData={state.showLoader} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
export default AddVor;
