import { CircularProgress } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import './UploaderModal.scss';

const SingleDocumentViewer = (props) => {
    const printSelectedImg = () => {
        const printableImage = document.getElementById('printableImage');
        if (printableImage) {
            window.print();
        }
    };

    return (
        <div style={{ height: '100%' }}>
            {_.isEmpty(props.imageList) ? (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <>
                    {props.slectedFileExt === '.pdf' ? (
                        <iframe src={props.imageList} id="editviewer" frameBorder="0" scrolling="auto" allowFullScreen width="100%" height="100%"></iframe>
                    ) : (
                        <div style={{ height: '100%' }}>
                            {/* <span onClick={printSelectedImg} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                print
                            </span> */}
                            <img src={props.imageList} alt="No document selected" id="printableImage" width="100%" height="100%" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SingleDocumentViewer;
