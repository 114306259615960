import { Card, FormHelperText, Grid, InputLabel, Tooltip, Typography, ButtonGroup, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, DatePicker, formatters, SingleSelect } from '../../../../Core/Controls';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { getVehicleScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { getBreakDownFaultType, getDefectFaultType } from '../../../../Core/Services/InternalUser/Maintenance/DefectsService';
import '../../VorReporting/vorReporting.scss';
import { RenderReportingByFilter } from '../../VorReporting/RenderReportingDesign';
import { ReportingBarChart } from './../../../../Core/Controls/Charts/index';
import { GetBreakdownReportingExcelFile, GetDefectReportingExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Reporting', active: true }
];

const crumdsPath = (val) => {
    if (val == 'defect') {
        return '/maintenance/defects';
    }
    if (val == 'breakdown') {
        return '/maintenance/breakdowns/:all';
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: '2px solid #f5f5f5',
        Opacity: 0.9,
        minWidth: 120,
        borderRadius: 10,
        boxShadow: 'none',
        background: '#8080800d',
        height: '100%',
        width: '100%'
    }
}));

const baseColumns = [
    // { key: 'action', name: 'Action', width: 100 },
    { key: 'regNo', name: 'Reg No', width: 120 },
    { key: 'defectNo', name: 'Defect No', width: 120 },
    { key: 'defectDate', name: 'Start Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    { key: 'defectDesc', name: 'Description' },
    { key: 'customer', name: 'Customer' },
    { key: 'depotName', name: 'Depot' },
    { key: 'faultTypes', name: 'Fault Types', filterable: 'no', sortable: false }
];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const CustomerBreakDownDefectReporting = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const [state, setState] = useState({
        customerList: [],
        errors: {},
        depotWithCustList: [],
        depotsList: [],
        customerID: +props?.match?.params.id || props.customerID || null,
        depotsID: null,
        startDate: startDate,
        endDate: endDate,
        fromScreen: window.location.pathname.includes('defect') ? 'defect' : 'breakdown',
        VehicleByType: [],
        VorList: [],
        NumberOfDepot: [],
        sumOfAllDepot: 0,
        url: '',
        vorByReg: [],
        top5Make: [],
        top5Model: [],
        setTop5ID: 1,
        setBarData: 4,
        depot: [],
        barValues: 4,
        makeList: [],
        vehicleCategoryListdropdown: []
    });

    const handleReports = (val) => {
        setState((st) => ({ ...st, setTop5ID: val }));
    };

    const handleBarChart = (val) => {
        setState((st) => ({ ...st, setBarData: val }));
    };

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null,
        depotsIDValue: null,
        makeIDValue: null,
        vehicleCategoryIDValue: null
    });

    const Crumbs = useMemo(() => {
        const tempCols = [...crumbs];
        tempCols.splice(1, 0, { name: `${capitalizeFirstLetter(state.fromScreen)}`, path: crumdsPath(state.fromScreen) });
        return tempCols;
    }, [state.fromScreen]);

    useEffect(async () => {
        let res = await getVehicleScreenDropdowns();
        setState((st) => ({
            ...st,
            customerList: res.data.customers,
            depotsList: res.data.depots,
            makeList: res.data.makes || [],
            vehicleCategoryListdropdown: res.data.vehicleCategories || []
        }));
    }, []);

    useEffect(() => {
        let newSt = {};
        if (state.customerID && state.customerList.length > 0) {
            newSt.customerIDValue = state.customerList.find(({ id }) => id === +state.customerID);
        } else {
            newSt.customerIDValue = state.customerList[0];
        }

        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [state.customerID, state.customerList]);

    useEffect(async () => {
        let res = {};

        if (state.customerList.length > 0 && tempOptions.customerIDValue?.id) {
            let newst = {};
            if (state.fromScreen == 'defect') {
                res = await getDefectFaultType(
                    tempOptions.customerIDValue?.id || '',
                    state.startDate,
                    state.endDate,
                    tempOptions.vehicleCategoryIDValue?.id || '',
                    tempOptions.makeIDValue?.id || ''
                );
            }
            if (state.fromScreen == 'breakdown') {
                res = await getBreakDownFaultType(
                    tempOptions.customerIDValue?.id || '',
                    state.startDate,
                    state.endDate,
                    tempOptions.vehicleCategoryIDValue?.id || '',
                    tempOptions.makeIDValue?.id || ''
                );
            }
            if (res.success) {
                newst.VehicleByType = res.data?.list?.map((m) => ({ ...m, name: m.faultType, ['Total Days']: m.total })) || [];
                newst.depot = res.data?.depot?.map((m) => ({ ...m, name: m.depot, ['Total Days']: m.total })) || [];
                newst.vorByReg = res.data?.byReg?.map((m) => ({ ...m, sum: m.total, val: m.reg })) || [];
                newst.top5Make = res.data?.top5Make?.map((m) => ({ ...m, sum: m.total, val: m.make })) || [];
                newst.top5Model = res.data?.top5Model?.map((m) => ({ ...m, sum: m.total, val: m.model })) || [];
            }
            setState((st) => ({
                ...st,
                ...newst,
                url: getURl()
            }));
        }
    }, [state.startDate, state.endDate, tempOptions.customerIDValue, tempOptions.makeIDValue, tempOptions.vehicleCategoryIDValue]);

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        if (val) {
            setTempOptions((st) => {
                let newSt = { ...st, [nm]: val };
                if (nm === 'customerIDValue') {
                    newSt.depotsIDValue = null;
                }
                return newSt;
            });
        } else {
            setTempOptions((st) => ({ ...st, [nm]: null }));
        }
    };

    const handleBar = (val) => {
        setState((st) => ({ ...st, barValues: val }));
    };

    const convertData = (res) => {
        setState((st) => ({ ...st, VorList: res.data.list }));
        return {
            records: res.data.list,
            total: res.data.total
        };
    };

    let settop5List = {
        1: state.vorByReg,
        2: state.top5Model,
        3: state.top5Make,
        4: state.VehicleByType,
        5: state.depot
    };
    let label = {
        1: 'Vehicles (Occurrence)',
        2: 'Models (Occurrence)',
        3: 'Makes (Occurrence)',
        4: 'By Fault Type (Occurrence)',
        5: 'By Depot (Occurrence)'
    };

    let getURl = () => {
        if (state.fromScreen == 'defect') {
            return `VehicleDefects/ByStartEndDate?startDate=${state.startDate}&endDate=${state.endDate}&vehicleCategoryID=${
                tempOptions.vehicleCategoryIDValue?.id || ''
            }&makeID=${tempOptions.makeIDValue?.id || ''}`;
        }
        if (state.fromScreen == 'breakdown') {
            return `VehicleBreakdowns/ByStartEndDate?startDate=${state.startDate}&endDate=${state.endDate}&vehicleCategoryID=${
                tempOptions.vehicleCategoryIDValue?.id || ''
            }&makeID=${tempOptions.makeIDValue?.id || ''}`;
        }
    };
    const renderVehicleReg = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() =>
                    history.push({
                        pathname: `/controller/editVehicle/${row.vehicleID}`
                    })
                }
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const gotoDefectEditScreen = (row) => {
        return state.fromScreen == 'defect'
            ? history.push({
                  pathname: `/maintenance/defect/Edit/${row.defectID}/${row.vehicleID}`,
                  state: 'Edit'
              })
            : history.push({
                  pathname: `/maintenance/breakdowns/Edit/${row.defectID}/${row.vehicleID}`,
                  state: 'Edit'
              });
    };
    const rendeDefectNo = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => gotoDefectEditScreen(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.defectNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const tempCol = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'regNo');
        let agentNameCol = tempCols.find((element) => element.key === 'defectNo');
        if (actionCol) {
            actionCol.formatter = renderVehicleReg;
        }
        if (agentNameCol) {
            agentNameCol.formatter = rendeDefectNo;
        }

        return tempCols;
    }, []);
    return (
        <div className=" vorReporting screen">
            <BreadCrumbs crumbs={Crumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6} lg={3}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <DatePicker value={state.startDate} onChange={inputChange} name="startDate" />
                </Grid>
                <Grid item xs={12} md={4} sm={6} lg={3}>
                    <InputLabel shrink>End Date</InputLabel>
                    <DatePicker value={state.endDate} onChange={inputChange} name="endDate" />
                </Grid>
                <Grid item xs={12} md={4} sm={4} lg={3} xl={2} className="mandatory-fields">
                    <InputLabel required shrink>
                        Vehicle Category
                    </InputLabel>
                    <SingleSelect
                        options={state.vehicleCategoryListdropdown}
                        value={tempOptions.vehicleCategoryIDValue}
                        onChange={singleSelectChange('vehicleCategoryIDValue')}
                    />
                    <FormHelperText error>{state.errors.vehicleCategoryID}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4} sm={4} lg={2} xl={2}>
                    <InputLabel shrink>Make</InputLabel>
                    <SingleSelect options={state.makeList} value={tempOptions.makeIDValue} onChange={singleSelectChange('makeIDValue')} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: -2, marginBottom: -2 }}>
                <Grid item xs={12}>
                    <Card className={classes.root}>
                        <Grid container>
                            <Grid item xs={12} md={6} lg={10}>
                                <div style={{ width: '100%', textAlign: 'left', color: '#f50057', fontWeight: 600, margin: '10px 20px' }}>
                                    Top 5 {label[state.setTop5ID]}
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6} lg={2}>
                                <div style={{ marginRight: 20, marginTop: 10, float: 'right' }}>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                        <Button
                                            style={{ fontSize: 10 }}
                                            variant={state.setTop5ID === 1 ? 'contained' : 'outlined'}
                                            onClick={() => handleReports(1)}
                                        >
                                            Vehicles
                                        </Button>
                                        <Button
                                            style={{ fontSize: 10 }}
                                            variant={state.setTop5ID === 2 ? 'contained' : 'outlined'}
                                            onClick={() => handleReports(2)}
                                        >
                                            Models
                                        </Button>
                                        <Button
                                            style={{ fontSize: 10 }}
                                            variant={state.setTop5ID === 3 ? 'contained' : 'outlined'}
                                            onClick={() => handleReports(3)}
                                        >
                                            {' '}
                                            Makes
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>

                        <RenderReportingByFilter
                            list={settop5List[state.setTop5ID]}
                            // label={`Top 5 ${label[state.setTop5ID]}`}
                            noRow={`No ${state.fromScreen}s reported in the selected date range`}
                        />
                    </Card>
                </Grid>
                {state.VehicleByType.length > 0 ? (
                    <Grid item xs={12} lg={12}>
                        <Card className={classes.root}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ width: '100%', textAlign: 'left', color: '#f50057', fontWeight: 600, margin: '10px 20px' }}>
                                        {label[state.barValues]}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ marginRight: 20, marginTop: 10, float: 'right' }}>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                            <Button
                                                variant={state.barValues === 4 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(4)}
                                                style={{ fontSize: 10 }}
                                            >
                                                By Fault Type
                                            </Button>
                                            <Button
                                                variant={state.barValues === 5 ? 'contained' : 'outlined'}
                                                onClick={() => handleBar(5)}
                                                style={{ fontSize: 10 }}
                                            >
                                                {' '}
                                                By Depot
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                            </Grid>
                            <ReportingBarChart list={settop5List[state.barValues]} label={label[state.barValues]} hideCurrnecy={true} />
                        </Card>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    {state.url && (
                        <GijgoGrid dataConverter={(res) => convertData(res)} columns={tempCol} getUrl={state.url} showDownload={true} downloadBtnName="VOR" />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomerBreakDownDefectReporting;
