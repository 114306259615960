import { CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Tooltip } from '@material-ui/core';
import { formatters, MandatoryField, SecondaryButton, SelectBox } from '../../../../../../Core/Controls';
import SearchIcon from '@material-ui/icons/Search';

export const JobItemPurchaseParts = ({ state, handleInputChange, pullDataByPartNumber, addPartToLocally }) => {
    return (
        <>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={2} className="mandatory-fields">
                    <MandatoryField
                        autoFocus
                        isDisabled={state.readOnly}
                        inputLabel="Part Number"
                        name="partNumber"
                        placeholder="Part Number"
                        onChange={handleInputChange}
                        value={state.partNumber}
                        errors={state.errors.partNumber}
                        // InputProps={{
                        //     endAdornment: (
                        //         <InputAdornment position="end">
                        //             <Tooltip title={'Fetch Part Details By Number'} arrow>
                        //                 {
                        //                     <Grid>
                        //                         {state.partNumber.length > 2 ? (
                        //                             <IconButton
                        //                                 style={{ padding: '0px' }}
                        //                                 onClick={(e) => pullDataByPartNumber(e, 'P')}
                        //                                 size="small"
                        //                                 state={state}
                        //                             >
                        //                                 {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                        //                             </IconButton>
                        //                         ) : null}
                        //                     </Grid>
                        //                 }
                        //             </Tooltip>
                        //         </InputAdornment>
                        //     )
                        // }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={5} className="mandatory-fields">
                    <MandatoryField
                        isDisabled={state.readOnly}
                        inputLabel="Part Description"
                        name="partDescription"
                        placeholder="Part Description"
                        onChange={handleInputChange}
                        value={state.partDescription}
                        errors={state.errors.partDescription}
                        // InputProps={{
                        //     endAdornment: (
                        //         <InputAdornment position="end">
                        //             <Tooltip title={'Fetch Part Details By Description'} arrow>
                        //                 <Grid>
                        //                     {state.partDescription.length > 2 ? (
                        //                         <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'D')} size="small">
                        //                             {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                        //                         </IconButton>
                        //                     ) : null}
                        //                 </Grid>
                        //             </Tooltip>
                        //         </InputAdornment>
                        //     )
                        // }}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2} className="mandatory-fields">
                    <MandatoryField
                        isDisabled={state.readOnly}
                        inputLabel="Quantity"
                        name="partQuantity"
                        placeholder="Quantity"
                        onChange={handleInputChange}
                        value={state.partQuantity}
                        autoFocus={state.focus}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} className="mandatory-fields">
                    <MandatoryField
                        isDisabled={state.readOnly}
                        inputLabel="Unit Price"
                        name="partUnitPrice"
                        type="number"
                        placeholder="Price"
                        onChange={handleInputChange}
                        value={state.partUnitPrice}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} className="mandatory-fields">
                    <MandatoryField
                        isDisabled={state.readOnly}
                        inputLabel="Customer Unit Price"
                        name="vehicleJobPartCustomerUnitPrice"
                        type="number"
                        placeholder="Price"
                        onChange={handleInputChange}
                        value={state.vehicleJobPartCustomerUnitPrice}
                    />
                </Grid>
                <Grid item>
                    <div className="input-style">
                        <FormHelperText shrink className="input_label" style={{ textAlign: 'right', marginRight: '15px' }}>
                            Total
                        </FormHelperText>
                        <div className="input_value" style={{ textAlign: 'right' }}>
                            {' '}
                            {formatters.CurrencyNumberWithPound(state.jobItemCustomerPartsPrice) || 0}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className="submit_btn" style={{ marginTop: 18 }}>
                    <SecondaryButton disabled={state.readOnly} onClick={addPartToLocally}>
                        {state.partId ? 'Update' : 'Add'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
