import { Button, ButtonGroup, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { DatePicker, MandatoryField, SecondaryButton, SimpleField } from '../../../Core/Controls';
import { postHolidayUserData } from '../../../Core/Services/ServiceAgent/Holiday.js';
import { useToast } from '../../../Core/Hooks/useToast.js';
import moment from 'moment';

const HolidayModal = ({ selectedDate, user, userData, onClose }) => {
    const [state, setState] = useState({
        holidayDate: userData.holidayDate ? moment(userData.holidayDate).format('YYYY-MM-DD') : selectedDate,
        holidayEndDate: userData.holidayEndDate ? moment(userData.holidayEndDate).format('YYYY-MM-DD') : selectedDate,
        holidayType: userData.holidayType || 3,
        holidayNotes: userData.holidayNotes || '',
        userHolidayUserID: userData.userHolidayUserID || user,
        holidayID: userData.holidayID || null,
        errors: {}
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'holidayDate') {
            setState((st) => ({ ...st, holidayEndDate: moment(value).format('YYYY-MM-DD') }));
        }
        setState((st) => ({ ...st, [name]: value }));
    }, []);
    const handleType = useCallback((val) => {
        setState((st) => ({ ...st, holidayType: val }));
    }, []);

    let validation = () => {
        let isValid = true;
        let errors = {};
        const { holidayType, holidayNotes, holidayDate } = state;
        // if (!holidayType) {
        //     errors.holidayType = 'This Field is Required';
        //     isValid = false;
        // }
        // if (!holidayNotes) {
        //     errors.holidayNotes = 'This Field is Required';
        //     isValid = false;
        // }
        if (!holidayDate) {
            errors.holidayNotes = 'This Field is Required';
            isValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const submitFrom = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                holidayDate: state.holidayDate,
                holidayType: state.holidayType,
                holidayNotes: state.holidayNotes,
                userHolidayUserID: state.userHolidayUserID,
                holidayID: state.holidayID,
                holidayEndDate: state.holidayEndDate
            };

            let res = await postHolidayUserData(data);
            if (res.success) {
                showToastSuccess(res?.data?.message);
                onClose(true);
            } else {
                showToastError(res?.data?.message);
                onClose(false);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <InputLabel shrink error>
                    Start Date
                </InputLabel>
                <DatePicker
                    value={state.holidayDate}
                    onChange={inputChange}
                    name="holidayDate"
                    error={state.errors.holidayDate}
                    InputProps={{ inputProps: { max: `${state.holidayEndDate}`, min: selectedDate } }}
                    autoFocus={true}
                />
                <FormHelperText error>{state.errors.holidayDate}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <InputLabel shrink>End Date</InputLabel>
                <DatePicker
                    value={state.holidayEndDate}
                    onChange={inputChange}
                    name="holidayEndDate"
                    error={state.errors.holidayEndDate}
                    InputProps={{ inputProps: { min: `${state.holidayDate}` } }}
                />
                <FormHelperText error>{state.errors.holidayDate}</FormHelperText>
            </Grid>
            {/* <Grid item xs={6}>
                <ButtonGroup color="primary">
                    <Button
                        variant={state.holidayType === 1 ? 'contained' : 'outlined'}
                        onClick={() => {
                            handleType(1);
                        }}
                    >
                        AM
                    </Button>
                    <Button
                        variant={state.holidayType === 2 ? 'contained' : 'outlined'}
                        onClick={() => {
                            handleType(2);
                        }}
                    >
                        PM
                    </Button>
                    <Button
                        variant={state.holidayType === 3 ? 'contained' : 'outlined'}
                        onClick={() => {
                            handleType(3);
                        }}
                    >
                        Full Day
                    </Button>
                </ButtonGroup>
                <FormHelperText error>{state.errors.holidayType}</FormHelperText>
            </Grid> */}
            <Grid item xs={12}>
                <SimpleField
                    inputLabel="Notes"
                    multiline
                    rows={4}
                    onChange={inputChange}
                    name="holidayNotes"
                    value={state.holidayNotes}
                    // errors={state.errors.holidayNotes}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitFrom}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default HolidayModal;
