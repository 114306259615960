import { FormHelperText, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { formatters, MandatoryField, SecondaryButton } from '../../../Core/Controls';
import { PartToBeOrdered } from '../../../Core/Services/Admin/partService';
import { useToast } from '../../../Core/Hooks/useToast';

const OrderParts = (props) => {
    const [state, setState] = useState({
        partNumber: props.data?.partNumber || 'N/A',
        description: props.data?.description || 'N/A',
        vehicleJobPartID: props.data?.vehicleJobPartID || '',
        message: '',
        partToBeOrdered: props.data?.QtyOrdered || 0,
        user: props.data?.User,
        ordered: props.data?.Ordered,
        isDisabled: props.data?.User
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let { partToBeOrdered, message } = state;
        let noErrors = true;
        let err = {};
        if (!partToBeOrdered || partToBeOrdered == 0) {
            err.partToBeOrdered = 'Part to be ordered is required';
            noErrors = false;
        }
        if (!/[^\s]/.test(message)) {
            err.message = 'Message is required';
            noErrors = false;
        }

        setErrors(err);
        return noErrors;
    };
    const handleData = async () => {
        if (validate()) {
            let data = {
                partToBeOrdered: state.partToBeOrdered,
                partNumber: state.partNumber,
                partDesc: state.description,
                message: state.message,
                vehicleJobPartID: state.vehicleJobPartID
            };
            // console.log(data, 'data');
            let res = await PartToBeOrdered(data);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose();
            } else {
                showToastError(res.message);
            }
        }
    };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={6} item className="typo">
                    <FormHelperText>Part Number</FormHelperText>
                    <Typography>{state.partNumber}</Typography>
                </Grid>
                <Grid xs={6} item className="typo">
                    <FormHelperText>Description</FormHelperText>
                    <Typography>{state.description}</Typography>
                </Grid>
                {state.user && (
                    <Grid xs={6} item className="typo">
                        <FormHelperText>Ordered By</FormHelperText>
                        <Typography>{state.user}</Typography>
                    </Grid>
                )}
                {state.ordered && (
                    <Grid xs={6} item className="typo">
                        <FormHelperText>Ordered On</FormHelperText>
                        <Typography>{formatters.DateTimeFormatter(state.ordered)}</Typography>
                    </Grid>
                )}
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        type="number"
                        inputLabel="Quantity"
                        name="partToBeOrdered"
                        placeholder="Quantity"
                        value={state.partToBeOrdered || ''}
                        onChange={valueChanged}
                        errors={errors.partToBeOrdered}
                        autoFocus={true}
                        disabled={state.isDisabled}
                    />
                </Grid>
                {!state.isDisabled && (
                    <Grid item xs={12} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Message"
                            name="message"
                            placeholder="Message"
                            value={state.message || ''}
                            onChange={valueChanged}
                            multiline
                            rows={4}
                            errors={errors.message}
                        />
                    </Grid>
                )}
                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton onClick={handleData} disabled={state.isDisabled}>
                        Send Request To Order Parts
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default OrderParts;
