import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getAllScheduleEvents = async () => {
    let res = await getWithAuth(`ScheduleEvents`);
    return res;
};
export const getScheduleEventsById = async (ID) => {
    let res = await getWithAuth(`ScheduleEvents/${ID}`);
    return res;
};
export const getAllDropdowns = async () => {
    let res = await getWithAuth(`ScheduleEvents/screenDropdowns`);
    return res;
};

export const postScheduleEvents = async (data) => {
    let res = await postWithAuth(`ScheduleEvents`, data);
    return res;
};
