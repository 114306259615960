import { Button, Checkbox, Grid, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import React, { useCallback, useEffect } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Multiselect(props) {
    let { isReadOnlyUser } = useLoginInfo();
    props.forceEditable && (isReadOnlyUser = false);

    return (
        <Autocomplete
            disabled={isReadOnlyUser}
            {...props}
            multiple
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
}
// export default Multiselect;

const CustomPaper = (paper_props, name) => {
    let btnClicked = (type) => () => {
        document.dispatchEvent(new CustomEvent(`${name}:multiselect:button_clicked`, { detail: { type } }));
    };
    return (
        <Grid container spacing={0}>
            <Grid item container xs={12} onMouseDown={(e) => e.preventDefault()}>
                {/* <Paper elevation={1}> */}
                <Grid item xs={12} style={{ background: 'white', minHeight: 150 }}>
                    {paper_props.children}
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: '#FF474D', textAlign: 'right', padding: '5px', zIndex: 99 }}>
                    <Button onClick={btnClicked('SelectAll')} style={{ backgroundColor: 'white', color: 'black', marginTop: 2, marginRight: 20 }}>
                        Select All
                    </Button>
                    <Button onClick={btnClicked('clear')} style={{ backgroundColor: 'white', color: 'black', marginTop: 2, marginRight: 20 }}>
                        clear
                    </Button>
                </Grid>
                {/* </Paper> */}
            </Grid>
        </Grid>
    );
};

export const MultiselectWithCheckbox = (props) => {
    let { selectAll, ...rest } = props;
    useEffect(() => {
        const handler = (ev) => {
            switch (ev.detail.type) {
                case 'SelectAll':
                    selectAll('SelectAll', props.name);
                    break;
                case 'clear':
                    selectAll('clear', props.name);
                    break;

                default:
                    // Handle the default case here
                    console.log('Invalid');
                    break;
            }
        };
        document.addEventListener(`${props.name}:multiselect:button_clicked`, handler);
        return () => {
            document.removeEventListener(`${props.name}:multiselect:button_clicked`, handler);
        };
    }, [selectAll]);

    const CustomPaperWrapper = useCallback((paper_props) => {
        return CustomPaper(paper_props, props.name);
    }, []);

    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.name === value.name}
            PaperComponent={CustomPaperWrapper}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};
