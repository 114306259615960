import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import JobDetail from '../Controllers/JOBS/JOBItems/JobDetails';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';
import StickyNotes from '../Controllers/Notes/stickyNotes';
import { Button, ButtonGroup, Grid } from '@material-ui/core';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails';
import DialogComp from '../../../Core/Dialog/DialogComp';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../Core/Basic/storage-service';

let final = [
    { name: 'Home', path: '/' },
    { name: 'Invoices', active: true }
];
const getCrumbs = (code) => {
    let newColumn = [...final];
    if (code === 'P') {
        newColumn.splice(3, 0, { name: 'Sales-Paid', active: true });
    } else if (code === 'U') {
        newColumn.splice(3, 0, { name: 'Sales-Unpaid', active: true });
    } else if (code === 'H') {
        newColumn.splice(3, 0, { name: 'Sales-Hold', active: true });
    } else {
        newColumn.splice(2, 0, { name: 'Sales-All', active: true });
    }
    return newColumn;
};

const basecolumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'ino', name: 'Number', width: 80 },
    { key: 'idate', name: 'Invoice Date', width: 110, formatter: formatters.Date_DDMMYYYY },
    { key: 'dd', name: 'Due Date', width: 90, formatter: formatters.Date_DDMMYYYY },
    { key: 'poNumber', name: 'Purchase Order Number', width: 180 },
    { key: 'status', name: 'Status', width: 140 },
    { key: 'jobRef', name: 'Job Reference', width: 130 },
    { key: 'c', name: 'Customer Name', width: 300 },
    { key: 'scs', name: 'Sage Code Customer', width: 160 },
    { key: 'sci', name: 'Sage Code Invoice', width: 160 },
    { key: 'total', name: 'Total', width: 80, formatter: formatters.NumberWithPound, align: 'right' }
    // { key: 'c', name: 'Customer' }
];

const SalesInvoiceScreen = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        modalToggle: false,
        invoiceID: null,
        selectedRowData: {},
        VehicleID: props.VehicleID || null,
        ServiceAgentID: props.ServiceAgentID || null,
        isReload: null,
        statusCode: props.statusCode || ''
    });
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const crumbs = getCrumbs(state.statusCode);
    const history = useHistory();

    useEffect(async () => {
        let getUrl;
        if (props.fromPage === 'job') {
            getUrl = `Invoices/SalesInvoiceByStatus${props.JobID ? `?JobID=${props.JobID}` : ''}`;
        } else {
            if (props.VehicleID) {
                getUrl = `Invoices/SalesInvoiceByStatus${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}`;
            } else {
                getUrl = `Invoices/SalesInvoiceByStatus?status=${state.statusCode}`;
            }
        }
        setState((st) => ({
            ...st,
            getUrl: getUrl
        }));
    }, [state.statusCode]);

    const pullInvoiceData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regno}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.c}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const actionButton = (data, row) => {
        return (
            <div className="Invoice-btn-update">
                <EditButton onClick={() => handleInvoiceUpdate(row)} toolTipTitle="Edit Invoice" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let customerNameCol = tempCols.find((element) => element.key === 'c');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (!props.VehicleID || props.JobID) {
            tempCols.splice(1, 0, { key: 'regno', name: 'Reg Number', width: 110 });
        }
        if (state.statusCode != 'U') {
            tempCols.splice(5, 0, { key: 'dp', name: 'Date Paid', width: 90, formatter: formatters.Date_DDMMYYYY });
        }
        let regNumberCol = tempCols.find((element) => element.key === 'regno');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        return tempCols;
    }, []);

    const handleInvoiceUpdate = (val) => {
        history.push({
            pathname: `/update_sales_invoice/${val.invoiceID}`,
            search: ''
        });
    };
    const handleChange = (statusID) => {
        setState((st) => ({ ...st, statusCode: statusID }));
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false
        }));
    };
    return (
        <div className={props.fromPage === 'job' || props.VehicleID ? '' : 'screen'}>
            <>
                {!props.VehicleID && (
                    <Grid container justify="space-between">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs} />
                        </Grid>
                        <Grid item>
                            <Grid component="label" item alignItems="center">
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={state.statusCode === '' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('')}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={state.statusCode === 'P' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('P')}
                                    >
                                        Paid
                                    </Button>
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={state.statusCode === 'U' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('U')}
                                    >
                                        Unpaid
                                    </Button>
                                    <Button
                                        style={{ fontSize: 12 }}
                                        variant={state.statusCode === 'H' ? 'contained' : 'outlined'}
                                        onClick={() => handleChange('H')}
                                    >
                                        Hold
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {props.JobID && <JobDetail scheduleJobID={props.JobID} refresherFlag={state.refresherFlag} VehicleID={props.VehicleID} />}
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data?.list?.map((m) => ({ ...m, total: m.vat + m.net })),
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={state.getUrl}
                    idFieldName={`${checkhaveAccess?.CanUploadSalesInvoiceDocuments ? 'invoiceID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanUploadSalesInvoiceDocuments ? 'SalesInvoice' : null}`}
                    isReload={state.isReload}
                    offset={props.fromPage === 'job' ? 410 : 350}
                />
            </>
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default SalesInvoiceScreen;
