import React, { useEffect, useState, useMemo } from 'react';
import { getAllVehicle } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../Core/Hooks/useToast';
import { EditButton, DataGrid, formatters, BreadCrumbs } from '../../../Core/Controls';
import DriverDailyChecks from '../../InternalUser/Controllers/Vehicles/DriverDailyChecks/driverDailyChecks';
import AddIcon from '@material-ui/icons/Add';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { Button } from '@material-ui/core';

// import '../../commonStyle.scss';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 30,
        addFileUploaderIcon: true
    },
    { key: 'vehicleRegNumber', name: 'Reg No', width: 50 },
    { key: 'vehicleMakeModel', name: 'Vehicle Description', width: 200 },
    {
        key: 'vehicleDateOfRegistration',
        name: 'Date Of Reg',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    {
        key: 'vehicleMOTExpiry',
        name: 'MOT Expiry',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    {
        key: 'vehicleTaxDue',
        name: 'Tax Due',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    // { key: 'customerCompanyName', name: 'Customer', width: 100 },
    { key: 'depotName', name: 'Depot', width: 100 }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', path: '/controller/Vehicles', active: true }
];

const DriverVehicleScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        showLoader: true,
        vehicleList: [],
        VehicleID: null
    });

    const pullVehicleAndUpdateState = async () => {
        let res = await getAllVehicle();
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, vehicleList: res.data.vehicles }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullVehicleAndUpdateState();
    }, []);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            VehicleID: record.vehicleID
        }));
    };

    const closeVehicleModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullVehicleAndUpdateState();
    };

    const dataFormatter = (data, row) => {
        // const val = data.row;
        return (
            <div style={{ textAlign: 'center' }}>
                {/* <Button onClick={() => handleAdd(data.row)}>
                    <AddIcon />
                </Button> */}
                <CustomIconButton
                    icon={AddIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    toolTipTitle="Add Checks"
                    onClick={() => handleAdd(row)}
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);
    const title = state.VehicleID ? 'Update Vehicle' : 'Add Vehicle';
    return (
        <div className="screen">
            {state.show ? (
                <DriverDailyChecks VehicleID={state.VehicleID} onFormSubmit={() => closeVehicleModal(true)} onClose={closeVehicleModal} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    {/* <DataGrid rows={state.vehicleList} columns={columns} enableSearch={true} loadingData={state.showLoader} paginate offset={220} /> */}
                    <GijgoGrid
                        columns={columns}
                        getUrl={`vehicles?mode=`}
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};
export default DriverVehicleScreen;
