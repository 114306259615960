import React, { useRef, useState } from 'react';
import './custStyle.scss';
import SimpleField, { MandatoryField } from '../Inputs/MandatoryField';
import { InputAdornment, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CustomerDetailsTable from '../Table/CustomerDetailsTable';
import { getCustomerAddressDetails } from '../../Services/InternalUser/postCodeService';
import useClickOutside from '../../Hooks/useClickOutside';

export default function CustomerAddressDetails(props) {
    const { name, value, onChange, getVal } = props;

    const [state, setState] = useState({
        showDetails: false,
        searchValue: '',
        customerList: []
    });

    const detailsRef = useRef();

    const pullAddress = (value) => {
        async function fetchData() {
            try {
                // if (value) {
                let res = await getCustomerAddressDetails(value);
                setState((st) => ({
                    ...st,
                    showDetails: true,
                    customerList: res.data.list ? [...res.data.list] : []
                }));
                // }
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    };

    const handleSearch = () => {
        pullAddress(value);
    };
    const closeDetails = () => {
        setState((st) => ({
            ...st,
            showDetails: false
        }));
    };

    useClickOutside(detailsRef, closeDetails);

    return (
        <div className="Maindiv">
            <SimpleField
                inputLabel="Customer Address"
                placeholder="Customer address"
                value={value}
                onChange={onChange}
                name={name}
                autoComplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Search Address" arrow>
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={handleSearch} />
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                {...props}
            />
            {state.showDetails ? (
                <div className="customScroll" ref={detailsRef}>
                    <CustomerDetailsTable onClose={closeDetails} List={state.customerList} getDetailsValue={getVal} />
                </div>
            ) : null}
        </div>
    );
}
