import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../../../Core/Controls/Grid';
import { getAllVehicleCheckDaily } from '../../../../Core/Services/InternalUser/Maintenance/VehicleCheckDaily';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const crumbs = [{ name: 'Home', path: '/' }, { name: 'Maintenance' }, { name: 'Vehicle Checks', path: '/', active: true }];

const baseColumns = [
    { key: 'action', name: 'Action', width: 110, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg No' },
    { key: 'vehicleDailyCheckDateCheck', name: 'Date of check', formatter: formatters.Date_DDMMYYYY },
    { key: 'vehicleDailyCheckResult', name: 'Result' },
    { key: 'vehicleDailyCheckMileage', name: 'mileage ' },
    { key: 'vehicleDailyCheckDriverComment', name: 'Driver comments' }
];

const CustomerVehicleCheckDaily = (props) => {
    const [state, setState] = useState({ modalToggle: false, rowVehicleID: null, defectID: null, List: [], showLoader: true });

    useEffect(async () => {
        pullDefects();
    }, []);

    const pullDefects = async () => {
        let res = await getAllVehicleCheckDaily();
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                List: res.data.list,
                showLoader: false,
                fromPage: props.frompage
            }));
        }
    };

    const renderEdit = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <EditButton toolTipTitle="Edit Check" onClick={() => alert('Update Pending')} />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    return (
        <div className={!props.VehicleID ? `screen` : ''} style={{ marginTop: 8 }}>
            {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}

            {state.modalToggle ? (
                <h6>Update popup</h6>
            ) : (
                <DataGrid
                    columns={columns}
                    className="custom-scroll"
                    rows={state.List}
                    enableSearch={true}
                    loadingData={state.showLoader}
                    isRowHovered={true}
                    paginate
                    offset={210}
                />
            )}
        </div>
    );
};

export default CustomerVehicleCheckDaily;
