import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, YesButton, NoButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';

import moment from 'moment';
import { ContractSetOffHire } from '../../../../../Core/Services/InternalUser/InternalDashService';

const ContractSetOffHireModal = (props) => {
    const { authorityReference, winterEndDate } = props;

    console.log(winterEndDate, 'winterEndDate');
    const [state, setState] = useState({
        errors: {},
        contractOffHireDate: winterEndDate ? moment(winterEndDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        contractOffHireAuthorityReference: authorityReference || ''
        // contractPaused: false
    });

    console.log(state.contractOffHireDate, 'contractOffHireDate');
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { contractOffHireDate } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractOffHireDate) {
            errors.contractOffHireDate = 'Date is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            let promises = props.checkedVehicleList.map((q) => {
                return ContractSetOffHire({
                    contractVehicleID: q,
                    contractOffHireDate: state.contractOffHireDate,
                    contractOffHireAuthorityReference: state.contractOffHireAuthorityReference
                });
            });

            try {
                let responses = await Promise.all(promises);

                // Check if all responses are successful
                let allSuccessful = responses.every((response) => response.success);

                if (allSuccessful) {
                    showToastSuccess('Vehicle delivery details saved successfully.');
                    props.onClose(true);
                } else {
                    // Find out which responses failed
                    let failedResponses = responses.filter((response) => !response.success);
                    showToastError(`Error: ${failedResponses.length} items failed.`);
                }
            } catch (error) {
                // Handle any other errors that may occur
                showToastError(`An error occurred: ${error.message}`);
            }
            setBtnLoader(false);
        }
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Winter Off Hire Date</InputLabel>
                <DatePicker name="contractOffHireDate" placeholder="Off Hire Date" value={state.contractOffHireDate} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.contractOffHireDate}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Off Hire Authority Reference</InputLabel>
                <InputText
                    placeholder="Off Hire Authority Reference"
                    value={state.contractOffHireAuthorityReference}
                    onChange={onFieldChange}
                    name="contractOffHireAuthorityReference"
                />
                <FormHelperText error>{state.errors.contractOffHireAuthorityReference}</FormHelperText>
            </Grid>
            {/* <Grid xs={12} container item>
                <InputLabel shrink>Contract Paused ?</InputLabel>
                <Grid xs={12} container item spacing={1}>
                    <Grid item xs={6}>
                        <YesButton state={state.contractPaused} name="contractPaused" onClick={() => handleClick('contractPaused', true)} />
                    </Grid>
                    <Grid item xs={6}>
                        <NoButton state={state.contractPaused} name="contractPaused" onClick={() => handleClick('contractPaused', false)} />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetOffHireModal;
