import { Hidden, TableRow, Container, Grid } from '@material-ui/core';
import React, { memo, useContext } from 'react';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

export const AddCheckTableHeader = memo(({ m, PassAllbutton, className }) => {
    const { flags, changeAllChildChecks } = useContext(InspectionCheCkContaxt);
    return (
        <>
            <Hidden only={['md', 'sm', 'xs']}>
                {flags.displayStartEvent ? (
                    <Grid container spacing={2} style={{ background: '#f5f5f5' }}>
                        <Grid item xs={5}>
                            <b style={{ marginLeft: 10, color: '#f50057' }}>Check</b>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}>
                            <PassAllbutton
                                state={m.state}
                                onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                                style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px 0px' }}
                            />
                            <b style={{ color: '#f50057' }}>Action</b>
                        </Grid>
                        <Grid item xs={5}>
                            <b style={{ color: '#f50057' }}></b>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', background: '#f5f5f5' }}>
                        <Grid item xs={5}>
                            <b style={{ marginLeft: 10, color: '#f50057' }}>Check</b>
                        </Grid>
                        <Grid item xs={1}>
                            <PassAllbutton
                                state={m.state}
                                onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                                style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px 0px' }}
                            />
                            <b style={{ color: '#f50057' }}>Action</b>
                        </Grid>
                        <Grid item xs={5}>
                            <b style={{ color: '#f50057' }}></b>
                        </Grid>
                    </Grid>
                )}
            </Hidden>

            <Hidden only={['lg', 'xl']}>
                <Grid container spacing={2} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                    <Grid item xs={12}>
                        <PassAllbutton
                            state={m.state}
                            onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                            style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px 0px' }}
                        />
                        <b>Action</b>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
});
